import React from 'react';
import Loader from '../../../../components/src/Loader';
import NutrationGapCalculatorWebController, { Props } from './NutrationGapCalculatorWebController';
import { Typography, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link } from "react-router-dom";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import { topHeaderBanner, sideBarBanner } from '../../../ProductRecommendationEngine/src/assets'
import { bonePain, lowImmunity, fatigue, symptomShape } from '.././assets'
import CustomizedToggleSwitche from '../../../../components/src/CustomizedToggleSwitche';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '1000px',
            backgroundSize: 'inherit',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
        },
        profileTopBanner: {
            height: '200px',
            backgroundImage: `url(${topHeaderBanner})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },

        headingTextContainer: {
            display: "flex",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        pageHeadingText: {
            fontFamily: `'Montserrat', sans-serif`,
            textTransform: 'capitalize',
            fontWeight: 600,
            color: '#37006E',
            lineHeight: "33px",
            fontSize: "25px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },
        backButtonText: {
            fontFamily: "Poppins",
            color: "#FF5050",
            lineHeight: "12px",
            fontStyle: "normal",
            fontSize: "22px",
            fontWeight: 600,
            cursor: 'pointer',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
            },
        },


        productButton: {
            width: '80%',
            fontFamily: `'Montserrat', sans-serif`,
            textTransform: 'uppercase',
            color: '#FFFFFF',
            fontStyle: 'normal',
            fontWeight: 600,
            textAlign: 'center',
            borderRadius: "44px",
            fontSize: '22px',
            margin: '2rem 0',
            background: '#38006D',
            padding: '15px 80px',
            '&:hover': {
                border: 'none',
                background: '#12125c',
            },
            [theme.breakpoints.down("md")]: {
                fontSize: '15px',
                padding: '12px 60px',
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: '12px',
                padding: '8px 50px',
            },
        },



        // bml health card end
        AccordionSummaryTextStyle: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontStyle: "normal",
            color: "#455A64",
            fontSize: 25,
            margin: '2rem 12px',
            [theme.breakpoints.down("sm")]: {
                fontSize: 16,
                fontWeight: 500,
                margin: 5,
            },
        },
        AccordionTitleTextStyle: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontStyle: "normal",
            color: "#455A64",
            fontSize: 25,
            margin: '0.7rem 12px',
            [theme.breakpoints.down("sm")]: {
                fontWeight: 500,
                margin: 5,
                fontSize: 16,
            },
        },
        AccordionDetailStyle: {
            fontFamily: "Poppins",
            color: "#455A64",
            fontStyle: "normal",
            fontSize: 25,
            fontWeight: 500,
            lineHeight: 2,
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                fontSize: 14,
                fontWeight: 500,
            },
        },

        AccordionBottomLinkText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            color: "#FF6B00",
            fontSize: 20,
            lineHeight: 2,
            fontWeight: 500,
            textDecoration: 'none',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down("sm")]: {
                fontSize: 13,
                fontWeight: 500,
            },
        },
        AccordionDetailBoxStyle: {
            display: "flex",
            alignItems: 'center',
            flexDirection: "column",
            padding: '1rem'
        },
        AccordionStyle: {
            background: '#FFFFFF',
            marginBottom: 34,
            borderRadius: '5px',
            border: '0.3px solid #757D8A',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
                marginLeft: "auto",
                marginRight: "auto",
            },
        },

    });




class NutritionGapReportWeb extends NutrationGapCalculatorWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
        } = this.state;
        const { classes } = this.props;
        if (loading) {
            return (
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={loading} />
                </Grid>
            );
        }

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} >
                        <div className={classes.profileTopBanner}>
                        </div>
                        <div className={classes.addNewMemberContainer}>
                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText='BMI Result' rightToggleText='Nutritional Gap' />
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>Nutrition gap report</Typography>
                                <Typography className={classes.backButtonText} onClick={() => this.backButton()}>
                                    Back
                                </Typography>
                            </div>
                            <div style={{ margin: '2rem 0' }}>

                                <Accordion className={classes.AccordionStyle} expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon style={{
                                            backgroundColor: "#FCC300", borderRadius: "50%", color: '#ffff'
                                        }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <img src={symptomShape} /> <Typography className={classes.AccordionTitleTextStyle}>
                                            Protein (g) <span style={{ paddingLeft: '1rem', fontSize: '18px', fontWeight: 400 }}>RDA 54%</span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.AccordionDetailStyle}>
                                        <div className={classes.AccordionDetailBoxStyle}>
                                            <img style={{ width: '150px' }} src={bonePain} />
                                            <Typography className={classes.AccordionSummaryTextStyle}>
                                                Bone & Joint Pain
                                            </Typography>

                                        </div>
                                        <div className={classes.AccordionDetailBoxStyle}>
                                            <img style={{ width: '150px' }} src={lowImmunity} />
                                            <Typography className={classes.AccordionSummaryTextStyle}>
                                                Low immunity
                                            </Typography>

                                        </div>
                                        <div className={classes.AccordionDetailBoxStyle}>
                                            <img style={{ width: '150px' }} src={fatigue} />
                                            <Typography className={classes.AccordionSummaryTextStyle}>
                                                Fatigue
                                            </Typography>

                                        </div>

                                    </AccordionDetails>
                                    <AccordionDetails style={{ justifyContent: 'center' }}>
                                        <Link to='#' className={classes.AccordionBottomLinkText}>Know More..</Link>
                                    </AccordionDetails>

                                </Accordion>


                            </div>

                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    className={classes.productButton} onClick={() => this.navigationBtnFnc('FavouriteSmartSearchWeb')}>
                                    Smart Search
                                </Button>
                            </div>
                        </div>
                    </Grid >
                </Grid >
            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    NutritionGapReportWeb
);