import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";



// Customizable Area Start
import React from 'react';
import "./CompareProducts.web.css";
import {FavouriteProductContext} from "./context/FavouriteProductContext"
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t:any;
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  data1: boolean;
  data2: boolean;
  data3: boolean;
  data4: boolean;
  data5: boolean;
  isOpen: boolean;
  allComparedProducts: any;
  tableComapreProducts: any;
  activeProduct: any;
  keyValues: any;
  allFavProducts: any;
  alertData: boolean;
  addFoodBasketloader: any;
  foodBasketLists: any;
  addFoodBasketErrorText: any;
  foodBasketListLoader: boolean;
  noFoodBasketNotification: any;
  foodProductDetailsLoader: boolean;
  foodProductDetails: any;
  foodProductNutritionValue: any;
  addFoodBasketApiResponseText: any
  modalOpen: boolean
  negativeKeyValues: any;
  isLoading: any;
  negValues: any;
  IsRemoveProduct:boolean;
  removeProductID:any;
  emptyListMessage:any;
  selectedProductId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CompareProductsWebController extends BlockComponent<
  Props,
  S,
  SS
> {


  // Customizable Area Start
  getAllCompareProductsId: any;
  deleteCompareProductsId: any;
  addToCompareProductsId: any;
  getCompareProductsId: any;

  PostID: string = '';
  PostDeleteID: string = '';
  addFoodBasketmessageId: any;
  deletefoodBasketApiCallId: string = '';
  getFoodBasketApiCallId: string = '';
  getHealthFoodDetailsApiCallId: string = '';
  updateFoodBasketmessageId: any;
  static contextType:any = FavouriteProductContext;


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      modalOpen: false,
      data1: true,
      data2: true,
      data3: true,
      data4: true,
      data5: true,
      isOpen: false,
      allComparedProducts: [],
      tableComapreProducts: [],
      activeProduct: '',
      allFavProducts: [],
      keyValues: [],
      alertData: false,
      addFoodBasketloader: false,
      foodBasketLists: [],
      addFoodBasketErrorText: '',
      foodBasketListLoader: false,
      noFoodBasketNotification: '',
      foodProductDetailsLoader: false,
      foodProductDetails: [],
      addFoodBasketApiResponseText: '',
      foodProductNutritionValue: '',
      negativeKeyValues: [],
      isLoading: false,
      negValues: ['calories', 'total_fat', 'saturate_fat', 'cholestrol', 'sodium', 'trans_fat', 'sugar'],
      IsRemoveProduct:false,
      removeProductID:"",
      emptyListMessage:"",
      selectedProductId: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }

    //Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllCompareProductsId &&
      this.getAllCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({ allComparedProducts: responseJson.data })
      }
      if (responseJson?.error) {
      }
    }


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteCompareProductsId &&
      this.deleteCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(responseJson?.success) {
        this.state.tableComapreProducts?.length < 1 && this.getCompareProducts()
      }

    }


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addToCompareProductsId &&
      this.addToCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        console.log(responseJson.data, 'data__added')
      }
      if (responseJson?.error) {
        console.log(responseJson.error, 'data__added')
      }

    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCompareProductsId &&
      this.getCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        let newData: any = await responseJson.data.map((e: any, i: any) => {
          return e?.data?.attributes?.positive_good
        })
        let populateValues1: any;
        let populateValues2: any;
        let populateValues3: any;
        let populateValues4: any;
        let populateValues5: any;

        if (responseJson?.data?.length === 1) {
          populateValues1 = await Object.entries(newData[0]).map(([key, value]) => {
            return { key, value }
          })
        } else if (responseJson?.data?.length === 2) {
          populateValues1 = await Object.entries(newData[0]).map(([key, value]) => {
            return { key, value }
          })
          populateValues2 = await Object.entries(newData[1]).map(([key, value]) => {
            return { key, value }
          })
        } else if (responseJson?.data?.length === 3) {
          populateValues1 = await Object.entries(newData[0]).map(([key, value]) => {
            return { key, value }
          })
          populateValues2 = await Object.entries(newData[1]).map(([key, value]) => {
            return { key, value }
          })
          populateValues3 = await Object.entries(newData[2]).map(([key, value]) => {
            return { key, value }
          })
        }
         else if (responseJson?.data?.length === 4) {
          populateValues1 = await Object.entries(newData[0]).map(([key, value]) => {
            return { key, value }
          })
          populateValues2 = await Object.entries(newData[1]).map(([key, value]) => {
            return { key, value }
          })
          populateValues3 = await Object.entries(newData[2]).map(([key, value]) => {
            return { key, value }
          })
          populateValues4 = await Object.entries(newData[3]).map(([key, value]) => {
            return { key, value }
          })
        }
         else if (responseJson?.data?.length === 5) {
          populateValues1 = await Object.entries(newData[0]).map(([key, value]) => {
            return { key, value }
          })
          populateValues2 = await Object.entries(newData[1]).map(([key, value]) => {
            return { key, value }
          })
          populateValues3 = await Object.entries(newData[2]).map(([key, value]) => {
            return { key, value }
          })
          populateValues4 = await Object.entries(newData[3]).map(([key, value]) => {
            return { key, value }
          })
          populateValues5 = await Object.entries(newData[4]).map(([key, value]) => {
            return { key, value }
          })
        }

        let filterValues1: any;
        let filterValues2: any;
        let filterValues3: any;
        let filterValues4: any;
        let filterValues5: any;

        if (responseJson?.data?.length === 1) {
          filterValues1 = await populateValues1.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
        } else if (responseJson?.data?.length === 2) {
          filterValues1 = await populateValues1.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues2 = await populateValues2.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
        } else if (responseJson?.data?.length === 3) {
          filterValues1 = await populateValues1.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues2 = await populateValues2.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues3 = await populateValues3.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })        
        } else if (responseJson?.data?.length === 4) {
          filterValues1 = await populateValues1.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues2 = await populateValues2.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues3 = await populateValues3.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues4 = await populateValues4.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
        }else if (responseJson?.data?.length === 5) {
          filterValues1 = await populateValues1.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues2 = await populateValues2.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues3 = await populateValues3.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues4 = await populateValues4.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
          filterValues5 = await populateValues5.filter((e: any, i: any) => {
            return e.value[0]?.level != 'N/A'
          })
        }

        let demoValues: any;
        if (responseJson?.data?.length === 1) {
          demoValues = [...filterValues1]
        } else if (responseJson?.data?.length === 2) {
          demoValues = [...filterValues1, ...filterValues2]
        } else if (responseJson?.data?.length === 3) {
          demoValues = [...filterValues1, ...filterValues2, ...filterValues3]
        }
        else if (responseJson?.data?.length === 4) {
          demoValues = [...filterValues1, ...filterValues2, ...filterValues3, ...filterValues4]
        }
        else if (responseJson?.data?.length === 5) {
          demoValues = [...filterValues1, ...filterValues2, ...filterValues3,...filterValues4,...filterValues5]
        }
        let uniqueNames = await demoValues.filter((ele: any, ind: any) => ind === demoValues.findIndex((elem: any) => elem.key === ele.key))

    
        let onlyKeyValues = Object.entries(uniqueNames).map(([key, value]) => {
          // @ts-ignore
          return value?.key
        })
        let posValues = ['Protein', 'Fibre', 'Probiotics'];
        let negValues = [...this.state.negValues];
        let minValues = ['calcium', 'iron', 'magnesium', 'zinc', 'iodine', 'potassium', 'phosphorus', 'manganese', 'copper', 'selenium', 'chromium', 'chloride'];
        let vitValues = ['vit_a', 'vit_c', 'vit_d', 'vit_b6', 'vit_b12', 'vit_b9', 'vit_b2', 'vit_b3', 'vit_b1', 'vit_b5', 'vit_b7'];

        let newposValues = posValues?.filter((e: any) => onlyKeyValues?.includes(e));
        let newnegValues = negValues?.filter((e: any) => onlyKeyValues?.includes(e));
        let newminValues = minValues?.filter((e: any) => onlyKeyValues?.includes(e));
        let newvitValues = vitValues?.filter((e: any) => onlyKeyValues?.includes(e));
        let updatedKeys = [...newposValues, ...newvitValues, ...newminValues, ...newnegValues]

        
        this.setState({ tableComapreProducts: responseJson.data, keyValues: updatedKeys, negativeKeyValues: newData, isLoading: false })
      }      
      if (responseJson?.error) {
        console.log(responseJson.error, 'data1__ error')
       
      }
      if (responseJson?.message) {        
        this.setState({emptyListMessage: responseJson?.message})
        this.setState({isLoading: false})
      }
      //
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PostID &&
      this.PostID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
          this.getCompareProducts();
          this.context?.getFavouriteListAPI();

      }
      if (responseJson?.error) {
        console.log(responseJson.error, 'data__added')

      }

    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PostDeleteID &&
      this.PostDeleteID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.success) {
        this.getCompareProducts();
        this.context?.getFavouriteListAPI();
        console.log(responseJson, 'data__added')


      }
      if (responseJson?.error) {
        console.log(responseJson.error, 'data__added')

      }

    }


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addFoodBasketmessageId &&
      this.addFoodBasketmessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          addFoodBasketloader: false
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }
      if (responseJson?.error) {
        this.setState({
          addFoodBasketloader:false,
          addFoodBasketErrorText: responseJson?.error
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }
    }

    //  <---------- response for  update food basket api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateFoodBasketmessageId &&
      this.updateFoodBasketmessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          addFoodBasketloader: false,
          addFoodBasketApiResponseText: 'Basket name updated'
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }
      if (responseJson?.error) {
        this.setState({
          addFoodBasketloader: false,
          addFoodBasketApiResponseText: responseJson?.error === 'Bad Request' ? 'Please try again' : responseJson?.error,
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }

    }


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFoodBasketApiCallId &&
      this.getFoodBasketApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          foodBasketLists: responseJson?.data,
          foodBasketListLoader: false,
          noFoodBasketNotification: ''
        })
      }
      if (responseJson?.error) {
        this.setState({
          foodBasketListLoader: false,
          noFoodBasketNotification: responseJson.error,
          foodBasketLists: []

        })
      }
    }

    //Customizable Area End

  }



  //Customizable Area Start

  async componentDidMount() {
    
    this.getCompareProducts();
    this.getFoodBasketList();
    let prevData = localStorage.getItem('favList');
    if (prevData) {
      this.setState({ allFavProducts: JSON.parse(prevData) })
    }
  }


  getAllCompareProducts = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.getAllCompareProductsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getcompareApiEndPoint}?language_id=${this.getLanguage()}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteCompareProducts = (id: any) => {
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.deleteCompareProductsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletecompareApiEndPoint}/${id}+id?language_id=${this.getLanguage()}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompareProducts = () => {
     
    this.setState({ isLoading: true })
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.getCompareProductsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getcompareProductsApiEndPoint}?language_id=${this.getLanguage()}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit = async (data: any) => {
    const token = await localStorage.getItem("token")


    const headers = {
      token: token
    };

    const form = new FormData();
    form.append("data[attributes][favourite]", "true");
    form.append("data[attributes][product_id]", data.product_id);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouriteProducts}?language_id=${this.getLanguage()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  deletefavList = async (id: any) => {
    const token = await localStorage.getItem("token")


    const headers = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostDeleteID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/favourite_products/${id}?language_id=${this.getLanguage()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "delete"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true })
  }

  // <---------- API call for Add food basket {rf} ---------->
  addFoodBasket = (data: any) => {

    this.setState({
      addFoodBasketloader: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    const httpBody = {
      data: data
    }

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.addFoodBasketmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.foodBasketApiEndPoint}?language_id=${this.getLanguage()}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFoodBasketList = () => {
    this.setState({
      foodBasketListLoader: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': 'application/json',
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFoodBasketApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.foodBasketApiEndPoint}?language_id=${this.getLanguage()}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigationBtnFnc = (path: any) => {
    this.props?.navigation?.navigate(path);
  }

  handleModalClose = () => {
    this.setState({ addFoodBasketApiResponseText: 'Product added successfully', modalOpen: false })
  }

  // <---------- API call for Add food basket {rf} ---------->
  updateFoodBasketName = (order_name: string, foodBasketId: any) => {

    this.setState({
      addFoodBasketloader: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    const httpBody = {
      data: {
        order_name: order_name
      }
    }

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.updateFoodBasketmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.foodBasketApiEndPoint}/${foodBasketId}?language_id=${this.getLanguage()}` // end point setup      
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getLanguage = () => {
    let languageId:any;
    const defaultEnglishLanguageId:any = configJSON.defaultLanguageIDEnglish        
    
    if(localStorage.getItem('languageSelect')) {
      languageId = localStorage.getItem('languageSelect');
    }
    else {
     languageId = defaultEnglishLanguageId;
    }
    return languageId;
  }

  removeComapreProductHandler = (deleteID:any) => {    
      let activeProducts = this.state.tableComapreProducts;
      let newData = activeProducts.filter((ele: any) => ele.data.attributes.id !=deleteID )
      this.deleteCompareProducts(deleteID)
      this.setState({IsRemoveProduct: false})
      return this.setState({ tableComapreProducts: newData })    
  }
  delteModalClose = () => {
    this.setState({IsRemoveProduct: false})
  }
  deleteModalOpen = (deleteID:any) => {
    this.setState({IsRemoveProduct: true, removeProductID: deleteID})
  }

  handleFavouriteProduct = (productId: any, status: boolean) => {
    this.setState({
      selectedProductId: productId
    });
    if (status) {
      this.deletefavList(productId)
    } else {
      this.handleSubmit({ product_id: productId })
    }
  }
  //Customizable Area End

}