import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
    getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import Cities from './utils/cities';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    t: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
    loading: boolean;
    fullName: string;
    emailId: string;
    relation: any;
    relationId: any;
    relationName: any
    contactNumber: string;
    weight: any;
    height: any;
    personeAge: any;
    activityLevel: any;
    address: string;
    stateName: string;
    pinCode: any;
    cityName: string;
    gender: string;
    personImage: any;
    imageName: any;
    newMemberList: any;
    imageuploadAndDeleteMenu: boolean;
    fullNameValidationError: boolean;
    emailIdValidationError: boolean;
    heightValidationError: boolean;
    weightValidationError: boolean;
    addressValidationError: boolean;
    pinCodeValidationError: boolean;
    stateNameValidationError: boolean;
    personImageValidationError: boolean;
    activityLevelValidationError: boolean;
    contactNumberValidationError: boolean;
    relationNameValidationError: boolean;
    personeAgeValidationError: boolean;
    cityNameValidationError: boolean;
    genderValidationError: boolean;
    memberDetailsLoading: boolean;
    newMemberListLoading: boolean;
    openDeleteModalBox: boolean;
    editProfileImage: boolean;
    isContactNumberValid: any;
    editprofileid: string;
    userProfileInfo: any;
    isEmailIdvalid: any;
    deleteId: any;
    errorReponse: any;
    ageRangeValidationError: boolean;
    isValidImage:boolean;
    open:boolean;
    image:any;
    result:any;
    orignalImage:any;
    cropper:any;
    // Customizable Area End
}

interface SS {
    id: any;
}


export default class UserProfileWebController extends BlockComponent<Props, S, SS> {
    addNewMenberApiCallid: string = '';
    getRelationWithUserApiCallId: string = '';
    getNewMemberListApiCallId: string = '';
    deleteProfileMemberApiCallId: string = '';
    getUserProfileDetailsApiCallId: string = '';
    getNewMemberDetailsApiCallId: string = '';
    updateNewMenberApiCallid: string = '';
    CitiesName: any = [];
    userId: any = '';
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage), //{rf}
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: '',
            loading: false,
            fullName: '',
            emailId: '',
            relation: [],
            relationId: '',
            relationName: 'Select',
            contactNumber: '',
            weight: '',
            height: '',
            personeAge: '',
            activityLevel: 'Select',
            address: '',
            stateName: 'Select',
            pinCode: '',
            cityName: 'Select',
            gender: 'Select',
            personImage: {},
            imageName: '',
            newMemberList: [],
            imageuploadAndDeleteMenu: false,
            fullNameValidationError: false,
            emailIdValidationError: false,
            heightValidationError: false,
            weightValidationError: false,
            addressValidationError: false,
            pinCodeValidationError: false,
            stateNameValidationError: false,
            personImageValidationError: false,
            activityLevelValidationError: false,
            contactNumberValidationError: false,
            relationNameValidationError: false,
            personeAgeValidationError: false,
            cityNameValidationError: false,
            genderValidationError: false,
            memberDetailsLoading: false,
            newMemberListLoading: false,
            openDeleteModalBox: false,
            editProfileImage: false,
            errorReponse: false,
            isContactNumberValid: '',
            userProfileInfo: [],
            isEmailIdvalid: '',
            editprofileid: '',
            deleteId: '',
            ageRangeValidationError: false,
            isValidImage:false,
            open:false,
            image:"",
            result:"",
            orignalImage:"",
            cropper:"",
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();

        if ('/AddNewMemberPageWeb' === this.props?.navigation?.history?.location?.pathname) {
            this.getRelationWithUser();

        }

        if ('/UserProfilePageWeb' === this.props?.navigation?.history?.location?.pathname) {
            this.getUserProfileDetails();
            this.getNewMemberList();

        }
        const urlParams = new URLSearchParams(window.location.search).get('edit');

        if (urlParams) {
            this.setState({ editprofileid: urlParams, editProfileImage: true });
            this.getNewMemberDetails(urlParams);
        }
    }

    // <-------------------------- Here API Calling functionalities {rf} -------------------------->

    //  <---------- API call for user profile details info {rf} ---------->
    getUserProfileDetails = async () => {
        this.setState({
            loading: true
        })

        if (localStorage.getItem("user")) {
            const userInfo = await localStorage.getItem("user");
            // @ts-ignore
            this.userId = JSON.parse(userInfo)
        }
        const userToken = await localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUserProfileDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getUserProfileDetailsApiEndPoint}/${this.userId.id}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    //  <---------- API call for user relation list {rf} ---------->
    getRelationWithUser = async () => {
        this.setState({
            loading: true
        })
        const userToken = await localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRelationWithUserApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getRelationWithUserApiEndPoint}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //  <---------- API call for add profile member {rf} ---------->
    addProfileMember = async () => {

        if (this.fromValidationCheck()) {
            const formData = new FormData();
            formData.append("full_name", this.state.fullName);
            formData.append("email", this.state.emailId);
            formData.append("height", this.state.height);
            formData.append("weight", this.state.weight);
            formData.append("address", this.state.address);
            formData.append("pincode", this.state.pinCode);
            formData.append("state", this.state.stateName);
            formData.append("activity_level", this.state.activityLevel.toLowerCase());
            formData.append("contact_no", `91${this.state.contactNumber}`);
            formData.append("relation_id", this.state.relationId);
            formData.append("age", this.state.personeAge);
            formData.append("city", this.state.cityName);
            formData.append("gender", this.state.gender.toLowerCase());
            formData.append("image", this.state.personImage);

            const userToken = await localStorage.getItem("token");
            const header = {
                'Content-Type': configJSON.applicationJsonContentType,
                token: userToken,
            };

            // rest api call message create
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            // save rest api resuest id
            this.addNewMenberApiCallid = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.addNewMemberApiEndPoint}?language_id=${this.getLanguage()}` // end point setup
            );

            // set header
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            // sent the body data
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage), formData
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.postAPiMethod // api method
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);


        }

    }

    //  <---------- API call for new member list {rf} ---------->
    getNewMemberList = async () => {
        this.setState({
            newMemberListLoading: true
        })
        const userToken = await localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getNewMemberListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getNewMemberListApiEndPoint}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //  <---------- API call for delete profile member {rf} ---------->
    deleteProfileMember = async (id: any) => {
        const userToken = await localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteProfileMemberApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.deleteProfileMemberApiEndPoint}/${this.state.deleteId}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //  <---------- API call for get new member details {rf} ---------->
    getNewMemberDetails = async (id: any) => {
        this.setState({
            loading: true
        })
        const userToken = await localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getNewMemberDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getNewMemberDetailsApiCallIDEndPoint}/${id}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //  <---------- API call for update new member details {rf} ---------->
    updateNewMemberDetails = async () => {

        if (this.fromValidationCheck()) {
            const formData = new FormData();
            formData.append("full_name", this.state.fullName);
            formData.append("email", this.state.emailId);
            formData.append("height", this.state.height);
            formData.append("weight", this.state.weight);
            formData.append("address", this.state.address);
            formData.append("pincode", this.state.pinCode);
            formData.append("state", this.state.stateName);
            formData.append("activity_level", this.state.activityLevel.toLowerCase());
            formData.append("contact_no", `91${this.state.contactNumber}`);
            formData.append("relation_id", this.state.relationId);
            formData.append("age", this.state.personeAge);
            formData.append("city", this.state.cityName);
            formData.append("gender", this.state.gender.toLowerCase());
            if (!this.state.editProfileImage) {
                formData.append("image", this.state.personImage);
            }


            const userToken = await localStorage.getItem("token");
            const header = {
                'Content-Type': configJSON.applicationJsonContentType,
                token: userToken,
            };

            // rest api call message create
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            // save rest api resuest id
            this.updateNewMenberApiCallid = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getNewMemberDetailsApiCallIDEndPoint}/${this.state.editprofileid}?language_id=${this.getLanguage()}` // end point setup
            );

            // set header
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            // sent the body data
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage), formData
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.PatchAPiMethod // api method
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);


        }
    }


    // <-------------------------- Here receive function for API response {rf} -------------------------->

    async receive(from: string, message: Message) {

        //  <---------- response for  user profile details {rf} ---------->

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getUserProfileDetailsApiCallId &&
            this.getUserProfileDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.getUserProfileDetailsApiResponse(responseJson);
        }

        // API response end {rf}

        //  <---------- response for  relation with user list {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getRelationWithUserApiCallId &&
            this.getRelationWithUserApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.getRelationWithUserApiResponse(responseJson);
        }

        // API response end {rf}

        //  <---------- response for Add member profile api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.addNewMenberApiCallid &&
            this.addNewMenberApiCallid === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.addNewMenberApiResponse(responseJson);
        }

        // API response end {rf}

        //  <---------- response for  new member list {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getNewMemberListApiCallId &&
            this.getNewMemberListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.getNewMemberListApiResponse(responseJson);
        }

        // API response end {rf}

        //  <---------- response for  delete profile member api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.deleteProfileMemberApiCallId &&
            this.deleteProfileMemberApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.deleteProfileMemberApiResponse(responseJson);

        }

        // API response end {rf}

        //  <---------- response for  get new member details api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getNewMemberDetailsApiCallId &&
            this.getNewMemberDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.getNewMemberDetailsApiResponse(responseJson);
        }

        // API response end {rf}

        //  <---------- response for  update profile member api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.updateNewMenberApiCallid &&
            this.updateNewMenberApiCallid === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.updateNewMenberApiResponse(responseJson);
        }

        // API response end {rf}

    }

    // <-------------------------- Api responses functionalities {rf} -------------------------->

    getUserProfileDetailsApiResponse = (responseJson: any) => {
        if (responseJson?.data && responseJson?.data != null) {
            this.setState({
                userProfileInfo: responseJson?.data,
                loading: false
            })
        }
        this.setState({
            loading: false
        })
    }

    getRelationWithUserApiResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({
                relation: responseJson?.data,
                loading: false

            })

        }
        this.setState({
            loading: false
        })
    }

    addNewMenberApiResponse = (responseJson: any) => {
        if (responseJson?.data && responseJson.data.type !== 'error') {
            this.initialingFormInputValue();
            this.navigationBtnFnc("UserProfilePageWeb")
        }

        if (responseJson?.data?.type === 'error') {
            if (responseJson?.data?.attributes?.errors?.contact_no) {
                if (responseJson?.data?.attributes?.errors?.contact_no[0] === 'is invalid') {
                    this.setState({
                        isContactNumberValid: 'Enter valid contact number'
                    })
                } else {
                    this.setState({
                        isContactNumberValid: 'Contact number has already been taken'
                    })
                }
            }

            if (responseJson?.data?.attributes?.errors?.email) {
                if (responseJson?.data?.attributes?.errors?.email[0] === 'is invalid') {
                    this.setState({
                        isEmailIdvalid: 'Enter valid email number'
                    })
                } else {
                    this.setState({
                        isEmailIdvalid: 'Email has already been taken'
                    })
                }
            }


        }
    }
    getNewMemberListApiResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({
                newMemberList: responseJson?.data,
                newMemberListLoading: false
            })

        }
        this.setState({
            newMemberListLoading: false
        })

        if (responseJson?.error) {
            this.setState({
                errorReponse: true,
                newMemberListLoading: false
            })
        }
    }
    deleteProfileMemberApiResponse = (responseJson: any) => {
        if (responseJson?.success) {
            this.handleModalClose()

            if ('/UserProfilePageWeb' === this.props?.navigation?.history?.location?.pathname) {
                this.getUserProfileDetails();
                this.getNewMemberList();

            }
        }

        this.handleModalClose()
    }
    getNewMemberDetailsApiResponse = async (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({
                fullName: responseJson?.data?.attributes?.full_name,
                emailId: responseJson?.data?.attributes?.email,
                relationName: responseJson?.data?.attributes?.relation_id,
                relationId: responseJson?.data?.attributes?.relation_id,
                contactNumber: responseJson?.data?.attributes?.contact_no.toString().slice(2),
                weight: responseJson?.data?.attributes?.weight,
                height: responseJson?.data?.attributes?.height,
                personeAge: responseJson?.data?.attributes?.age,
                activityLevel: responseJson?.data?.attributes?.activity_level,
                address: responseJson?.data?.attributes?.address,
                stateName: responseJson?.data?.attributes?.state,
                pinCode: responseJson?.data?.attributes?.pincode,
                cityName: responseJson?.data?.attributes?.city,
                gender: responseJson?.data?.attributes?.gender,
                imageName: responseJson?.data?.attributes?.image
            });
            this.getCityNameBaseOnState(responseJson?.data?.attributes?.state)

        }
        this.setState({
            loading: false
        })

        if (responseJson?.error) {
            this.setState({
                errorReponse: true,
                newMemberListLoading: false
            })
        }
    }
    updateNewMenberApiResponse = (responseJson: any) => {
        if (responseJson?.data && responseJson.data.type !== 'error') {
            this.initialingFormInputValue();
            this.navigationBtnFnc("UserProfilePageWeb");
        }

        if (responseJson?.data?.type === 'error') {
            if (responseJson?.data?.attributes?.errors?.contact_no) {
                if (responseJson?.data?.attributes?.errors?.contact_no[0] === 'is invalid') {
                    this.setState({
                        isContactNumberValid: 'Enter valid contact number'
                    })
                } else {
                    this.setState({
                        isContactNumberValid: 'Contact number has already been taken'
                    })
                }
            }

            if (responseJson?.data?.attributes?.errors?.email) {
                if (responseJson?.data?.attributes?.errors?.email[0] === 'is invalid') {
                    this.setState({
                        isEmailIdvalid: 'Enter valid email number'
                    })
                } else {
                    this.setState({
                        isEmailIdvalid: 'Email has already been taken'
                    })
                }
            }

            if (responseJson?.error) {
                this.setState({
                    errorReponse: true,
                    newMemberListLoading: false
                })
            }
        }
    }


    // <-------------------------- Here needed helper functionalities {rf} -------------------------->
    handleDeleteModalOpen = (id: any) => {
        this.setState({ openDeleteModalBox: true, deleteId: id })
    }

    handleModalClose = () => {
        this.setState({ openDeleteModalBox: false, deleteId: '' })
    }

    relationNameFinder = (id: any) => {
        const filterData = this.state.relation.find((item: any) => item.id === id.toString());
        return filterData?.attributes?.relation
    }

    initialingFormInputValue = () => {
        this.setState({
            fullName: '',
            emailId: '',
            relationName: 'Select',
            contactNumber: '',
            weight: '',
            height: '',
            personeAge: '',
            activityLevel: 'Select',
            address: '',
            stateName: 'Select',
            pinCode: '',
            cityName: 'Select',
            gender: 'Select',
            emailIdValidationError: false,
            imageName: '',
        });
    }

    fromValidationCheck = () => {
        if (!this.state.personImage) {
            this.setState({
                personImageValidationError: true
            })
        }

        else if (!this.state.fullName) {
            this.setState({
                fullNameValidationError: true
            })
        } else if (this.state.emailId === '') {
            this.setState({
                emailIdValidationError: true
            })
        } else if (!this.state.personeAge) {
            this.setState({
                personeAgeValidationError: true
            })
        } else if (this.state.gender === 'Select') {
            this.setState({
                genderValidationError: true
            })
        } else if (!this.state.weight) {
            this.setState({
                weightValidationError: true
            })
        } else if (!this.state.height) {
            this.setState({
                heightValidationError: true
            })
        } else if (this.state.relationName === 'Select') {
            this.setState({
                relationNameValidationError: true
            })
        } else if (this.state.activityLevel === 'Select') {
            this.setState({
                activityLevelValidationError: true
            })
        } else if (!this.state.address) {
            this.setState({
                addressValidationError: true
            })
        } else if (!this.state.contactNumber) {
            this.setState({
                contactNumberValidationError: true
            })
        } else if (!this.state.pinCode) {
            this.setState({
                pinCodeValidationError: true
            })
        } else if (this.state.stateName === 'Select') {
            this.setState({
                stateNameValidationError: true
            })
        } else if (this.state.cityName === 'Select') {
            this.setState({
                cityNameValidationError: true
            })
        } else {
            return true;
        }
        return false;
    }

    goBack = () => {

        this.props?.navigation?.goBack();
    }

    navigationBtnFnc = (path: any) => {

        this.props?.navigation?.navigate(path);
    }



    // here from handel change functionalities {rf}

    handleNameFieldValueChange = (event: any) => {
        const input = event.target.value;
        const firstChar = input.charAt(0);
        const isLetter = /^[a-zA-Z]$/.test(firstChar);
        if (!isLetter) {
            this.setState({
                fullName: '',
            })
        } else {
            this.setState({
                fullName: input,
                fullNameValidationError: false
            })
        }
    }

    handleEmailChange = (email: any) => {
        const regex = configJSON.emailregularExpression;
        this.setState({
            isEmailIdvalid: '',
        })
        !regex.test(email)
            ? this.setState({
                emailIdValidationError: true
            })
            : this.setState({
                emailIdValidationError: false
            })
        this.setState({
            emailId: email
        })
    };

    handleAgeValueChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue === '0') {
            event.preventDefault();
        } else {
            if (!isNaN(inputValue) && inputValue >= 0 && inputValue.length <= 3) {
                if (inputValue <= 150) {
                    this.setState({
                        personeAge: inputValue,
                        personeAgeValidationError: false,
                        ageRangeValidationError: false
                    })
                } else {
                    this.setState({
                        ageRangeValidationError: true
                    })

                }

            }
        }
    };

    handleWeightFieldValueChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue === '0') {
            event.preventDefault();
        } else {
            this.setState({
                weight: inputValue,
                weightValidationError: false
            })
        }

    }
    handleHeightFieldValueChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue === '0') {
            event.preventDefault();
        } else {
            this.setState({
                height: inputValue,
                heightValidationError: false
            })
        }
    }

    handleAddressFieldValueChange = (event: any) => {
        const input = event.target.value;
        const firstChar = input.charAt(0);
        const isLetter = /^[a-zA-Z]$/.test(firstChar);
        if (!isLetter) {
            this.setState({
                address: '',
            })
        } else {
            this.setState({
                address: input,
                addressValidationError: false
            })
        }
    }

    handelContactNumberValueChange = (event: any) => {
        const inputValue = event.target.value;
        this.setState({
            contactNumber: inputValue,
            contactNumberValidationError: false,
            isContactNumberValid: ''
        })
    }

    handlePinCodeFieldValueChange = (event: any) => {
        const inputValue = event.target.value;
        this.setState({
            pinCode: inputValue,
            pinCodeValidationError: false
        })
    }
    handelRelationSelectField = (event: any) => {
        if (event.target.value === 'Select') {
            this.setState({
                relationName: event.target.value,
                relationId: ''
            })
        } else {
            const id = event.target.value
            this.setState({
                relationName: event.target.value,
                relationId: id,
                relationNameValidationError: false
            })
        }
    }
    handelGenderSelectField = (event: any) => {
        this.setState({
            gender: event.target.value,
            genderValidationError: false
        })
    }
    handelActivityLevelSelectField = (event: any) => {
        this.setState({
            activityLevel: event.target.value,
            activityLevelValidationError: false
        })
    }
    handelStateSelectField = (stateName: any) => {
        this.setState({
            stateName: stateName,
            stateNameValidationError: false,
            cityName: 'Select',
        })
        if (stateName != 'Select') {
            this.getCityNameBaseOnState(stateName);
        }
    }
    getCityNameBaseOnState = (stateName: any) => {

        const filterData = Cities.find((city: { state: any; }) => city.state === stateName);
        this.CitiesName = filterData?.name;
    }

    handelCitySelectField = (event: any) => {
        this.setState({
            cityName: event.target.value,
            cityNameValidationError: false
        })
    }

    // here key press functionalities {rf}

    handlePositiveNumberOnKeyPress = (event: any) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (keyCode === 8 || keyCode === 0) return;
        if (!/^\d+$/.test(keyValue)) event.preventDefault();
    }
    handlePositiveDecimalNumberOnKeyPress = (event: any) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (keyCode === 8 || keyCode === 0) return;
        if (!/^[0-9.]*$/.test(keyValue)) event.preventDefault();
    }

    disableDownKey = (event: any) => {
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
        }
    }

    specialCharacterVlidation = (event: any) => {
        const pattern = /^[a-zA-Z\s-.]+$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    getLanguage = () => {
        let languageId: any;
        const defaultEnglishLanguageId: any = configJSON.defaultLanguageIDEnglish
        if (localStorage.getItem('languageSelect')) {
            languageId = localStorage.getItem('languageSelect');
        }
        else {
            languageId = defaultEnglishLanguageId;
        }
        return languageId;
    }

    // Profile Modification
    onChange = (e: any) => {
        e.preventDefault();
        let files;    
          if (e.target.files && e.target.files.length > 0) {
               if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)){
                this.setState({
                    isValidImage:true
                })  
           }
            else{
                this.setState({
                    isValidImage:false,
                    orignalImage:e.target.files[0]
                })  
              
              if (e.dataTransfer) {
                files = e.dataTransfer.files;
              } else if (e.target) {
                files = e.target.files;
              }
              const reader = new FileReader();
              reader.onload = () => {
                this.setState({
                    image:reader.result as any                 
                })                  
              };
              reader.readAsDataURL(files[0]);
            }
          }
        }
    getCropData = () => {
        if (typeof this.state.cropper !== "undefined") {          
          this.setState({open:false})
          const croppedImage = this.state.cropper.getCroppedCanvas().toDataURL()      
          const convertedImage = this.base64ToFile(croppedImage, this.state.orignalImage?.name);
          this.setState({result:convertedImage})          
          this.FrontImage(convertedImage)   
        }
        else {
            this.setState({
                isValidImage:true
            })    
        }
      };
    originalImage = () => {
        if(this.state.orignalImage){
            this.setState({
                result: this.state.orignalImage,
                open:false
            })     
            this.FrontImage(this.state.orignalImage)     
        //   croppedImageFunction(orignalImage)  need enable oncce all issue fixed
          
        }
        else {  
            this.setState({
                isValidImage:true
            })              
        }
      }
    handleClickOpen = () => {
        
        this.setState({open:true})
      };
    
    handleClose = () => {
        this.setState({
            open:false,
            image:"",
            result:"",
            orignalImage:"",
        })        
        this.clearFrontPackImage();
      };
    
    handleSnackBarClose = () => {        
        this.setState({isValidImage:false})
      }
    base64ToFile = (base64Data: any, filename: any) => {
        const arr = base64Data.split(',');
        const mime = arr[0].split(':')[1].split(';')[0];
        const bstr = atob(arr[1]);
      
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n) {
          u8arr[n - 1] = bstr.charCodeAt(n - 1);
          n -= 1;
        }
        return new File([u8arr], filename, { type: mime });
      };
      FrontImage = (e:any) => {       
        if (e) {        
          this.setState({
            personImage: e,          
            imageName: e?.name,
            personImageValidationError: false,
            editProfileImage:false
          })
        } else {
            // this.setState({invalidFrontPackImageFileType: true})
        }
    }
    clearFrontPackImage = () => {
        this.setState({ personImage: "", imageName: "", editProfileImage:false })
      }
    // Customizable Area End
}