// App.js - WEB
import React, { Component, Suspense } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import CfAddProduct from '../../blocks/CfAddProduct/src/CfAddProduct';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import CfVoiceAssistedSearch from '../../blocks/CfVoiceAssistedSearch/src/CfVoiceAssistedSearch';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Customform from '../../blocks/customform/src/Customform';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import OfflineWork from '../../blocks/OfflineWork/src/OfflineWork';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import CfContentBasedImageRetrival from '../../blocks/CfContentBasedImageRetrival/src/CfContentBasedImageRetrival';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen.web';
import UserSignUpScreenWeb from '../../blocks/social-media-account-registration/src/UserSignUpScreen.web';

import Videos4 from '../../blocks/Videos4/src/Videos4';
import SearchHistory from '../../blocks/SearchHistory/src/SearchHistory';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import CfDataScapping from '../../blocks/CfDataScapping/src/CfDataScapping';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ProductRecommendationEngine from '../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine';
import CfBusinessRuleEngine2 from '../../blocks/CfBusinessRuleEngine2/src/CfBusinessRuleEngine2';
import LiveChat2 from '../../blocks/LiveChat2/src/LiveChat2';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import HomePage from '../../blocks/catalogue/src/HomePage.web';
import FaqsComponent from '../../blocks/ContentManagement/src/FAQs.web';
import PrivacyPolicy from '../../blocks/ContentManagement/src/PrivacyPolicy.web';
import AboutUsWeb from '../../blocks/ContentManagement/src/AboutUs.web';
import TermsAndConditionsWeb from '../../blocks/ContentManagement/src/TermsAndConditions.web';
// import FooterWeb from '../../components/src/Footer.web';
import ProductDetailsScreenWeb from '../../blocks/ProductRecommendationEngine/src/ProductDetailsScreen.web';

import Search from '../../blocks/SearchHistory/src/Search.web';
import LandingPage from '../../blocks/catalogue/src/LandingPage.web';
import NoProduct from '../../blocks/SearchHistory/src/NoProduct.web';
import NoRatedComponent from '../../blocks/SearchHistory/src/NoRated.web';
import HealthyFoodBasketWeb from '../../blocks/ProductRecommendationEngine/src/HealthyFoodBasket.web'
import HealthyFoodDetailsPageWeb from '../../blocks/ProductRecommendationEngine/src/HealthyFoodDetailsPage.web'
import CompareProductsWeb from '../../blocks/ProductRecommendationEngine/src/CompareProducts.Web'

import FavouriteSmartSearchWeb from '../../blocks/AdvancedSearch/src/FavouriteSmartSearchWeb'

import SmartSearch from '../../blocks/AdvancedSearch/src/SmartSearch.web'


import UserProfilePageWeb from '../../blocks/CustomisableUserProfiles/src/UserProfilePage.web'
import AddNewMemberPageWeb from '../../blocks/CustomisableUserProfiles/src/AddNewMemberPage.web'
import BmiCalculatorPageWeb from '../../blocks/CustomisableUserProfiles/src/bmi_calculator/BmiCalculatorPage.web'
import BmiCalculatorDashboardWebPage from '../../blocks/CustomisableUserProfiles/src/bmi_calculator/BmiCalculatorDashboardPage.web'
import BmiSignAndSymptomsWebPage from '../../blocks/CustomisableUserProfiles/src/bmi_calculator/BmiSignAndSymptomsPage.web'
import HealthPointsWebPage from '../../blocks/ProductRecommendationEngine/src/healthPoints/HealthPointsPage.web'
import HealthPointDetailsPageWeb from '../../blocks/ProductRecommendationEngine/src/healthPoints/HealthPointDetailsPage.web'
import HealthPointLeaderBoardWeb from '../../blocks/ProductRecommendationEngine/src/healthPoints/HealthPointLeaderBoard.web'
import NutritionGapCalculatorWeb from '../../blocks/CustomisableUserProfiles/src/nutrition_gap_calculator/NutritionGapCalculator.web'
import NutritionGapReportWeb from '../../blocks/CustomisableUserProfiles/src/nutrition_gap_calculator/NutritionGapReport.web'
import SmartSearchProductDetailsWebPage from '../../blocks/ProductRecommendationEngine/src/smartSearchProductDetailsPage/SmartSearchProductDetailsPage.web'
import YourProductWeb from '../../blocks/CfAddProduct/src/yourproduct.web'
import AddProductWeb from '../../blocks/CfAddProduct/src/addproducts.web'
import ViewProductWeb from '../../blocks/CfAddProduct/src/viewProduct.web'
import ProductReportedWebPage from '../../blocks/ProductRecommendationEngine/src/productReports/ProductReported.web'
import ProductReportFormWebPage from '../../blocks/ProductRecommendationEngine/src/productReports/ProductReportFormPage.web'
import FavoriteProductsWeb from '../../blocks/CustomisableUserProfiles/src/favorite_products/FavoriteProductsWeb'
const FooterWeb = React.lazy(() => import("../../components/src/Footer.web"));
import FavouriteProductContextProvider from '../../blocks/ProductRecommendationEngine/src/context/FavouriteProductContextProvider.web';

const routeMap = {
  CfAddProduct: {
    component: CfAddProduct,
    path: '/CfAddProduct'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  CfVoiceAssistedSearch: {
    component: CfVoiceAssistedSearch,
    path: '/CfVoiceAssistedSearch'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  OfflineWork: {
    component: OfflineWork,
    path: '/OfflineWork'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  CfContentBasedImageRetrival: {
    component: CfContentBasedImageRetrival,
    path: '/CfContentBasedImageRetrival'
  },

  UserSignUpScreenWeb: {
    component: UserSignUpScreenWeb,
    path: '/signup'
  },
  Videos4: {
    component: Videos4,
    path: '/Videos4'
  },
  SearchHistory: {
    component: SearchHistory,
    path: '/SearchHistory'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  CfDataScapping: {
    component: CfDataScapping,
    path: '/CfDataScapping'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ProductRecommendationEngine: {
    component: ProductRecommendationEngine,
    path: '/ProductRecommendationEngine'
  },
  ProductDetails: {
    component: ProductDetailsScreenWeb,
    path: '/ProductDetails'
  },
  HealthyFoodBasket: {
    component: HealthyFoodBasketWeb,
    path: '/HealthyFoodBasket'
  },
  HealthyFoodDetails: {
    component: HealthyFoodDetailsPageWeb,
    path: '/HealthyFoodDetails'
  },
  CompareProducts: {
    component: CompareProductsWeb,
    path: '/CompareProducts',
    exact: true
  },
  FavoriteProductsWeb: {
    component: FavoriteProductsWeb,
    path: '/favoriteProducts',
    exact: true
  },
  CfBusinessRuleEngine2: {
    component: CfBusinessRuleEngine2,
    path: '/CfBusinessRuleEngine2'
  },
  LiveChat2: {
    component: LiveChat2,
    path: '/LiveChat2'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },

  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  Home: {
    component: HomePage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  ContentManagementPrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  ContentManagementFaqs: {
    component: FaqsComponent,
    path: '/faqs'
  },
  AboutUsWeb: {
    component: AboutUsWeb,
    path: '/aboutUs'
  },
  TermsAndConditionsWeb: {
    component: TermsAndConditionsWeb,
    path: '/TermsAndConditions'
  },
  Search: {
    component: Search,
    path: "/search"
  },
  LandingPage: {
    component: LandingPage,
    path: "/landingPage"
  },
  NoProudctFound: {
    component: NoProduct,
    path: "/noproduct"
  },
  NoRated: {
    component: NoRatedComponent,
    path: "/norated"
  },
  FavouriteSmartSearchWeb: {
    component: FavouriteSmartSearchWeb,
    path: "/FavouriteSmartSearchWeb",
  },
  FavoriteProductsWeb: {
    component: FavoriteProductsWeb,
    path: '/favoriteProducts',
  },
  // FavProducts: {
  //   component: FavoriteProductsWeb,
  //   path: '/favoriteProducts',
  //   exact: true
  // },
  SmartSearch: {
    component: SmartSearch,
    path: "/smartsearch",
  },
  UserProfilePageWeb: {
    component: UserProfilePageWeb,
    path: "/UserProfilePageWeb",
  },
  AddNewMemberPageWeb: {
    component: AddNewMemberPageWeb,
    path: "/AddNewMemberPageWeb",
  },
  BmiCalculatorPageWeb: {
    component: BmiCalculatorPageWeb,
    path: "/BmiCalculatorPageWeb",
  },

  BmiCalculatorDashboardWebPage: {
    component: BmiCalculatorDashboardWebPage,
    path: "/BmiCalculatorDashboardWebPage",
  },
  BmiSignAndSymptomsWebPage: {
    component: BmiSignAndSymptomsWebPage,
    path: "/BmiSignAndSymptomsWebPage",
  },
  HealthPointsWebPage: {
    component: HealthPointsWebPage,
    path: "/HealthPointsWebPage",
  },
  HealthPointDetailsPageWeb: {
    component: HealthPointDetailsPageWeb,
    path: "/HealthPointDetailsPageWeb",
  },
  HealthPointLeaderBoardWeb: {
    component: HealthPointLeaderBoardWeb,
    path: "/HealthPointLeaderBoardWeb",
  },
  NutritionGapCalculatorWeb: {
    component: NutritionGapCalculatorWeb,
    path: "/NutritionGapCalculatorWeb"
  },
  NutritionGapReportWeb: {
    component: NutritionGapReportWeb,
    path: "/NutritionGapReportWeb"
  },
  SmartSearchProductDetailsWebPage: {
    component: SmartSearchProductDetailsWebPage,
    path: "/SmartSearchProductDetailsWebPage"
  },
  YourProductWeb: {
    component: YourProductWeb,
    path: '/yourproducts'
  },
  AddProductWeb: {
    component: AddProductWeb,
    path: '/addproducts'
  },
  ViewProductWeb: {
    component: ViewProductWeb,
    path: '/viewproduct'
  },

  ProductReportedWebPage: {
    component: ProductReportedWebPage,
    path: "/ProductReportedWebPage"
  },
  ProductReportFormWebPage: {
    component: ProductReportFormWebPage,
    path: "/ProductReportFormWebPage"
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <div style={{ height: '100vh', width: '100%', margin: 0 }}>
        <FavouriteProductContextProvider>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
          <Suspense fallback={<div>Loading...</div>}>
            <FooterWeb />
          </Suspense>
        </FavouriteProductContextProvider>
      </div>
    );
  }
}

export default App;
