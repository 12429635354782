import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
    getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import Cookies from 'js-cookie';
import i18next from 'i18next'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    t:any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
    loading: boolean;
    // Calling the Filter
    category:any;
    subCategory:any;
    niqScore:any;
    foodAllergies:any;
    foodPreferences:any;
    functionalPreferences:any;
    healthPreferences:any;  
    smartSearchResultsDisplay:any;
    smartSearchPagination:any;    
    favsearchCards:any;
    isFilterSelect:boolean;    
    paginationRefId:any;
    tempId:any;
    smartSearchFilters:any;   
    isFavSmartSearchCards:any;       
    deletingFavSearch:any;
    AddFavouriteSearch:any;
    smartsearchLoading:any;    
    chipTypeLabel:any;  
    deleteDialog:any;
    deleteFavSearchId:any;
    handleFilterInResp:any;
    displayFavChips:any;
    handleFavChips:any;
    isAgainAddToFav:any;
    favSearchValidation:any;
    favSearchLoader:any;
    isLogin:any;
    loginNotifiModalOpen:any;
    favSearchUpdate:any;
    filterLoaders:any;
    pageNumber:any;
    multiLanguageID:any;
    multiLanguageString:any;
    // Customizable Area End
}

interface SS {
    id: any;
}


export default class FavouriteSmartSearchController extends BlockComponent<Props, S, SS> {
    getFilterTypeApiCallID: string = '';
    getCategoryTypeApiCallID: string = '';
    getSubCategoryTypeApiCallID: string = '';
    getNiqScoreApiCallID: string = '';
    getFoodAllergiesApiCallID: string = '';
    getFoodPreferenceApiCallID: string = '';
    getFunctionalPreferenceApiCallID: string = '';
    getHealthPreferenceApiCallID: string = '';
    getSearchResultsApiCallID:string = '';
    getFavouriteSearchApiCallID:string = '';
    deleteFavouriteSearchApiID:string = '';
    SmartSearchPostID:string = '';
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage), //{rf}
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: '',
            loading: false,
            smartSearchResultsDisplay:[],
            smartSearchPagination:{},    
            category:"",
            subCategory:"",
            niqScore:"",
            foodAllergies:"",
            foodPreferences:"",
            functionalPreferences:[],
            healthPreferences:"",
            favsearchCards:"",
            isFilterSelect:false,
            paginationRefId:"",
            tempId:"",
            smartSearchFilters:{
                food_type:[],
                product_category:[],
                product_sub_category:[],
                niq_score:[],
                food_allergies:[],
                food_preference:[],
                health_preference:[],
                functional_preference:[],
            },      
            isFavSmartSearchCards:true,          
            deletingFavSearch:false,
            AddFavouriteSearch:false,
            smartsearchLoading:false,            
            chipTypeLabel:{good:["Low", "Medium", "High"],notSoGood:["Free", "Low", "High"]},   
            deleteDialog:false,               
            deleteFavSearchId:"",
            handleFilterInResp:false,
            displayFavChips:{},
            handleFavChips:false,
            isAgainAddToFav:false,
            favSearchValidation:false,
            favSearchLoader:false,
            isLogin:false,
            loginNotifiModalOpen:false,
            favSearchUpdate:-1,
            filterLoaders:{
                category:false,
                subCategory:false,
                niqScore:false,
                foodAllergies:false,
                foodPreferences:false,
                functionalPreferences:false,
                healthPreferences:false,
            },
            pageNumber:1,
            multiLanguageID:1,
            multiLanguageString:"en",
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();               
        this.multiLanguageHandler()
        const haveToken = localStorage.getItem('token')

    if (haveToken) {
      await  this.setState({isLogin: true}, ()=>{this.state.isLogin;this.pageRendering();});        
    }         
    }
  async componentDidUpdate(prevProps:any, prevState:any) {
        if (i18next.language !== this.state.multiLanguageString) {
            await this.multiLanguageHandler();
            await this.removeFilterData();
            await this.smartSearchFilterHandler()                         
        }        
      }

    removeFilterData = () => {
            localStorage.removeItem("CategoryTypeFilter");
            localStorage.removeItem("niqFilter");
            localStorage.removeItem("fdAllergies");
            localStorage.removeItem("fdPref");
            localStorage.removeItem("fncPref");
            localStorage.removeItem("helathPref");
        }
    
    multiLanguageHandler = () => {
        let currentLanguage = i18next.language
        this.setState({multiLanguageString: currentLanguage},()=>{this.state.multiLanguageString})
        if(currentLanguage === 'en') {
            this.setState({multiLanguageID: 1}, ()=>{this.state.multiLanguageID})
        }
        else if(currentLanguage === 'hi') {
            this.setState({multiLanguageID: 2}, ()=>{this.state.multiLanguageID})
        }
        else if(currentLanguage === 'kn') {
            this.setState({multiLanguageID: 3}, ()=>{this.state.multiLanguageID})
        }
    }

    // Customizable Area Start   
    smartSearchFilterHandler = () => {
        if (this.props?.navigation?.history?.location?.pathname === "/smartsearch") {  
        let getCategoryTypeFilter = localStorage.getItem("CategoryTypeFilter");
        let niqFilter:any = localStorage.getItem("niqFilter");
        let fdAllergies:any = localStorage.getItem("fdAllergies");
        let fdPref:any = localStorage.getItem("fdPref");
        let fncPref:any = localStorage.getItem("fncPref");
        let helathPref:any = localStorage.getItem("helathPref");
           /* istanbul ignore else */     
          if(getCategoryTypeFilter) {
            this.setState({category: JSON.parse(getCategoryTypeFilter)}, ()=>{this.state.category});                 
            this.setState({niqScore: JSON.parse(niqFilter)}, ()=>{this.state.niqScore});                 
            this.setState({foodAllergies: JSON.parse(fdAllergies)}, ()=>{this.state.foodAllergies});                 
            this.setState({foodPreferences: JSON.parse(fdPref)}, ()=>{this.state.foodPreferences});                 
            this.setState({functionalPreferences: JSON.parse(fncPref)}, ()=>{this.state.functionalPreferences});                 
            this.setState({healthPreferences: JSON.parse(helathPref)}, ()=>{this.state.healthPreferences});                 
            } else{
           
            this.getCategoryType('');                       
            this.getNiqScoreType('');
            this.getFoodAllergiesType('');
            this.getFoodPreferenceType('');
            this.getFunctionalPreferenceType('');
            this.getHealthPreferenceType(''); 
            } 
    }  
}
    pageRendering = async () => {
        
        let favId:any = localStorage.getItem("smartFavID");                    
        let getSmartSearchresults:any = localStorage.getItem("favSearchProducts");
        let forcepageNum:any = localStorage.getItem("forcePageNumber");
        let paginationRefIdData:any = localStorage.getItem("paginationRefId");
        if (this.props?.navigation?.history?.location?.pathname === "/smartsearch") {  
                
         
           this.smartSearchFilterHandler()
            if(paginationRefIdData) {
                let rx_pageRef:any =JSON.parse(paginationRefIdData);
                this.setState({paginationRefId: rx_pageRef}) 
            }

            if(forcepageNum) {
                let rx_num: any = JSON.parse(forcepageNum)
                this.setState({pageNumber: rx_num}, ()=>{this.state.pageNumber})
            }

            //method to call the favourite products results           
            if(favId){      
                // alert("apple")            
                let rx_data:any = JSON.parse(favId);
                await this.setState({handleFavChips: true, isFilterSelect: false,
                      displayFavChips: rx_data,paginationRefId: rx_data?.id,
                    },()=>{
                      this.state.handleFavChips,this.state.isFilterSelect,
                      this.state.displayFavChips,this.state.paginationRefId});                
                await this.getSearchResults(rx_data?.id,this.state.pageNumber);                         
            } else{
                this.setState({isFilterSelect:true})
            }                                 
            /* istanbul ignore else */        
            // let rx_results:any = JSON.parse(getSmartSearchresults);
            // console.log(rx_results, "Test__")
            // if(getSmartSearchresults) { 
            //     alert("dd")               
            //     this.setState({smartSearchResultsDisplay: rx_results?.products})
            //     this.setState({ smartSearchPagination: rx_results?.meta,
            //         // pageNumber: rx_results?.meta?.pagination?.current_page
            //      });                                
            // } else {
            //     this.setState({isFilterSelect:true})
            // }                        
        }                              
        if (this.props?.navigation?.history?.location?.pathname === "/FavouriteSmartSearchWeb"){
            this.getFavouriteSearchesCards(); 
        }
    }
  
    

    // <-------------------- API Call for Category filter --------------------->
    getCategoryType = (cat_id:any) => {  
        let categoryID = ''       
        if(cat_id === '' || null ) {
            categoryID = '';
        }
        else {
            categoryID = cat_id;
        }
        this.setState({ 
            // smartsearchLoading: true,
            favSearchValidation:false, 
            filterLoaders:{...this.state.filterLoaders, category:true} })        
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCategoryTypeApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getCategoryTypeEndPoint}?query=${"category"}&fav_search_id=${categoryID}&language_id=${this.state.multiLanguageID}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
      }
    
    // <-------------------- API Call for Sub Category filter --------------------->
    getSubCategoryType = (sub_cat_id:any) => {       
        this.setState({ 
            // smartsearchLoading: true,
            favSearchValidation:false,
            filterLoaders:{...this.state.filterLoaders, subCategory:true}
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSubCategoryTypeApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getSubCategoryTypeEndPoint}?query=${"sub_category"}&fav_search_id=${sub_cat_id}&language_id=${this.state.multiLanguageID}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
      }

    // <-------------------- API Call for Niq Score filter --------------------->
    getNiqScoreType = (niq_id:any) => { 
        
        let niqScoreID = ''       
        if(niq_id === '' || null ) {
            niqScoreID = '';
        }
        else {
            niqScoreID = niq_id;
        }
        this.setState({ 
            // smartsearchLoading: true,
            favSearchValidation:false,
            filterLoaders:{...this.state.filterLoaders, niqScore:true}
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getNiqScoreApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getNiqScoreEndPoint}?query=${'niq_score'}&fav_search_id=${niqScoreID}&language_id=${this.state.multiLanguageID}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
      }

    // <-------------------- API Call for Food Allergies filter --------------------->
    getFoodAllergiesType = (food_allergies_id: any) => {
        
        let allergies_id = ''       
        if(food_allergies_id === '' || null ) {
            allergies_id = '';
        }
        else {
            allergies_id = food_allergies_id;
        }
        this.setState({ 
            // smartsearchLoading: true,
            favSearchValidation:false,
            filterLoaders:{...this.state.filterLoaders, foodAllergies:true}
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFoodAllergiesApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getFoodAllergiesEndPoint}?query=${'food_allergies'}&fav_search_id=${allergies_id}&language_id=${this.state.multiLanguageID}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
      }

    // <-------------------- API Call for Food Preferences filter --------------------->
    getFoodPreferenceType = (food_preference_id:any) => {
        let preference_id = ''       
        if(food_preference_id === '' || null ) {
            preference_id = '';
        }
        else {
            preference_id = food_preference_id;
        }
        this.setState({ 
            // smartsearchLoading: true,
            favSearchValidation:false,
            filterLoaders:{...this.state.filterLoaders, foodPreferences:true}
         })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFoodPreferenceApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getFoodPreferenceEndPoint}?query=${'food_preference'}&fav_search_id=${preference_id}&language_id=${this.state.multiLanguageID}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
    }

    // <-------------------- API Call for Functional Preferences filter --------------------->
    getFunctionalPreferenceType = (functional_preference_id:any) => {
        let preference_id = ''       
        if(functional_preference_id === '' || null ) {
            preference_id = '';
        }
        else {
            preference_id = functional_preference_id;
        }
        this.setState({ 
            // smartsearchLoading: true,
            favSearchValidation:false,
            filterLoaders:{...this.state.filterLoaders, functionalPreferences:true}
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFunctionalPreferenceApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getFunctionalPreferenceEndPoint}?query=${'functional_preference'}&fav_search_id=${preference_id}&language_id=${this.state.multiLanguageID}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
      }

    // <-------------------- API Call for Health Preferences filter --------------------->
    getHealthPreferenceType = (health_preference_id:any) => {
        let preference_id = ''       
        if(health_preference_id === '' || null ) {
            preference_id = '';
        }
        else {
            preference_id = health_preference_id;
        }
        this.setState({ 
            // smartsearchLoading: true,
            favSearchValidation:false,
            filterLoaders:{...this.state.filterLoaders, healthPreferences:true} })
    const userToken = localStorage.getItem("token");
    const header = {
        'Content-Type': 'application/json',
        token: userToken,
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHealthPreferenceApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getHealthPreferenceEndPoint}?query=${'health_preference'}&fav_search_id=${preference_id}&language_id=${this.state.multiLanguageID}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
    }
    
    // <-------------------- API Call for Smart Search Results --------------------->
    getSearchResults = (id:any, pageNO: any = 1) => {
        
        this.setState({ loading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchResultsApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getSearchResultsApiCallIDEndPoint}?fav_search_id=${id}&page=${pageNO}&language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
      }

    // <-------------------- API Call for Smart Search Results --------------------->
    getFavouriteSearchesCards = () => {
        
        this.setState({ loading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFavouriteSearchApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getFavouriteSearchEndPoint}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
      }

    // <-------------------- Post the Data with Selected filters for Smart Search Results --------------------->      
    postSmartSearchFilters = async (foodTypeData:any, isfavSearch:any, method:any) => { 
        localStorage.removeItem("smartFavID");         
         this.setState({pageNumber:1})
        if(isfavSearch === false){
            // this.setState({smartsearchLoading:true});
        } else {
        this.setState({smartsearchLoading:false});
        }
        const postSelectedFilters:any = {}                         
        const pro_cat_Key                 : any = Object.keys(foodTypeData).find(key => foodTypeData[key] === foodTypeData?.product_category) ;
        const pro_sub_cat_Key             : any = Object.keys(foodTypeData).find(key => foodTypeData[key] === foodTypeData?.product_sub_category);
        const niq_Score_Key               : any = Object.keys(foodTypeData).find(key => foodTypeData[key] === foodTypeData?.niq_score);
        const food_allergies_Key          : any = Object.keys(foodTypeData).find(key => foodTypeData[key] === foodTypeData?.food_allergies);
        const food_preference_Key         : any = Object.keys(foodTypeData).find(key => foodTypeData[key] === foodTypeData?.food_preference); 
        const functional_preference_Key   : any = Object.keys(foodTypeData).find(key => foodTypeData[key] === foodTypeData?.functional_preference); 
        const health_preference_Key       : any = Object.keys(foodTypeData).find(key => foodTypeData[key] === foodTypeData?.health_preference); 

  
        if (foodTypeData?.product_category.length           !== 0) {postSelectedFilters[pro_cat_Key] = JSON.stringify(foodTypeData?.product_category);}        
        if (foodTypeData?.product_sub_category.length       !== 0) {postSelectedFilters[pro_sub_cat_Key] = JSON.stringify(foodTypeData?.product_sub_category);}        
        if (foodTypeData?.niq_score.length                  !== 0) {postSelectedFilters[niq_Score_Key] = JSON.stringify([foodTypeData?.niq_score]);}
        if (foodTypeData?.food_allergies.length             !== 0) {postSelectedFilters[food_allergies_Key] = JSON.stringify(foodTypeData?.food_allergies);}
        if (foodTypeData?.food_preference.length            !== 0) {postSelectedFilters[food_preference_Key] = JSON.stringify(foodTypeData?.food_preference);}
        if (foodTypeData?.functional_preference.length      !== 0) {postSelectedFilters[functional_preference_Key] = JSON.stringify(foodTypeData?.functional_preference);}
        if (foodTypeData?.health_preference.length          !== 0) {postSelectedFilters[health_preference_Key] = JSON.stringify(foodTypeData?.health_preference);}
            
        const userToken = await localStorage.getItem("token");
        const header = {token: userToken,};        
        var form = new FormData();

        // Iterate through the object
        for (const key in postSelectedFilters) {
            if (postSelectedFilters.hasOwnProperty(key)) {                                   
              await form.append(key, postSelectedFilters[key]);
            }
          }
                      
        // isfavSearch is true then added to Favourite search else not added to favourite search        
        await form.append("favourite", isfavSearch);        
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.SmartSearchPostID = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          method === "post" ? `${configJSON.postFavouriteSearchEndPoint}?language_id=${this.getLanguage()}` : `${configJSON.updateFavouriteSearchEndPoint}${this.state.favSearchUpdate}?language_id=${this.getLanguage()}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          form
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
        method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };

    // <-------------------- API for Delete the Favourite Search --------------------->
    deleteFavouriteSearch = (delete_id:any) => {
        this.setState({deletingFavSearch:true})
        this.setState({ loading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteFavouriteSearchApiID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.deleteFavouriteSearchEndPoint}/${delete_id}?language_id=${this.getLanguage()}`);        
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
      }


    // receive function for API response
    async receive(from: string, message: Message) {
    
        //  <---------- response for  smart search Category type  ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getCategoryTypeApiCallID &&
            this.getCategoryTypeApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var categoryResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (categoryResponse) {                
                localStorage.setItem("CategoryTypeFilter", JSON.stringify(categoryResponse));
                this.setState({ category: categoryResponse, filterLoaders:{...this.state.filterLoaders, category:false}},
                    ()=>{this.state.category, this.state.filterLoaders});                                        
            }
        }

        //  <---------- response for  smart search Sub Category type  ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getSubCategoryTypeApiCallID &&
            this.getSubCategoryTypeApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var subCategoryResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );            
            if (subCategoryResponse) {                        
                this.setState({ subCategory: subCategoryResponse, filterLoaders:{...this.state.filterLoaders, subCategory:false}},
                    ()=>{this.state.subCategory, this.state.filterLoaders});
        
            }

        }

         //  <---------- response for  smart search Niq Score  ---------->
         if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getNiqScoreApiCallID &&
            this.getNiqScoreApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var NiqScoreResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (NiqScoreResponse) {                     
                if(this.state.tempId){
                this.setState({ niqScore: NiqScoreResponse,   filterLoaders:{...this.state.filterLoaders, niqScore:false} },
                ()=>{this.state.niqScore, this.state.filterLoaders});
                }
                else {
                this.setState({ niqScore: NiqScoreResponse,   filterLoaders:{...this.state.filterLoaders, niqScore:false} },
                ()=>{this.state.niqScore, this.state.filterLoaders});
                localStorage.setItem("niqFilter", JSON.stringify(NiqScoreResponse))    
                }
                
            }

        }

            //  <---------- response for  smart search Food Allergies  ---------->
            if (
                getName(MessageEnum.RestAPIResponceMessage) === message.id &&
                this.getFoodAllergiesApiCallID &&
                this.getFoodAllergiesApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                var foodAllergiesResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                
                if (foodAllergiesResponse) { 
                    if(this.state.tempId){                       
                    this.setState({ foodAllergies: foodAllergiesResponse,  filterLoaders:{...this.state.filterLoaders, foodAllergies:false}},
                        ()=>{this.state.foodAllergies, this.state.filterLoaders});                                         
                    }
                    else {
                        this.setState({ foodAllergies: foodAllergiesResponse,  filterLoaders:{...this.state.filterLoaders, foodAllergies:false}},
                            ()=>{this.state.foodAllergies, this.state.filterLoaders});  
                        localStorage.setItem("fdAllergies", JSON.stringify(foodAllergiesResponse))   
                    }
                }
    
            }
             //  <---------- response for  smart search Food Preferences  ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFoodPreferenceApiCallID &&
            this.getFoodPreferenceApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var foodPreferenceResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (foodPreferenceResponse) {   
                if(this.state.tempId){                      
                this.setState({ foodPreferences: foodPreferenceResponse, filterLoaders:{...this.state.filterLoaders, foodPreferences:false} },
                    ()=>{this.state.foodPreferences, this.state.filterLoaders});            
                }
                else {
                    this.setState({ foodPreferences: foodPreferenceResponse, filterLoaders:{...this.state.filterLoaders, foodPreferences:false} },
                        ()=>{this.state.foodPreferences, this.state.filterLoaders});            
                    localStorage.setItem("fdPref", JSON.stringify(foodPreferenceResponse))   
                }
            }

        }

          //  <---------- response for  smart search Functioanl Preferences  ---------->
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFunctionalPreferenceApiCallID &&
            this.getFunctionalPreferenceApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var functioanlPreferenceResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );            
            if (functioanlPreferenceResponse) {                
                if(this.state.tempId){    
                this.setState({ functionalPreferences: functioanlPreferenceResponse, filterLoaders:{...this.state.filterLoaders, functionalPreferences:false} },
                    ()=>{this.state.functionalPreferences, this.state.filterLoaders});
                }
                else {
                    this.setState({ functionalPreferences: functioanlPreferenceResponse, filterLoaders:{...this.state.filterLoaders, functionalPreferences:false} },
                        ()=>{this.state.functionalPreferences, this.state.filterLoaders});
                        localStorage.setItem("fncPref", JSON.stringify(functioanlPreferenceResponse))   
                }
            }

        }

        //  <---------- response for  smart search Health Preferences  ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getHealthPreferenceApiCallID &&
            this.getHealthPreferenceApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var healthPreferenceResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (healthPreferenceResponse) { 
                if(this.state.tempId){                    
                this.setState({ healthPreferences: healthPreferenceResponse, filterLoaders:{...this.state.filterLoaders, healthPreferences:false} },
                    ()=>{this.state.healthPreferences, this.state.filterLoaders}
                    );
                // await this.setState({ smartsearchLoading: false });
                }
                else {
                    this.setState({ healthPreferences: healthPreferenceResponse, filterLoaders:{...this.state.filterLoaders, healthPreferences:false} },
                        ()=>{this.state.healthPreferences, this.state.filterLoaders});
                        localStorage.setItem("helathPref", JSON.stringify(healthPreferenceResponse))   
                }
            }

        }
              

        //  <---------- response for  smart search Results  ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getSearchResultsApiCallID &&
            this.getSearchResultsApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var searchResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (searchResponse?.products || searchResponse?.meta) {
                // localStorage.removeItem("smartFavID");               
                this.setState({smartSearchResultsDisplay: searchResponse?.products,smartSearchPagination: searchResponse?.meta,
                    //  pageNumber: searchResponse?.meta?.pagination?.current_page 
                    },
                     async()=>{
                        await this.state.smartSearchResultsDisplay,
                        await this.state.smartSearchPagination,
                        await localStorage.setItem("favSearchProducts", JSON.stringify(searchResponse))                        
                        
                    })
                    // this.setState({  }, ()=>{});                
                
                this.setState({ loading: false });
            }                              
        }    
        

        //  <---------- response for  Favourite smart search Cards  ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFavouriteSearchApiCallID &&
            this.getFavouriteSearchApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var FavsearchResponseCards = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );   
            this.setState({ loading: false });                          
            if (FavsearchResponseCards?.data) {                                                
                 this.setState({isFavSmartSearchCards: false})           
                 this.setState({favsearchCards: FavsearchResponseCards})                   
                 
            } 
            else if (FavsearchResponseCards?.message)  {
             this.setState({isFavSmartSearchCards: true})                          
        }
        }    

        //  <----------  post response for  smart search Results  ---------->        
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.SmartSearchPostID &&
            this.SmartSearchPostID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var smartSearchPostAPIresponse:any = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
                this.setState({favSearchLoader: false})
            if (smartSearchPostAPIresponse?.data?.attributes?.favourite === false) {                             
                await this.setState({tempId: smartSearchPostAPIresponse?.data?.id},()=>{
                    this.state.tempId
                });                
                          
                await this.setState({paginationRefId: smartSearchPostAPIresponse?.data?.id})
                await localStorage.setItem("paginationRefId", JSON.stringify(smartSearchPostAPIresponse?.data?.id)) 
                if(smartSearchPostAPIresponse?.data?.attributes?.product_category !== null) {
                await this.getSubCategoryType(smartSearchPostAPIresponse?.data?.id)
                }            
                await this.getNiqScoreType(smartSearchPostAPIresponse?.data?.id);
                await this.getFoodAllergiesType(smartSearchPostAPIresponse?.data?.id);
                await this.getFoodPreferenceType(smartSearchPostAPIresponse?.data?.id);                
                await this.getFunctionalPreferenceType(smartSearchPostAPIresponse?.data?.id);                
                await this.getHealthPreferenceType(smartSearchPostAPIresponse?.data?.id);    
                     
            }
            if(smartSearchPostAPIresponse?.data?.attributes?.favourite === true){
                await this.setState({favSearchUpdate: smartSearchPostAPIresponse?.data?.id},()=>{
                    this.state.favSearchUpdate
                }); 
                this.setState({AddFavouriteSearch:true})    
                setTimeout(
                     /* istanbul ignore next */
                    ()=>{
                    this.setState({AddFavouriteSearch:false})    
                }, 2000)           
            }
            if(smartSearchPostAPIresponse?.data?.attributes?.errors?.message[0]){
                this.setState({isAgainAddToFav:true})    
                setTimeout(
                     /* istanbul ignore next */
                    ()=>{
                    this.setState({isAgainAddToFav:false})    
                }, 2000)           
            }

        }
      
        //  <----------  Delete response for  Favourite Search  ---------->        
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.deleteFavouriteSearchApiID &&
            this.deleteFavouriteSearchApiID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var deleteResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (deleteResponse?.success == true) {  
                await this.setState({deletingFavSearch:false})              
                await this.getFavouriteSearchesCards();
            }            

        }

    }

    navigationBtnFnc = (path: any) => {
        this.props?.navigation?.navigate(path);
    }

    // Method to handle the Pagination
    handlePageClick = async (pageNumber: any) => {
        this.setState({ loading: true });
        let num = pageNumber.selected + 1
        await this.getSearchResults(this.state.paginationRefId, num);   
        await localStorage.setItem("forcePageNumber", JSON.stringify(num))     
      }


    // Method To Handle the Category Type Filter
    handleCategoryTypeChange = async(event:any, cat_type:any) => {          
        this.setState({isFilterSelect:true})
        this.setState({handleFavChips:false})
        const temp =  {...this.state.smartSearchFilters?.product_category};     
        if(!this.state.smartSearchFilters?.product_category[cat_type]) {
            temp[cat_type] = []
        }        
        const currentFoodType:any = temp[cat_type];                       

        if(!currentFoodType.includes(event.target?.value)) {
            currentFoodType.push(event.target?.value)                                         
            await this.setState({smartSearchFilters: {...this.state.smartSearchFilters, product_category:temp}});                             
        }     
        else {            
            currentFoodType.splice(currentFoodType.indexOf(event.target?.value), 1);       
            if(temp[cat_type].length < 1) {
                delete temp[cat_type]
            }  
                                    
            await this.setState({smartSearchFilters: {...this.state.smartSearchFilters, product_category:temp}});                        
        }
    }

    // Method To Handle the SUB Category Type Filter
    handleSubCategoryTypeChange = async(event:any, foodDrinkFilter:any, category:any ) => {
        this.setState({isFilterSelect:true})
        this.setState({handleFavChips:false})
        const temp:any =  {...this.state.smartSearchFilters?.product_sub_category};   
        if(!this.state.smartSearchFilters?.product_sub_category[foodDrinkFilter]) {
            temp[foodDrinkFilter] = {}
        }
        if (!temp[foodDrinkFilter][category]){
            temp[foodDrinkFilter][category] = []
        }
        const currentFoodType:any = temp[foodDrinkFilter][category];
        if(!currentFoodType.includes(event.target.value)) {
            currentFoodType.push(event.target.value)                                         
            await this.setState({smartSearchFilters: {...this.state?.smartSearchFilters, product_sub_category:temp}})
              
        }
        else {
            currentFoodType.splice(currentFoodType.indexOf(event.target.value), 1);
            if(temp[foodDrinkFilter][category].length < 1) {
                delete temp[foodDrinkFilter][category]
                delete temp[foodDrinkFilter]
            }                             
            await this.setState({smartSearchFilters: {...this.state?.smartSearchFilters, product_sub_category:temp}})         
        }  
    }

    // Method To Handle the Niq Score Type Filter
    handleNiqScoreTypeChange = async(event:any) => {
        this.setState({isFilterSelect:true})
        this.setState({handleFavChips:false})
        if(!this.state.smartSearchFilters?.niq_score?.includes(event.target.value)){    
            await this.setState({smartSearchFilters: {...this.state?.smartSearchFilters, niq_score:event.target.value}})             
        } else {
            await this.state.smartSearchFilters?.niq_score?.splice(this.state.smartSearchFilters?.niq_score?.indexOf(event.target.value), 1);
            
        }
    }

    // Method To Handle the Food Allergies Filter
    handleFoodAllergiesTypeChange = async(event:any) => {        
        this.setState({isFilterSelect:true});
        this.setState({handleFavChips:false})
        const currentFoodType:any = this.state.smartSearchFilters?.food_allergies
        if(!currentFoodType.includes(event.target.value)) {
            currentFoodType.push(event.target.value)                                         
            await this.setState({smartSearchFilters: {...this.state.smartSearchFilters, food_allergies:currentFoodType}})           
        }  else {
                currentFoodType.splice(currentFoodType.indexOf(event.target.value), 1);                                    
                await this.setState({smartSearchFilters: {...this.state.smartSearchFilters,food_allergies:currentFoodType}})                 
        }        
    }

    // Method To Handle the Food Preferences Filter
    handleFoodPreferencesTypeChange = async(event:any) => {        
        this.setState({isFilterSelect:true});
        this.setState({handleFavChips:false})
        const currentFoodType:any = this.state.smartSearchFilters?.food_preference
    if(!currentFoodType.includes(event.target.value)) {
        currentFoodType.push(event.target.value)                                         
        await this.setState({smartSearchFilters: {...this.state.smartSearchFilters, food_preference:currentFoodType}})   
    } else {
        currentFoodType.splice(currentFoodType.indexOf(event.target.value), 1);                                    
        await this.setState({smartSearchFilters: {...this.state.smartSearchFilters,food_preference:currentFoodType}})              
    }
    }

    // Method To Handle the Functional Preferences Filter
    handleFunctionalPreference = async(event:any,neneg_data:any) => {
        this.setState({isFilterSelect:true})  
        this.setState({handleFavChips:false})  
        const temp =  {...this.state.smartSearchFilters?.functional_preference};     
            if(!this.state.smartSearchFilters?.functional_preference[neneg_data]) {
                temp[neneg_data] = []
            }
            const currentFoodType:any = temp[neneg_data];                       
    
            if(!currentFoodType.includes(event.target?.value)) {
                currentFoodType.push(event.target?.value)                                         
                await this.setState({smartSearchFilters: {...this.state.smartSearchFilters, functional_preference:temp}});            
                              
            }     
            else {
                currentFoodType.splice(currentFoodType.indexOf(event.target?.value), 1);                                    
                if(temp[neneg_data].length < 1) {
                    delete temp[neneg_data]
                }  
                await this.setState({smartSearchFilters: {...this.state.smartSearchFilters, functional_preference:temp}});                        
            }              
    }

    // Method To Handle the Health Preferences Type Filter
    handleHealthPreferenceTypeChange = async(event:any) => {
        this.setState({isFilterSelect:true})
        this.setState({handleFavChips:false})
        
        if(!this.state.smartSearchFilters?.health_preference?.includes(event.target.value)){    
            await this.setState({smartSearchFilters: {...this.state?.smartSearchFilters, health_preference:event.target.value}})             
        }
        else {
            await this.state.smartSearchFilters?.health_preference?.splice(this.state.smartSearchFilters?.health_preference?.indexOf(event.target.value), 1);
            
        }
        
    }
    
    // Debounce Method
    debounce = (callback:any, delay:any) => {        
        let timer:any;
        return (...args:any) => {
            clearTimeout(timer);            
            timer = setTimeout(() => callback(...args), delay)            
        }      
    }

    //Debound Call Method
    debounceLog = this.debounce(()=>this.postSmartSearchFilters(this.state?.smartSearchFilters, false, "post"), 1200);
  
    favSearchResults = async (favId:any) => {           
            await localStorage.setItem("smartFavID", JSON.stringify(favId));            
            await localStorage.setItem("forcePageNumber", JSON.stringify(1));            
            this.setState({paginationRefId: favId?.id});            
            this.props?.navigation?.history?.push({
                pathname:"/smartsearch",
                state:{favSearchID:favId}
            })            
    }  
        
     // function for navigate to the product details page with GetProductDetailsAdapter {adapter} {add by rifadulDev}
  goToProductDetailsPage = (id: any) => {
    localStorage.setItem('proId', id);
    const msg: Message = new Message(getName(MessageEnum.FromProductPageNavigationMessage)); // here nedd to change messageEnum name {adapter} {add by rifadulDev}
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), id); // here nedd to change value {adapter} {add by rifadulDev}
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SmartSearchProductDetailsWebPage"); // here need to change Route name
    this.send(msg);    
  }
  
  goToNoRatedPage = async (e:any) => {            
    await localStorage.setItem("noRated", JSON.stringify(e))
    await this.props?.navigation?.navigate("NoRated");    
  }
  
  //Method to handle the search button in smartsearch page
  onHandleSearchClick = () => {
      /* istanbul ignore else */    
    if(this.state.tempId && 
      Object.keys(this.state?.smartSearchFilters?.product_category).length < 1 &&
      Object.keys(this.state?.smartSearchFilters?.product_sub_category).length  < 1 &&
      this.state?.smartSearchFilters?.niq_score.length < 1 &&
      Object.keys(this.state?.smartSearchFilters?.food_allergies).length < 1 &&
      Object.keys(this.state?.smartSearchFilters?.functional_preference).length < 1 &&
      Object.keys(this.state?.smartSearchFilters?.food_preference).length < 1 &&
      this.state?.smartSearchFilters?.health_preference.length < 1
        ){
            alert("Please select atleast one filter");
       
      } else if (
        this.state.tempId && 
      Object.keys(this.state?.smartSearchFilters?.product_category).length > 0 ||
      Object.keys(this.state?.smartSearchFilters?.product_sub_category).length  > 0 ||
      this.state?.smartSearchFilters?.niq_score.length > 0 ||
      Object.keys(this.state?.smartSearchFilters?.food_allergies).length > 0 ||
      Object.keys(this.state?.smartSearchFilters?.functional_preference).length > 0 ||
      Object.keys(this.state?.smartSearchFilters?.food_preference).length > 0 ||
      this.state?.smartSearchFilters?.health_preference.length > 0
      ) {
        this.setState({isFilterSelect:false});                 
        this.getSearchResults(this.state.tempId);        
      }  
      else {
        alert("Please select atleast one filter");
      }
  }
                
    //Method to handle the Add to Favourite searches button in smartsearch page
    onHandleAddToFavSearch = () => {
        
        // this.setState({favSearchValidation:true});
          /* istanbul ignore else */    
        if(this.state.tempId && 
            Object.keys(this.state?.smartSearchFilters?.product_category).length < 1 &&
            Object.keys(this.state?.smartSearchFilters?.product_sub_category).length  < 1 &&
            this.state?.smartSearchFilters?.niq_score.length < 1 &&
            Object.keys(this.state?.smartSearchFilters?.food_allergies).length < 1 &&
            Object.keys(this.state?.smartSearchFilters?.functional_preference).length < 1 &&
            Object.keys(this.state?.smartSearchFilters?.food_preference).length < 1 &&
            this.state?.smartSearchFilters?.health_preference.length < 1)
            {
                  alert("Please select atleast one filter");
            } 
            else if (
                this.state.tempId && 
                Object.keys(this.state?.smartSearchFilters?.product_category).length > 0 ||
                Object.keys(this.state?.smartSearchFilters?.product_sub_category).length  > 0 ||
                this.state?.smartSearchFilters?.niq_score.length > 0 ||
                Object.keys(this.state?.smartSearchFilters?.food_allergies).length > 0 ||
                Object.keys(this.state?.smartSearchFilters?.functional_preference).length > 0 ||
                Object.keys(this.state?.smartSearchFilters?.food_preference).length > 0 ||
                this.state?.smartSearchFilters?.health_preference.length > 0 ) 
            {                
                if(this.state.favSearchUpdate > -1) {
                    this.setState({favSearchLoader: true});
                    this.postSmartSearchFilters(this.state.smartSearchFilters, true, "patch");                    
              }
              else {
                this.setState({favSearchLoader: true});
                this.postSmartSearchFilters(this.state.smartSearchFilters, true, "post");                
              }                        
                // this.setState({AddFavouriteSearch:true})                        
            }  
            else {
              alert("Please select atleast one filter");
            }
    }

    handleDeleteFavSearch = (deleteID:any) => {        
        this.setState({deleteFavSearchId:deleteID}, ()=>{
            this.state.deleteFavSearchId
        })
        this.setState({deleteDialog:true})        
    }

    confirmDeleteFavSearch = () => {
        this.setState({deleteDialog:false})
        this.deleteFavouriteSearch(this.state.deleteFavSearchId);
    }
    handleLoginNotifiModalOpen = () => {
        this.setState({ loginNotifiModalOpen: true })
    }
    handleLoginNotifiModalClose = () => {
    this.setState({ loginNotifiModalOpen: false })
    }
    newSearchBtn = (path:any) => {
             /* istanbul ignore else */  
    if(this.state.isLogin){
    this.props.navigation.navigate(path)
    }
    else {
        this.handleLoginNotifiModalOpen();
    }
    }
    /* istanbul ignore next */ 
    getProductsDetails = (results:any) => {  
        results?.attributes?.product_rating === 'NA' ?                                      
        this.goToNoRatedPage(results)
         : this.goToProductDetailsPage(results?.attributes?.id);                                                                            
    }

    getLanguage = () => {
        let languageId:any;
        const defaultEnglishLanguageId:any = configJSON.defaultLanguageIDEnglish    
        if(localStorage.getItem('languageSelect')) {
          languageId = localStorage.getItem('languageSelect');
        }
        else {
         languageId = defaultEnglishLanguageId;
        }
        return languageId;
      }

      clearAllFilters = () => {
        window.location.reload()
    }    
    handleProductRating = (rating:any) => {
        let productRating;
        switch(true) {
          case rating === "A":
            productRating = "#00AB79";
            break;  
          case rating === "B":
            productRating = "#AAC83C";
            break;
          case rating === "C":
            productRating = "#FCC300";
            break;
          case rating === "D":
            productRating = "#FF784B";
            break;      
          case rating === "E":
            productRating = "#FF5050";
            break;
          default : productRating = "#555454";
        }
        return productRating;
    }    
    // Customizable Area End
}