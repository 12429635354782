import React from 'react'
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({

    selectRoot: {
        '&:focus': {
            backgroundColor: 'transparent'
        },
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    selectInput: {
        padding: '0 0.7rem'
    },
    foodBasketTypeTextField: {
        border: "double 1px transparent",
        height: '55px',
        borderRadius: 8,
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(205deg,#41479B,#FF3939)",
        backgroundClip: "content-box, border-box",
        backgroundOrigin: "border-box",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "auto",
            marginBottom: 16,
            marginRight: "auto",
        },
    },
    menuItemText: {
        fontFamily: `'Montserrat', sans-serif`,
        fontStyle: "normal",
        fontWeight: 500,
        backgroundColor: 'transparent',
        height: '100%',
    }
}));


const GenderSelectField = ({ instance, Gender, MenuProps }: any) => {
    const classes = useStyles();
    const [t] = useTranslation(["UserProfilePageWebLang"]);
    return (
        <div className={classes.foodBasketTypeTextField}>
            <Select
                disableUnderline
                data-testid="genderField"
                value={instance.state.gender}
                onChange={(event: any) => instance.handelGenderSelectField(event)}
                fullWidth
                className={`${classes.selectInput} ${classes.menuItemText}`}
                classes={{ root: classes.selectRoot }}
                MenuProps={MenuProps}
            >
                <MenuItem value="Select" className={classes.menuItemText}>
                    Select
                </MenuItem>
                {Gender.map((item: any, key: any) => {
                    return (
                        <MenuItem
                            key={key}
                            value={item.value}
                            className={classes.menuItemText}
                        >
                            {item.label}
                        </MenuItem>
                    )
                })}
            </Select>
        </div>
    )
}

export default GenderSelectField

