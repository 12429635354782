Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.PatchAPiMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.addNewMemberApiEndPoint = "bx_block_add_profile/add_profiles";
exports.getRelationWithUserApiEndPoint = "bx_block_add_profile/relations";
exports.getfavProductApiEndPoint = "bx_block_catalogue/favourite_products";
exports.getcompareApiEndPoint = "bx_block_catalogue/favourite_products";
exports.getAllcompareApiEndPoint = "bx_block_catalogue/compare_products";
exports.getNewMemberListApiEndPoint = "bx_block_add_profile/add_profiles";
exports.deleteProfileMemberApiEndPoint = "bx_block_add_profile/add_profiles";
exports.getUserProfileDetailsApiEndPoint = "account_block/accounts";
exports.getBmiProfileListApiCallIDEndPoint = "bx_block_add_profile/add_profiles"
exports.getBmiCalulationApiCallIDEndPoint = "add_profiles/calculate_bmi"
exports.getNewMemberDetailsApiCallIDEndPoint = "bx_block_add_profile/add_profiles"
exports.addTocompareApiEndPoint = "bx_block_catalogue/compare_products?product_id"
exports.btnExampleTitle = "CLICK ME";
exports.emailregularExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Customizable Area End