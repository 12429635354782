export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const healthPointArrow = require("../assets/arrow.svg");
export const familyMemberImg = require("../assets/familyMemberImg.svg");
export const bmiDashboardsiteimg = require("../assets/bmiDashboardsiteimg.svg");
export const normalweightList = require("../assets/normalweightList.svg");
export const obesityList = require("../assets/obesityList.svg");
export const overweightList = require("../assets/overweightList.svg");
export const underweightList = require("../assets/underweightList.svg");
export const lowImmunity = require("../assets/lowImmunity.svg");
export const bonePain = require("../assets/bonePain.svg");
export const fatigue = require("../assets/fatigue.svg");
export const symptomShape = require("../assets/symptomShape.svg");
export const newTopBanner = require("../assets/favouriteSmartSearchTopBg.svg");
export const topBannerBMI = require("../assets/topBannerBMI.svg");
export const topBannerBMIDashboard = require("../assets/topBannerBMIDashboard.svg");
export const BmiSymPgTopBanner = require("../assets/BmiSymPgTopBanner.svg");
export const addNewMemberPageBanner = require("../assets/addNewMemberPageBanner.svg");
export const compareIcon = require("../assets/compare.png");
export const favIcon = require("../assets/Favorite.svg");
export const successfullyImg = require("../assets/successfullyImg.svg");
export const lodingImage = require("../assets/lodingImage.png");
