import React from "react";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import {
    createStyles,
    withStyles,
    Theme,
    makeStyles
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    toggleText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "12px",
        color: "#000000",
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",

        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",

        },
    },

}));
const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 45,
            height: 25,
            padding: 0,
            display: "flex",
            alignItems:"center",
            [theme.breakpoints.down("sm")]: {
                width: 25,
                height: 15,
    
            },
        },
        switchBase: {
            padding: 2,
            color: '#FF784B',
            "&$checked": {
                transform: "translateX(21.5px)",
                [theme.breakpoints.down("sm")]: {
                    transform: "translateX(11.5px)",
        
                },
                color: '#FF784B',
                "& + $track": {
                    opacity: 1,
                    backgroundColor: "#38006D",
                    borderColor: "#38006D"
                }
            }
        },
        thumb: {
            width: 20,
            height: 20,
            boxShadow: "none",
            [theme.breakpoints.down("sm")]: {
                width: 10,
                height: 10,
    
            },
        },
        track: {
            border: `1px solid $#FF784B`,
            borderRadius: '2rem',
            opacity: 1,
            backgroundColor: "#38006D"
        },
        checked: {}
    })
)(Switch);
interface Props {
    checked: any;
    onChange: any;
    rightToggleText: any;
    leftToggleText: any;
}
export default function CustomizedToggleSwitche({
    checked, onChange, leftToggleText, rightToggleText
}: Props) {
    const classes = useStyles();
    return (

        <Grid component="label" container alignItems="center" justifyContent="center" spacing={1}>
            <Grid item className={classes.toggleText}>{leftToggleText}</Grid>
            <Grid item>
                {/* @ts-ignore */}
                <AntSwitch checked={checked} onClick={onChange} />
            </Grid>
            <Grid item className={classes.toggleText}>{rightToggleText}</Grid>
        </Grid>
    );
}