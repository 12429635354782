// Customizable Area Start
import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import SearchControllerWeb, { Props, } from "./SearchControllerWeb";
import { searchBgImage, NoProductFoundImg } from "./assets"
import { withTranslation } from 'react-i18next';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 10,
      [theme.breakpoints.up(600)]: {
        marginTop: 64,
      },
      [theme.breakpoints.down(600)]: {
        marginTop: 48,
      },
      [theme.breakpoints.down(515)]: {
        marginTop: 56,
      },
    },
    noProductContainer: {
      background: '#E5E5E5',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      paddingTop: 50,
      paddingBottom: 50,

      [theme.breakpoints.down("lg")]: {
        paddingTop: 40,
        paddingBottom: 40,
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: 25,
        paddingBottom: 25,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    noProductImage: {
      width: 200,
      height: 250,
      [theme.breakpoints.down("md")]: {
        width: 150,
        height: 200,
      },
      [theme.breakpoints.down("xs")]: {
        width: 100,
        height: 150,
      },
    },
    noProductTitle: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: "117.5%",
      color: '#FF3939',
      textAlign: "center",
      padding: 50,
      [theme.breakpoints.down("lg")]: {
        padding: 35,
      },
      [theme.breakpoints.down("md")]: {
        padding: 25,
        fontSize: '20px',
      },
      [theme.breakpoints.down("sm")]: {
        padding: 15,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 10,
        fontSize: '16px',
      },
    },
    straightLine: {
      width: "90%",
      borderBottom: "1px solid #455A64",
      marginLeft: 80,
      marginRight: 80,
    },
    containerStyle: {
      marginTop: 50,
      paddingBottom: 100,
      width: "87%",

      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        marginRight: 0,
        paddingBottom: 0,
      },
    },
    AccordionRootStyle: {
      marginBottom: 203.86,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 30,
      },
    },
    AccordionStyle: {
      marginBottom: 34,
      background: '#FFFFFF',
      border: '0.3px solid #757D8A',
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
      borderRadius: '5px',
      [theme.breakpoints.down("sm")]: {
        marginBottom: 16,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    AccordionSummaryStyle: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 22,
      margin: 12,
      color: "#455A64",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        margin: 5,
        fontWeight: 500,
      },
    },
    AccordionDetailStyle: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 2,
      marginLeft: 30,
      marginRight: 70,
      marginBottom: 24,
      color: "#757D8A",
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: 14,
        marginLeft: 0,
        marginRight: 0,
      },
    },
    AccordionDetailBoxStyle: {
      display: "flex",
      flexDirection: "column",
      rowGap: 10,
    },
    submitProductButton: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '22px',
      textAlign: 'center',
      textDecorationLine: 'underline',
      textTransform: "none",
      color: '#FF3939',
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    submitProductButtonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 30,
      marginBottom: 30,

      [theme.breakpoints.down("sm")]: {
        marginTop: 30,
        marginBottom: 30,
      },
    },
    SearchAnotherProductButton: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#FFFFFF',
      background: '#38006D',
      borderRadius: "44px",
      padding: '25px 100px',
      '&:hover': {
        background: '#12125c',
        border: 'none',
      },
      [theme.breakpoints.down("sm")]: {
        padding: '15px 50px',
        fontSize: '16px',
      },
      [theme.breakpoints.down("sm")]: {
        padding: '5px 20px',
        fontSize: '12px',
      },
    },
    AccordionSummaryStyleChild: {
      textUnderlineOffset: "0.5rem",
      textDecoration: "underline",
      textDecorationColor: "#FB951A"
    }

  });

class NoProductWeb extends SearchControllerWeb {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t } = this.props;
    return (

      <div className={classes.root}>
        <img src={searchBgImage} alt="bgImage" style={{ width: "100%", }} />
        <div className={classes.noProductContainer}>
          <img src={NoProductFoundImg} alt="bgImage"
            className={classes.noProductImage} />
          <Typography className={classes.noProductTitle} id="title">{t("No_product_found")}</Typography>
          <div className={classes.straightLine} />
          <Grid container justifyContent="center" alignItems="center" >
            <div className={classes.containerStyle}>
              <Grid item>
                <div style={{ flexGrow: 1 }} />
                <div className={classes.AccordionRootStyle}>
                  <Accordion className={classes.AccordionStyle} defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{
                        backgroundColor: "#FCC300", borderRadius: "50%",
                      }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        <span className={classes.AccordionSummaryStyleChild}>{t("Looks_like_this_product_is_not")}</span> {t("available_in_our_database")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography style={{ fontSize: "inherit" }}>
                          {t("Would_you_like_to_help_us_build_a_more_complete_database_by_submitting_this_product_information")}.
                        </Typography>

                      </div>
                    </AccordionDetails>
                    <div className={classes.submitProductButtonContainer}>
                      <Button className={classes.submitProductButton}
                        data-testid="submitProduct"
                        onClick={(e: any) => this.searchAnotherProductButton("YourProductWeb")}>
                        {t("Submit_a_product")}
                      </Button>
                    </div>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{
                        backgroundColor: "#FCC300", borderRadius: "50%",
                      }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        <span className={classes.AccordionSummaryStyleChild}>{t("Some_products_are_not_bein")}</span>{t("g_rated")}?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography style={{ fontSize: "inherit" }}>
                          {t("There_are_various_types_of_products_that_app_does_not_rate_because_they_are_very_specific_products_for_which_no_rating_method_exists")}:
                        </Typography>
                        <ol>
                          <li>{t("Alcohol")}</li>
                          <li>{t("Sugar")}</li>
                          <li>{t("Infant_formula")}</li>
                          <li>{t("Food_products_designed_for_special_medical_purposes")}</li>
                          <li>{t("Unprocessed_products_made_of_single_ingredient_or_category_of_ingredients")}</li>
                          <li>{t("Water")}</li>
                          <li>{t("Herbs_spices_salt_and_salt_substitutes_table_top_sweeteners")}</li>
                          <li>{t("Milled_coffee_beans")}</li>
                          <li>{t("Herbal_and_fruit_infusions")}</li>
                          <li>{t("Pet_food")}</li>
                          <li>{t("Nutritional_supplements")}({t("Sports_nutrition_Multi_vitamins_etc")}.)</li>
                          <li>{t("Products_which_have_incomplete_or_no_nutritional_declaration_will_not_be_rated")}</li>
                        </ol>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className={classes.submitProductButtonContainer}>
                  <Button
                    className={classes.SearchAnotherProductButton}
                    data-testid="searchAnotherProduct"
                    onClick={(e: any) => this.searchAnotherProductButton("Home")}
                  >
                    {t("Search_Another_Product")}
                  </Button>
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>

    );
  }
}
export default withStyles(styles, { withTheme: true })(withTranslation('NoProductLng')(NoProductWeb));
// Customizable Area End
