import React from 'react'
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { BmiReaultGradeShowCircle } from './BmiReaultGradeShowCircle';
import BmiMeasurementInfo from './BmiMeasurementInfo';
import { BmiUserInfo } from './BmiUserInfo';

const useStyles = makeStyles((theme) => ({
    containerStyle: {
        backgroundColor: '#38006D', color: 'white', padding: '1.5rem', borderRadius: '1rem',
        [theme.breakpoints.down("sm")]: {
            padding: '1.0rem',
        },

    },
    editProfileText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "15px",
        color: "#FED600",
        textAlign: 'center',
        textDecoration: 'underline',
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: '13px',
        },
    },
    heightWeightDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '2rem 0',
        [theme.breakpoints.down("sm")]: {
            margin: '1.5rem 0',
        },
    },
}));
const BmiHealthCard = ({ profileData }: any) => {

    const classes = useStyles();
    const [t] = useTranslation(["BMiCalculatorLng"]);
    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.containerStyle}
        >
            <Grid item xs={6}>
                <BmiUserInfo userName={profileData?.attributes?.full_name} userGender={profileData?.attributes?.gender} userAge={profileData?.attributes?.age} />
                <div className={classes.heightWeightDiv}>
                    <BmiMeasurementInfo BmiMeasurementLable={t("Height")} BmiMeasurementValue={profileData?.attributes?.height} BmiMeasurementUnit={t("meter")} />
                    <BmiMeasurementInfo BmiMeasurementLable={t("Weight")} BmiMeasurementValue={profileData?.attributes?.weight} BmiMeasurementUnit={t("KiloGram")} />
                </div>

                <Link to={`AddNewMemberPageWeb?edit=${profileData?.attributes?.id}`}>
                    <Typography className={classes.editProfileText}>
                        {t("editProfile")}
                    </Typography>
                </Link>
            </Grid>
            <Grid item xs={6}>
                <BmiReaultGradeShowCircle BmiProfileDetails={profileData} />
            </Grid>

        </Grid>
    )
}

export default BmiHealthCard