import React, { useCallback,memo } from "react";
//Customise area start
import { Typography,Checkbox, Button,FormControlLabel, FormControl, FormGroup,Radio,RadioGroup,CircularProgress,Chip,Accordion,AccordionSummary,AccordionDetails,} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { fill, unfill } from "../../../blocks/AdvancedSearch/src/assets";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  
      FilterContainer: {
        cursor:"pointer",
        height: "541px",
        width:"100%",
        background: `linear-gradient(180.37deg, #38006F 0.32%, #B25843 232.68%)`,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",
        display: "flex",
        flexDirection:"column",
        marginRight: "2px",
   
        overflowY: "scroll",
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#DFDEDE',
  
        },
        [theme.breakpoints.down("xs")]: {
            borderBottomLeftRadius:0, 
            borderBottomRightRadius:0, 
        }          
      },
      filterTitle: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        letterSpacing: "0.03em",
        color: "#FFE601",
        padding: 20,
        paddingBottom:10,
      },
      FoodTypeUnderline: {
        textUnderlineOffset: "0.5rem",
        textDecoration: "underline",
        textDecorationColor: "#FFF",
      },
      filterNames: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "12px",
        color: "#FFFFFF",
        textTransform:"capitalize",
        overflowWrap:"anywhere"
      },
      sideCompButtons:{
        display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",
        [theme.breakpoints.down('xs')]: {
            width:"100%",
            borderRadius: "8px",
            borderTopLeftRadius:0, 
            borderTopRightRadius:0,           
          },
      },
      Search:{
        fontFamily: "Montserrat",
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight:"20px",
        textAlign: 'center',
        textTransform: 'capitalize',
        color: '#FFFFFF',
        background:'#38006D',
        borderRadius:"43px",
        height:40,
        width:"80%",
        margin:"20 20",
        '&:hover': {
          background: '#12125c',
          border: 'none',
      },
      
      },
      AddFavSearch:{
        fontFamily: "Montserrat",
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight:"15px",
        textAlign: 'center',
        textTransform: 'capitalize',
        color: '#41479B',                  
        margin:"0 20",
        width:"100%",
        textDecorationLine:"underline",
        '&:hover': {
          color: '#12125c',
          border: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        color: '#fff',           
        '&:hover': {
            color: '#aaa',
            border: 'none',
        },
      },

      },   
      accordianSummary:{
        padding:0
      },
      chipRoot:{
        margin:0,       
        justifyContent:"space-between"
      },
      funcPrefChipRoot:{   
       padding:0
      },
  })
);

//Customise area stop
const FiltersWeb = (filterData: any) => {
  //Customise area start
  const classes = useStyles();
  const [t] = useTranslation(["smartSearchLng"]);

  return ( 
    <>
    <div className={classes.FilterContainer}>     
    {/* Category Results */}
    <FormControl>        
    <Typography className={classes.filterTitle}><span className={classes.FoodTypeUnderline}>{t("FilterCategory1")}</span>{t("FilterCategory2")}{filterData?.filterLoaders?.category && 
    <CircularProgress style={{color:"#fff", position:"absolute", marginLeft:10}} size={"20"}/>}</Typography>
    
    <FormGroup>
    {filterData?.category.category?.map((foodtypelist:any, index:any)=>{                   
    return(   
        <div key={index}>                    
        <div style={{margin:5, marginLeft: "25px",  display:foodtypelist?.category === "Raw Food" ? "none" :"flex", alignItems:"center" }}>
        <Typography className={classes.filterNames} style={{color:"#FB951A",fontSize:12,}}>{foodtypelist?.category}</Typography>
        </div>
        {foodtypelist?.category_filter?.map((cat_filter:any, index:any)=>{                 
    return(   
        <div key={index}> 
        <FormControlLabel
        style={{marginLeft: "20px", display:"flex", alignItems:"center", }}
        control={<Checkbox icon={<img src={unfill} alt="" width={10} height={10} />}
                checkedIcon={<img src={fill} alt="" width={10} height={10} />}  
                checked={filterData?.smartSearchFilters?.product_category[foodtypelist?.category]?.includes(cat_filter?.category_filter)}                    
                value={cat_filter?.category_filter}
                onChange={(e:any)=>{
                    filterData?.handleCategoryTypeChange(e,foodtypelist?.category);
                    filterData?.debounceLog();                       
                    }} 
                />}
                data-testid="handleCategoryTypeChange"
                label={<Typography className={classes.filterNames}>{cat_filter?.category_filter} ({cat_filter?.count})</Typography>}
                
            />                                       
        </div> )})}
    </div> )})}
    </FormGroup>
    </FormControl>

    {/* Sub Category Results */}
    <FormControl>
    <Typography className={classes.filterTitle}><span className={classes.FoodTypeUnderline}>{t("FilterSubCategory1")}</span>{t("FilterSubCategory2")}{filterData?.filterLoaders?.subCategory &&         
    <CircularProgress style={{color:"#fff", position:"absolute", marginLeft:10}} size={"20"}/>}</Typography>
    <FormGroup>
    {filterData?.subCategory?.sub_category?.map((foodtypelist:any, index:any)=>{ 
    return(   
        <div key={index}>                    
        <div style={{marginLeft: "25px", display:"flex", alignItems:"center" }}>                      
        <Typography className={classes.filterNames}style={{color:"#FB951A", fontSize:12}}>{foodtypelist?.food_drink_filter}</Typography>                     
        </div>
        {foodtypelist?.category_filter.map((cat:any, index:any)=>{
        return (
            <div key={index} style={{display:"flex", flexDirection:"column"}} >
            <div style={{margin:10,marginLeft: "35px",  }}>
            <Typography  className={classes.filterNames}>{cat?.category} ({cat?.count})</Typography>
            </div>
            {cat?.sub_category_filter?.map((sub_cat:any, index:any)=>{
                return (
                    <div key={index} style={{display:"flex", flexDirection:"column"}} >
                    <FormControlLabel style={{marginLeft: "45px", display:"flex",alignItems:"center" }}
                    control={<Checkbox icon={<img src={unfill} alt="" width={10} height={10} />}
                            checkedIcon={<img src={fill} alt="" width={10} height={10} />}                          
                            checked={filterData?.smartSearchFilters?.product_sub_category[foodtypelist?.food_drink_filter]?.[cat?.category]?.includes(sub_cat?.sub_category_filter)}                    
                            value={sub_cat?.sub_category_filter}
                            onChange={(e:any)=>{filterData?.debounceLog();filterData?.handleSubCategoryTypeChange(e, foodtypelist?.food_drink_filter, cat?.category )}}
                            />}
                            data-testid="handleSubCategoryTypeChange"
                            label={ <Typography  className={classes.filterNames}>{sub_cat?.sub_category_filter} ({sub_cat?.count})</Typography>}
                        />                
                    </div>)})}           
                </div>)})}
        </div> )})} 
    </FormGroup>
    </FormControl>

    {/* Niq Score Results */}
    <FormControl component="fieldset">
        <Typography className={classes.filterTitle}><span className={classes.FoodTypeUnderline}>N<sup>IQ</sup>&nbsp;{t("FilterNiqScore1")}</span>{t("FilterNiqScore2")} ({filterData?.niqScore?.count})
        {filterData?.filterLoaders?.niqScore && <CircularProgress style={{color:"#fff", position:"absolute", marginLeft:10}} size={"20"}/>}</Typography>
        <RadioGroup aria-label="Niq Score" >
            {filterData?.niqScore?.niq_score?.map((niqSocre:any, index:any)=>{                       
            return(                 
                <FormControlLabel key={index} style={{marginLeft: "10px", display:"flex", alignItems:"center", }}            
                control={<Radio icon={<RadioButtonUncheckedIcon fontSize="small"              
                style={{color: niqSocre?.product_rating === 'A' ? '#3C9637' : niqSocre?.product_rating === 'B' ? '#AAC83C' : niqSocre?.product_rating === 'C' ? '#FCC300' : niqSocre?.product_rating === 'D' ?  '#FF784B' : niqSocre?.product_rating === 'E' ? '#FF5050' : "#fff" }}
                />} checkedIcon={<RadioButtonCheckedIcon fontSize="small"
                style={{color: niqSocre?.product_rating === 'A' ? '#3C9637' : niqSocre?.product_rating === 'B' ? '#AAC83C' : niqSocre?.product_rating === 'C' ? '#FCC300' : niqSocre?.product_rating === 'D' ?  '#FF784B' : niqSocre?.product_rating === 'E' ? '#FF5050' : "#fff" }} 
                />} size="small"  value={niqSocre?.product_rating}  onChange={(event:any) => {filterData.debounceLog();filterData.handleNiqScoreTypeChange(event)}}      
                />}
                data-testid="handleNiqScoreTypeChange"
                label={<Typography className={classes.filterNames}>N<sup>IQ</sup> Score {niqSocre?.product_rating} ({niqSocre?.count})</Typography>} /> )})}
        </RadioGroup>
    </FormControl>

    {/* Food Allergies Results */}
    <FormControl>
    <Typography className={classes.filterTitle}><span className={classes.FoodTypeUnderline}>{t("FilterFdAllergies1")}</span>{t("FilterFdAllergies2")}&nbsp;({filterData?.foodAllergies?.count}){filterData?.filterLoaders?.foodAllergies && 
    <CircularProgress style={{color:"#fff", position:"absolute", marginLeft:10}} size={"20"}/>}</Typography>
    <FormGroup>
    {filterData?.foodAllergies?.food_allergies?.map((foodAllergies:any, index:any)=>{                       
        return(   
            <div key={index}>                    
                <FormControlLabel style={{marginLeft: "10px", display:"flex", alignItems:"center" }}
                control={<Checkbox icon={<img src={unfill} alt="" width={10} height={10} />}
                checkedIcon={<img src={fill} alt="" width={10} height={10} />} 
                checked={filterData?.smartSearchFilters?.food_allergies?.[foodAllergies?.product_rating]?.includes(foodAllergies?.product_rating)}                    
                value={foodAllergies?.product_rating} onChange={(e:any)=>{filterData.debounceLog();filterData.handleFoodAllergiesTypeChange(e)}}
                />}
                data-testid="allergies"
                label={<Typography className={classes.filterNames}>{foodAllergies?.product_rating} ({foodAllergies?.count})</Typography>} />
            </div> )})}
    </FormGroup>
    </FormControl>
            
    {/* Food Preferences Results */}
    <FormControl>
    <Typography className={classes.filterTitle}><span className={classes.FoodTypeUnderline}>{t("FilterFdPref1")}</span>{t("FilterFdPref2")}&nbsp;({filterData?.foodPreferences?.count}){filterData?.filterLoaders?.foodPreferences &&  
    <CircularProgress style={{color:"#fff", position:"absolute", marginLeft:10}} size={"20"}/>}</Typography>
    <FormGroup>
        {filterData?.foodPreferences?.food_preference?.map((foodPreferences:any, index:any)=>{                       
            return(   
                <div key={index}>                    
                    <FormControlLabel style={{marginLeft: "10px", display:"flex", alignItems:"center" }}
                    control={<Checkbox icon={<img src={unfill} alt="" width={10} height={10} />}
                        checkedIcon={<img src={fill} alt="" width={10} height={10} />} 
                        checked={filterData.smartSearchFilters?.food_preference?.[foodPreferences?.product_rating]?.includes(foodPreferences?.product_rating)}                    
                        value={foodPreferences?.product_rating} onChange={(e:any)=>{filterData.debounceLog();filterData.handleFoodPreferencesTypeChange(e)}} />}
                        data-testid="handleFoodPreferencesTypeChange"
                       label={<Typography className={classes.filterNames}>{foodPreferences?.product_rating} ({foodPreferences?.count})</Typography>} />
                </div>)})}
    </FormGroup>
    </FormControl>

    {/* Functional Preference Results */}       
    <FormControl>
    <Typography className={classes.filterTitle}><span className={classes.FoodTypeUnderline}>{t("FilterFuncPref1")}</span>{t("FilterFuncPref2")}{filterData?.filterLoaders?.functionalPreferences &&       
    <CircularProgress style={{color:"#fff", position:"absolute", marginLeft:10}} size={"20"}/>}</Typography>
    <FormGroup>
    {filterData?.functionalPreferences?.slice(0).reverse().map((fun_pre_type: any, index:any)=>{        
        return(
            <div key={index} style={{padding: "0 20px"}}>
                <Accordion style={{maxWidth:250, background:"none",boxShadow: " 0px 1px 0px 0px #FB951A"}} variant={"elevation"}
                    defaultExpanded ={fun_pre_type?.functional_preference === "Good Ingredients" ? true : false}>                   
                <AccordionSummary classes= {{root: classes.accordianSummary,}} expandIcon={<ArrowDropDownIcon style={{color:"#FB951A",}}/>} aria-controls="panel1a-content" id="panel1a-header" >                      
                    <Typography className={classes.filterNames} style={{color:"#FB951A",fontSize:12,padding:0}}>{fun_pre_type?.functional_preference}</Typography>
                </AccordionSummary>
            {fun_pre_type?.functional_preference === "Not So Good Ingredients" ? fun_pre_type?.data[1]?.title?.map((neg_data:any, neg_index:any)=>{                     
                return(
                    <AccordionDetails key={neg_index} style={{display:"flex", flexDirection:"column",padding:5,paddingLeft:0,paddingRight:0}}>                       
                    <FormControlLabel control={ <div> {filterData?.chipTypeLabel.notSoGood.map((notSoGoodItem:any, i:any)=>{
                        return(
                            <Checkbox icon={ <Chip label={<Typography className={classes.filterNames}>{notSoGoodItem}</Typography>}
                                variant={"outlined"} style={{border:"1px solid #fff",marginRight:3,marginTop:3}} size="small" />}
                                checkedIcon={<Chip label={<Typography className={classes.filterNames}>{notSoGoodItem}</Typography>}
                                style={{border:"1px solid #38006D",marginRight:3,marginTop:3,background:"#38006D"}} size="small" />} 
                                key={i} className={classes.funcPrefChipRoot} value={notSoGoodItem}
                                onChange={(e:any)=>{filterData.debounceLog();filterData.handleFunctionalPreference(e,neg_data)}} /> )})}</div>}
                                labelPlacement="start" classes= {{root:classes.chipRoot}}
                                label={<Typography className={classes.filterNames}>{neg_data}</Typography>} />
                    </AccordionDetails> )}) 
                    :                      
            fun_pre_type?.data?.map((good_Ing:any,good_index:any)=>{                                                                
            return(                
                <AccordionDetails style={{display:"flex", flexDirection:"column",padding:5,paddingLeft:0,paddingRight:0}} key={good_index}>
                {good_Ing?.title?.map((gd_title:any, i:any)=>{   
                    return(  
                        <FormControlLabel key={i}
                        control={ <div> {filterData?.chipTypeLabel.good.map((notSoGoodItem:any, i:any)=>{
                            return(                     
                                <Checkbox icon={ <Chip label={<Typography className={classes.filterNames}>{notSoGoodItem}</Typography>} variant={"outlined"}
                                style={{border:"1px solid #fff",marginRight:3,marginTop:3}} size="small" />}
                                checkedIcon={<Chip label={<Typography className={classes.filterNames}>{notSoGoodItem}</Typography>}
                                style={{border:"1px solid #38006D",marginRight:3,marginTop:3,background:"#38006D"}} size="small"  />} 
                                key={i} className={classes.funcPrefChipRoot} value={notSoGoodItem}
                                onChange={(e:any)=>{filterData.debounceLog();filterData.handleFunctionalPreference(e,gd_title)}} />)})}
                                </div>} labelPlacement="start" data-testid="FunctionalPreferenceGood"
                                label={<Typography className={classes.filterNames}>{gd_title}</Typography>}
                                classes= {{root:classes.chipRoot}} />)})} 
                
                        {good_Ing?.Vitamins ? <Accordion style={{maxWidth:250, background:"none",boxShadow: " 0px 1px 0px 0px #fff", marginBottom:5 }} variant={"elevation"} >
                    <AccordionSummary classes= {{root: classes.accordianSummary,}} expandIcon={<ArrowDropDownIcon style={{color:"#fff",}}/>}
                    aria-controls="panel1a-content" id="panel1a-header2">                      
                        <Typography className={classes.filterNames} style={{color:"#fff",fontSize:12,padding:0}}>Vitamins</Typography>                    
                    </AccordionSummary>

                {good_Ing?.Vitamins?.title?.map((vitamin:any, vit_index:any)=>{
                    return(
                    <AccordionDetails style={{display:"flex", flexDirection:"column",padding:5,paddingLeft:0,paddingRight:0}} key={vit_index}>                                                                              
                    <FormControlLabel
                        control={<div>{filterData?.chipTypeLabel.good.map((goodItem:any, i:any)=>{
                            return( 
                                <Checkbox icon={ <Chip label={<Typography className={classes.filterNames}>{goodItem}</Typography>} variant={"outlined"}
                                 style={{border:"1px solid #fff",marginRight:3,marginTop:3}} size="small"/>}
                                checkedIcon={<Chip label={<Typography className={classes.filterNames}>{goodItem}</Typography>} 
                                style={{border:"1px solid #38006D",marginRight:3,marginTop:3,background:"#38006D"}} size="small" />} 
                                key={i} className={classes.funcPrefChipRoot} value={goodItem} 
                                onChange={(e:any)=>{filterData.debounceLog();filterData.handleFunctionalPreference(e,vitamin)}} /> )})}
                                </div> } labelPlacement="start" label={<Typography className={classes.filterNames}>{vitamin}</Typography>}
                                classes= {{root:classes.chipRoot}}/>
                    </AccordionDetails>)})}
                </Accordion> : null} 
                
                {good_Ing?.Minerals ? <Accordion style={{maxWidth:250, background:"none",boxShadow: " 0px 1px 0px 0px #fff", marginBottom:5}} variant={"elevation"}>
                <AccordionSummary classes= {{root: classes.accordianSummary,}} expandIcon={<ArrowDropDownIcon style={{color:"#fff",}}/>} aria-controls="panel1a-content" id="panel1a-header3">                      
                    <Typography className={classes.filterNames} style={{color:"#fff",fontSize:12,padding:0}}>Minerals</Typography>                    
                </AccordionSummary>
            
                {good_Ing?.Minerals?.title?.map((minerals:any, min_index:any)=>{
                    return(
                    <AccordionDetails style={{display:"flex", flexDirection:"column",padding:5,paddingLeft:0,paddingRight:0}} key={min_index}>
                    <FormControlLabel
                        control={<div>{filterData?.chipTypeLabel.good.map((goodItem:any, i:any)=>{
                            return(            
                                <Checkbox icon={ <Chip label={<Typography className={classes.filterNames}>{goodItem}</Typography>}
                                    variant={"outlined"} style={{border:"1px solid #fff",marginRight:3,marginTop:3}} size="small"/>}
                                checkedIcon={<Chip label={<Typography className={classes.filterNames}>{goodItem}</Typography>}
                                style={{border:"1px solid #38006D",marginRight:3,marginTop:3,background:"#38006D"}} size="small" />} 
                                key={i} className={classes.funcPrefChipRoot} value={goodItem}
                                onChange={(e:any)=>{filterData.debounceLog();filterData.handleFunctionalPreference(e,minerals)}}/>)})}
                                </div>} labelPlacement="start" classes= {{root:classes.chipRoot}}
                                label={<Typography className={classes.filterNames}>{minerals}</Typography>}/>   
                    </AccordionDetails>
                    )})} </Accordion> : null} 
                </AccordionDetails> )})}                        
            </Accordion>
            </div>)})}
    </FormGroup>
    </FormControl>

    {/* Health Preferences Results */}
    <FormControl component="fieldset">
    <Typography className={classes.filterTitle}><span className={classes.FoodTypeUnderline}>{t("FilterHealthPref1")}</span>{t("FilterHealthPref2")} ({filterData?.healthPreferences?.count}){filterData?.filterLoaders?.healthPreferences && 
    <CircularProgress style={{color:"#fff", position:"absolute", marginLeft:10}} size={"20"}/>}</Typography>
   <RadioGroup aria-label="Niq Score" >
    {filterData.healthPreferences?.health_preference?.map((health_pref:any, index:any)=>{                       
    return(                 
        <FormControlLabel key={index} style={{marginLeft: "10px", display:"flex", alignItems:"center", }}            
        control={<Radio icon={<RadioButtonUncheckedIcon fontSize="small" style={{color:"#fff" }} />}
        checkedIcon={<RadioButtonCheckedIcon fontSize="small" style={{color:"#fff" }} />}
        size="small" value={health_pref?.health_preference}
        onChange={(event:any) => {filterData.debounceLog();filterData.handleHealthPreferenceTypeChange(event)}} />} 
        data-testid="handleHealthPreferenceTypeChange"
       label={<Typography className={classes.filterNames}>{health_pref?.health_preference} ({health_pref?.count})</Typography>} /> )})}
    </RadioGroup>
    </FormControl>
    </div> 

    <div className={classes.sideCompButtons}>
      <Button className={classes.Search} onClick={filterData.onHandleSearchClick} data-testid="SearchBtn">{t("searchBtn")}</Button>                          
      <Button className={classes.AddFavSearch} onClick={filterData.onHandleAddToFavSearch} data-testid="AddToFavBtn">{t("AddToFavouriteSearch")}</Button>          
      <Button className={classes.AddFavSearch} onClick={filterData.clearAllFilters} data-testid="clearAllFiltersBtn">{t("clearAllBtn")}</Button>          
    </div>
    </>
  );
};
export default memo(FiltersWeb);
 // Customise area stop