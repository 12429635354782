import React from 'react';
import Loader from '../../../../components/src/Loader';
import HealthPointWebController, { Props } from './HealthPointWebController';
import { Typography, Grid } from "@material-ui/core";
import { topHeaderBanner, sideBarBanner} from '../assets';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import CustomizedToggleSwitche from '../../../../components/src/CustomizedToggleSwitche';
import { withTranslation } from 'react-i18next';



const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        profileTopBanner: {
            backgroundImage: `url(${topHeaderBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '200px'
        },

        // css for textContainer
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        headdingText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            color: "#37006E",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },

        },
        backButtonText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF5050",
            cursor: 'pointer',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        FoodTypeUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        // css for basketContainer
        basketContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },

        rankContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        rankText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            color: "#455A64",
            textDecoration: 'underline',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        rankTextValue: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            color: "#FF3939",
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        cityName: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "18px",
            color: "#455A64",
            textDecoration: 'underline',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        locationText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "11px",
            color: "#455A64",
            // [theme.breakpoints.down("sm")]: {
            //     fontSize: "14px",

            // },
        },

        circleParentDiv: {
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            border: '4px solid rgba(255, 57, 57, 1)',
            backgroundColor: 'rgba(56, 0, 109, 1)',
            position: 'relative',
            margin: '0 auto',
            [theme.breakpoints.down("sm")]: {
                width: '50px',
                height: '50px',
            },
        },
        circleDiv: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
        },
        circleValueText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "25px",
            color: "#FFFFFF",
            [theme.breakpoints.down("sm")]: {
                fontSize: '18px',
            },
        },
        leaderBoardContainer: {
            // width: '90%',
            border: "double 1px transparent",
            borderRadius: '10px',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(170deg,rgba(56, 0, 111, 1),rgba(255, 80, 80, 1))",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            marginTop: '2rem'
        },

        userName: {
            color: '#2E445C',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: '27px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '15px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '20px',
            },
        },
        userHealthPoint: {
            color: '#41479B                    ',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: '33px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '20px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '22px',
            },
        },
    });

class HealthPointLeaderBoardWeb extends HealthPointWebController {
    // Customizable Area Start
    render() {
        const {
            foodProductDetailsLoader
        } = this.state;
        const { classes, t } = this.props;
        if (foodProductDetailsLoader) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                    data-testid="loadingComp"
                >
                    <Loader loading={foodProductDetailsLoader} />
                </Grid>
            );
        }
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <div className={classes.profileTopBanner}>
                        </div>

                        <div className={classes.basketContainer}>
                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText={t("Health_Points")} rightToggleText={t("Leader_Board")} />
                            <div className={classes.textContainer}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography className={`${classes.FoodTypeUnderline} ${classes.headdingText}`} variant='h1'>
                                        {t("Leader_Board")}
                                    </Typography>
                                </div>
                                <Typography className={classes.backButtonText} data-testid="backBtn" onClick={() => this.backButton()}>
                                    {t("Back")}
                                </Typography>
                            </div>

                            <div className={classes.rankContainer}>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography className={classes.rankText}>{t("Your_Rank")}</Typography>
                                    <Typography className={classes.rankTextValue}>04</Typography>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography className={classes.cityName}>{t("Bengaluru")}</Typography>
                                    <Typography className={classes.locationText}>{t("Location")}</Typography>
                                </div>
                            </div>

                            <div style={{ margin: '3rem 0' }}>
                                <Grid container spacing={2} className={classes.leaderBoardContainer} alignItems='center'>
                                    <Grid item>
                                        <div className={classes.circleParentDiv}>
                                            <div className={classes.circleDiv}>
                                                <Typography className={classes.circleValueText}>15.9</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs container direction="row" justifyContent='space-between' alignItems='center' spacing={2} style={{ padding: '1.5rem' }}>
                                        <Typography gutterBottom variant="subtitle1" className={classes.userName}>
                                            Raman Ojha
                                        </Typography>
                                        <Typography gutterBottom variant="subtitle1" className={classes.userHealthPoint}>
                                            468468 pt
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} className={classes.leaderBoardContainer} alignItems='center'>
                                    <Grid item>
                                        <div className={classes.circleParentDiv}>
                                            <div className={classes.circleDiv}>
                                                <Typography className={classes.circleValueText}>15.9</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs container direction="row" justifyContent='space-between' alignItems='center' spacing={2} style={{ padding: '1.5rem' }}>
                                        <Typography gutterBottom variant="subtitle1" className={classes.userName}>
                                            Raman Ojha
                                        </Typography>
                                        <Typography gutterBottom variant="subtitle1" className={classes.userHealthPoint}>
                                            468468 pt
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid >
                </Grid >

            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('HealthyFoodBasketWebLang')(HealthPointLeaderBoardWeb)
);