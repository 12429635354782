import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import StopIcon from '@material-ui/icons/Stop';

const QRicon = require("../assets/QRScanIcon.svg") as string;
const VoiceSearch = require("../assets/Voice Search Icon.svg") as string;

const useStyles = makeStyles((theme:Theme) => createStyles({
    voiceQrIcon: {
        display: "flex",
        columnGap: 10,
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.up("xl")]: {
          columnGap: 30,
        },
        [theme.breakpoints.down("sm")]: {
          columnGap: 20,
        },
        [theme.breakpoints.down("xs")]: {
          columnGap: 10,
        },
      },
  
      HomevoiceQrIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      },
    HomeVoiceQrIconStyle: {
        marginRight: 40,
        height: 24,
        width: 24,
        [theme.breakpoints.down("sm")]: {
          height: 16,
          width: 16,
          marginRight: 20,
        },
        [theme.breakpoints.down("xs")]: {
          height: 16,
          width: 16,
          marginRight: 20,
        },
      },
}));
//The end--------------------

const VoiceBarCodeSearch = ({...props}: any) => {
  const classes = useStyles()
 
    return (

        <div className={`${props.propData === "TopNav" ? classes.voiceQrIcon : classes.HomevoiceQrIcon}`}>
            <img
              src={QRicon}
              alt="QR scanner Icon"
              onClick={props.handleOpenCam}      
              style={{cursor:"pointer"}}
              className={`${props.propData === "TopNav" ? "" : classes.HomeVoiceQrIconStyle}`}
            />
            {
              props.listening && props.isUserClicked ? <StopIcon style={{ color: "#FF3939", cursor:"pointer" }} fontSize={"small"}
                className={`${props.propData === "TopNav" ? "" : classes.HomeVoiceQrIconStyle}`}

                onClick={props.stopListeningVoice}
              /> :
                <img
                  src={VoiceSearch}
                  alt="VoiceSearch Icon"
                  style={{cursor:"pointer"}}
                  className={`${props.propData === "TopNav" ? "" : classes.HomeVoiceQrIconStyle}`}
                  onClick={props.startListeningVoice}
                />}
          </div>
    )
}
export default VoiceBarCodeSearch