import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";



interface ApiCallingProps {
    contentType: any;
    apiEndPoint: any;
    payload?: any;
    apiMethod: any;
}

const ApiCall = ({ contentType, apiEndPoint, payload = null, apiMethod }: ApiCallingProps) => {
    // get token from localStore
    const userToken = localStorage.getItem("token");

    // api header
    const header = {
        'Content-Type': contentType,
        token: userToken,
    };

    // rest api call message create
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );


    // set api end point
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint
    );

    // set header
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    // set body data if method POST or PETCH
    if (payload) {
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(payload)
        );
    }

    // set api method
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        apiMethod
    );
    //  return created message
    return requestMessage;
};

export default ApiCall;