import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import ApiCall from '../../../components/src/APICall';


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  loading: boolean;
  switchChecked: boolean;
  selectedValue: any;
  isSubmitModal: any;
  productName: any;
  productWeight: any;
  barCodeImage: any;
  barCodeImageName: any;
  productFrontImage: any;
  productNutritionImage: any;
  productFrontImageName: any;
  productIngredientsImage: any;
  productUrlValidation: boolean;
  productNameValidation: boolean;
  productNutritionImageName: any;
  barcodeImageValidation: boolean;
  productWeightValidation: boolean;
  productIngredientsImageName: any;
  productFrontImageValidation: boolean;
  productNutritionImageValidation: boolean;
  productIngredientsImageValidation: boolean;
  addProductLoader: boolean;
  yourProductList: any;
  myImageArray: any;
  myBarCodeImageArray: any;
  productUnit: any;
  refernceURL: any;
  invalidFrontPackImageFileType: boolean;
  invalidNutritionImageFileType: boolean;
  invalidIngredientsImageFileType: boolean;
  invalidBarcodeImageFileType: boolean;
  viewProductName:any;
  viewProducrWeight:any;
  viewProductWeightUnits:any
  viewRefUrl:any;
  viewFrontImg:any;
  viewNutrionalImg:any;
  viewIngredientImg:any;
  viewBarcodeImg:any;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End

}

export default class AddProductControllerWeb extends BlockComponent<Props, S, SS> {
  addProductApiCallID: string = "";
  getYouProductListApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: '',
      // Customizable Area Start
      loading: false,
      switchChecked: false,
      selectedValue: ['Packaged Food', "Raw Food", "Cooked Food"],
      isSubmitModal: false,
      productName: '',
      productNameValidation: false,
      productWeight: '',
      productWeightValidation: false,
      productFrontImage: '',
      productNutritionImage: '',
      productIngredientsImage: '',
      productFrontImageName: '',
      productNutritionImageName: '',
      productIngredientsImageName: '',
      barCodeImage: '',
      barCodeImageName: '',
      barcodeImageValidation: false,
      productFrontImageValidation: false,
      productNutritionImageValidation: false,
      productIngredientsImageValidation: false,
      productUrlValidation: false,
      addProductLoader: false,
      yourProductList: [],
      myImageArray: [],
      myBarCodeImageArray: [],
      productUnit: 'gm',
      refernceURL: '',
      invalidFrontPackImageFileType: false,
      invalidNutritionImageFileType: false,
      invalidIngredientsImageFileType: false,
      invalidBarcodeImageFileType: false,
      viewProductName:"",
      viewProducrWeight:"",
      viewProductWeightUnits:"",
      viewRefUrl:"",
      viewFrontImg:"",
      viewNutrionalImg:"",
      viewIngredientImg:"",
      viewBarcodeImg:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.props.navigation?.history?.location?.pathname === "/yourproducts" || "/addproducts") {
      this.setState({ switchChecked: false })
      
    }
    if (this.props.navigation?.history?.location?.pathname === "/yourproducts") {
      this.getYourProductListData()
    }
    if (this.props.navigation?.history?.location?.pathname === "/viewproduct") {
      this.viewAddedProduct(this.props.navigation?.history?.location?.state)
   
    }    
  }
  // Customizable Area Start

  //  <---------- API call for add profile member {rf} ---------->
  formatTheDate = (date: any) => {
    let newDate = new Date(date);
    /* Date format you have */
    let dateMDY = `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
    return dateMDY;
  }


  AddProduct = async (e: any) => {
    if (this.fromValidationCheck()) {
      this.setState({ loading: true })
      const userToken = await localStorage.getItem("token");
      const header = {
        token: userToken,
      };

      const formData = new FormData();
      formData.append("name", this.state.productName);
      formData.append("weight", `${this.state.productWeight} ${this.state.productUnit}`);
      formData.append("refernce_url", this.state.refernceURL);
      formData.append("product_image[]", this.state.productFrontImage);
      formData.append("product_image[]", this.state.productNutritionImage);
      formData.append("product_image[]", this.state.productIngredientsImage);
      formData.append("barcode_image[]", this.state.barCodeImage);


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addProductApiCallID = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postAPiMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProductApiEndPoint}?language_id=${this.getLanguage()}`);
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  }

  // get product listing data
  getYourProductListData = () => {
    this.setState({
      loading: true
    })

    const contentType = configJSON.applicationJsonContentType;
    const apiEndPoint = `${configJSON.productListingApiEndPoint}?language_id=${this.getLanguage()}`;
    const apiMethod = configJSON.getApiMethodType

    const apiCallingData = {
      contentType, apiEndPoint, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);

    this.getYouProductListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {

    //  <---------- response for Add member profile api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addProductApiCallID &&
      this.addProductApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      /* istanbul ignore else */
      if (responseJson?.data) {
        this.initialingFormInputValue();
        this.setState({ loading: false })
        this.setState({ isSubmitModal: true })
      } else {
        this.setState({ loading: false })
        this.setState({ isSubmitModal: false })
      }
    }

    //  <---------- response for  your product list {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getYouProductListApiCallId &&
      this.getYouProductListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson?.data) {
        this.setState({
          loading: false,
          yourProductList: responseJson?.data,
        })

      }
      if (responseJson?.error) {
        this.setState({
          loading: false
        })
      }
      this.setState({
        loading: false
      })

    }

    // end for {rf}
  }

  // --------------------Your Produtcs------------------
  backButton = () => {
    this.props?.navigation?.goBack();
  }
  navigationBtnFnc = (path: any) => {
    this.props?.navigation?.navigate(path);
  }
  toggleChecked = () => {
    this.state.switchChecked ? this.setState({
      switchChecked: false
    },
      /* istanbul ignore next */
      () => {
        this.navigationBtnFnc("YourProductWeb");
      }) : this.setState({
        switchChecked: true
      }, () => { this.navigationBtnFnc("ProductReportedWebPage"); })
  };
  // --------------------Add a Produtcs------------------

  fromValidationCheck = () => {
    if (!this.state.productName) {
      this.setState({
        productNameValidation: true
      })
    } else if (this.state.productWeight === '') {
      this.setState({
        productWeightValidation: true
      })
    } else if (!this.state.productFrontImageName) {
      this.setState({
        productFrontImageValidation: true
      })
    } else if (!this.state.productNutritionImageName) {
      this.setState({
        productNutritionImageValidation: true
      })
    } else if (!this.state.productIngredientsImageName) {
      this.setState({
        productIngredientsImageValidation: true
      })
    }
    else if (!this.state.barCodeImage) {
      this.setState({
        barcodeImageValidation: true
      })
    }
    else {
      return true;
    }
    return false;
  }

  initialingFormInputValue = () => {
    this.setState({
      productName: '',
      productNameValidation: false,
      productWeight: '',
      productWeightValidation: false,
      refernceURL: '',
      productFrontImageName: '',
      productFrontImageValidation: false,
      productIngredientsImageName: '',
      productIngredientsImageValidation: false,
      productNutritionImageName: '',
      productNutritionImageValidation: false,
      barCodeImageName: '',
      barcodeImageValidation: false,
      productUnit: 'gm',

    });
  }

  getLanguage = () => {
    let languageId: any;
    const defaultEnglishLanguageId: any = configJSON.defaultLanguageIDEnglish
    if (localStorage.getItem('languageSelect')) {
      languageId = localStorage.getItem('languageSelect');
    }
    else {
      languageId = defaultEnglishLanguageId;
    }
    return languageId;
  }

  // here key press functionalities {rf}

  handlePositiveDecimalNumberOnKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (keyCode === 8 || keyCode === 0) return;
    if (!/^[0-9.]*$/.test(keyValue)) event.preventDefault();
  }

  disableDownKey = (event: any) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  }

  specialCharacterVlidation = (event: any) => {
    const pattern = /[a-zA-Z0-9,'.\s()_|\/-]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  handleNameFieldValueChange = (event: any) => {
    const input = event.target.value;
    const firstChar = input.charAt(0);
    const isLetter = /^[a-zA-Z]$/.test(firstChar);
    if (!isLetter) {
        this.setState({
          productName: '',
        })
    } else {
        this.setState({
          productName: input,
            productNameValidation: false
        })
    }
}

  validateUrl = (url: string) => {
    try {
      new URL(url);
      this.setState({
        productUrlValidation: false
      })

    } catch (e) {
      this.setState({
        productUrlValidation: true
      })

    }
  };

  clearnutritionalImage= () => {
    this.setState({ productNutritionImage: "", productNutritionImageName: "" })
  }
  clearFrontPackImage = () => {
    this.setState({ productFrontImage: "", productFrontImageName: "" })
  }
  clearingredientsImage = () => {
    this.setState({ productIngredientsImage: "", productIngredientsImageName: "" })
  }
  clearbarcodeImage = () => {
    this.setState({ barCodeImage: "", barCodeImageName: "" })
  }

  FrontImage = (e:any) => {       
      if (e) {        
        this.setState({
          productFrontImage: e,          
          productFrontImageName: e?.name,
          productFrontImageValidation: false,
          invalidFrontPackImageFileType: false
        })
      } else {this.setState({invalidFrontPackImageFileType: true})}
  }
  nutritionalImage = (e:any) => {  
        if (e) {
          this.setState({
            productNutritionImage: e,
            productNutritionImageName: e?.name,
            productNutritionImageValidation: false,
            invalidNutritionImageFileType: false
          })
        } else {
          this.setState({
            invalidNutritionImageFileType: true,
          })
        }
  }

  ingredientsImage = (e: any) => {
    
      if (e) {    
        this.setState({
          productIngredientsImage: e,
          
          productIngredientsImageName: e?.name,
          productIngredientsImageValidation: false,
          invalidIngredientsImageFileType: false
        })
      } else {
        this.setState({
          invalidIngredientsImageFileType: true,
        })
      }
    
  }
  barcodeImage = (e: any) => {
    
      if (e) {            
        this.setState({
          barCodeImage: e,              
          barCodeImageName: e?.name,
          barcodeImageValidation: false,
          invalidBarcodeImageFileType: false
        })
      } else {
        this.setState({
          invalidBarcodeImageFileType: true,
        })                  
  }
  }
  viewAddedProduct = (ViewProductData:any) => {
    if(ViewProductData){
      const viewSubmittedProduct = ViewProductData      
      const weight = viewSubmittedProduct?.attributes?.weight.split(" ")
    
      this.setState({
        viewProductName:viewSubmittedProduct?.attributes?.name,
        viewProducrWeight:weight[0],
        viewProductWeightUnits:weight[1],
        viewRefUrl:viewSubmittedProduct?.attributes?.refernce_url,
        
      })        
      if(viewSubmittedProduct?.attributes?.product_image){
        this.setState({
          viewFrontImg:viewSubmittedProduct?.attributes?.product_image[0]?.url,
          viewNutrionalImg:viewSubmittedProduct?.attributes?.product_image[1]?.url,
          viewIngredientImg:viewSubmittedProduct?.attributes?.product_image[2]?.url,
        })
      }
      if(viewSubmittedProduct?.attributes?.barcode_image){
        this.setState({
          viewBarcodeImg:viewSubmittedProduct?.attributes?.barcode_image[0]?.url,

        })
      }
     
    }
  }
  // Customizable Area End
}