import React from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles: any = makeStyles((theme) => ({
    errorvalidationText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#FF3939',
        fontSize: '15px',
        marginTop: '0.785rem',
        textAlign: 'center'
    },
    uplaodImageTitle: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        textTransform: 'capitalize',
        color: '#757D8A',
        marginTop: "6px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: '16px',
        },
    },
}));


interface Props {
    errorState: boolean;
    errorMessage: string;
    placeholderMessage: string;
}
export const AddProductErrorValidation = ({ errorState, errorMessage, placeholderMessage }: Props) => {
    const classes = useStyles();
    return (
        <>
            {errorState ? <Typography className={classes.errorvalidationText}>{errorMessage}</Typography> : <Typography className={classes.uplaodImageTitle}>{placeholderMessage}</Typography>}
        </>

    )
}
