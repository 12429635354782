// Customizable Area Start
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
// Customizable Area Start
import ApiCall from '../../../../components/src/APICall';
import { FavouriteProductContext } from '../../../ProductRecommendationEngine/src/context/FavouriteProductContext';

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  t: any;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string;
  // Customizable Area Start
  listofProducts: any;
  openFilter: any;
  activeFilter: any;
  selectNiqScores: any;
  applyFilterValidation: any;
  renderselectNiqScores: any;
  renderselectedPrice: any;
  loginNotifiModalOpen: boolean;
  allComparedProducts: any;
  isLogin: boolean;
  isOpen: boolean;
  comparedataLimit: any;
  compareProductadded: boolean
  maxValue: any;
  minValue: any;
  applyPriceValidation: boolean,
  favProductLoader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}


export default class FavoriteProductsWebController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  getFavoriteListApiCallId: any
  favProductDeleteApi: any
  addToCompareProductsId: any;
  getAllCompareProductsId: any;
  getCompareProductsId: any;
  static contextType: any = FavouriteProductContext;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage), //{rf}
      // Customizable Area End
    ];
    // Customizable Area Start
    this.state = {
      token: '',
      listofProducts: [],
      openFilter: false,
      activeFilter: 'NIQ',
      selectNiqScores: [],
      applyFilterValidation: false,
      renderselectNiqScores: [],
      renderselectedPrice: [],
      loginNotifiModalOpen: false,
      allComparedProducts: [],
      isLogin: false,
      isOpen: false,
      comparedataLimit: 0,
      compareProductadded: false,
      maxValue: '',
      minValue: '',
      applyPriceValidation: false,
      favProductLoader: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem('token')
    if (token) {
      this.getAllCompareProducts()
      return this.getFavoriteList()
    } else {
      return this.props.navigation.navigate('Home')
    }
  }

  // <-------------------------- Here API Calling functionalities {rf} -------------------------->


  getFavoriteList = async () => {

    this.setState({
      favProductLoader: true
    })
    const userToken = await localStorage.getItem("token");
    const header = {
      'Content-Type': 'application/json',
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFavoriteListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getfavProductApiEndPoint}?language_id=${this.getLanguage()}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addToCompareProducts = (id: any) => {
    const contentType = configJSON.applicationJsonContentType;
    const apiEndPoint = `${configJSON.addTocompareApiEndPoint}=${id}&language_id=${this.getLanguage()}`;
    const apiMethod = configJSON.postAPiMethod
    const apiCallingData = {
      contentType, apiEndPoint, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);
    this.addToCompareProductsId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllCompareProducts = () => {
    const contentType = configJSON.validationApiContentType;
    const apiEndPoint = `${configJSON.getAllcompareApiEndPoint}?language_id=${this.getLanguage()}`
    const apiMethod = configJSON.getApiMethodType
    const apiCallingData = {
      contentType, apiEndPoint, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);
    this.getAllCompareProductsId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompareProducts = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.getCompareProductsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getcompareProductsApiEndPoint}?language_id=${this.getLanguage()}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // <-------------------------- Here receive function for API response {rf} -------------------------->

  handleConditions = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        listofProducts: responseJson?.data,
      })
    }
    this.setState({
      favProductLoader: false
    })
  }
  async receive(from: string, message: Message) {

    // API response end {rf}

    //  <---------- response for getFavoriteListApiCallId api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFavoriteListApiCallId &&
      this.getFavoriteListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleConditions(responseJson);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.favProductDeleteApi &&
      this.favProductDeleteApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.context?.getFavouriteListAPI();
      }

    }
    //  <---------- response for addToCompareProducts api {rf} ---------->

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addToCompareProductsId &&
      this.addToCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.addToCompareProductsApiResponse(responseJson)
    }
    //  <---------- response for getAllCompareProducts api {rf} ---------->

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllCompareProductsId &&
      this.getAllCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({ allComparedProducts: responseJson.data })
      }

    }

    // API response end {rf}

  }

  // <-------------------------- Here needed helper functionalities {rf} -------------------------->

  deletefavList = async (id: any) => {

    let allData = await localStorage.getItem('favList')
    if (allData) {
      let stateData = JSON.parse(allData);
      let newValues = stateData.filter((e: any) => {
        return e.attributes.product_id != id;
      })

      await localStorage.setItem('favList', JSON.stringify(newValues));
    }
    const token = await localStorage.getItem("token")

    const headers = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.favProductDeleteApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/favourite_products/${id}?language_id=${this.getLanguage()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "delete"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onNiqScoreHandle = (data: any) => {
    let allValues = [...this.state.selectNiqScores];

    if (allValues.includes(data.target.value) === true) {
      allValues = allValues.filter((e: any) => e != data.target.value && e)
    } else {
      allValues.push(data.target.value)
    }

    return this.setState({ selectNiqScores: allValues })
  }

  applyFilters = (data: any) => {
    if (data.length > 0) {
      this.setState({
        applyFilterValidation: true,
        openFilter: false,
        renderselectNiqScores: this.state.selectNiqScores
      })
    } else {
      this.setState({
        applyFilterValidation: false,
        openFilter: false,
        renderselectNiqScores: this.state.selectNiqScores
      })
    }
  }

  applyFiltersPrice = (ele: any) => {
    ele.preventDefault();

    let allValues = this.state.listofProducts.filter((e: any, i: any) => {
      let baseValue = e.attributes.product.data.attributes.price_mrp;
      if (+baseValue > +this.state.minValue && +baseValue < +this.state.maxValue) {
        return e
      }
    })
    let values = allValues.map((e: any) => {
      return e.attributes.product_id
    })
    this.setState({
      applyPriceValidation: true,
      openFilter: false,
      renderselectedPrice: values
    })
  }

  handleFavFilterChange = () => {
    this.setState({ openFilter: true })
  }

  getLanguage = () => {
    let languageId: any;
    const defaultEnglishLanguageId: any = configJSON.defaultLanguageIDEnglish

    if (localStorage.getItem('languageSelect')) {
      languageId = localStorage.getItem('languageSelect');
    }
    else {
      languageId = defaultEnglishLanguageId;
    }
    return languageId;
  }

  checkCompareProducts = (id: any) => {
    if (this.state.allComparedProducts?.length > 0) {
      if (this.state.allComparedProducts?.length === 3) {
        return this.handleOpen(this.state.allComparedProducts?.length)
      } else {
        return this.addToCompareProducts(id)
      }
    } else {
      this.addToCompareProducts(id)
      return this.handleOpen(this.state.allComparedProducts?.length)
    }

  }

  addToCompareProductsApiResponse = (responseJson: any) => {
    if (responseJson?.data) {

      let totalValues = 0;
      if (this.state.allComparedProducts.length === 1 && responseJson.data.attributes.selected) {
        totalValues = 2;
      }
      if (this.state.allComparedProducts.length > 1 || totalValues > 1) {
        return this.props.navigation.history.push({ pathname: "CompareProducts" })
      }
      this.getAllCompareProducts();
    }
  }

  handleLoginNotifiModalOpen = () => {
    this.setState({ loginNotifiModalOpen: true })
  }
  handleLoginNotifiModalClose = () => {
    this.setState({ loginNotifiModalOpen: false })
  }
  handleOpen = (data: any) => {
    this.setState({ isOpen: true, comparedataLimit: data })
  };
  handleClose = () => {
    this.setState({ isOpen: false })
  };
  // Customizable Area Ends22



  handlePositiveDecimalNumberOnKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (keyCode === 8 || keyCode === 0) return;
    if (!/^[0-9.]*$/.test(keyValue)) event.preventDefault();
  }
  disableDownKey = (event: any) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  }
}
// Customizable Area End
