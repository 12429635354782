Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.applicationJsonContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfAddProduct";
exports.labelBodyText = "CfAddProduct Body";
exports.productListingApiEndPoint = "bx_block_catalogue/products/requested_products_list";
// { 'Content-Type':'multipart/form-data'}
exports.btnExampleTitle = "CLICK ME";
exports.addProductApiEndPoint = "bx_block_catalogue/products/requested_products";
// Customizable Area End