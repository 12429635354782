import React from 'react';
import { Typography, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Avatar from '@material-ui/core/Avatar';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Alert from '@material-ui/lab/Alert';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Badge from '@material-ui/core/Badge';




const useStyles = makeStyles((theme) => ({

  avatarSize: {
    width: 200, height: 200,
    margin: "0 auto",
    borderRadius: '50%',
    border: "double 2px transparent",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(205deg,#41479B,#FF3939)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    [theme.breakpoints.down("md")]: {
      width: 150, height: 150,
    },
    [theme.breakpoints.down("xs")]: {
      width: 100, height: 100,
    },
  },

  addInputImageIcon: {
    fontSize: '3rem',
    color: "#757D8A",
    "&:hover": {
      color: "#fff"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '2.7rem',
    },
  },
  imgPreview: {
    overflow: "hidden"
  },
  iconButtonHover: {
    "&:hover": {
      backgroundColor: "#380060"
    }
  },
  CloudUploadIconHover: {
    color: "#38006D",
    "&:hover": {
      color: "#fff"
    }
  },
  buttonStyle: {
    fontWeight: 700, textTransform: "none", margin: "0 auto", marginTop: 8
  },
  deleteInputImageIcon: {
    color: "#ff5050",
    "&:hover": {
      color: "#FCC300"
    }
  }

}));


const ImageInputfield = ({ instance }: any) => {
  const classes = useStyles();

  return (
    <>
      {
        !instance.state.imageName ?
          <Avatar className={classes.avatarSize}>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={instance.handleClickOpen} className={classes.iconButtonHover}>
              <AddAPhotoIcon className={classes.addInputImageIcon} style={{}} />
            </IconButton>
          </Avatar>
          :
          <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
            badgeContent={<IconButton onClick={instance.handleClose} className={classes.iconButtonHover}
              size='small'>  <DeleteIcon className={classes.deleteInputImageIcon} fontSize="large" /> </IconButton>}>
            <Avatar alt="Remy Sharp" src={instance.state.editProfileImage ? instance.state.imageName : URL.createObjectURL(instance.state.personImage)}
              className={classes.avatarSize} data-testid="setProfileAvatar"
              onClick={() => { instance.setState({ imageuploadAndDeleteMenu: true, }) }} >s</Avatar>
          </Badge>
      }

      <Dialog open={instance.state.open} onClose={instance.handleClose} style={{ minWidth: "300px", zIndex: 9999 }}>
        <DialogTitle style={{ padding: 8 }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography style={{ fontFamily: `'Montserrat', sans-serif`, fontStyle: "normal", fontWeight: 500, fontSize: "16px", color: "#38006D", textAlign: "center" }} >Image Upload</Typography>
            <IconButton size='small' onClick={instance.handleClose}><HighlightOffIcon style={{ color: "#38006D" }} /></IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers>

          {instance.state.isValidImage && <Alert onClose={instance.handleSnackBarClose} severity="error" variant='outlined'>Please Uplaod A Valid Image</Alert>}
          {!instance.state.image ?
            <>
              <label htmlFor='test' style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', cursor: "pointer" }} >
                <IconButton component="span" className={classes.iconButtonHover} size='small'>
                  <CloudUploadIcon className={classes.CloudUploadIconHover} fontSize='large' />
                </IconButton>
                <Typography style={{ fontFamily: 'Poppins', fontStyle: "normal", fontWeight: 500, fontSize: "16px", color: "#C4C4C4", textAlign: "center" }}>Upload a Image</Typography>
              </label>
              <input type="file" accept=".png, .jpg, .jpeg" onChange={instance.onChange} id="test" style={{ visibility: 'hidden' }} />
            </>

            : <Cropper
              style={{ height: 400, width: "100%" }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview={classes.imgPreview}
              src={instance.state.image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(event: any) => {
                instance.setState({ cropper: event })
              }}
              guides={true}
            />}

        </DialogContent>
        <DialogActions>
          <Button style={{ border: "1px solid #38006D", color: "#38006D", }} className={classes.buttonStyle} disabled={instance.state.image ? false : true} onClick={instance.originalImage} size="small" variant='outlined'>Original Image</Button>
          <Button style={{ background: "#38006D", color: "#fff", }} className={classes.buttonStyle} disabled={instance.state.image ? false : true} onClick={instance.getCropData} size="small" variant='contained'>Crop</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ImageInputfield