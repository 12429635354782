import React from "react";
// Customizable Area Start
import Avatar from '@material-ui/core/Avatar';
import {Typography, CircularProgress} from '@material-ui/core';
import CompareProductsWebController from "./CompareProductsWebController";
import {  
  Vector,
  Vector2,   
  Rectangle609,
  Rectangle609A,
  Rectangle609B, 
  Deleteicon, 
  add_more_product,
} from "./assets";
import "./CompareProducts.web.css";
import { withTranslation } from 'react-i18next';
import CompareProdFoodBasket from "./CompareProdFoodBasket";
import CustomDeleteAlartDailogBox from "../../../components/src/CustomDeleteAlartDailogBox"
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
// Customizable Area End

class CompareProductsWeb extends CompareProductsWebController {
  // Customizable Area Start
  render() {
const {t}=this.props
    return (
      
      <div style={{ minHeight: '100vh' }}>
        <div className="toppanecontainer">
          <div className="comparename">
            <p>{t("Product_Comparision")}</p>
          </div>
          <div >
            <p className="totalcount">{t("Total_Products")} : {this.state.tableComapreProducts?.length}</p>
          </div>
        </div>
        {this.state.isLoading ? (          
            <div style={{ textAlign: 'center', padding: '10px 0', display:"flex", alignItems:"center", justifyContent:'center',height:"40vh" }}><CircularProgress /></div>
        ) : (
          this.state.tableComapreProducts?.length > 0 ?
            <div style={{ overflowX: "scroll" }}>
              <table className="tablecontainer">

                {/* //---------------delete compare products-----------------// */}

                <tr className="ProteinRow">
                  <td className="td1" />
                  {this.state.tableComapreProducts.map((e: any, i: any) => {
                    return (
                      <React.Fragment key={i}>
                        <td className="td2">
                          <div style={{ position: "relative" }}>
                            <img className="deleteiconview" style={{ cursor: 'pointer' }} src={Deleteicon} onClick={()=>this.deleteModalOpen(e.data.attributes.id)}
                            />
                          </div>
                        </td>
                      </React.Fragment>
                    )
                  })}
                </tr>
                {/* ________________________Products image and product name______________________________ */}

                <tr className="ProteinRow">
                  <td className="tdh">
                    <p>{t("Elements/Products")}</p>
                    <div className="line" />
                  </td>

                  {this.state.tableComapreProducts.map((e: any, i: any) => {
                    return (
                      <React.Fragment key={i}>
                        <td className="td2" style={{ width: '100px', cursor:"pointer" }} 
                         onClick={() => {
                          localStorage.setItem("proId", e.data.attributes.id);
                          this.props?.navigation?.history?.push({
                            pathname: "ProductDetails",
                            search: `${e.data.attributes.id}`,
                            state: { searchName: `${e.data.attributes.id}` }
                          });
                        }}
                        >
                          <img className="productsView" src={e?.data?.attributes?.image} />
                          <div className="productNames">{e.data.attributes.product_name}</div>
                        </td>
                        {/* ________________________Add another button______________________________ */}

                        {this.state.tableComapreProducts.length <= 4 && this.state.tableComapreProducts.length - 1 === i && (
                          <td>
                            <div
                              onClick={() => { this.props.navigation.navigate('Search') }}
                              style={{ margin: "0 auto", cursor: "pointer" }} >                              
                              <img className="addView" style={{ cursor: "pointer" }} src={add_more_product} />                              
                              <div className="productNames" style={{ cursor: "pointer" }}>{t("Add")}{t("Another")}</div>
                            </div>
                          </td>
                        )}
                      </React.Fragment>
                    )
                  })}

                </tr>
                {/* _________________________N I Q score_____________________________ */}
                <tr className="ProteinRow">
                  <td className="td1">
                    N<sup>IQ</sup> {t("Score")}
                  </td>

                  {this.state.tableComapreProducts.map((e: any, i: any) => {
                    return (
                      <React.Fragment key={i}>
                        <td className="td2">
                          <div style={{ position: "relative" }}>
                            <Avatar style={{
                              height:32, width:32,
                              background: e?.data?.attributes?.product_rating === 'A' ? "#00AB79" :
                                e?.data?.attributes?.product_rating === 'B' ? "#AAC83C" :
                                  e?.data?.attributes?.product_rating === 'C' ? "#fcc300" :
                                    e?.data?.attributes?.product_rating === 'D' ? "#ff784b" :
                                      e?.data?.attributes?.product_rating === 'E' ? "#ff5050" : "#000", margin: "0 auto"
                            }}>{e?.data?.attributes?.product_rating}</Avatar>                            
                          </div>
                        </td>
                      </React.Fragment>
                    )
                  })}

                </tr>
                {/* ________________________positive goog values______________________________ */}

                {this.state.keyValues && this.state.keyValues.map((ele: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="ProteinRow">
                        <td className="td11" style={{ textTransform:"capitalize" }}>{ele?.split('_')?.join(' ')}</td>
                        {this.state.tableComapreProducts && this.state.tableComapreProducts.map((e: any, i: any) => {
                          let value = e?.data?.attributes?.positive_good[ele][0]?.level;
                          return (
                            <React.Fragment key={i}>
                              {this.state.negValues?.includes(ele) ? (
                                <td className="td3" style={{ color: value === 'High' ? "#FF3939" : value === 'Medium' ? "#9EBF22" : value === 'Free' ? '#008D59' : value === 'Low' ? '#9EBF22' : "#38006D" }}>{value ? value : 'N/A'}</td>
                              ) : (
                                <td className="td3" style={{ color: value === 'High' ? "#008D59" : value === 'Medium' ? "#9EBF22" : value === 'Low' ? '#FF3939' : "#38006D" }}>{value ? value : 'N/A'}</td>
                              )}
                            </React.Fragment>
                          )
                        })}

                      </tr>
                    </React.Fragment>
                  )
                })}                 
                {/* _________________________product details button_____________________________ */}

                <tr className="ProteinRow">
                  <td className="td1" />
                  {this.state.tableComapreProducts.map((e: any, i: any) => {
                    return (
                      <React.Fragment key={i}>
                        <td className="td2">
                          <div style={{ position: "relative", cursor: "pointer" }}
                            onClick={() => {
                              localStorage.setItem("proId", e.data.attributes.id);
                              this.props?.navigation?.history?.push({
                                pathname: "ProductDetails",
                                search: `${e.data.attributes.id}`,
                                state: { searchName: `${e.data.attributes.id}` }
                              });
                            }
                            }
                          >
                            <img className="productsView1" src={Rectangle609} />
                            <img className="iconView" src={Vector} />
                          </div>
                        </td>
                      </React.Fragment>
                    )
                  })}
                </tr>
                {/* _________________________add to fav button_____________________________ */}

                <tr className="ProteinRow">
                  <td className="td1" />
                  {this.state.tableComapreProducts.map((e: any, i: any) => {
                    
                    return (
                      <React.Fragment key={i}>
                        <td className="td2">
                          <div style={{ position: "relative", cursor: "pointer" }}                            
                            onClick={() => this.handleFavouriteProduct(e?.data?.attributes?.id,e?.data?.attributes?.added_to_fav)}
                          >
                            <img className="productsView1" src={Rectangle609A} />                            
                            {e?.data?.attributes?.added_to_fav ? 
                            <FavoriteIcon className="iconView" style={{ color: '#FF3939'}}fontSize="large" /> 
                            : <FavoriteBorderIcon className="iconView" style={{ color: '#ADADAD' }} fontSize="large" />}
                          </div>
                        </td>
                      </React.Fragment>
                    )
                  })}
                </tr>

                {/* _________________________add to healthy food basket_____________________________ */}

                <tr className="ProteinRow">
                  <td className="td1" />
                  {this.state.tableComapreProducts.map((e: any, i: any) => {
                    return (
                      <React.Fragment key={i}>
                        <td className="td2">
                          <div style={{ position: "relative", cursor: "pointer" }}
                          >
                            <CompareProdFoodBasket
                              titleIcon={
                                <>
                                  <img className="productsView1" src={Rectangle609B} />
                                  <img className="iconView" src={Vector2} />
                                </>
                              }
                              foodProductId={e.data.attributes.id}
                              addFoodBasketApiCall={this.addFoodBasket}
                              addFoodBasketloader={this.state.addFoodBasketloader}
                              foodBasketLists={this.state.foodBasketLists}
                              foodBasketListLoader={this.state.foodBasketListLoader}
                              navigationBtnFnc={this.navigationBtnFnc}
                              addFoodBasketApiResponseText={this.state.addFoodBasketApiResponseText}
                              modalOpen={this.state.modalOpen}
                              handleModalClose={this.handleModalClose}
                              isLogin={true}
                              updateFoodBasketApiCall={this.updateFoodBasketName}
                              addFoodBasketErrorText={this.state.addFoodBasketErrorText}
                            />
                          </div>
                        </td>
                      </React.Fragment>
                    )
                  })}
                </tr>

              </table>

            </div> :
        

        <div style={{ margin: "0 auto" }}>
            <div style={{display: "flex",flexDirection: "column",justifyContent: "center",alignItems: 'center',paddingTop: 50,paddingBottom: 50, }}>
                  <Typography style={{ color: "#455A64",  fontFamily: 'Poppins',fontStyle: 'normal',
                  fontWeight: 500,fontSize: '24px',textAlign: "center",padding: 50, }}>{this.state.emptyListMessage}
                  &nbsp;<span style={{textDecoration:"underline", color:"blue", cursor:"pointer"}} onClick={() => { this.props.navigation.navigate('Search') }}>Click here</span>
                  </Typography>

            </div>
          </div>
          )}
        
          

<CustomDeleteAlartDailogBox isOpen={this.state.IsRemoveProduct} cancleFunction={this.delteModalClose} deleteFunction={this.removeComapreProductHandler} deleteId={this.state.removeProductID} />
      </div>
    );
  }
}
// Customizable Area End

export default withTranslation('CompareProductsLang')(CompareProductsWeb);
// export default withStyles(styles, { withTheme: true })(CompareProductsWeb);
