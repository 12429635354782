import React from 'react'
import { Typography, Grid } from "@material-ui/core";
import { submittedIcon, weightIcon, approvedIcon, pendingIcon, rejectIcon } from "./assets"
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ScrollingContainer from '../../../components/src/ScrollingContainer';


const useStyles: any = makeStyles((theme) => ({
    scrollingContainer: {
        maxHeight: 640,
        overflow: "hidden",
        overflowY: "scroll",
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.00)',
        }
    },
    productContainer: {
        marginTop: '2rem',
        border: "1px solid #FF3939",
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
        borderRadius: '6px',
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            marginTop: '1rem',
        },
    },
    card: {
        margin: 10,
        border: '0.4px solid #ADADAD',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
        borderRadius: '4px', width: 75,
        height: 96,
        [theme.breakpoints.down("xs")]: {
            margin: 5,
            width: 46,
            height: 56,
        },
    },
    media: {
        width: "auto",
        height: 96,
        [theme.breakpoints.down("xs")]: {
            width: "auto",
            height: 56,
        },
    },
    productInfoContainer: {
        height: 96, display: "flex", flexDirection: "column", justifyContent: "space-around",
        [theme.breakpoints.down("xs")]: {
            height: 56,
        },
    },
    productTitle: {
        fontFamily: `'Montserrat', sans-serif`, fontStyle: 'normal',
        fontWeight: 600, fontSize: '19px', color: '#455A64',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '1350px',
        [theme.breakpoints.down("lg")]: {
            fontSize: '16px',
            width: '800px',
        },
        [theme.breakpoints.down("md")]: {
            width: '500px',
        },
        [theme.breakpoints.down("sm")]: {
            width: '350',
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: '16px',
            width: '175px',
        },
    },
    productInfo: {
        fontFamily: `'Montserrat', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        color: '#857E7E',
        marginLeft: 5,
    },
    statusIcon: {
        width: 28,
        height: 28,
        [theme.breakpoints.down("xs")]: {
            width: 20,
            height: 20,
        },
    },
    NoProductNofificationText: {
        color: '#FF3939',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '23px',
        textTransform: 'capitalize',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        },
    },
}));

interface Props {
    yourProductLists: any;
    formatTheDate: (data: any) => any;
    pageProps: any
}

export const YourProductLists = ({ yourProductLists, formatTheDate, pageProps }: Props) => {
    const classes = useStyles();
    const [t] = useTranslation(["ProductDetailsLang"]);
    return (
        <>
            {
                yourProductLists.length > 0 ? (
                    <ScrollingContainer>
                        {yourProductLists?.map((product: any, key: any) => {
                            return (
                                <Grid container justifyContent="space-between" alignItems='center' className={classes.productContainer} key={key} onClick={() => {
                                    pageProps.navigation.history.push({
                                        pathname: "/viewproduct",
                                        state: product
                                    })
                                }} >
                                    <Grid item style={{ display: "flex", alignItems: "center" }} >
                                        <Card style={{
                                        }} className={classes.card}>
                                            <CardMedia
                                                component="img"
                                                className={classes.media}
                                                image={product?.attributes?.product_image ? product?.attributes?.product_image[0]?.url : ""}
                                                title={product?.attributes?.name ? product?.attributes?.name : "product Image"}
                                            />
                                        </Card>
                                        <div
                                            className={classes.productInfoContainer}
                                        >
                                            <Typography className={classes.productTitle}
                                            >{product?.attributes?.name}</Typography>
                                            <div style={{ display: "flex" }}>
                                                <img src={weightIcon} alt="weightIcon" style={{}} />
                                                <Typography className={classes.productInfo}>{t("Weight")}: {product?.attributes?.weight}</Typography>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <img src={submittedIcon} alt="weightIcon" />

                                                <Typography style={{ color: '#757D8A', }}
                                                    className={classes.productInfo}>{t("Submited_On")}: <span style={{ color: "#B8B8B8" }}>{formatTheDate(product?.attributes?.created_at)}</span></Typography>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item style={{ display: "flex", flexDirection: 'column', alignItems: "center", marginRight: "10px" }}>
                                        {product?.attributes?.status === 'pending' ? <img src={pendingIcon} alt="approvedIcon" className={classes.statusIcon} /> : null}
                                        {product?.attributes?.status === 'approved' ? <img src={approvedIcon} alt="approvedIcon" className={classes.statusIcon} /> : null}
                                        {product?.attributes?.status === 'rejected' ? <img src={rejectIcon} alt="approvedIcon" className={classes.statusIcon} /> : null}
                                        <Typography style={{
                                            fontFamily: `'Poppins', sans-serif`,
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            fontSize: '11px',
                                            lineHeight: '16px',
                                            color: '#757D8A',
                                            textTransform: 'capitalize'
                                        }}>
                                            {product?.attributes?.status}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </ScrollingContainer>
                ) : <Typography className={classes.NoProductNofificationText}>{t("No_products_added_here")}, <Link to="/addproducts">{t("Create_one")}</Link> </Typography>
            }
        </>

    )
}
