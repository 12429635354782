// Customizable Area Start
import React from 'react';
import FavoriteProductsWebController from './FavoriteProductsWebController';
import { Typography, Grid, Button } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import { sideBarBanner } from '../../../ProductRecommendationEngine/src/assets'
import { newTopBanner, successfullyImg } from '../assets'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FavoriteProductsWebComponent from './FavoriteProductWebComponent';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            fontFamily: `'Montserrat', sans-serif`,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        favTopBanner: {
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "7px"
            },
        },
        favSideBar: {
            height: '1000px',
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        // css for headingTextContainer
        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        pageHeadingText: {
            lineHeight: "33px",
            textTransform: 'capitalize',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "25px",
            color: '#37006E',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "20px",
            },
        },
        totalProductsText: {
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "33px",
            color: 'gray',
            fontFamily: `'Montserrat', sans-serif`,
            marginBottom: '1.5rem',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },
        productNameTextStyle: {
            color: '#04182C',
            margin: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500
        },
        favProduct: {
            position: 'relative',
            border: "double 1px transparent",
            borderRadius: 6,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(360deg,#440A70,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            display: 'flex',
            flexDirection: 'column',
            height: '282px',
            overflow: 'hidden',
            boxShadow: 'rgb(0 0 0 / 16%) 0px 0px 12px'
        },
        favProductCompare: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0px 10px'
        },
        favProductCompareNiq: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '5px 10px'
        },
        // css for userProfileContainer
        userProfileContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },
        modalFilter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: `'Montserrat', sans-serif`,
        },
        paperFilter: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '20px 0',
            borderRadius: '6px'
        },
        filterBox: {
            display: 'flex',
        },
        filterApplyBtn: {
            background: '#37006E', color: '#fff',
            border: 'none',
            padding: '6px 16px',
            cursor: 'pointer',
            fontSize: '16px',
            textTransform: 'capitalize',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 500,
            lineHeight: '1.5rem',
            '&:hover': {
                background: '#37006E', color: '#fff',
            }
        },
        filterSideBarItems: {
            width: '90px',
            background: '#37006E',
            padding: '10px 20px',
            color: '#fff',
            cursor: 'pointer',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            lineHeight: '1.5rem'
        },
        niqProduct: {
            background: '#00AB79',
            borderRadius: '50%',
            padding: '1px 8px',
            color: '#fff'
        },
        niqtextheading: {
            fontFamily: `'Poppins', sans-serif`,
            margin: 0,
            color: '#757D8A',
            fontWeight: 500
        },
        favIcon: {
            color: 'gray', height: '26px', width: '26px'
        },
        compareImg: {
            height: '15px',
            width: '15px'
        },
        compareContainer: {
            color: '#36006D', fontWeight: 500, display: 'flex', alignItems: 'center', gap: '3px'
        },
        productImgContainer: {
            textAlign: 'center',
            marginBottom: '10px',
            borderBottom: '1px solid red'
        },
        successTextView: {
            textAlign: 'center',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '10px',
            marginTop: '10px',
            color: '#455A64'
        },
        compareProductsPaper: {
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: '#000',
            padding: '30px',
            width: '300px',
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'absolute',
            borderRadius: '20px',
        },
        compareProductsmodal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        resetBtn: {
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 500,
            lineHeight: '1.5rem',
            textTransform: 'capitalize',
            textDecoration: 'underline',
            color: '#36006D',
            fontSize: '16px',
            "&:hover": {
                background: 'none',
                textDecoration: 'underline',
            }
        },
        unserlineText: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#FED600",
        },
        priceFilterInputField: {
            "& .MuiOutlinedInput-input": {
                padding: '12px 14px'
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #36006D",

            },
        },
        subHeadingTextStyle: {
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 500,
            lineHeight: '1.5rem'
        },
        filterHeadingtextStyle: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            lineHeight: '1.5rem'
        },
        priceFilterInputFieldContainer: {
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'row', width: '150px',
            height: '70px',
            alignItems: 'center',
            gap: '22px'
        },
        buttonContainer: {
            textAlign: 'center',
            marginTop: '10px'
        },
        closeButtonStyle: {
            color: '#fff',
            background: '#38006D',
            borderRadius: '30px',
            padding: '10px 30px',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: '14px',
            textTransform: "capitalize",
        },
        filterTextStyle: {
            fontFamily: `'Montserrat', sans-serif`,
            fontSize: '1.17rem',
            fontWeight: 600,
            lineHeight: '1.5rem',
            cursor: 'pointer',
            color: '#FF3939'
        },
        NoProductNofificationText: {
            fontFamily: `'Poppins', sans-serif`,
            color: '#FF3939',
            fontWeight: 500,
            textTransform: 'capitalize',
            fontSize: '23px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px',
            },
        },
    });

class FavoriteProductsWeb extends FavoriteProductsWebController {


    renderFavProducts = (classes: any) => {
        return this.context.allFavProducts.length > 0 ?
            this.context.allFavProducts.map((e: any, i: any) => {
                /* istambul ignore if */
                if (this.state.applyFilterValidation && this.state.applyPriceValidation) {
                    if (this.state.renderselectNiqScores.includes(e?.attributes?.product?.data?.attributes?.product_rating) && this.state.renderselectedPrice.includes(e.attributes.product_id)) {
                        return <FavoriteProductsWebComponent
                            allComparedProducts={this.state.allComparedProducts}
                            checkCompareProducts={this.checkCompareProducts}
                            navigation={this.props.navigation}
                            i={i}
                            classes={classes}
                            e={e}
                            deletefavList={this.deletefavList}
                            key={i}
                            handleOpen={this.handleOpen} />
                    }
                } else if (!this.state.applyFilterValidation && this.state.applyPriceValidation) {
                    if (this.state.renderselectedPrice.includes(e.attributes.product_id)) {
                        return <FavoriteProductsWebComponent
                            allComparedProducts={this.state.allComparedProducts}
                            checkCompareProducts={this.checkCompareProducts}
                            navigation={this.props.navigation}
                            i={i}
                            classes={classes}
                            e={e}
                            deletefavList={this.deletefavList}
                            key={i}
                            handleOpen={this.handleOpen} />
                    }
                } else if (this.state.applyFilterValidation && !this.state.applyPriceValidation) {
                    if (this.state.renderselectNiqScores.includes(e?.attributes?.product?.data?.attributes?.product_rating)) {
                        return <FavoriteProductsWebComponent
                            allComparedProducts={this.state.allComparedProducts}
                            checkCompareProducts={this.checkCompareProducts}
                            navigation={this.props.navigation}
                            i={i}
                            classes={classes}
                            e={e}
                            deletefavList={this.deletefavList}
                            key={i}
                            handleOpen={this.handleOpen} />
                    }
                }
                else {
                    return <FavoriteProductsWebComponent
                        allComparedProducts={this.state.allComparedProducts}
                        checkCompareProducts={this.checkCompareProducts}
                        navigation={this.props.navigation}
                        i={i}
                        classes={classes}
                        e={e}
                        deletefavList={this.deletefavList}
                        key={i}
                        handleOpen={this.handleOpen} />
                }
            })
            : <Grid item><Typography className={classes.NoProductNofificationText}>No products added here</Typography></Grid>
    }
    render() {

        const { classes, t } = this.props;
        console.log('fav produvt', this.context.allFavProducts);


        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.favSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <img src={newTopBanner} alt="bgImage" style={{ width: "100%" }} className={classes.favTopBanner} />
                        <div className={classes.headingTextContainer}>
                            <Typography className={classes.pageHeadingText}>{t("Favorite_Products")}</Typography>
                            <Typography className={classes.filterTextStyle} data-testid='clickfilter' onClick={this.handleFavFilterChange}>{t("FILTER")}</Typography>
                        </div>

                        <div className={classes.userProfileContainer}>
                            {
                                !this.context.isloading ?
                                    <>
                                        <Typography className={classes.totalProductsText}>{t("Total_Products")} : {this.context.allFavProducts.length}</Typography>
                                        <Grid container spacing={3} className="MYTESTT">
                                            {this.renderFavProducts(classes)}
                                        </Grid>
                                    </>
                                    : <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div>
                            }
                        </div>
                    </Grid >
                </Grid >
                {/* @ts-ignore*/}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modalFilter}
                    open={this.state.openFilter}
                    onClose={() => this.setState({ openFilter: false })}
                    data-testid="NiqScoreMenuClose"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openFilter}>
                        <div className={classes.paperFilter}>
                            <div className={classes.filterBox}>
                                <div style={{ width: '160px' }}>
                                    <div className={classes.filterSideBarItems}
                                        data-testid="NiqScoreMenu"
                                        style={{
                                            background: this.state.activeFilter == 'NIQ' ? '#37006E' : '#fff',
                                            color: this.state.activeFilter == 'NIQ' ? '#fff' : '#37006E'
                                        }}
                                        onClick={() => this.setState({ activeFilter: 'NIQ' })}>
                                        {t("NIQ_Score")}
                                    </div>
                                    <div className={classes.filterSideBarItems}
                                        onClick={() => this.setState({ activeFilter: 'Price' })}
                                        style={{
                                            background: this.state.activeFilter != 'NIQ' ? '#37006E' : '#fff',
                                            color: this.state.activeFilter != 'NIQ' ? '#fff' : '#37006E'
                                        }}>{t("Price")}</div>

                                </div>
                                <div style={{ width: '210px' }}>
                                    {this.state.activeFilter == 'NIQ' ? (
                                        <div>
                                            <Typography className={classes.filterHeadingtextStyle}>
                                                <span className={classes.unserlineText}>{t("Filter_By_N")}<sup>{t("IQ")}</sup>{t("Score")}</span>

                                            </Typography>
                                            <FormControlLabel
                                                value="A"
                                                control={<Checkbox style={{ color: '#37006E' }} onChange={
                                                    (e) => this.onNiqScoreHandle(e)} checked={this.state.selectNiqScores.includes('A')} />}
                                                label={<span className={classes.subHeadingTextStyle}>N<sup>IQ</sup> {t("Score")} A</span>}
                                                labelPlacement="end"
                                                data-testid="NiqScoreA"

                                            />
                                            <FormControlLabel
                                                value="B"
                                                control={<Checkbox style={{ color: '#37006E' }} onChange={
                                                    (e) => this.onNiqScoreHandle(e)} checked={this.state.selectNiqScores.includes('B')} />}
                                                label={<span className={classes.subHeadingTextStyle}>N<sup>IQ</sup> {t("Score")} B</span>}
                                                labelPlacement="end"
                                                data-testid="NiqScoreB"
                                            />
                                            <FormControlLabel
                                                value="C"
                                                control={<Checkbox style={{ color: '#37006E' }} onChange={
                                                    (e) => this.onNiqScoreHandle(e)} checked={this.state.selectNiqScores.includes('C')} />}
                                                label={<span className={classes.subHeadingTextStyle}>N<sup>IQ</sup> {t("Score")} C</span>}
                                                labelPlacement="end"
                                                data-testid="NiqScoreC"
                                            />
                                            <FormControlLabel
                                                value="D"
                                                control={<Checkbox style={{ color: '#37006E' }} onChange={
                                                    (e) => this.onNiqScoreHandle(e)} checked={this.state.selectNiqScores.includes('D')} />}
                                                label={<span className={classes.subHeadingTextStyle}>N<sup>IQ</sup> {t("Score")} D</span>}
                                                labelPlacement="end"
                                                data-testid="NiqScoreD"
                                            />
                                            <FormControlLabel
                                                value="E"
                                                control={<Checkbox style={{ color: '#37006E' }} onChange={
                                                    (e) => this.onNiqScoreHandle(e)} checked={this.state.selectNiqScores.includes('E')} />}
                                                label={<span className={classes.subHeadingTextStyle}>N<sup>IQ</sup> {t("Score")} E</span>}
                                                labelPlacement="end"
                                                data-testid="NiqScoreE"
                                            />
                                            <div className={classes.buttonContainer}>
                                                <Button
                                                    variant='contained'
                                                    size='small'
                                                    className={classes.filterApplyBtn}
                                                    data-testid="filtermodal"
                                                    onClick={() => this.applyFilters(this.state.selectNiqScores)}>{t("Apply")}</Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ margin: '10px', }}>
                                            <Typography className={classes.filterHeadingtextStyle}><span className={classes.unserlineText}>{t("Filter_By_Price")}</span></Typography>
                                            <form onSubmit={this.applyFiltersPrice}>
                                                <div className={classes.priceFilterInputFieldContainer}>
                                                    <Typography className={classes.subHeadingTextStyle}>Min</Typography>
                                                    <TextField
                                                        required
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        value={this.state.minValue}
                                                        type="number"
                                                        onChange={(e: any) => this.setState({ minValue: e.target.value })}
                                                        onKeyPress={(event: any) => { this.handlePositiveDecimalNumberOnKeyPress(event) }}
                                                        onKeyDown={(event: any) => { this.disableDownKey(event) }}
                                                        className={classes.priceFilterInputField}
                                                    />
                                                </div>
                                                <div className={classes.priceFilterInputFieldContainer}>
                                                    <Typography className={classes.subHeadingTextStyle}>Max</Typography>
                                                    <TextField
                                                        required
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        value={this.state.maxValue}
                                                        type="number"
                                                        onChange={(e: any) => this.setState({ maxValue: e.target.value })}
                                                        onKeyPress={(event: any) => { this.handlePositiveDecimalNumberOnKeyPress(event) }}
                                                        onKeyDown={(event: any) => { this.disableDownKey(event) }}
                                                        className={classes.priceFilterInputField}
                                                    />
                                                </div>
                                                <div className={classes.buttonContainer}>
                                                    <Button
                                                        variant='contained'
                                                        size='small'
                                                        className={classes.filterApplyBtn}
                                                        data-testid="filtermodal">
                                                        {t("Apply")}</Button>
                                                </div>
                                                <div className={classes.buttonContainer}>
                                                    <Button
                                                        variant='text'
                                                        size='small'
                                                        data-testid="filtermodal"
                                                        className={classes.resetBtn}
                                                        onClick={() => this.setState({ applyPriceValidation: false, minValue: '', maxValue: '', openFilter: false })}>
                                                        Reset
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>

                {/* @ts-ignore*/}
                <Modal className='compareProductsmodal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{ zIndex: 9999 }}
                >
                    <Fade in={this.state.isOpen}>
                        <div className="compareProductsPaper">
                            {this.state.comparedataLimit != 3 && (
                                <img style={{ height: 70 }} src={successfullyImg} />
                            )}
                            <p className="successTextView">{this.state.comparedataLimit === 3 ? `${t("You_have_already_added_3_Products")}` : `${t("Product_added_successfully")}`}</p>
                            <Button
                                className={classes.closeButtonStyle}
                                data-testid="compareModalBtn" onClick={() => {
                                    if (this.state.comparedataLimit === 3) {
                                        return this.props.navigation.navigate("CompareProducts");
                                    } else {
                                        this.setState({ compareProductadded: true })
                                        this.handleClose()
                                    }
                                }
                                }
                            >
                                {this.state.comparedataLimit === 3 ? `${t("Go_to_Compare_Products")}` : `${t("Close")}`}
                            </Button>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }

}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('FavProductsLang')(FavoriteProductsWeb)
);