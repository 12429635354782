// Customizable Area Start
import React from "react";
import { Typography, Button } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import SearchControllerWeb, { Props, } from "./SearchControllerWeb";
import { noDataImage } from "./assets"
import { withTranslation } from 'react-i18next';
const styles = (theme: Theme) =>
  createStyles({

    back_btn: {
      height: 35,
      width: 90,
      fontSize: '15px',
      borderRadius: 20,
      color: "#FF3939", fontFamily: `'Poppins', sans-serif`, fontStyle: 'normal', fontWeight: 500,
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        height: 20, width: 40,
        fontSize: '12px',
      },
    },
    product_bgImg: {
      width: "100%", height: 400,
      display: "flex", justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        height: 250,
      },
    },
    product_img: {
      width: 300, height: 300, background: '#fff',
      border: "double 6px transparent",
      borderRadius: '50%',
      backgroundImage:
        "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
      backgroundOrigin: "border-box",
      backgroundClip: "content-box, border-box",
      [theme.breakpoints.down("xs")]: {
        border: "double 4px transparent",
        width: 180, height: 180,
      },
    },
    productname: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '35px',
      color: '#455A64',
      [theme.breakpoints.down("lg")]: {
        fontSize: '29px',
        lineHeight: '30px',
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '24',
        lineHeight: '25px',
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '18',
      },

    },
    foodType: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '23px',
      color: '#FF5050',
      marginTop: 20,
      [theme.breakpoints.down("lg")]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '17',
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '14',
      },
    },
    cantBeRateImg: {
      width: 200, height: 200, margin: "10 auto",
      [theme.breakpoints.down("sm")]: {
        width: 100, height: 100,
      },
    },
    CardContentContainer: {
      margin: 50,
      [theme.breakpoints.down("sm")]: {
        margin: 0
      },
    },
    cantbeRateTxt: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '27px',
      lineHeight: '10px',
      color: '#FF3939',
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        fontSize: '21px',
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '18',
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '16',
      },
    },
    discription: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '25px',
      textAlign: "center",
      lineHeight: '38px',
      color: '#455A64',
      [theme.breakpoints.down("lg")]: {
        fontSize: '21px',
        lineHeight: '34px',
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '18',
        lineHeight: '30px',
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '16',
        lineHeight: '25px',
      },
    },
    submitProductButtonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 30,
      marginBottom: 30,

      [theme.breakpoints.down("sm")]: {
        marginTop: 30,
        marginBottom: 30,
      },
    },
    SearchAnotherProductButton: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#FFFFFF',
      background: '#38006D',
      borderRadius: "44px",
      padding: '25px 100px',
      '&:hover': {
        background: '#12125c',
        border: 'none',
      },
      [theme.breakpoints.down("sm")]: {
        padding: '15px 50px',
        fontSize: '16px',
      },
      [theme.breakpoints.down("sm")]: {
        padding: '5px 20px',
        fontSize: '12px',
      },
    },
    product_img_child: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '59%'
    }

  });

class NoRatedComponent extends SearchControllerWeb {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t } = this.props;
    return (

      <div style={{ padding: 50, }}>
        <div style={{ marginTop: 40, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" className={classes.back_btn}
            data-testid="goBack"
            onClick={this.props.navigation.goBack}
            size="small">{t("Back")}</Button>
        </div>

        <Card style={{ marginTop: 20, minHeight: 700, boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)" }} >
          <CardMedia
            image={this.state.noRatedData?.attributes?.image}
            title="imgae"
            className={classes.product_bgImg}>

            <div className={classes.product_img} style={{ position: "relative" }} >
              <img
                data-testid="image"
                className={classes.product_img_child}
                src={this.state.noRatedData?.attributes?.image}
                alt="productImage"
              />
            </div>
          </CardMedia>

          <CardContent
            className={classes.CardContentContainer}>

            <Typography
              data-testid="productName"
              className={classes.productname}>
              {this.state.noRatedData?.attributes?.product_name}

            </Typography>
            <Typography className={classes.foodType} data-testid="productType" >
              {this.state.noRatedData?.attributes?.category_type}
            </Typography>
          </CardContent>

          <CardMedia
            className={classes.cantBeRateImg}
            image={noDataImage}
            title="cantbeRatedImg"
          />

          <CardContent className={classes.CardContentContainer}>
            <Typography className={classes.cantbeRateTxt}>
              {t("Cant_be_rated")}
            </Typography>
          </CardContent>
          <CardContent className={classes.CardContentContainer}>
            <Typography className={classes.discription}>
              {t("This_product_cannot_be_rated_because_the_manufacturer_did_not_publish_complete_nutritional_information")}
            </Typography>
          </CardContent>
          <CardActions className={classes.submitProductButtonContainer}>
            <Button className={classes.SearchAnotherProductButton}
              data-testid="searchAnotherProduct"
              onClick={(e: any) => this.searchAnotherProductButton("Home")}
            >{t("Search_Another_Product")}</Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(withTranslation('NoRatedLng')(NoRatedComponent));
// Customizable Area End