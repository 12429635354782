import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    // navigation side bar 
    // side nav section
    profileContainer: {
        padding: '4rem 0',
    },
    profileImage: {
        height: 120,
        width: 120,
        [theme.breakpoints.down("lg")]: {
            height: 96,
            width: 96,
        },
        [theme.breakpoints.down("md")]: {
            height: 64,
            width: 64,
        },

    },
    userNameText: {
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '22px',
        color: '#FFFFFF',
        textAlign: "center",
        [theme.breakpoints.down("lg")]: {
            fontSize: '19px',
        },

        [theme.breakpoints.only("md")]: {
            fontSize: '16px',
        },

    },
    userAccountText: {
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 400,
        fontSize: '20px',
        color: '#FED600',
        textAlign: "center",
        [theme.breakpoints.down("lg")]: {
            fontSize: '17px',
        },

        [theme.breakpoints.only("md")]: {
            fontSize: '14px',
        },
    },

    sideNavText: {
        backgroundColor: 'transparent',
        fontFamily: 'DM Sans!important',
        fontWeight: 400,
        fontSize: '18px',
        [theme.breakpoints.down("lg")]: {
            fontSize: '16px',
        },

        [theme.breakpoints.only("md")]: {
            fontSize: '14px',
        },
    },

    active: {
        color: '#FB951A'
    },
    normal: {
        color: 'white',
    }
}));

const SideNavbarWeb = () => {

    const classes = useStyles();
    const [t] = useTranslation(["SideNavbarWebLang"]);
    const [userInfo, setUserInfo] = useState<any>("");
    useEffect(() => {
        const userDetails: any = localStorage.getItem("user");
        let user: any = JSON.parse(userDetails);
        setUserInfo(user)
    }, [])

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.profileContainer}
            >
                <div>
                    {userInfo?.image ?
                        <Avatar alt="profile Image" src={userInfo?.image} className={classes.profileImage} />
                        :
                        <Avatar>{userInfo?.name}</Avatar>}
                </div>
                <Typography className={classes.userNameText}>{userInfo?.name}</Typography>
                <Typography className={classes.userAccountText}>{t("My_account")}</Typography>
            </Grid>
            <Divider style={{ backgroundColor: 'white', marginBottom: '2.1rem' }} />
            <div>

                <Link to='/UserProfilePageWeb' style={{ textDecoration: "none" }}>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        style={
                            {
                                padding: '12px 5px',
                                cursor: 'pointer',
                            }
                        }

                        className={'/UserProfilePageWeb' === window.location.pathname || '/AddNewMemberPageWeb' === window.location.pathname ? classes.active : classes.normal}
                    >
                        <Grid item xs={2}>
                            <PersonOutlineIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className={classes.sideNavText}>{t("My_Profile")}</Typography>
                        </Grid>
                    </Grid>
                </Link>

                <Link to='/BmiCalculatorPageWeb' style={{ textDecoration: "none" }}>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        style={
                            {
                                padding: '12px 5px',
                                cursor: 'pointer',
                            }
                        }

                        className={'/BmiCalculatorPageWeb' === window.location.pathname || '/BmiCalculatorDashboardWebPage' === window.location.pathname || '/BmiSignAndSymptomsWebPage' === window.location.pathname ? classes.active : classes.normal}
                    >
                        <Grid item xs={2}>
                            <AccessibilityIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className={classes.sideNavText}>{t("BMI_Calculator")}</Typography>
                        </Grid>
                    </Grid>
                </Link>

                <Link to='/NutritionGapCalculatorWeb' style={{ textDecoration: "none" }}>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        style={
                            {
                                padding: '12px 5px',
                                cursor: 'pointer',
                            }
                        }

                        className={'/NutritionGapCalculatorWeb' === window.location.pathname || '/NutritionGapReportWeb' === window.location.pathname ? classes.active : classes.normal}
                    >
                        <Grid item xs={2}>
                            <AssignmentIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className={classes.sideNavText}>{t("Nutrition_Gap_Calculator")}</Typography>
                        </Grid>
                    </Grid>
                </Link>

                <Link to='/HealthyFoodBasket' style={{ textDecoration: "none" }}>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        style={
                            {
                                padding: '12px 5px',
                                cursor: 'pointer',
                            }
                        }

                        className={window.location.pathname === '/HealthyFoodBasket' || window.location.pathname === '/HealthyFoodDetails' ? classes.active : classes.normal}
                    >
                        <Grid item xs={2}>
                            <ListAltIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className={classes.sideNavText}>{t("Healthy_Food_Basket")}</Typography>
                        </Grid>
                    </Grid>
                </Link>
                <Link to='HealthPointsWebPage' style={{ textDecoration: "none" }}>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        style={
                            {
                                padding: '12px 5px',
                                cursor: 'pointer',
                            }
                        }

                        className={'/HealthPointsWebPage' === window.location.pathname || '/HealthPointDetailsPageWeb' === window.location.pathname || '/HealthPointLeaderBoardWeb' === window.location.pathname ? classes.active : classes.normal}
                    >
                        <Grid item xs={2}>
                            <LocalHospitalIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className={classes.sideNavText}>{t("Health_Points")}</Typography>
                        </Grid>
                    </Grid>
                </Link>

                <Link to='favoriteProducts' style={{ textDecoration: "none" }}>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        style={
                            {
                                padding: '12px 5px',
                                cursor: 'pointer',
                            }
                        }

                        className={'/favoriteProducts' === window.location.pathname ? classes.active : classes.normal}
                    >
                        <Grid item xs={2}>
                            <FavoriteBorderIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className={classes.sideNavText}>{t("Favorites_Products")}</Typography>
                        </Grid>
                    </Grid>
                </Link>
                <Link to='#' style={{ textDecoration: "none" }}>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        style={
                            {
                                padding: '12px 5px',
                                cursor: 'pointer',
                            }
                        }

                        className={classes.normal}
                    >
                        <Grid item xs={2}>
                            <SettingsApplicationsIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className={classes.sideNavText}>{t("Preferences")}</Typography>
                        </Grid>
                    </Grid>
                </Link>

                <Link to='/yourproducts' style={{ textDecoration: "none" }}>
                    <Grid
                        container spacing={3}
                        alignItems="center"
                        style={
                            {
                                padding: '12px 5px',
                                cursor: 'pointer',
                            }
                        }

                        className={'/yourproducts' === window.location.pathname || '/addproducts' === window.location.pathname || '/ProductReportedWebPage' === window.location.pathname || '/ProductReportFormWebPage' === window.location.pathname ? classes.active : classes.normal}
                    >
                        <Grid item xs={2}>
                            <StoreMallDirectoryIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className={classes.sideNavText}>{t("Submit_a_Product")}</Typography>
                        </Grid>
                    </Grid>
                </Link>




            </div>
            {/* <Divider style={{ backgroundColor: 'white' }} /> */}

        </>
    )
}

export default SideNavbarWeb;