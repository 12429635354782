import React, { useState } from 'react';
import { Button, Dialog, DialogActions, Typography, DialogContent, Grid, DialogTitle, IconButton, FormControlLabel, FormControl, CircularProgress, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { successfullyImg, notificationImg } from './assets';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    productButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: '5rem',
        marginBottom: 30,

        [theme.breakpoints.down("sm")]: {
            marginTop: 30,
            marginBottom: 30,
        },
    },
    productButton: {
        fontFamily: `'Montserrat', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '22px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        background: '#38006D',
        borderRadius: "44px",
        padding: '15px 100px',
        '&:hover': {
            background: '#12125c',
            border: 'none',
        },
        [theme.breakpoints.down("md")]: {
            padding: '15px 50px',
            fontSize: '15px',
        },
        [theme.breakpoints.down("sm")]: {
            padding: '8px 25px',
            fontSize: '12px',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(3),
        top: theme.spacing(3),
        color: '#455A64',
        border: '1px solid #455A64',
        padding: '0px'

    },
    foodBasketDialogBoxStyle: {
        "& .MuiDialog-paperWidthSm": {
            minWidth: '450px',
            borderRadius: '1rem',
            padding: '2rem 0',
            [theme.breakpoints.down("sm")]: {
                minWidth: '300px',
            },
        },

    },
    foodSaveBtn: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "33px",
        color: "#ffff",
        backgroundColor: '#38006D',
        borderRadius: '2rem',
        padding: '0.6rem 5rem',
        margin: '1rem 0',
        '&:hover': {
            backgroundColor: '#38006D',
        },
        [theme.breakpoints.down("sm")]: {
            padding: '0.2rem 4.5rem',
            fontSize: '15px',
        },
    },
    modelContent: {
        padding: '2rem',
        borderRadius: '1rem',
    },
    successText: {
        textAlign: 'center',
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "22px",
        lineHeight: "33px",
        margin: '1rem 0',
        color: "#455A64",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "12px",
        },
    },
    secondDilogBoxBtn: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "28px",
        padding: '0.5rem 1.3rem',
        borderRadius: '4rem',
        backgroundColor: '#12125c',
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "23px",
            padding: '0.3rem 1.6rem',
        },
        '&:hover': {
            background: '#12125c',
            border: 'none',
        },

    },
    updateDilogBoxBtn: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "28px",
        padding: '0.5rem 1.8rem',
        borderRadius: '4rem',
        backgroundColor: '#12125c',
        color: '#ffff',
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "23px",
            padding: '0.4rem 1.6rem',
        },
        '&:hover': {
            background: '#12125c',
            border: 'none',
        },

    },
    cancelDilogBoxBtn: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "28px",
        padding: '0.5rem 1.8rem',
        borderRadius: '4rem',
        color: '#12125c',
        border: '2px solid #12125c',
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "23px",
            padding: '0.3rem 1.6rem',
        },
        '&:hover': {
            background: '#ffff',
        },

    },

    // 
    foodBasketTypeTextField: {
        marginBottom: 24,
        border: "double 1px transparent",
        borderRadius: 8,
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(185deg,#41479B,#FF3939)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    foodBasketListTitle: {
        fontFamily: `'Montserrat', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        color: '#757D8A',
        [theme.breakpoints.down("sm")]: {
            fontSize: '16px',
        },
    },
    foodBasketGroupTitle: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        color: '#757D8A',
        margin: '1.5rem 0',
        [theme.breakpoints.down("sm")]: {
            fontSize: '16px',
        },
    },
    foodBasketGroupList: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        color: '#455A64',
        margin: '1.1rem 0',
        textTransform: 'capitalize',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        },
    },
    foodBasketEditText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        color: 'red',
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        },
    },
    textInputStyle: {
        backgroundColor: 'transparent',
        padding: '0.7rem',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 500,
        color: '#455A64',

    },

}));

interface Props {
    addFoodBasketApiCall: (data: any) => void;
    updateFoodBasketApiCall: (order_name: string, foodBasketId: any) => void;
    addFoodBasketloader: boolean;
    foodProductId: any;
    foodBasketLists: any;
    foodBasketListLoader: boolean;
    navigationBtnFnc: (path: any) => void;
    addFoodBasketApiResponseText: any;
    modalOpen: boolean;
    handleModalClose: any;
    isLogin: boolean;
}

const HealthyFoodBasketButton = ({
    addFoodBasketApiCall,
    addFoodBasketloader,
    foodProductId,
    foodBasketLists,
    foodBasketListLoader,
    navigationBtnFnc,
    addFoodBasketApiResponseText,
    modalOpen,
    handleModalClose,
    isLogin,
    updateFoodBasketApiCall
}: Props) => {
    const classes = useStyles();
    const [t] = useTranslation(["HealthyFoodBasketButtonLang"]);
    const [firstDialogBoxOpen, setFirstDialogBoxOpen] = useState(false);
    const [foodBasketError, setFoodBasketError] = useState(false);
    const [foodOrderNameValue, setFoodOrderNameValue] = useState('');
    const [radioOptionForFoodBasketListValue, setRadioOptionForFoodBasketListValue] = useState('');
    const [loginNotification, setLoginNotification] = useState(false)
    const [editButtonClick, setEditButtonClick] = useState(false)
    const [foodBasketId, setFoodBasketId] = useState('')

    const getLanguage = () => {
        let languageId: any;
        const defaultEnglishLanguageId: any = 1
        if (localStorage.getItem('languageSelect')) {
            languageId = localStorage.getItem('languageSelect');
        }
        else {
            languageId = defaultEnglishLanguageId;
        }
        return languageId;
    }

    const handleRadioOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFoodOrderNameValue('');
        setRadioOptionForFoodBasketListValue(event.target.value);
        setEditButtonClick(false);
    };

    const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioOptionForFoodBasketListValue('');
        setFoodOrderNameValue(event.target.value);
    }

    const handelEditOption = (id: any, basketName: any) => {
        setRadioOptionForFoodBasketListValue('');
        setFoodOrderNameValue(basketName);
        setEditButtonClick(true);
        setFoodBasketId(id);
    }
    const handleClickOpen = () => {
        if (isLogin) {
            setFirstDialogBoxOpen(true);
        } else {
            setLoginNotification(true)
        }
    };

    const handleClose = () => {
        if (isLogin) {
            setFirstDialogBoxOpen(false);
            setRadioOptionForFoodBasketListValue('');
            setFoodOrderNameValue('');
        } else {
            setLoginNotification(false);
        }
    };

    const handleSave = () => {
        const languageId = getLanguage()
        if (foodOrderNameValue === '' && radioOptionForFoodBasketListValue === '') {
            setFoodBasketError(true)
        } else {
            let data = {};
            if (foodOrderNameValue) {
                data = { product_id: foodProductId, order_name: foodOrderNameValue, language_id: languageId };
            } else {
                const _attr = JSON.parse(radioOptionForFoodBasketListValue);

                data = { order_id: _attr.id, product_id: foodProductId, order_name: _attr.order_name, language_id: languageId }
            }
            addFoodBasketApiCall(data);
            setFoodBasketError(false)
            setFirstDialogBoxOpen(false)
            setFoodOrderNameValue('');
            setRadioOptionForFoodBasketListValue('');
        }
    }

    const handelUpdateBasket = () => {
        updateFoodBasketApiCall(foodOrderNameValue, foodBasketId);
        setFirstDialogBoxOpen(false);
        setFoodOrderNameValue('');
        setEditButtonClick(false)
    }
    const handleCancelButton = () => {
        setEditButtonClick(false);
        setFoodOrderNameValue('');
    }

    const inputPlaceholder = t("Type")
    return (
        <>
            <div className={classes.productButtonContainer}>
                <Button
                    className={classes.productButton} data-testid="foodBasketbtn" onClick={() => handleClickOpen()} >
                    {t("Add_to_healthy_Food_Basket")}
                </Button>
            </div>

            {/* Login Notification dialog Box start */}



            <Dialog
                open={loginNotification}
                style={{ zIndex: 9999 }}
                aria-labelledby="form-dialog-title"
                BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}>
                <div className={classes.modelContent}>
                    <DialogTitle id="form-dialog-title">
                        {t("Looks_like_you_have_not_signed_up")}{' '}
                    </DialogTitle>
                    <DialogActions>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            <Button variant="outlined" data-testid="navigationBtn" onClick={() => navigationBtnFnc('UserSignUpScreenWeb')} color="primary">
                                {t("Sign_Up")}
                            </Button>
                            <Button onClick={handleClose} data-testid="modalCloseBtn" variant="contained" color="primary">
                                {t("Continue")}
                            </Button>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>

            {/* login notification dialog box end */}
            {/* first dialog Box start */}
            <Dialog
                open={firstDialogBoxOpen}
                // onClose={handleClose}
                BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.foodBasketDialogBoxStyle}
                style={{ zIndex: 9999 }}
            >

                <MuiDialogTitle disableTypography>
                    {firstDialogBoxOpen ? (
                        <IconButton aria-label="close" className={classes.closeButton} data-testid="modalCloseBtn" onClick={handleClose}>
                            <CloseIcon style={{ fontSize: '13px' }} />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>

                <DialogContent >

                    <Typography gutterBottom className={classes.foodBasketListTitle}>
                        {t("Name_of_the_list")}
                    </Typography>


                    <div className={classes.foodBasketTypeTextField}>
                        <InputBase
                            id="outlined-basic"
                            placeholder={inputPlaceholder}
                            fullWidth
                            className={classes.textInputStyle}
                            onChange={handleTextInput} value={foodOrderNameValue}
                        />
                    </div>

                    <Typography gutterBottom className={classes.foodBasketGroupTitle}>
                        {t("Or_Select_From_Previous_group")}
                    </Typography>
                    <FormControl style={{ width: '100%', borderRadius: '1rem', maxHeight: '200px' }}>
                        <RadioGroup
                            aria-label="quiz"
                            name="quiz"
                            value={radioOptionForFoodBasketListValue}
                            data-testid="basketListRadioGroup"
                            onChange={handleRadioOption}
                        >
                            {
                                foodBasketListLoader ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> :
                                    (foodBasketLists.map((item: any, key: any) => {
                                        return (
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                key={key}
                                            >
                                                <FormControlLabel
                                                    value={JSON.stringify(item?.attributes)}
                                                    control={<Radio />}
                                                    label={<Typography className={classes.foodBasketGroupList}>{item?.attributes?.order_name}</Typography>}
                                                />
                                                <Typography className={classes.foodBasketEditText} data-testid="clickForEdit" onClick={() => handelEditOption(item?.attributes?.id, item?.attributes?.order_name)} >{t("Edit")}</Typography>

                                            </Grid>
                                        )
                                    }))
                            }
                        </RadioGroup>
                    </FormControl>

                </DialogContent>
                <DialogActions style={{ flexDirection: 'column' }}>
                    {foodBasketError && <Typography style={{ color: 'red', padding: '10px 0' }}>{t("Please_Enter_Food_Type_or_Select_One")}</Typography>}
                    {editButtonClick ?
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            <Button variant="outlined" color="primary" className={classes.cancelDilogBoxBtn} data-testid="handlecancelBtn" onClick={handleCancelButton}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="contained" color="primary" className={classes.updateDilogBoxBtn} data-testid="handelUpdatebasketBtn" onClick={() => handelUpdateBasket()}>
                                {t("UPDATE")}
                            </Button>
                        </Grid>
                        :
                        <Button variant="contained" color="primary" data-testid="saveBtn" onClick={handleSave} className={classes.foodSaveBtn}>
                            {t("SAVE")}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            {/* first dialog box end */}

            {/* second dialog Box start */}
            {
                addFoodBasketloader ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> :
                    <Dialog
                        open={modalOpen}
                        style={{ zIndex: 9999 }}
                        aria-labelledby="form-dialog-title"
                        BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
                    >
                        <div className={classes.modelContent}>
                            <MuiDialogTitle disableTypography>
                                {modalOpen ? (
                                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleModalClose}>
                                        <CloseIcon style={{ fontSize: '13px' }} />
                                    </IconButton>
                                ) : null}
                            </MuiDialogTitle>
                            <DialogContent style={{ textAlign: 'center' }}>
                                {
                                    addFoodBasketApiResponseText === `${t("Product_added_successfully")}` || addFoodBasketApiResponseText === `${t("Basket_name_updated")}` ? <img src={successfullyImg} /> : <img src={notificationImg} />
                                }

                                <Typography className={classes.successText}>{addFoodBasketApiResponseText}</Typography>
                            </DialogContent>

                            <DialogActions>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                >
                                    <Button variant="outlined" color="primary" className={classes.cancelDilogBoxBtn} onClick={handleModalClose}>
                                        {t("Back")}
                                    </Button>
                                    <Button variant="contained" color="primary" className={classes.secondDilogBoxBtn} data-testid="navigationBtn" onClick={() => navigationBtnFnc('Home')}>
                                        {t("New_search")}
                                    </Button>
                                </Grid>
                            </DialogActions>
                        </div>
                    </Dialog>
            }


            {/* second dialog box end */}


        </>
    );
}

export default HealthyFoodBasketButton