import React, { Component } from "react";
// Customizable Area Start
import { withStyles } from '@material-ui/core/styles';
import ReactFlagsSelect from 'react-flags-select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withTranslation } from 'react-i18next';
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

type MyProps = {
  setPhoneNumValue: any
  gotErrorResponse: any
};
type MyState = {
  countrySelected: any;
  countryCode: any;
  phoneNumber: any;

};

const styles = (theme: any) => ({

  flags: {
    background: "#EEEEEE",
    borderRadius: "4px",
    border: "none",
    fontFamily: `'Montserrat', sans-serif`,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    color: '#000000',
  },
  root: {
    marginBottom: 10,
  },

  input: {
    fontFamily: `'Montserrat', sans-serif`,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    color: '#808080',
  }
})

// Customizable Area End
class MobileNumber extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      countrySelected: "IN",
      countryCode: "+91",
      phoneNumber: "",
    };
  }
  handlePhoneChange(e: any) {
    this.props.setPhoneNumValue(this.state.countryCode + '' + e)
  }
  render() {
    // Customizable Area Start 
    const { classes, gotErrorResponse, t }: any = this.props;

    return (
      <>
        <TextField
          className={classes.margin}
          id="input-with-icon-textfield"
          placeholder={t('mobileNumPlaceHolder')}
          type="number"
          margin="dense"
          autoFocus
          error={gotErrorResponse}
          helperText={gotErrorResponse ? "Please check your number" : ""}
          style={{ width: "90%", }}
          InputProps={{
            classes: { inputMarginDense: classes.root, input: classes.input },
            startAdornment: (
              <InputAdornment position="start" style={{ marginBottom: 10 }}>
                <ReactFlagsSelect

                  customLabels={configJSON.phones}
                  showSecondaryOptionLabel
                  onSelect={code => {
                    this.setState({ countrySelected: code });
                    this.setState({ countryCode: configJSON.phones[code].primary, phoneNumber: "" });
                  }}
                  selected={this.state.countrySelected}
                  className="flag-select"
                  showSecondarySelectedLabel={false}
                  searchable
                  searchPlaceholder="Search countries"
                  fullWidth={false}
                  alignOptionsToRight={false}
                  selectButtonClassName={classes.flags}
                />
              </InputAdornment>
            ),
          }}
          value={this.state.phoneNumber}
          onChange={(e: any) => {
            this.setState({ phoneNumber: e.target.value })
            this.handlePhoneChange(e.target.value)
          }}
        />
      </>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withTranslation('signUpLoginLng')(MobileNumber));
// Customizable Area End
