// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { FavouriteProductContextType, defaultContext } from "./FavouriteProductContext";

export const configJSON = require("../config");

export interface Props {
    navigation?: any;
    id?: string;
    children?: JSX.Element | JSX.Element[] | string | string[];
}

interface S extends FavouriteProductContextType {
    isloading: boolean;
    allFavProducts: any;
    errorMsg: string;
}

interface SS {
    id: any;
}

export default class FavouriteProductContextController extends BlockComponent<
    Props,
    S,
    SS
> {
    getFavouriteListApiCallID: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            ...defaultContext,
            isloading: false,
            allFavProducts: [],
            errorMsg: "",
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async componentDidMount() {        
        this.getFavouriteListAPI()
    }

    getLanguage = () => {
        let languageId: any;
        const defaultEnglishLanguageId: any = configJSON.defaultLanguageIDEnglish
        if (localStorage.getItem('languageSelect')) {
            languageId = localStorage.getItem('languageSelect');
        }
        else {
            languageId = defaultEnglishLanguageId;
        }
        return languageId;
    }

    getFavouriteListAPI = () => {        

        this.setState({ isloading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFavouriteListApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.favouriteProductApiEndPoint}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getgetFavouriteListAPIListAPIResponse = (responseJson: any, errorReponse: any) => {
        
        if (responseJson && !responseJson.errors) {
            if (responseJson?.data !== null) {
                this.setState({
                    allFavProducts: responseJson.data,
                    isloading: false
                })
                localStorage.setItem('favList', JSON.stringify(responseJson.data))
            }
        }
        else {
            this.setState({ isloading: false, errorMsg: errorReponse })
        }
    }


    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);


        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {

                if (apiRequestCallId == this.getFavouriteListApiCallID) {
                    this.getgetFavouriteListAPIListAPIResponse(responseJson, errorReponse)
                }
            }
        }

    }

    clearThemeContext = () => {
        this.setState({
            ...defaultContext,
            isloading: false,
        })
    }

}

 // Customizable Area End
