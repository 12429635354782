// Customizable Area Start
import React, { useContext } from "react";
export interface FavouriteProductContextType {
    allFavProducts: any;
}
export const defaultContext: FavouriteProductContextType = {
    allFavProducts: [],
};

export const FavouriteProductContext = React.createContext<FavouriteProductContextType>(defaultContext);

export function useTheme() {
    return useContext(FavouriteProductContext);
}
// Customizable Area End
