import React from 'react';
// Customizable Area Start
import AddProductControllerWeb from './AddProductController.web';
import { Typography, Grid, Button, InputBase, InputAdornment, Select, MenuItem } from "@material-ui/core";
import { topBanner } from "./assets"
import SideNavbarWeb from '../../../components/src/SideNavbarWeb';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { styles } from "./addproducts.web"

// Customizable Area Stop
class ViewProductWeb extends AddProductControllerWeb {
    // Customizable Area Start
    render() {
        const { classes, t } = this.props;
        return (
            <div className={classes.rootMain}>
                <Grid container>
                    <Grid item md={2} sm={false} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item md={10} xs={12} sm={12} >
                        <img src={topBanner} style={{ width: "100%" }} alt="bgImage" className={classes.profileTopBanner} />
                        <div className={classes.addNewMemberContainer} >
                            <Button variant="text" className={classes.backButtonText} color="primary" onClick={this.backButton} data-testid='backButton'>{t("Back")}</Button>
                            <div className={classes.headingTextContainer} >
                                <Typography className={classes.pageHeadingText} >{t("Add_A_PRODUCT")}</Typography>
                            </div>
                            {this.state.loading ? (
                                <Grid
                                    direction="row"
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CircularProgress />
                                </Grid>
                            ) : (<>

                                <div className={classes.inputContainer} >
                                    <Typography className={classes.FoodType}>{t("Name_of_the_product")}</Typography>
                                    <TextField variant='outlined'
                                        id="Name-of-the-product"
                                        disabled
                                        fullWidth
                                        value={this.state.viewProductName}
                                        placeholder={t("Enter_Product_Name")}
                                        className={classes.inputFieldWithLinearGradient}
                                    />
                                </div>

                                <div className={classes.inputContainer}>
                                    <Typography className={classes.FoodType}>{t("Weight_of_the_product")}</Typography>
                                    <div className={classes.foodBasketTypeTextField} style={{ marginTop: '0' }}>
                                        <InputBase
                                            data-testid="Weight-of-the-product"
                                            placeholder={t("weight_of_Product")}
                                            fullWidth
                                            style={{ backgroundColor: 'transparent', padding: '0.7rem' }}
                                            value={this.state.viewProducrWeight}
                                            type="number"
                                            disabled
                                            endAdornment={<InputAdornment position="end" data-testid='units-of-weight'>
                                                <div style={{ background: "#FF3939", height: 56, width: 76, marginRight: "-12px", borderRadius: 4, position: "relative", display: "inline-block" }}>
                                                    <Select
                                                        disableUnderline
                                                        autoFocus={false}
                                                        value={this.state.viewProductWeightUnits}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        disabled
                                                        fullWidth
                                                        inputProps={{ classes: { icon: classes.icon, }, "data-testid": "units" }}
                                                        style={{
                                                            color: '#212529',
                                                            fontFamily: `'Montserrat', sans-serif`,
                                                            fontStyle: "normal",
                                                            fontWeight: 500,
                                                            backgroundColor: '#fff',
                                                            height: '100%',
                                                            padding: '0 0.7rem',
                                                            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
                                                        }}
                                                        MenuProps={{ MenuListProps: { disablePadding: true, } }}>
                                                        <MenuItem
                                                            value={this.state.viewProductWeightUnits}
                                                            style={{
                                                                color: '#212529',
                                                                fontFamily: `'Montserrat', sans-serif`,
                                                                fontStyle: "normal",
                                                                fontWeight: 700,
                                                                backgroundColor: '#fff',
                                                                height: '100%',
                                                            }}>
                                                            {this.state.viewProductWeightUnits}
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </InputAdornment>}
                                        />
                                    </div>
                                </div>
                                <div className={classes.inputContainer}>
                                    <Typography className={classes.FoodType}>{t("Reference_Url_of_the_product_Optional")}</Typography>
                                    <TextField variant='outlined'
                                        fullWidth
                                        id="Reference-Url"
                                        placeholder="https://www.amazon.com/product-name"
                                        value={this.state.viewRefUrl}
                                        className={classes.inputFieldWithLinearGradient}
                                        disabled
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <Typography className={classes.FoodType}>{t("Upload_product_image")}</Typography>

                                    <Grid container direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="flex-start">
                                        <Grid item className={classes.imageWrapperContainer}>
                                            <div className={classes.imageContainer}>
                                                <img alt={"Front of Pack Photo"} src={this.state.viewFrontImg} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%', }} className={classes.uploadIcon} />
                                            </div>
                                            <Typography className={classes.uplaodImageTitle}>{t("Front_Of_Pack_photo")}</Typography>
                                        </Grid>

                                        <Grid item className={classes.imageWrapperContainer}>
                                            <div className={classes.imageContainer}>
                                                <img alt={"Nutritional Facts Image Of The Product"} src={this.state.viewNutrionalImg} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%', }} className={classes.uploadIcon} />
                                            </div>
                                            <Typography className={classes.uplaodImageTitle}>{t("Nutritional_Facts_Image")}<br /> {t("Of_The_Product")}</Typography>
                                        </Grid>

                                        <Grid item className={classes.imageWrapperContainer}>
                                            <div className={classes.imageContainer}>
                                                <img alt={"Ingredient List Image"} src={this.state.viewBarcodeImg} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%', }} className={classes.uploadIcon} />
                                            </div>
                                            <Typography className={classes.uplaodImageTitle}>{t("Ingredient_List_image")}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.inputContainer}>
                                    <Typography className={classes.FoodType}>{t("Upload_the_barcode_image_of_the_product")}</Typography>

                                    <Grid container >
                                        <Grid item style={{ margin: "12px" }} xs={12}>
                                            <div style={{
                                                position: 'relative',
                                                width: "100%",
                                                height: 200,
                                                display: 'inline-block',
                                                borderRadius: '3px',
                                                border: '2px dotted #fff',
                                                background: 'linear-gradient(#f0f0f0 0 0) padding-box,linear-gradient(180deg, #41479B 0%, #FF3939 100%) border-box',
                                            }}
                                            >
                                                <img alt={"BarcodeImage"} src={this.state.viewBarcodeImg} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%', }} className={classes.uploadIcon} />
                                            </div>
                                            <Typography className={classes.uplaodImageTitle}>{t("In_case_of_Hazy_or_poor_photo_Please_reupload_the_clear_images")}</Typography>

                                        </Grid>

                                    </Grid>
                                </div>
                            </>)}
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(withTranslation('ProductDetailsLang')(ViewProductWeb));
