import React from 'react';
import Loader from '../../../../components/src/Loader';
import NutrationGapCalculatorWebController, { Props } from './NutrationGapCalculatorWebController';
import { Typography, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import { topHeaderBanner, sideBarBanner } from '../../../ProductRecommendationEngine/src/assets'
import CustomizedToggleSwitche from '../../../../components/src/CustomizedToggleSwitche';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        profileTopBanner: {
            backgroundImage: `url(${topHeaderBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '200px'
        },
        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
        },

        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },
        linearBgBtn: {
            backgroundImage: `linear-gradient(91.39deg, #41479B 4.42%, #FF3939 100%)`,
            color: '#FFFFFF',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "33px",
            padding: '10px 5rem',
            border: '6px',
            // textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "13px",
                lineHeight: "12px",
                padding: '10px 3rem',
            },
        },
        rightArrowIcon: {
            border: '2px solid #38006D',
            borderRadius: '50%',
            padding: '0.2rem',
            color: '#38006D',
            fontSize: '2rem',
            fontWeight: 900,
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
            },
        },
        ArrowForwardIconStyle: {
            fontSize: '32px',
            fontWeight: 900,
            color: '#455A64',
            [theme.breakpoints.down("sm")]: {
                fontSize: "23px",
            },
        },
        weekDayName: {
            color: '#455A64',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "30px",
            // lineHeight: "33px",
            // textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
            },
        },
        subText: {
            color: '#AAA7A7',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "18px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
            },
        }

    });




class NutritionGapCalculatorWeb extends NutrationGapCalculatorWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
        } = this.state;
        const { classes } = this.props;
        if (loading) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={loading} />
                </Grid>
            );
        }

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} >
                        <div className={classes.profileTopBanner}>
                        </div>
                        <div className={classes.addNewMemberContainer}>
                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText='BMI Result' rightToggleText='Nutritional Gap' />
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>Nutrition Gap Calculator</Typography>
                            </div>

                            <div style={{ margin: '3rem 0' }}>
                                <div className={classes.headingTextContainer}>
                                    <Button variant="contained" color="primary" className={classes.linearBgBtn}>
                                        + Add New
                                    </Button>
                                    <Typography className={classes.pageHeadingText}>Filter</Typography>
                                </div>
                            </div>

                            <div style={{ margin: '2rem 0' }}>
                                <Link to='/NutritionGapReportWeb' style={{ textDecoration: 'none' }}>
                                    <Grid container spacing={3} alignItems="center" style={{ boxShadow: '0px 0px 12px rgb(0 0 0 / 12%)', padding: '22px 0' }}>
                                        <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', paddingLeft: '4rem' }}>
                                            <ArrowForwardOutlinedIcon className={classes.rightArrowIcon} />
                                            <div style={{ paddingLeft: '1.5rem' }}>
                                                <Typography className={classes.weekDayName}>Monday Diet</Typography>
                                                <Typography className={classes.subText}>deficiency 03</Typography>
                                            </div>

                                        </Grid>
                                        <Grid item xs>
                                            <ArrowForwardIosIcon className={classes.ArrowForwardIconStyle} />
                                        </Grid>
                                    </Grid>
                                </Link>

                                <Link to='/NutritionGapReportWeb' style={{ textDecoration: 'none' }}>
                                    <Grid container spacing={3} alignItems="center" style={{ boxShadow: '0px 0px 12px rgb(0 0 0 / 12%)', padding: '22px 0' }}>
                                        <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', paddingLeft: '4rem' }}>
                                            <ArrowForwardOutlinedIcon className={classes.rightArrowIcon} />
                                            <div style={{ paddingLeft: '1.5rem' }}>
                                                <Typography className={classes.weekDayName}>Monday Diet</Typography>
                                                <Typography className={classes.subText}>deficiency 03</Typography>
                                            </div>

                                        </Grid>
                                        <Grid item xs>
                                            <ArrowForwardIosIcon className={classes.ArrowForwardIconStyle} />
                                        </Grid>
                                    </Grid>
                                </Link>


                                <Link to='/NutritionGapReportWeb' style={{ textDecoration: 'none' }}>
                                    <Grid container spacing={3} alignItems="center" style={{ boxShadow: '0px 0px 12px rgb(0 0 0 / 12%)', padding: '22px 0' }}>
                                        <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', paddingLeft: '4rem' }}>
                                            <ArrowForwardOutlinedIcon className={classes.rightArrowIcon} />
                                            <div style={{ paddingLeft: '1.5rem' }}>
                                                <Typography className={classes.weekDayName}>Monday Diet</Typography>
                                                <Typography className={classes.subText}>deficiency 03</Typography>
                                            </div>

                                        </Grid>
                                        <Grid item xs>
                                            <ArrowForwardIosIcon className={classes.ArrowForwardIconStyle} />
                                        </Grid>
                                    </Grid>
                                </Link>
                            </div>
                        </div>

                    </Grid >
                </Grid >
            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    NutritionGapCalculatorWeb
);