import React, { memo } from "react";
//Customise area start
import { Typography, Chip } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchResultChips: {
      border: "double 1.4px transparent", borderRadius: 23,
      backgroundImage:
        "linear-gradient(white, white), linear-gradient(180deg, #41479B,#FB951A)",
      backgroundOrigin: "border-box",
      backgroundClip: "content-box, border-box",
    },
    chipLabel: {
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: '8px',
      fontWeight: 600,
      lineHeight: '10px',
      letterSpacing: '0em',
      textAlign: 'left',
      display: "flex",
    },
    chipScroll: {
      display: "flex",
      marginRight: "2px",
      overflowX: "scroll",
      cursor: "pointer",
      '&::-webkit-scrollbar': {
        height: '0px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        // backgroundColor: '#DFDEDE',
        // backgroundColor: '#D23',      
      }
    },
    chipInfo: {
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: '10px',
      fontWeight: 700,
      lineHeight: '11px',
      letterSpacing: '0em',
      marginLeft: "5px",
    },
  })
);

//Customise area stop
const ChipsWeb = (filterData: any) => {
  //Customise area start
  const classes = useStyles();
  const [t] = useTranslation(["smartSearchLng"]);
  return (
    <>
      {!filterData?.handleFavChips ?
        <div style={{ margin: '10px 0px', }} className={classes.chipScroll} >

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("Category")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{Object.values(filterData?.smartSearchFilters?.product_category).join().replace(/\s*,\s*/g, ", ").replace(/\s*:\s*/g, ": ")}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: Object.keys(filterData?.smartSearchFilters?.product_category).join().length > 0 ? "flex" : "none" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("SubCategory")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{JSON.stringify(filterData?.smartSearchFilters?.product_sub_category).replace(/[^a-zA-Z0-9:,&]/g, ' ').replace(/\s*,\s*/g, ", ").replace(/\s*:\s*/g, ": ")}</Typography>
          </div>}
            style={{ marginLeft: 10, display: Object.keys(filterData?.smartSearchFilters?.product_sub_category).length > 0 ? "flex" : "none" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>N<sup>IQ</sup> {t("Score")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo} style={{ marginTop: '4px' }}>{Object.values(filterData?.smartSearchFilters?.niq_score).join()}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData.smartSearchFilters?.niq_score.length > 0 ? "flex" : "none" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("FoodAllergies")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{Object.values(filterData?.smartSearchFilters?.food_allergies).join().replace(/\s*,\s*/g, ", ")}<span>&nbsp;</span></Typography>
          </div>}

            style={{ marginLeft: 10, display: filterData.smartSearchFilters?.food_allergies.length > 0 ? "flex" : "none" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("FoodPreferences")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{Object.values(filterData?.smartSearchFilters?.food_preference).join().replace(/\s*,\s*/g, ", ")}<span>&nbsp;</span></Typography>
          </div>}
            style={{
              marginLeft: 10,
              display: filterData.smartSearchFilters?.food_preference.length > 0 ? "flex" : "none"
            }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("FunctionalPreferences")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{JSON.stringify(filterData?.smartSearchFilters?.functional_preference).replace(/[^a-zA-Z0-9:,]/g, ' ').replace(/\s*,\s*/g, ", ").replace(/\s*:\s*/g, ": ")}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: Object.keys(filterData?.smartSearchFilters?.functional_preference).length > 0 ? "flex" : "none" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("HealthPreference")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{filterData?.smartSearchFilters?.health_preference}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData.smartSearchFilters?.health_preference.length > 0 ? "flex" : "none" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />
        </div>
        : // Favourite chip scroll container Start 
        <div style={{ margin: '10px 0px', }} className={classes.chipScroll} >
          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("Category")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{filterData?.displayFavChips?.attributes?.product_category === null ? "" : filterData?.displayFavChips?.attributes?.product_category.replace(/[^a-zA-Z0-9:,&]/g, ' ').replace(/\s*,\s*/g, ", ").replace(/\s*:\s*/g, ": ")}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData?.displayFavChips?.attributes?.product_category === null ? "none" : "flex" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("SubCategory")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{filterData?.displayFavChips?.attributes?.product_sub_category === null ? "" : filterData?.displayFavChips?.attributes?.product_sub_category.replace(/[^a-zA-Z0-9:,&]/g, ' ').replace(/\s*,\s*/g, ", ").replace(/\s*:\s*/g, ": ")}</Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData?.displayFavChips?.attributes?.product_sub_category === null ? "none" : "flex" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>N<sup>IQ</sup> {t("Score")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{filterData?.displayFavChips?.attributes?.niq_score.length < 1 ? "" : filterData?.displayFavChips?.attributes?.niq_score}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData?.displayFavChips?.attributes?.niq_score.length < 1 ? "none" : "flex" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("FoodAllergies")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{filterData?.displayFavChips?.attributes?.food_allergies.length < 1 ? "" : filterData?.displayFavChips?.attributes?.food_allergies.join(", ")}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData?.displayFavChips?.attributes?.food_allergies.length < 1 ? "none" : "flex" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("FoodPreferences")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{filterData?.displayFavChips?.attributes?.food_preference.length < 1 ? "" : filterData?.displayFavChips?.attributes?.food_preference.join(", ")}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData?.displayFavChips?.attributes?.food_preference.length < 1 ? "none" : "flex" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("FunctionalPreferences")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{filterData?.displayFavChips?.attributes?.functional_preference === null ? "" : filterData?.displayFavChips?.attributes?.functional_preference.replace(/[^a-zA-Z0-9:,]/g, ' ').replace(/\s*,\s*/g, ", ").replace(/\s*:\s*/g, ": ")}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData?.displayFavChips?.attributes?.functional_preference === null ? "none" : "flex" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />

          <Chip label={<div style={{ display: "flex" }}>
            <Typography className={classes.chipInfo}>{t("HealthPreference")}:&nbsp;</Typography>
            <Typography className={classes.chipInfo}>{filterData?.displayFavChips?.attributes?.health_preference === null ? "" : filterData?.displayFavChips?.attributes?.health_preference.replace(/^"(.*)"$/, '$1')}<span>&nbsp;</span></Typography>
          </div>}
            style={{ marginLeft: 10, display: filterData?.displayFavChips?.attributes?.health_preference === null ? "none" : "flex" }}
            variant="outlined" className={classes.searchResultChips}
            classes={{ label: classes.chipLabel, }} size="small"
          />
        </div>
      }
    </>
  );
};
export default memo(ChipsWeb);
 // Customise area stop