export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const product_bg = require("../assets/product_bg.png");
export const ellipse = require("../assets/ellipse.png");
export const Compare = require("../assets/Compare.svg");
export const Favorite = require("../assets/Favorite.svg");
export const ReportThis = require("../assets/ReportThis.svg");
export const rightArrow = require("../assets/rightArrow.png");
export const LeftArrow = require("../assets/LeftArrow.png");
export const topHeaderBanner = require("../assets/topHeaderBanner.svg");
export const sideBarBanner = require("../assets/sideBarBanner.svg");
export const profileImage = require("../assets/profileImage.svg");
export const successfullyImg = require("../assets/successfullyImg.svg");
export const appleImage = require("../assets/appleImage.svg");
export const notificationImg = require("../assets/notificationImg.svg");
export const scaleFirstPart = require("../assets/scaleFirstPart.jpg");
export const ratingScaleImg = require("../assets/ratingScaleImg.jpg");
export const compareproductApple = require("../assets/Apple.png");
export const compareproductImg2 = require("../assets/Banana.png");
export const Vector = require("../assets/Vector.png");
export const Vector1 = require("../assets/Vector1.png");
export const Vector2 = require("../assets/Vector2.png");
export const Rectangle609 = require("../assets/Rectangle609.png");
export const Rectangle609A = require("../assets/Rectangle609A.png");
export const Rectangle609B = require("../assets/Rectangle609B.png");
export const Ellipse140 = require("../assets/Ellipse140.png");
export const Ellipse141 = require("../assets/Ellipse141.png");
export const Ellipse142 = require("../assets/Ellipse142.png");
export const A = require("../assets/A.png");
export const B = require("../assets/B.png");
export const D = require("../assets/D.png");
export const Deleteicon = require("../assets/Deleteicon.png");
export const DeleteImg = require("../assets/DeleteImg.png");
export const download = require("../assets/download.png");
export const images = require("../assets/images.png");
export const add_more_product = require("../assets/add_more_product.png");
export const topHeaderBanner1 = require("../assets/topHeaderBanner1.svg");
export const productReportTopBanner = require("../assets/productReportTopBanner.svg");
export const successGif = require("../assets/success.gif");

