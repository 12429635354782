import React from 'react';
import './productDetailsPagestyle.css'
import { Tooltip, } from '@material-ui/core';
import { makeStyles, Theme, } from '@material-ui/core/styles';
export interface StyleProps {
    niqRating: any;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    customWidth: {
        height: '25px',
        width: '65px',
        backgroundColor: ({ niqRating }) => handleToolTipStyle(niqRating),
        textAlign: 'center',
        fontSize: '12px',
    },
    customArrow: {
        color: ({ niqRating }) => handleToolTipStyle(niqRating),
    },
    NiqScoreText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        color: '#FFFFFF',
    }
}));
//The end--------------------
const handleToolTipStyle = (rating: any) => {
    let productRating;
    switch (true) {
        case rating === "A":
            productRating = "#3C9637";
            break;
        case rating === "B":
            productRating = "#AAC83C";
            break;
        case rating === "C":
            productRating = "#FCC300";
            break;
        case rating === "D":
            productRating = "#FF784B";
            break;
        case rating === "E":
            productRating = "#FF5050";
            break;
        default: productRating = "#c8c4c4";
    }
    return productRating;
}
const ProductRatingToolTip = (props: any) => {
    const ToolTipProps = { niqRating: props.item }
    const classes = useStyles(ToolTipProps);
    const handleToolTip = () => {
        let toolTip;
        switch (true) {
            case props.item === "A":
                toolTip = 'A';
                break;
            case props.item === "B":
                toolTip = 'B';
                break;
            case props.item === "C":
                toolTip = 'C';
                break;
            case props.item === "D":
                toolTip = 'D';
                break;
            case props.item === "E":
                toolTip = 'E';
                break;
            default: toolTip = "NA"
        }
        return toolTip
    }
    return (
        <Tooltip title={<span className={classes.NiqScoreText} >N<sup className={classes.NiqScoreText}>IQ</sup> Score</span>}
            arrow open={props.itemRating === handleToolTip() ? true : false} classes={{ tooltip: classes.customWidth, arrow: classes.customArrow }}>
            {props.children}
        </Tooltip>
    )
}
export default ProductRatingToolTip