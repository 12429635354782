import React from 'react';
import BmiCalculatorWebController, { Props } from './BmiCalculatorWebController';
import { Typography, Grid, Button } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import { sideBarBanner } from '../../../ProductRecommendationEngine/src/assets'
import { bmiDashboardsiteimg, normalweightList, obesityList, overweightList, underweightList } from '../assets'
import BmiHealthCard from './BmiHealthCard.web';
import CustomizedToggleSwitche from '../../../../components/src/CustomizedToggleSwitche';
import { topBannerBMIDashboard } from "../assets"
import Slider from '@material-ui/core/Slider';
import InputBase from "@material-ui/core/InputBase";
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoadingErrorNotificationText } from '../../../../components/src/LoadingErrorNotificationText';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },
        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        profileTopBanner: {
            marginTop: "-1px",
            marginLeft: "-1px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "7px"
            },
            [theme.breakpoints.down("xs")]: {
                marginTop: "3px"
            },
        },
        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
        },
        shortDescription: {
            color: '#455A64',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "33px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "20px",
            },
        },
        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },
        backButtonText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF5050",
            cursor: 'pointer',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },

        // add member list css
        linearGradientBorderColorContainer: {
            border: "double 2px transparent",
            borderRadius: '10px',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(80deg,#38006F,#FF5050)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
        },
        productButton: {
            fontFamily: `'Montserrat', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '22px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            background: '#38006D',
            borderRadius: "44px",
            padding: '10px 80px',
            margin: '2rem 0',
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },
            [theme.breakpoints.down("md")]: {
                padding: '12px 60px',
                fontSize: '15px',
            },
            [theme.breakpoints.down("sm")]: {
                padding: '8px 50px',
                fontSize: '12px',
            },
        },
        itemListText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "18px",
            color: "#757D8A",
            paddingLeft: '0.6rem',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        ratingItemList: {
            display: 'flex',
            alignItems: 'center',
            margin: '1rem 0'
        },
        progresSlider: {
            width: '100%',
            height: "18px",
            '& .MuiSlider-thumb': {
                height: 28,
                width: 15,
                background: '#37006E',
                borderRadius: '5px',
            },
            '& .MuiSlider-rail': {
                background: "linear-gradient(to right, #FFD71D 0 18.5%, #00AB79 18.5% 25%, #FB951A 25% 30%, #FF3939 30% 100%)",
                opacity: 1,
                height: '18px',
                borderRadius: 'unset',
            },
            '& .MuiSlider-thumb:hover': {
                boxShadow: 'none',
            },
        },
        dashboardImgResp: {
            [theme.breakpoints.down("xs")]: {
                display: "none",
            }
        },
        dashboardImg: {
            margin: "2rem auto",
            display: "block",
            [theme.breakpoints.up("sm")]: {
                display: "none",
            }
        },
        //Slider Styles
        progresSliderHeightWeight: {
            width: '100%',
            height: "18px",
            color: '#2E445C',
            '& .MuiSlider-thumb': {
                height: 27,
                width: 10,
                backgroundColor: '#DFD9D9',
                borderRadius: '5px',
            },
            '& .MuiSlider-rail': {
                backgroundColor: '#2E445C',
                opacity: 1,
                height: '18px',
                borderRadius: 'unset',
            },
            '& .MuiSlider-thumb:hover': {
                boxShadow: 'none',
            },
        },
        inputContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        inputLabelText: {
            color: '#455A64',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '-0.01em',
            [theme.breakpoints.down("sm")]: {
                // fontSize: '1rem',
            },
        },
        inputUnitText: {
            color: '#455A64',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '1rem',
            [theme.breakpoints.down("sm")]: {
                fontSize: '0.7rem',
            },
        },
        inputField: {
            backgroundColor: 'transparent',
            padding: '0.6rem',
            border: "2px solid #808080",
            borderRadius: 8,
            color: '#808080',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            margin: '0 5px',
            '& .MuiInputBase-input': {
                height: '0.8em'
            },

            [theme.breakpoints.down("sm")]: {
                margin: '0 3px',
                padding: '0.3rem',
            },
        },
        nofificationText: {
            color: '#FF3939',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '23px',
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px',
            },
        },

    });




class BmiCalculatorDashboardWebPage extends BmiCalculatorWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
        } = this.state;
        const { classes, t } = this.props;
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} >
                        <img src={topBannerBMIDashboard} alt="bgImage" style={{ width: "100%" }} className={classes.profileTopBanner} />
                        <div className={classes.addNewMemberContainer}>

                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText={t("BMIResult")} rightToggleText={t("NutritionalGap")} />
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>{t("BMICalculator")}</Typography>
                                <Typography className={classes.backButtonText} data-testid="backbtn" onClick={() => this.backButton()}>
                                    {t("Back")}
                                </Typography>
                            </div>
                            <div>
                                <Typography className={classes.shortDescription}>
                                    Body Mass Index is a simple calculation using a person's height and weight. The formula is <br /> BMI = kg/m2 where kg is a person's weight in kilograms and m2 is their height in metres squared.
                                </Typography>
                            </div>

                            {
                                loading ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> : (
                                    <div style={{ margin: '2.5rem 0' }}>
                                        {
                                            this.state.BmiProfileDetails.length === 0 || this.state.errorReponse ? <LoadingErrorNotificationText /> : (
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <BmiHealthCard profileData={this.state.BmiProfileDetails} />
                                                        <div
                                                            className={classes.dashboardImg}
                                                        >
                                                            <div className={classes.linearGradientBorderColorContainer} style={{ textAlign: 'center' }}>
                                                                <img src={bmiDashboardsiteimg} alt="" />
                                                            </div>

                                                        </div>
                                                        <div style={{ margin: '20px 0' }}>

                                                            <Grid container spacing={2} alignItems="center" style={{ margin: '10px 0' }}>
                                                                <Grid item xs={6} sm={6} md={5}>
                                                                    <div className={classes.inputContainer}>
                                                                        <Typography className={classes.inputLabelText}>{t("Weight")}:&nbsp;&nbsp;&nbsp;</Typography>
                                                                        <InputBase
                                                                            id="outlined-basic"
                                                                            data-testid="sliderInput"
                                                                            placeholder=""
                                                                            // @ts-ignore
                                                                            onChange={(event: any, slider: any) => { this.handleChange(event, slider = "weight") }}
                                                                            value={this.state.weightBmi}
                                                                            inputProps={{
                                                                                min: 0,
                                                                                step: 0.5,
                                                                                max: 200,
                                                                                type: 'number',
                                                                                'aria-labelledby': 'input-slider',
                                                                            }}
                                                                            type="number"
                                                                            className={classes.inputField}
                                                                        />
                                                                        <Typography className={classes.inputUnitText}>{t("KiloGram")}</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={7}>
                                                                    <Slider
                                                                        step={0.5}
                                                                        max={200}
                                                                        value={this.state.weightBmi}
                                                                        // @ts-ignore
                                                                        onChange={(event: any, newValue: any, slider: any) => { this.sliderChange(event, newValue, slider = "weight") }}
                                                                        aria-labelledby="input-slider"
                                                                        data-testid={"sliderInput"}
                                                                        className={classes.progresSliderHeightWeight}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={2} alignItems="center" style={{ margin: '10px 0' }}>
                                                                <Grid item xs={6} sm={6} md={5}>
                                                                    <div className={classes.inputContainer}>
                                                                        <Typography className={classes.inputLabelText}>{t("Height")}:&nbsp;&nbsp;&nbsp;</Typography>
                                                                        <InputBase
                                                                            id="outlined-basic"
                                                                            data-testid="sliderInput"
                                                                            placeholder=""

                                                                            // @ts-ignore
                                                                            onChange={(event: any, slider: any) => { this.handleChange(event, slider = "height") }}
                                                                            value={this.state.heightBmi}
                                                                            inputProps={{
                                                                                min: 0,
                                                                                step: 0.01,
                                                                                max: 2,
                                                                                type: 'number',
                                                                            }}
                                                                            type="number"
                                                                            className={classes.inputField}
                                                                        />
                                                                        <Typography className={classes.inputUnitText}>{t("meter")}</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={7}>
                                                                    <Slider
                                                                        step={0.01}
                                                                        min={0}
                                                                        max={2}
                                                                        value={this.state.heightBmi}
                                                                        // @ts-ignore
                                                                        onChange={(event: any, newValue: any, slider: any) => { this.sliderChange(event, newValue, slider = "height") }}
                                                                        aria-labelledby="input-slider"
                                                                        data-testid={"sliderInput"}
                                                                        className={classes.progresSliderHeightWeight}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <Slider
                                                            value={this.state.BmiProfileDetails?.attributes?.bmi_result}
                                                            aria-labelledby="input-slider"
                                                            data-testid={"sliderInput"}
                                                            className={classes.progresSlider}
                                                            track={false}
                                                            valueLabelDisplay="auto"
                                                            step={0.01}
                                                        />
                                                        <Typography style={{ fontFamily: `'Poppins', sans-serif`, fontWeight: 500, fontSize: '18px', lineHeight: '27px', color: '#808080', textAlign: "center" }}>{t("BMIScale")}</Typography>
                                                        <div style={{ margin: '2rem 0' }}>
                                                            <div className={classes.ratingItemList}>
                                                                <img src={underweightList} alt="underweightList" />
                                                                <Typography className={classes.itemListText}> {t("UnderWeight")}</Typography>
                                                            </div>

                                                            <div className={classes.ratingItemList}>
                                                                <img src={normalweightList} alt="underweightList" />
                                                                <Typography className={classes.itemListText}> {t("NormalWeight")}</Typography>
                                                            </div>

                                                            <div className={classes.ratingItemList}>
                                                                <img src={overweightList} alt="underweightList" />
                                                                <Typography className={classes.itemListText}> {t("OverWeight")}</Typography>
                                                            </div>

                                                            <div className={classes.ratingItemList}>
                                                                <img src={obesityList} alt="underweightList" />
                                                                <Typography className={classes.itemListText}> {t("Obesity")}</Typography>
                                                            </div>
                                                        </div>

                                                        <div style={{ textAlign: 'center' }}>
                                                            <Button
                                                                data-testid="navigateToBmiSymptomPageBtn"
                                                                className={classes.productButton} onClick={() => {
                                                                    this.props.navigation?.history?.push({
                                                                        pathname: '/BmiSignAndSymptomsWebPage',
                                                                        state: this.state.BmiProfileDetails.id
                                                                    })
                                                                }}>
                                                                {t("Show_Signs_Symptoms")}
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm={6}
                                                        className={classes.dashboardImgResp}
                                                    >
                                                        <div className={classes.linearGradientBorderColorContainer} style={{ textAlign: 'center' }}>
                                                            <img src={bmiDashboardsiteimg} alt="" />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </div>
                                )}
                        </div>
                    </Grid >
                </Grid >

            </div >
        );
    }
}
// Customizable Area End
export default withStyles(styles, { withTheme: true })(withTranslation('BMiCalculatorLng')(BmiCalculatorDashboardWebPage));
