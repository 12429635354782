import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import googleController, { GoogleWebDelegate } from "./GoogleWebController";
import facebookController, { FacebookDelegate } from "./FacebookController";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  classes: any;
  // Customizable Area End
}

interface S {
  loading: boolean;
  isRegistration: boolean;
  // Customizable Area Start
  gridItemNumber: number;
  mobileNumberInputPageEnable: boolean;
  otpInputPageEnable: boolean;
  describeInputPageEnable: boolean;
  guestUser: boolean;
  gotErrorResponse: boolean;
  uToken: any;
  inValidOTP:any;
  isInValidOTP:boolean;
  expireOTP:any;
  isExpireOTP:boolean;
  // Customizable Area End
}

interface SS { }

export default class UserSignUpScreenWebController
  extends BlockComponent<Props, S, SS>
  implements GoogleWebDelegate {
  createAccountAPICallId: any;
  googleUser: any;

  apiSentOtpCallmessageId: string = '';
  apiOtpVerificationmessageId: string = '';
  resendOtpCallmessageId: string = '';
  facebookAuthMessageId: string = '';
  googleAuthMessageId: string = '';
  userProfileUpdateMessageId: string = '';


  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
    ]);

    this.state = {
      loading: false,
      // Customizable Area Start
      isRegistration: false,
      gridItemNumber: 1,
      mobileNumberInputPageEnable: true,
      otpInputPageEnable: false,
      describeInputPageEnable: false,
      guestUser: false,
      gotErrorResponse: false,
      uToken: '',
      inValidOTP:'',
      isInValidOTP:false,
      expireOTP:'',
      isExpireOTP:false,
      // Customizable Area End
    };
  }



  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    if (localStorage.getItem('mobileNumberInputPageEnable') && localStorage.getItem('otpInputPageEnable') && localStorage.getItem('describeInputPageEnable')) {
      this.setState(
        {
          mobileNumberInputPageEnable: localStorage.getItem('mobileNumberInputPageEnable') === 'true' ? true : false,
          otpInputPageEnable: localStorage.getItem('otpInputPageEnable') === 'true' ? true : false,
          describeInputPageEnable: localStorage.getItem('describeInputPageEnable') === 'true' ? true : false,
        }
      )
    }
    // Customizable Area End
  }

  /********************
  
  This area for write normal function for this controller <--start--> {rf}
  
  ********************/

  //  <---------- function for page state management in localstore {rf} ---------->
  pageManageInLocalstore = (mobileInputPage: boolean, otpInputPage: boolean, profileDetailsInputPage: boolean) => {
    this.setState(
      {
        mobileNumberInputPageEnable: mobileInputPage,
        otpInputPageEnable: otpInputPage,
        describeInputPageEnable: profileDetailsInputPage,
      }
    )
    localStorage.setItem('mobileNumberInputPageEnable', mobileInputPage.toString())
    localStorage.setItem('otpInputPageEnable', otpInputPage.toString())
    localStorage.setItem('describeInputPageEnable', profileDetailsInputPage.toString())
  }


  //  <---------- function for sotre user details in localstore {rf} ---------->
  userDetailsStoreInLocalStore = (responseJson: any) => {
    const user = {
      id: responseJson?.data?.id,
      type: responseJson?.data?.type,
      name: responseJson?.data?.attributes?.full_name,
      email: responseJson?.data?.attributes?.email,
      image: responseJson?.data?.attributes?.image
    }
    localStorage.clear();
    localStorage.setItem('id', responseJson?.data?.id);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', responseJson?.meta?.token);
  }

  // --------- function for navigation  ---------- //
  navigationBtnFnc = (path: any) => {
    this.props?.navigation?.navigate(path);
  }


  handleContinue = (otp: string) => {
    this.setState({ guestUser: true });
    this.mobileOtpVerification(otp)
  }

  /******************
  
  This area for write normal function for this controller <--end--> {rf}
  
  ******************/



  /******************
  
  This area for write API call function for this controller <--start--> {rf}
  
  ******************/


  // <---------- API call for signup with mobile number start{rf} ---------->

  sentMobileOtpRequest = (phoneNumber: string) => {
    this.startLoading();

    // const userToken = localStorage.getItem("authToken");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      // token: userToken,
    };

    const httpBody = {
      data: {
        type: 'sms_account',
        attributes: {
          full_phone_number: phoneNumber,
        },
      },
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.apiSentOtpCallmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSignupEndPointURL // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // <---------- API call for otp verification code {rf} ---------->
  mobileOtpVerification = (otp: string) => {
    this.startLoading();
    const userToken = localStorage.getItem("otpToken");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    const httpBody = {
      data: {
        type: 'sms_account',
        attributes: {
          pin: otp,
        },
      },
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.apiOtpVerificationmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpVerificationEndPontURL // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // <---------- API call for  Resend OTP request {rf} ---------->

  resentOtpRequest = (phoneNumber: any) => {

    this.startLoading();
    // const userToken = localStorage.getItem("authToken");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      // token: userToken,
    };

    const httpBody = {
      data: {
        type: 'email_account',
        attributes: {
          full_phone_number: phoneNumber,
        },
      },
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.resendOtpCallmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resentOtpRequestEndPontURL // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // signup with mobile number end {rf}



  // <---------- API call for socialGoogleAuthResponse Facebook login {rf} ---------->

  socialFacebookAuthResponse = (response: any) => {
    this.startLoading();
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      // token: userToken,
    };

    const httpBody = {
      data: {
        type: 'social_account',
        attributes: {
          full_name: response?.name,
          email: response?.email,
          unique_auth_id: response?.id,
          image_url: response?.picture?.data?.url
        },
      },
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.facebookAuthMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSignupEndPointURL // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // <---------- API call for socialGoogleAuthResponse {rf} ---------->
  socialGoogleAuthResponse = (response: any) => {
    this.startLoading();
    // const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      // token: userToken,
    };

    const httpBody = {
      data: {
        type: 'social_account',
        attributes: {
          full_name: response.profileObj.name,
          email: response.profileObj.email,
          unique_auth_id: response.profileObj.googleId,
          image_url: response?.profileObj?.imageUrl
        },
      },
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.googleAuthMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSignupEndPointURL // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // <---------- API call for user profile update {rf} ---------->
  userProfileUpdate = (full_name: string, email: string, gender: string) => {
    this.startLoading();
    const userToken = localStorage.getItem("token");
    const header = {
      // 'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    const formData = new FormData();
    formData.append("data[type]", 'social_account');
    formData.append("data[attributes][full_name]", full_name);
    formData.append("data[attributes][email]", email);
    formData.append("data[attributes][gender]", gender);

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.userProfileUpdateMessageId = requestMessage.messageId;
    const uId = localStorage.getItem('id')
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountSignupEndPointURL}/${uId}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
      // JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  /******************
  
  This area for write API call function for this controller <--end--> {rf}
  
  ******************/


  /******************
  
  This area for write recived function for get API response after API call  <--start--> {rf}
  
  ******************/

  // receive function for api call
  async receive(from: string, message: Message) {
    //  <---------- response for mobile number otp sent  start {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiSentOtpCallmessageId &&
      this.apiSentOtpCallmessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.otpSendPostAPIResponse(responseJson);
    }

    // end for {rf}

    //  <---------- response for number otp Verification   start {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiOtpVerificationmessageId &&
      this.apiOtpVerificationmessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.otpVerificationAPIResponse(responseJson);
    }

    // end for {rf}


    //  <---------- response for  Resend otp Verification   start {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.resendOtpCallmessageId &&
      this.resendOtpCallmessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.resendOtpAPIResponse(responseJson)
      
    }

    // end for {rf}


    //  <---------- response for  facbook auth start {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.facebookAuthMessageId &&
      this.facebookAuthMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.faceBookAuthAPIResponse(responseJson);
     
    }

    // end for {rf}


    //  <---------- response for  google auth start {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.googleAuthMessageId &&
      this.googleAuthMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.googleAuthAPIResponse(responseJson)
    
    }

    // end for {rf}


    //  <---------- response for  user profile update {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userProfileUpdateMessageId &&
      this.userProfileUpdateMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.userProfileUpdateAPIResponse(responseJson);
     

    }

    // end for {rf}


    if (getName(MessageEnum.SessionSaveMessage) === message.id) {
      this.openInfoPage();
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createAccountAPICallId != null &&
      this.createAccountAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.saveLoggedInUserData(responseJson);
        this.stopLoading();
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.stopLoading();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
        this.stopLoading();
      }
    } else {
      runEngine.debugLog("GOIT");
      this.stopLoading();
    }


  }

  /******************
  
  This area for write recived function for get API response after API call <--end--> {rf}
  
  ******************/




  // ---------------- here previous written function -----------------

  //When facebook sends back the reponse this gets called
  googleUserStatusChanged(userInfo: any): void {
    if (this.state.isRegistration) {
      this.createAccountFromSocial(userInfo.email, userInfo.id, this.props);
    } else {
      this.logInWithSocial(
        userInfo.email,
        userInfo.email,
        userInfo.id,
        this.props
      );
    }
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  responseFacebook = (response: any) => {
    this.stopLoading();
    if (this.state.isRegistration) {
      this.createAccountFromSocial(response.email, response.id, this.props);
    } else {
      this.logInWithSocial(
        response.email,
        response.email,
        response.id,
        this.props
      );
    }
    runEngine.debugLog(response);
  };

  googleLogIn = (isRegistration: boolean) => {
    const self = this;
    //@ts-ignore
    googleController.googleLogIn(this).then(
      function () {
        self.stopLoading();
        runEngine.debugLog("User SIGNED IN.");
      },
      function (error: any) {
        self.stopLoading();
        if (error.error === "popup_closed_by_user") {
          //handle window closed event
        }
      }
    );
  };


  async createAccountFromSocial(
    incomingEmail: String,
    incomingId: String,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountURL
    );

    const data = {
      type: "social_account",
      attributes: {
        email: incomingEmail,
        password: incomingEmail,
        unique_auth_id: incomingId
      }
    };

    const httpBody = {
      tdata: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async logInWithSocial(
    incomingEmail: string,
    incomingPassword: string,
    incomingId: string,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      'Content-Type': 'application/json',
    };

    const attrs = {
      email: incomingEmail,
      password: incomingPassword,
      unique_auth_id: incomingId
    };

    const data = {
      type: 'social_account',
      attributes: {
        email: incomingEmail,
        password: incomingEmail,
        unique_auth_id: incomingId
      },
    };

    const httpBody = {
      tdata: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSignupEndPointURL //change
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigate() {
    runEngine.debugLog('this.isRegistration');
    runEngine.debugLog(this.state.isRegistration);
    if (this.state.isRegistration) {
      runEngine.debugLog('Registration');
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToSignup();
    } else {
      runEngine.debugLog('Registration');
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToLogin();
    }
  }

  navigateToSignup() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg = new Message(
      getName(
        this.state.isRegistration
          ? MessageEnum.AccoutResgistrationSuccess
          : MessageEnum.AccoutLoginSuccess
      )
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog('Result Name: ' + result.name);
    }
  }

  btnFacebookLogInProps = {
    onPress: () => {
      this.startLoading();
    },
    callback: this.responseFacebook,
  };

  btnGoogleLogInProps = {
    onPress: () => {
      this.googleLogIn(this.state.isRegistration);
      this.startLoading();
    },
  };
  btnNavigateProps = {
    onPress: () => this.navigate()

  };
  otpExpire = (respMsg:any) => {
    this.setState({expireOTP: respMsg, isExpireOTP: true}) 
    setTimeout(()=>{
      localStorage.clear();
      this.setState({isExpireOTP: false})
      this.pageManageInLocalstore(true, false, false);
      this.navigationBtnFnc('UserSignUpScreenWeb');
      window.location.reload()
    }, 3000)   
  }

  otpSendPostAPIResponse = (responseJson:any) => {
    if (responseJson?.errors) {
      this.stopLoading();
      // alert(responseJson?.errors[0]?.full_phone_number)
      this.setState({ gotErrorResponse: true });
      
    }
    if (responseJson?.status === 500) {
      this.stopLoading();
      // alert(responseJson?.error)
      this.setState({ gotErrorResponse: true });
      
    }
    if (responseJson?.meta && responseJson?.data) {
      localStorage.setItem('otpToken', responseJson?.meta?.token)        
      localStorage.setItem('id', responseJson?.data?.id)
      this.pageManageInLocalstore(false, true, false);
      this.setState({ gotErrorResponse: false });
      this.stopLoading();
    } else {
      this.setState({ gotErrorResponse: true });
      localStorage.removeItem('inputPhoneNumber')
      this.stopLoading();
      
    }
  }

  otpVerificationAPIResponse = (responseJson:any) => {
    if (responseJson?.errors) {
      this.stopLoading();
      if(responseJson?.errors[0]?.pin) {
        this.setState({inValidOTP: responseJson?.errors[0]?.pin, isInValidOTP: true})      
      }
      if(responseJson?.errors[0]?.phone) {      
          this.otpExpire(responseJson?.errors[0]?.phone)  
      }
      
    }
    if (responseJson?.data) {
      this.userDetailsStoreInLocalStore(responseJson)
      this.setState({isInValidOTP: false})
      if (responseJson?.meta?.additional_details) {
        this.pageManageInLocalstore(true, false, false);
        this.stopLoading();
        this.props?.navigation?.navigate('Home');
      } else {
        if (this.state.guestUser) {
          this.pageManageInLocalstore(true, false, false);
          this.setState({ guestUser: false });
          this.stopLoading();
          this.props?.navigation?.navigate('Home');
        } else {
          this.pageManageInLocalstore(false, false, true);
          this.stopLoading();
        }
      }

    }
  }

  resendOtpAPIResponse = (responseJson:any) => {
    if (responseJson?.errors) {
      this.stopLoading();
      alert(responseJson?.errors[0]?.full_phone_number)
      
    }
    if (responseJson?.status === 500) {
      localStorage.clear();
      this.stopLoading();
      alert(responseJson?.error)
      
    }
    if (responseJson?.meta) {
      localStorage.setItem('otpToken', responseJson?.meta?.token)
      
      this.stopLoading();

    }
  }

  faceBookAuthAPIResponse = (responseJson:any) => {
    if (responseJson?.data && !responseJson?.meta?.message) {

      this.userDetailsStoreInLocalStore(responseJson)
      if (responseJson?.meta?.additional_details) {
        this.pageManageInLocalstore(true, false, false);
        this.stopLoading();
        this.props?.navigation?.navigate('Home');
      } else {
        this.pageManageInLocalstore(false, false, true);
        this.stopLoading();
      }

    }
    if (responseJson?.meta?.additional_details) {
      this.userDetailsStoreInLocalStore(responseJson)
      this.pageManageInLocalstore(true, false, false);
      this.stopLoading();
      this.props?.navigation?.navigate('Home');
    }
  }

  googleAuthAPIResponse = (responseJson:any) => {
    if (responseJson?.data && !responseJson?.meta?.message) {

      this.userDetailsStoreInLocalStore(responseJson)
      if (responseJson?.meta?.additional_details) {
        this.pageManageInLocalstore(true, false, false);
        this.stopLoading();
        this.props?.navigation?.navigate('Home');
        window.location.reload();
      } else {
        this.pageManageInLocalstore(false, false, true);
        this.stopLoading();
      }

    }
    if (responseJson?.meta?.additional_details) {
      this.userDetailsStoreInLocalStore(responseJson)
      this.pageManageInLocalstore(true, false, false);
      this.stopLoading();
      this.props?.navigation?.navigate('Home');
      window.location.reload();
    }
  }

  userProfileUpdateAPIResponse = (responseJson:any) => {
    if (responseJson?.data) {
      const user = {
        id: responseJson?.data?.id,
        type: responseJson?.data?.type,
        name: responseJson?.data?.attributes?.full_name,
        email: responseJson?.data?.attributes?.email,
        image: responseJson?.data?.attributes?.image
      }
      localStorage.setItem('id', responseJson?.data?.id);
      localStorage.setItem('user', JSON.stringify(user));
      this.pageManageInLocalstore(true, false, false);
      this.stopLoading();
      this.props?.navigation?.navigate('Home');
    }
  }
}
    // Customizable Area End