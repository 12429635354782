// Customizable Area Start
import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { createStyles,withStyles, Theme } from "@material-ui/core/styles";
import ContentManagementController, {Props} from "./ContentManagementController";
import { withTranslation } from 'react-i18next';

const styles = (theme:Theme) => createStyles({
  
  Textstyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
    fontWeight: 100,
    fontSize: "14px",
    },
  },
  TextColor: {
    color:"#f00",
  },
  
  RootBoxContainerStyle: {
    marginTop: 100,
    marginLeft: 55.5,
    marginRight: 55.5,
    background: "#FFFFFF",
    paddingBottom: 50,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 25,
      marginRight:25,
    },
  },
  
  TitleBoxContainerStyle: {
    marginLeft: 43,
    marginTop: 45,
    marginBottom: 57,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    marginTop: 10,
    marginBottom: 25,
    },
  },
  
  HeadingStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "39px",
    lineHeight: "48px",
    color: "#455A64",    
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "30px",
    },
  },
  
  ContentContainerStyle: {
              marginLeft: 59,
              marginRight: 45,
              marginBottom: 203.86,
              [theme.breakpoints.down("sm")]: {
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 0,
              },
  },
  linkResStyle: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      },
  }
  
});

class PrivacyPolicy extends ContentManagementController {
  constructor(props:Props){
    super(props)
  }
  render() {

    const { classes, t } = this.props;
    return (
      <Grid container justifyContent="center" alignItems="center">
        <div className={classes.RootBoxContainerStyle}>
          <div className={classes.TitleBoxContainerStyle}>
            <Typography className={classes.HeadingStyle}>
              {t("Privacy_Policy")}
            </Typography>
          </div>

          <div className={classes.ContentContainerStyle}>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
              <Typography className={classes.Textstyle}>
                <Typography className={classes.Textstyle}>
                  These terms govern
                </Typography>
                <ul className={classes.Textstyle}>
                  <li>The use of What’syourN<sup>IQ</sup> / N<sup>IQ</sup> score, and,</li>
                  <li>
                    Any other related Agreement or legal relationship with the
                    Owner
                  </li>
                  <Typography className={classes.Textstyle}>
                    In a legally binding way. Capitalized words are defined in
                    the relevant dedicated section of this document.
                  </Typography>
                </ul>
              </Typography>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", rowGap: 10 }}
            >
              <Typography className={classes.Textstyle}>
                The User must read this document carefully.
              </Typography>

              <Typography className={classes.Textstyle}>
                Although the entire contractual relationship relating to these
                Products is entered into solely by the Owner and Users, Users
                acknowledge and agree that, where What’syourN<sup>IQ</sup>/N<sup>IQ</sup> score has
                been provided to them via the Apple App Store, Apple may enforce
                these Terms as a third-party beneficiary.
              </Typography>

              <Typography className={classes.Textstyle}>
                Nothing in these Terms creates any relationship of employment,
                agency, or partnership between the involved parties.
                What’syourN<sup>IQ</sup> is provided by: Superfood Valley Pvt. Ltd
              </Typography>

              <div>
                <Typography className={classes.Textstyle}>
                  Owner contact email:
                  <a href="mailto:Support@ What’syourN<sup>IQ</sup>.com">
                    Support@What’syourN<sup>IQ</sup>.com
                  </a>
                </Typography>
                <Typography className={classes.Textstyle}>
                  “What’s your N<sup>IQ</sup>” refers to
                </Typography>
                <ul className={classes.Textstyle}>
                  <li>
                    The website, including its subdomains and any other website
                    through which the Owner makes its Service available;
                  </li>
                  <li>
                    Applications for mobile, tablet and other smart device
                    systems;
                  </li>
                  <li>The Service;</li>
                  <Typography className={classes.Textstyle}>
                    The following documents are incorporated by reference into
                    these Terms:
                  </Typography>
                  <li style={{ textDecoration: "underline" }}>
                    End-user license agreement
                  </li>
                </ul>
                <ul className={classes.Textstyle} />
              </div>

              <Typography className={classes.Textstyle}>
                <strong>What the User should know at a glance</strong>
              </Typography>
              <ul className={classes.Textstyle}>
                <li style={{ marginBottom: "20px" }}>
                  What’syourN<sup>IQ</sup> uses automatic renewal for Product
                  subscriptions. Information about the a) renewal period, b)
                  termination details and c) termination notice can be found in
                  the relevant section of these Terms.
                </li>
              </ul>
            </div>

            <div
              style={{
                height: 2,
                width: "100%",
                backgroundColor: "#000",
                marginBottom: 10,
                flexGrow: 1,
              }}
            />

            <div>
              <Typography className={classes.Textstyle}>
                <strong>TERMS OF USE</strong>
              </Typography>
              <Typography className={classes.Textstyle}>
                Unless otherwise specified, the terms of use detailed in this
                section apply generally when using What’syourN<sup>IQ</sup>. Single or
                additional conditions of use or access may apply in specific
                scenarios and in such cases are additionally indicated within
                this document.
              </Typography>
              <Typography className={classes.Textstyle}>
                By using What’syourN<sup>IQ</sup>, Users confirm to meet the following
                requirements:
              </Typography>
              <ul className={classes.Textstyle}>
                <li>Users must qualify as Consumers;</li>
                <li className={classes.TextColor}>
                  Users aren’t located in a country that is subject to a U.S.
                  Government embargo, or that has been designated by the U.S.
                  Government as a “terrorist-supporting” country;
                </li>
                <li className={classes.TextColor}>
                  Users aren’t listed on any U.S. Government list of prohibited
                  or restricted parties;
                </li>
              </ul>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", rowGap: 10 }}
            >
              <Typography className={classes.Textstyle}>
                <strong>Account registration</strong>
              </Typography>
              <Typography className={classes.Textstyle}>
                To use the Service Users must register or create a User account,
                providing all required data or information in a complete and
                truthful manner.
              </Typography>

              <div>
                <Typography className={classes.Textstyle}>
                  Failure to do so will cause unavailability of the Service.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users are responsible for keeping their login credentials
                  confidential and safe. For this reason, Users are also
                  required to choose passwords that meet the highest standards
                  of strength permitted by What’syourN<sup>IQ</sup>.
                </Typography>
                <Typography className={classes.Textstyle}>
                  By registering, Users agree to be fully responsible for all
                  activities that occur under their username and password.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users are required to immediately and unambiguously inform the
                  Owner via the contact details indicated in this document, if
                  they think their personal information, including but not
                  limited to User accounts, access credentials or personal data,
                  have been violated, unduly disclosed or stolen.
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Conditions for account registration</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Registration of User accounts on What’syourN<sup>IQ</sup> is subject to
                  the conditions outlined below. By registering, Users agree to
                  meet such conditions.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      Accounts registered by bots or any other automated methods
                      are not permitted
                    </li>
                    <li>
                      Unless otherwise specified, each User must register only
                      one account
                    </li>
                    <li>
                      Unless explicitly permitted, a User account may not be
                      shared with other persons
                    </li>
                  </ul>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Account termination</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users can terminate their account and stop using the Service
                  at any time by doing the following:
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      By using the tools provided for account termination on
                      What’syourN<sup>IQ</sup>
                    </li>
                    <li>
                      By directly contacting the Owner at the contact details
                      provided in this document
                    </li>
                  </ul>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Account suspension and deletion</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  The Owner reserves the right, at its sole discretion, to
                  suspend or delete at any time and without notice, User
                  accounts which it deems inappropriate, offensive or in
                  violation of these Terms.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The suspension or deletion of User accounts shall not entitle
                  Users to any claims for compensation, damages or
                  reimbursement.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The suspension or deletion of accounts due to causes
                  attributable to the User does not exempt the User from paying
                  any applicable fees or prices.
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Content on What’syourN<sup>IQ</sup></strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Unless where otherwise specified or clearly recognizable, all
                  content available on What’syourN<sup>IQ</sup> is owned or provided by the
                  Owner or its licensors.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The Owner undertakes its utmost effort to ensure that the
                  content provided on What’syourN<sup>IQ</sup> infringes no applicable
                  legal provisions or third-party rights. However, it may not
                  always be possible to achieve such a result.
                </Typography>
                <Typography className={classes.Textstyle}>
                  In such cases, without prejudice to any legal prerogatives of
                  Users to enforce their rights, Users are kindly asked to
                  preferably report related complaints using the contact details
                  provided in this document.
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>
                    Rights regarding content on What’syourN<sup>IQ</sup> - All rights
                    reserved
                  </strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  The Owner holds and reserves all intellectual property rights
                  for any such content.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users may not therefore use such content in any way that is
                  not necessary or implicit in the proper use of the Service.
                </Typography>
                <Typography className={classes.Textstyle}>
                  In particular, but without limitation, Users may not copy,
                  download, share (beyond the limits set forth below), modify,
                  translate, transform, publish, transmit, sell, sublicense,
                  edit, transfer/assign to third parties or create derivative
                  works from the content available on What’syourN<sup>IQ</sup>, nor allow
                  any third party to do so through the User or their device,
                  even without the User's knowledge.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Where explicitly stated on What’syourN<sup>IQ</sup>, the User may
                  download, copy and/or share some content available through
                  What’syourN<sup>IQ</sup> for its sole personal and non-commercial use and
                  provided that the copyright attributions and all the other
                  attributions requested by the Owner are correctly implemented.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Any applicable statutory limitation or exception to copyright
                  shall stay unaffected.
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Content provided by Users</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  The Owner allows Users to upload, share or provide their own
                  content to What’syourN<sup>IQ</sup>
                </Typography>
                <Typography className={classes.Textstyle}>
                  By providing content to What’syourNI<sup>IQ</sup>, Users confirm that they
                  are legally allowed to do so and that they are not infringing
                  any statutory provisions and/or third-party rights.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Further insights regarding acceptable content can be found
                  inside the section of these Terms which detail the acceptable
                  uses.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users acknowledge and accept that by providing their own
                  content to What’syourN<sup>IQ</sup> they grant the Owner a non-exclusive,
                  worldwide, fully paid-up and royalty-free, irrevocable,
                  perpetual (or for the entire protection term), sub-licensable
                  and transferable license to use, access, store, reproduce,
                  modify, distribute, publish, process into derivative works,
                  broadcast, stream, transmit or otherwise exploit such content
                  to provide and promote its Service in any media or manner.
                </Typography>
                <Typography className={classes.Textstyle}>
                  To the extent permitted by applicable law, Users waive any
                  moral rights in connection with content they provide to
                  What’syourN<sup>IQ</sup>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users acknowledge, accept and confirm that all content they
                  provide through What’syourN<sup>IQ</sup> is provided subject to the same
                  general conditions set forth for content on What’syourN<sup>IQ</sup>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Liability for provided content</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users are solely liable for any content they upload, post,
                  share, or provide through What’syourN<sup>IQ</sup>. Users acknowledge and
                  accept that
                  <strong>
                    the Owner does not filter or moderate such content
                  </strong>
                  .
                </Typography>
                <Typography className={classes.Textstyle}>
                  However, the Owner reserves the right to remove, delete, block
                  or rectify such content at its own discretion and to, without
                  prior notice, deny the uploading User access to What’syourN<sup>IQ</sup>:
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>If any complaint based on such content is received;</li>
                    <li>
                      If a notice of infringement of intellectual property
                      rights is received;
                    </li>
                    <li>Upon order of a public authority; or</li>
                    <li>
                      Where the Owner is made aware that the content, while
                      being accessible via What’syourN<sup>IQ</sup>, may represent a risk
                      for Users, third parties and/or the availability of the
                      Service.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  The removal, deletion, blocking or rectification of content
                  shall not entitle Users that have provided such content or
                  that are liable for it, to any claims for compensation,
                  damages or reimbursement.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users agree to hold the Owner harmless from and against any
                  claim asserted and/or damage suffered due to content they
                  provided to or provided through What’syourN<sup>IQ</sup>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>
                    Removal of content from parts of What’syourN<sup>IQ</sup> available
                    through the Play Store/ App Store
                  </strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  If the reported content is deemed objectionable, it will be
                  removed within 24 hours and the User who provided the content
                  will be barred from using the Service.
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Access to external resources</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Through What’syourN<sup>IQ</sup> Users may have access to external
                  resources provided by third parties. Users acknowledge and
                  accept that the Owner has no control over such resources and
                  is therefore not responsible for their content and
                  availability. Conditions applicable to any resources provided
                  by third parties, including those applicable to any possible
                  grant of rights in content, result from each such third
                  parties’ terms and conditions or, in the absence of those,
                  applicable statutory law.
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>
                    How to file a takedown notice (called a “DMCA notice”)
                  </strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  If copyright holders or their agents believe that any content
                  on N<sup>IQ</sup> infringes upon their copyrights, they may submit a
                  notification pursuant to the Digital Millennium Copyright Act
                  ("DMCA") by providing the Owner’s Copyright Agent with the
                  following information in writing (see 17 U.S.C 512(c)(3) for
                  further detail):
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      A physical or electronic signature of a person authorized
                      to act on behalf of the holder of an exclusive right that
                      is allegedly infringed;
                    </li>
                    <li>
                      Identification of the copyrighted work claimed to have
                      been infringed, or, if multiple copyrighted works at a
                      single online site are covered by a single notification, a
                      representative list of such works at that site;
                    </li>
                    <li>
                      Identification of the material that is claimed to be
                      infringing or to be the subject of infringing activity and
                      that is to be removed or access to which is to be disabled
                      and information reasonably sufficient to permit the Owner
                      to locate the material;
                    </li>
                    <li>
                      Information reasonably sufficient to permit the Owner to
                      contact the notifying party, such as an address, telephone
                      number, and, if available, an electronic mail;
                    </li>
                    <li>
                      A statement that the notifying party has a good faith
                      belief that use of the material in the manner complained
                      of is not authorized by the copyright owner, its agent, or
                      the law; and
                    </li>
                    <li>
                      A statement that the information in the notification is
                      accurate, and under penalty of perjury, that the notifying
                      party is authorized to act on behalf of the owner of an
                      exclusive right that is allegedly infringed.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Failure to comply with all of the requirements outlined above
                  may result in invalidity of the DMCA notice. Copyright
                  infringement notifications may be addressed to the Owner’s
                  Copyright Agent at the contact details specified in this
                  document.
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Acceptable use</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  What’syourN<sup>IQ</sup> and the Service may only be used within the
                  scope of what they are provided for, under these Terms and
                  applicable law.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users are solely responsible for making sure that their use of
                  What’syourN<sup>IQ</sup> and/or the Service violates no applicable law,
                  regulations or third-party rights.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Therefore, the Owner reserves the right to take any
                  appropriate measure to protect its legitimate interests
                  including denying Users access to What’syourN<sup>IQ</sup> or the
                  Service, terminating contracts, reporting any misconduct
                  performed through What’syourN<sup>IQ</sup> or the Service to the
                  competent authorities – such as judicial or administrative
                  authorities - whenever Users are suspected to be in violation
                  of any laws, regulations, third-party rights and/or these
                  Terms, including, but not limited to, by engaging in any of
                  the following activities:
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Conduct restrictions</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      Pretending to fulfil any possible condition or
                      requirements for accessing What’syourN<sup>IQ</sup> and/or using the
                      Services, such as for instance being adult according to
                      law or qualifying as a Consumer;
                    </li>
                    <li>
                      Concealing their identity or stealing someone else's
                      identity or pretend to be or represent a third-party, if
                      not allowed to do so by such third-party;
                    </li>
                    <li>
                      Manipulating identifiers to disguise or otherwise conceal
                      the origin of their messages or of the content posted;
                    </li>
                    <li>
                      Defaming, abusing, harassing, using threatening practices,
                      threatening or violating the legal rights of others in any
                      other way;
                    </li>
                    <li>
                      Promoting activity that may endanger the User’s life or
                      the life of any other User or lead to physical harm. This
                      includes but is not limited to suicide threats or
                      instigations, intentional physical trauma, the use of
                      illegal drugs, or excessive drinking. Under no
                      circumstance is any User allowed to post any content
                      promoting and/or encouraging and/or showing any
                      self-destructive or violent behaviour on What’syourN<sup>IQ</sup>;
                    </li>
                    <li>
                      Probing, scanning or testing the vulnerability of
                      What’syourN<sup>IQ</sup>, including the services or any network
                      connected to the website, nor breaching the security or
                      authentication measures on What’syourN<sup>IQ</sup>, including the
                      services or any network connected to What’syourN<sup>IQ</sup>;
                    </li>
                    <li>
                      Installing, embedding, uploading or otherwise
                      incorporating any malware into or via What’syourN<sup>IQ</sup>;
                    </li>
                    <li>
                      Using What’syourN<sup>IQ</sup> or the technical infrastructure in an
                      abusive, excessive or otherwise inappropriate way (for
                      example: for spamming purposes);
                    </li>
                    <li>
                      Attempting to disrupt or tamper with the technical
                      infrastructure in a manner that harms or places an undue
                      burden on What’syourN<sup>IQ</sup> or the Service;
                    </li>
                    <li>
                      Pretending to purchase any Products offered via
                      What’syourN<sup>IQ</sup> without any real intent to do so;
                    </li>
                    <li>Failing to pay for Products purchased;</li>
                  </ul>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Excessive use of the Service</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      Using a resource of What’syourN<sup>IQ</sup> excessively in relation
                      to other Users of What’syourN<sup>IQ</sup> – in such cases, the
                      Owner, at its sole discretion, additionally reserves the
                      right to suspend the User’s account or limit the related
                      activity until the User reduces the excessive consumption;
                    </li>
                  </ul>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Scraping</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      Adopting any automated process to extract, harvest or
                      scrape information, data and/or content from What’syourN<sup>IQ</sup>
                      and all the digital properties thereto related unless
                      where explicitly allowed to do so by the Owner;
                    </li>
                  </ul>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Content restrictions</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      Disseminating or publishing content that is unlawful,
                      obscene, illegitimate, libellous or inappropriate;
                    </li>
                    <li>
                      Publishing any content that promotes, either directly or
                      indirectly, hate, racism, discrimination, pornography,
                      violence;
                    </li>
                    <li>
                      Disseminating or publishing any content that is false or
                      may create unjustified alarm;
                    </li>
                    <li>
                      Using What’syourN<sup>IQ</sup> to publish, disseminate or otherwise
                      provide content protected by intellectual property laws,
                      including but not limited to patent, trademark or
                      copyright law, unlawfully and without the legitimate
                      right-holder’s consent;
                    </li>
                    <li>
                      Using What’syourN<sup>IQ</sup> to publish, disseminate or otherwise
                      make available any other content which infringes on any
                      third-party rights, including but not limited to state,
                      military, trade or professional secrets and personal data;
                    </li>
                    <li>
                      Publishing any content or carrying out any activity that
                      disrupts, interrupts, harms, or otherwise violates the
                      integrity of What’syourN<sup>IQ</sup> or another User's experience or
                      devices. Such activities include: spamming, distributing
                      unauthorized advertisements, phishing, defrauding others,
                      spreading malware or viruses etc.;
                    </li>
                  </ul>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>User protection</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      Misappropriating any account in use by another User;
                    </li>
                    <li>
                      Harvesting or collecting any personally identifying
                      information of other Users including but not limited to
                      their email addresses or contact information, by
                      circumventing the privacy setting of other Users’ accounts
                      on What’syourN<sup>IQ</sup> or by any other means;
                    </li>
                    <li>
                      Using any information relating to other Users, including
                      personal or contact data, for purposes other than those
                      What’syourN<sup>IQ</sup> is intended for;
                    </li>
                  </ul>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Commercial use restrictions</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      Registering or using What’syourN<sup>IQ</sup> in order to promote,
                      sell or advertise products or services of any kind in any
                      way;
                    </li>
                    <li>
                      Indicating or trying to imply in any manner, that a User
                      stands in a qualified relationship with What’syourN<sup>IQ</sup> or
                      that What’syourN<sup>IQ</sup> has endorsed the User, the User’s
                      products or services or any third party's products and
                      services for any purpose;
                    </li>
                  </ul>
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>Software license</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Any intellectual or industrial property rights, and any other
                  exclusive rights on software or technical applications
                  embedded in or related to What’syourN<sup>IQ</sup> are held by the Owner
                  and/or its licensors.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Subject to Users’ compliance with and notwithstanding any
                  divergent provision of these Terms, the Owner merely grants
                  Users a revocable, non-exclusive, non-sublicensable and
                  non-transferable license to use the software and/or any other
                  technical means embedded in the Service within the scope and
                  for the purposes of What’syourN<sup>IQ</sup> and the Service offered.
                  This license does not grant Users any rights to access, usage
                  or disclosure of the original source code. All techniques,
                  algorithms, and procedures contained in the software and any
                  documentation thereto related is the Owner’s or its licensors’
                  sole property.
                </Typography>
                <Typography className={classes.Textstyle}>
                  All rights and license grants to Users shall immediately
                  terminate upon any termination or expiration of the Agreement.
                  Without prejudice to the above, under this license Users may
                  download, install, use and run the software on the permitted
                  number of devices, provided that such devices are common and
                  up-to-date in terms of technology and market standards. The
                  Owner reserves the right to release updates, fixes and further
                  developments of What’syourN<sup>IQ</sup> and/or its related software and
                  to provide them to Users for free. Users may need to download
                  and install such updates to continue using What’syourN<sup>IQ</sup>
                  and/or its related software.
                </Typography>
                <Typography className={classes.Textstyle}>
                  New releases may only be available against payment of a fee.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The User may download, install, use and run the software on 2
                  devices.
                </Typography>
                <Typography className={classes.Textstyle}>
                  However, it may not be permitted to run the software on more
                  than one device at a time.
                </Typography>
              </div>

              <div>
                <Typography className={classes.Textstyle}>
                  <strong>TERMS AND CONDITIONS OF SALE</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Paid Products</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Some of</strong> the Products provided on
                  What’syourN<sup>IQ</sup>, as part of the Service, are provided on the
                  basis of payment. The fees, duration and conditions applicable
                  to the purchase of such Products are described below and in
                  the dedicated sections of N<sup>IQ</sup>.
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>Product description</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Prices, descriptions or availability of Products are outlined
                  in the respective sections of N<sup>IQ</sup> and are subject to change
                  without notice.
                </Typography>
                <Typography className={classes.Textstyle}>
                  While Products on N<sup>IQ</sup> are presented with the greatest accuracy
                  technically possible, representation on N<sup>IQ</sup> through any means
                  (including, as the case may be, graphic material, images,
                  colors, sounds) is for reference only and implies no warranty
                  as to the characteristics of the purchased Product. The
                  characteristics of the chosen Product will be outlined during
                  the purchasing process.
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>Purchasing process</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Any steps taken from choosing a Product to order submission
                  form part of the purchasing process.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The purchasing process includes these steps:
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      Users must choose the desired Product and verify their
                      purchase selection.
                    </li>
                    <li>
                      After having reviewed the information displayed in the
                      purchase selection, Users may place the order by
                      submitting it.
                    </li>
                  </ul>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>Order submission</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  When the User submits an order, the following applies:
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      The submission of an order determines contract conclusion
                      and therefore creates for the User the obligation to pay
                      the price, taxes and possible further fees and expenses,
                      as specified on the order page.
                    </li>
                    <li>
                      In case the purchased Product requires active input from
                      the User, such as the provision of personal information or
                      data, specifications or special wishes, the order
                      submission creates an obligation for the User to cooperate
                      accordingly.
                    </li>
                    <li>
                      Upon submission of the order, Users will receive a receipt
                      confirming that the order has been received.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  All notifications related to the described purchasing process
                  shall be sent to the email address provided by the User for
                  such purposes.
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>Prices</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users are informed during the purchasing process and before
                  order submission, about any fees, taxes and costs (including,
                  if any, delivery costs) that they will be charged.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Prices on N<sup>IQ</sup> are displayed:
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      either exclusive or inclusive of any applicable fees,
                      taxes and costs, depending on the section the User is
                      browsing.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Methods of payment</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Information</strong> related to accepted payment
                  methods are made available during the purchasing process.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Some payment methods may only be available subject to
                  additional conditions or fees. In such cases related
                  information can be found in the dedicated section of N<sup>IQ</sup>.
                </Typography>
                <Typography className={classes.Textstyle}>
                  All payments are independently processed through third-party
                  services. Therefore, N<sup>IQ</sup> does not collect any payment
                  information – such as credit card details – but only receives
                  a notification once the payment has been successfully
                  completed.
                </Typography>
                <Typography className={classes.Textstyle}>
                  If a payment through the available methods fails or is refused
                  by the payment service provider, the Owner shall be under no
                  obligation to fulfil the purchase order. Any possible costs or
                  fees resulting from the failed or refused payment shall be
                  borne by the User.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Purchase via app store</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>
                    N<sup>IQ</sup> or specific Products available for sale on N<sup>IQ</sup> must be
                    purchased via a third-party app store. To access such
                    purchases, Users{" "}
                  </strong>{" "}
                  must follow the instructions provided on the relevant online
                  store (such as "Apple App Store" or "Google Play"), which may
                  vary depending on the particular device in use.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Unless otherwise specified, purchases done via third-party
                  online stores are also subject to such third-parties’ terms
                  and conditions, which, in case of any inconsistency or
                  conflict, shall always prevail upon these Terms.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users purchasing through such third-party online stores must
                  therefore read such terms and conditions of sale carefully and
                  accept them.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Retention of Product ownership</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Until payment</strong> of the total purchase price is
                  received by the Owner, any Products ordered shall not become
                  the User’s property.
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>Retention of usage rights</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Users do not acq</strong>uire any rights to use the
                  purchased Product until the total purchase price is received
                  by the Owner.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Delivery</strong>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>Delivery of digital content</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Unless otherwise stated, digital content purchased on N<sup>IQ</sup> is
                  delivered via download on the device(s) chosen by Users.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users acknowledge and accept that in order to download and/or
                  use the Product, the intended device(s) and its respective
                  software (including operating systems) must be legal, commonly
                  used, up-to-date, and consistent with current
                  market-standards.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users acknowledge and accept that the ability to download the
                  purchased Product may be limited in time and space.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Contract duration</strong>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>Subscriptions</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Subscriptions allow Users to receive a Product continuously or
                  regularly over time. Details regarding the type of
                  subscription and termination are outlined below.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Fixed-term subscriptions</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Paid fixed-term subscriptions start on the day the payment is
                  received by the Owner and last for the subscription period
                  chosen by the User or otherwise specified during the
                  purchasing process.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Once the subscription period expires, the Product shall no
                  longer be accessible.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Subscriptions handled via Apple ID</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users may subscribe to a Product using the Apple ID associated
                  with their Apple App Store account by using the relevant
                  process on N<sup>IQ</sup>. When doing so, Users acknowledge and accept
                  that
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      any payment due shall be charged to their Apple ID
                      account;
                    </li>
                    <li>
                      subscriptions are automatically renewed for the same
                      duration unless the User cancels at least 24 hours before
                      the current period expires;
                    </li>
                    <li>
                      any and all fees or payments due for renewal will be
                      charged within 24-hours before the end of the current
                      period;
                    </li>
                    <li>
                      subscriptions can be managed or cancelled in the Users’
                      Apple App Store account settings.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  The above shall prevail upon any conflicting or diverging
                  provision of these Terms.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Automatic renewal of fixed-term subscriptions
                </Typography>
                <Typography className={classes.Textstyle}>
                  Subscriptions are automatically renewed through the payment
                  method that the User chose during purchase.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The renewed subscription will last for a period equal to the
                  original term.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Termination
                </Typography>
                <Typography className={classes.Textstyle}>
                  Subscriptions may be terminated by sending a clear and
                  unambiguous termination notice to the Owner using the contact
                  details provided in this document, or — if applicable — by
                  using the corresponding controls inside N<sup>IQ</sup>.
                </Typography>
                <Typography className={classes.Textstyle}>
                  If the notice of termination is received by the Owner before
                  the subscription renews, the termination shall take effect as
                  soon as the current period is completed.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Exception for Consumers based in Germany
                </Typography>
                <Typography className={classes.Textstyle}>
                  However, regardless of the above, if the User is based in
                  Germany and qualifies as a Consumer, the following applies:
                </Typography>
                <Typography className={classes.Textstyle}>
                  At the end of the initial term, subscriptions are
                  automatically extended for an unlimited period, unless the
                  User terminates before the end of such term.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The fee due upon extension will be charged on the payment
                  method that the User chose during purchase.
                </Typography>
                <Typography className={classes.Textstyle}>
                  After extension, the subscription will last for an indefinite
                  period and may be terminated monthly.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Termination
                </Typography>
                <Typography className={classes.Textstyle}>
                  Extended subscriptions may be terminated at any time by
                  sending a clear and unambiguous termination notice to the
                  Owner using the contact details provided in this document, or
                  — if applicable — by using the corresponding controls inside
                  N<sup>IQ</sup>.
                </Typography>
                <Typography className={classes.Textstyle}>
                  If the notice of termination is received by the Owner by the
                  end of the current month, the subscription shall expire at the
                  end of such month. User rights
                </Typography>
                <Typography className={classes.Textstyle}>
                  Right of withdrawal
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Unless exceptions apply, the User may be eligible to withdraw
                  from the contract within the period specified below (generally
                  14 days), for any reason and without justification. Users can
                  learn more about the withdrawal conditions within this
                  section.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Who the right of withdrawal applies to
                </Typography>
                <Typography className={classes.Textstyle}>
                  Unless any applicable exception is mentioned below, Users who
                  are European Consumers are granted a statutory cancellation
                  right under EU rules, to withdraw from contracts entered into
                  online (distance contracts) within the specified period
                  applicable to their case, for any reason and without
                  justification.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users that do not fit this qualification, cannot benefit from
                  the rights described in this section.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Exercising the right of withdrawal
                </Typography>
                <Typography className={classes.Textstyle}>
                  To exercise their right of withdrawal, Users must send to the
                  Owner an unequivocal statement of their intention to withdraw
                  from the contract.
                </Typography>
                <Typography className={classes.Textstyle}>
                  To this end, Users may use the model withdrawal form available
                  from within the “definitions” section of this document. Users
                  are, however, free to express their intention to withdraw from
                  the contract by making an unequivocal statement in any other
                  suitable way. In order to meet the deadline within which they
                  can exercise such right, Users must send the withdrawal notice
                  before the withdrawal period expires.
                </Typography>
                <Typography className={classes.Textstyle}>
                  When does the withdrawal period expire?
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      In case of purchase of a digital content not supplied in a
                      tangible medium, the withdrawal period expires 14 days
                      after the day that the contract is entered into, unless
                      the User has waived the withdrawal right.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Effects of withdrawal
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users who correctly withdraw from a contract will be
                  reimbursed by the Owner for all payments made to the Owner,
                  including, if any, those covering the costs of delivery.
                </Typography>
                <Typography className={classes.Textstyle}>
                  However, any additional costs resulting from the choice of a
                  particular delivery method other than the least expensive type
                  of standard delivery offered by the Owner, will not be
                  reimbursed.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Such reimbursement shall be made without undue delay and, in
                  any event, no later than 14 days from the day on which the
                  Owner is informed of the User’s decision to withdraw from the
                  contract. Unless otherwise agreed with the User,
                  reimbursements will be made using the same means of payment as
                  used to process the initial transaction. In any event, the
                  User shall not incur any costs or fees as a result of such
                  reimbursement.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Liability and indemnification
                </Typography>
                <Typography className={classes.Textstyle}>EU Users</Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Indemnification
                </Typography>
                <Typography className={classes.Textstyle}>
                  The User agrees to indemnify and hold the Owner and its
                  subsidiaries, affiliates, officers, directors, agents,
                  co-branders, partners and employees harmless from and against
                  any claim or demand ⁠— including but not limited to lawyer's
                  fees and costs ⁠— made by any third party due to or in
                  relation with any culpable use of or connection to the
                  Service, violation of these Terms, infringement of any
                  third-party rights or statutory provision by the User or its
                  affiliates, officers, directors, agents, co-branders, partners
                  and employees to the extent allowed by applicable law.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Limitation of liability
                </Typography>
                <Typography className={classes.Textstyle}>
                  Unless otherwise explicitly stated and without prejudice to
                  applicable statutory product liability provisions, Users shall
                  have no right to claim damages against the Owner (or any
                  natural or legal person acting on its behalf).
                </Typography>
                <Typography className={classes.Textstyle}>
                  This does not apply to damages to life, health or physical
                  integrity, damages resulting from the breach of an essential
                  contractual obligation such as any obligation strictly
                  necessary to achieve the purpose of the contract, and/or
                  damages resulting from intent or gross negligence, as long as
                  N<sup>IQ</sup> has been appropriately and correctly used by the User.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Unless damages have been caused by way of intent or gross
                  negligence, or they affect life, health or physical integrity,
                  the Owner shall only be liable to the extent of typical and
                  foreseeable damages at the moment the contract was entered
                  into.
                </Typography>
                <Typography className={classes.Textstyle}>
                  In particular, within the limits stated above, the Owner shall
                  not be liable for:
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      any loss of business opportunities and any other loss,
                      even indirect, that may be incurred by the User (such as,
                      but not limited to, trading losses, loss of revenue,
                      income, profits or anticipated savings, loss of contracts
                      or business relationships, loss of reputation or goodwill,
                      etc.);
                    </li>
                    <li>
                      damages or losses resulting from interruptions or
                      malfunctions of N<sup>IQ</sup> due to acts of force majeure, or
                      unforeseen and unforeseeable events and, in any case,
                      independent of the will and beyond the control of the
                      Owner, such as, but not limited to, failures or
                      disruptions of telephone or electrical lines, the Internet
                      and / or other means of transmission, unavailability of
                      websites, strikes, natural disasters, viruses and cyber
                      attacks, interruptions in the delivery of products,
                      third-party services or applications;
                    </li>
                    <li>
                      any losses that are not the direct consequence of a breach
                      of the Terms by the Owner;
                    </li>
                    <li>
                      any damage, prejudice or loss occurring due to viruses or
                      other malware contained in or connected to files available
                      for download from the internet or via N<sup>IQ</sup>. Users are
                      responsible for implementing sufficient security measures
                      – such as anti-viruses and firewalls to prevent any such
                      infection or attack and for securing backup copies of all
                      data or information exchanged via or uploaded to N<sup>IQ</sup>.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Notwithstanding the above, the following limitation applies to
                  all Users not qualifying as Consumers:
                </Typography>
                <Typography className={classes.Textstyle}>
                  In any event of liability, the compensation may not exceed the
                  total payments that have been, will be or would be received by
                  the Owner from the User based on the contract over a period of
                  12 months, or the period of the duration of the Agreement, if
                  shorter.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Australian Users
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Limitation of liability
                </Typography>
                <Typography className={classes.Textstyle}>
                  Nothing in these Terms excludes, restricts or modifies any
                  guarantee, condition, warranty, right or remedy which the User
                  may have under the Competition and Consumer Act 2010 (Cth) or
                  any similar State and Territory legislation and which cannot
                  be excluded, restricted or modified (non-excludable right). To
                  the fullest extent permitted by law, our liability to the
                  User, including liability for a breach of a non-excludable
                  right and liability which is not otherwise excluded under
                  these Terms of Use, is limited, at the Owner’s sole
                  discretion, to the re-performance of the services or the
                  payment of the cost of having the services supplied again.
                </Typography>
                <Typography className={classes.Textstyle}>US Users</Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Disclaimer of Warranties
                </Typography>
                <Typography className={classes.Textstyle}>
                  N<sup>IQ</sup> is provided strictly on an “as is” and “as available”
                  basis. Use of the Service is at Users’ own risk. To the
                  maximum extent permitted by applicable law, the Owner
                  expressly disclaims all conditions, representations, and
                  warranties — whether express, implied, statutory or otherwise,
                  including, but not limited to, any implied warranty of
                  merchantability, fitness for a particular purpose, or
                  non-infringement of third-party rights. No advice or
                  information, whether oral or written, obtained by user from
                  owner or through the Service will create any warranty not
                  expressly stated herein.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Without limiting the foregoing, the Owner, its subsidiaries,
                  affiliates, licensors, officers, directors, agents,
                  co-branders, partners, suppliers and employees do not warrant
                  that the content is accurate, reliable or correct; that the
                  Service will meet Users’ requirements; that the Service will
                  be available at any particular time or location, uninterrupted
                  or secure; that any defects or errors will be corrected; or
                  that the Service is free of viruses or other harmful
                  components. Any content downloaded or otherwise obtained
                  through the use of the Service is downloaded at users own risk
                  and users shall be solely responsible for any damage to Users’
                  computer system or mobile device or loss of data that results
                  from such download or Users’ use of the Service.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The Owner does not warrant, endorse, guarantee, or assume
                  responsibility for any product or service advertised or
                  offered by a third party through the Service or any
                  hyperlinked website or service, and the Owner shall not be a
                  party to or in any way monitor any transaction between Users
                  and third-party providers of products or services.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The Service may become inaccessible or it may not function
                  properly with Users’ web browser, mobile device, and/or
                  operating system. The owner cannot be held liable for any
                  perceived or actual damages arising from Service content,
                  operation, or use of this Service.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Federal law, some states, and other jurisdictions, do not
                  allow the exclusion and limitations of certain implied
                  warranties. The above exclusions may not apply to Users. This
                  Agreement gives Users specific legal rights, and Users may
                  also have other rights which vary from state to state. The
                  disclaimers and exclusions under this agreement shall not
                  apply to the extent prohibited by applicable law.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Limitations of liability
                </Typography>
                <Typography className={classes.Textstyle}>
                  To the maximum extent permitted by applicable law, in no event
                  shall the Owner, and its subsidiaries, affiliates, officers,
                  directors, agents, co-branders, partners, suppliers and
                  employees be liable for
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      any indirect, punitive, incidental, special, consequential
                      or exemplary damages, including without limitation damages
                      for loss of profits, goodwill, use, data or other
                      intangible losses, arising out of or relating to the use
                      of, or inability to use, the Service; and
                    </li>
                    <li>
                      any damage, loss or injury resulting from hacking,
                      tampering or other unauthorized access or use of the
                      Service or User account or the information contained
                      therein;
                    </li>
                    <li>any errors, mistakes, or inaccuracies of content;</li>
                    <li>
                      personal injury or property damage, of any nature
                      whatsoever, resulting from User access to or use of the
                      Service;
                    </li>
                    <li>
                      any unauthorized access to or use of the Owner’s secure
                      servers and/or any and all personal information stored
                      therein;
                    </li>
                    <li>
                      any interruption or cessation of transmission to or from
                      the Service;
                    </li>
                    <li>
                      any bugs, viruses, trojan horses, or the like that may be
                      transmitted to or through the Service;
                    </li>
                    <li>
                      any errors or omissions in any content or for any loss or
                      damage incurred as a result of the use of any content
                      posted, emailed, transmitted, or otherwise made available
                      through the Service; and/or
                    </li>
                    <li>
                      the defamatory, offensive, or illegal conduct of any User
                      or third party. In no event shall the Owner, and its
                      subsidiaries, affiliates, officers, directors, agents,
                      co-branders, partners, suppliers and employees be liable
                      for any claims, proceedings, liabilities, obligations,
                      damages, losses or costs in an amount exceeding the amount
                      paid by User to the Owner hereunder in the preceding 12
                      months, or the period of duration of this agreement
                      between the Owner and User, whichever is shorter.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  This limitation of liability section shall apply to the
                  fullest extent permitted by law in the applicable jurisdiction
                  whether the alleged liability is based on contract, tort,
                  negligence, strict liability, or any other basis, even if
                  company has been advised of the possibility of such damage.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Some jurisdictions do not allow the exclusion or limitation of
                  incidental or consequential damages, therefore the above
                  limitations or exclusions may not apply to User. The terms
                  give User specific legal rights, and User may also have other
                  rights which vary from jurisdiction to jurisdiction. The
                  disclaimers, exclusions, and limitations of liability under
                  the terms shall not apply to the extent prohibited by
                  applicable law.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Indemnification
                </Typography>
                <Typography className={classes.Textstyle}>
                  The User agrees to defend, indemnify and hold the Owner and
                  its subsidiaries, affiliates, officers, directors, agents,
                  co-branders, partners, suppliers and employees harmless from
                  and against any and all claims or demands, damages,
                  obligations, losses, liabilities, costs or debt, and expenses,
                  including, but not limited to, legal fees and expenses,
                  arising from
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      User’s use of and access to the Service, including any
                      data or content transmitted or received by User;
                    </li>
                    <li>
                      User’s violation of these terms, including, but not
                      limited to, User’s breach of any of the representations
                      and warranties set forth in these terms;
                    </li>
                    <li>
                      User’s violation of any third-party rights, including, but
                      not limited to, any right of privacy or intellectual
                      property rights;
                    </li>
                    <li>
                      User’s violation of any statutory law, rule, or
                      regulation;
                    </li>
                    <li>
                      any content that is submitted from User’s account,
                      including third party access with User’s unique username,
                      password or other security measure, if applicable,
                      including, but not limited to, misleading, false, or
                      inaccurate information;
                    </li>
                    <li>User’s wilful misconduct; or</li>
                    <li>
                      statutory provision by User or its affiliates, officers,
                      directors, agents, co-branders, partners, suppliers and
                      employees to the extent allowed by applicable law.
                    </li>
                  </ul>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Common provisions
                </Typography>
                <Typography className={classes.Textstyle}>No Waiver</Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  The Owner’s failure to assert any right or provision under
                  these Terms shall not constitute a waiver of any such right or
                  provision. No waiver shall be considered a further or
                  continuing waiver of such term or any other term.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Service interruption
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  To ensure the best possible service level, the Owner reserves
                  the right to interrupt the Service for maintenance, system
                  updates or any other changes, informing the Users
                  appropriately.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Within the limits of law, the Owner may also decide to suspend
                  or terminate the Service altogether. If the Service is
                  terminated, the Owner will cooperate with Users to enable them
                  to withdraw Personal Data or information in accordance with
                  applicable law.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Additionally, the Service might not be available due to
                  reasons outside the Owner’s reasonable control, such as “force
                  majeure” (eg. labor actions, infrastructural breakdowns or
                  blackouts etc).
                </Typography>
                <Typography className={classes.Textstyle}>
                  Service reselling
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Users may not reproduce, duplicate, copy, sell, resell or
                  exploit any portion of N<sup>IQ</sup> and of its Service without the
                  Owner’s express prior written permission, granted either
                  directly or through a legitimate reselling programme.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Privacy policy
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  To learn more about the use of their Personal Data, Users may
                  refer to the privacy policy of N<sup>IQ</sup>.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Intellectual property rights
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Without prejudice to any more specific provision of these
                  Terms, any intellectual property rights, such as copyrights,
                  trademark rights, patent rights and design rights related to
                  N<sup>IQ</sup> are the exclusive property of the Owner or its licensors
                  and are subject to the protection granted by applicable laws
                  or international treaties relating to intellectual property.
                </Typography>
                <Typography className={classes.Textstyle}>
                  All trademarks — nominal or figurative — and all other marks,
                  trade names, service marks, word marks, illustrations, images,
                  or logos appearing in connection with N<sup>IQ</sup> are, and remain, the
                  exclusive property of the Owner or its licensors and are
                  subject to the protection granted by applicable laws or
                  international treaties related to intellectual property.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Changes to these Terms
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  The Owner reserves the right to amend or otherwise modify
                  these Terms at any time. In such cases, the Owner will
                  appropriately inform the User of these changes.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Such changes will only affect the relationship with the User
                  for the future.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The continued use of the Service will signify the User’s
                  acceptance of the revised Terms. If Users do not wish to be
                  bound by the changes, they must stop using the Service.
                  Failure to accept the revised Terms, may entitle either party
                  to terminate the Agreement.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The applicable previous version will govern the relationship
                  prior to the User's acceptance. The User can obtain any
                  previous version from the Owner.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Assignment of contract
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  The Owner reserves the right to transfer, assign, dispose of
                  by novation, or subcontract any or all rights or obligations
                  under these Terms, taking the User’s legitimate interests into
                  account. Provisions regarding changes of these Terms will
                  apply accordingly.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Users may not assign or transfer their rights or obligations
                  under these Terms in any way, without the written permission
                  of the Owner.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Contacts</strong>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  All communications relating to the use of N<sup>IQ</sup> must be sent
                  using the contact information stated in this document.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Severability</strong>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Should any provision of these Terms be deemed or become
                  invalid or unenforceable under applicable law, the invalidity
                  or unenforceability of such provision shall not affect the
                  validity of the remaining provisions, which shall remain in
                  full force and effect.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>US Users</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Any such invalid or unenforceable provision will be
                  interpreted, construed and reformed to the extent reasonably
                  required to render it valid, enforceable and consistent with
                  its original intent. These Terms constitute the entire
                  Agreement between Users and the Owner with respect to the
                  subject matter hereof, and supersede all other communications,
                  including but not limited to all prior agreements, between the
                  parties with respect to such subject matter. These Terms will
                  be enforced to the fullest extent permitted by law.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>EU Users</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Should any provision of these Terms be or be deemed void,
                  invalid or unenforceable, the parties shall do their best to
                  find, in an amicable way, an agreement on valid and
                  enforceable provisions thereby substituting the void, invalid
                  or unenforceable parts.
                </Typography>
                <Typography className={classes.Textstyle}>
                  In case of failure to do so, the void, invalid or
                  unenforceable provisions shall be replaced by the applicable
                  statutory provisions, if so permitted or stated under the
                  applicable law.
                </Typography>
                <Typography className={classes.Textstyle}>
                  Without prejudice to the above, the nullity, invalidity or the
                  impossibility to enforce a particular provision of these Terms
                  shall not nullify the entire Agreement, unless the severed
                  provisions are essential to the Agreement, or of such
                  importance that the parties would not have entered into the
                  contract if they had known that the provision would not be
                  valid, or in cases where the remaining provisions would
                  translate into an unacceptable hardship on any of the parties.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Governing law</strong>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  These Terms are governed by the law of the place where the
                  Owner is based, as disclosed in the relevant section of this
                  document, without regard to conflict of laws principles.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Exception for European Consumers</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  However, regardless of the above, if the User qualifies as a
                  European Consumer and has their habitual residence in a
                  country where the law provides for a higher consumer
                  protection standard, such higher standards shall prevail.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>US Users</strong>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  <strong>Surviving provisions</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  This Agreement shall continue in effect until it is terminated
                  by either N<sup>IQ</sup> or the User. Upon termination, the provisions
                  contained in these Terms that by their context are intended to
                  survive termination or expiration will survive, including but
                  not limited to the following:
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul>
                    <li>
                      the User’s grant of licenses under these Terms shall
                      survive indefinitely;
                    </li>
                    <li>
                      the User’s indemnification obligations shall survive for a
                      period of five years from the date of termination;
                    </li>
                    <li>
                      the disclaimer of warranties and representations, and the
                      stipulations under the section containing indemnity and
                      limitation of liability provisions, shall survive
                      indefinitely.
                    </li>
                  </ul>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Dispute resolution</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Amicable dispute resolution</strong>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  Users may bring any disputes to the Owner who will try to
                  resolve them amicably.
                </Typography>
                <Typography className={classes.Textstyle}>
                  While Users' right to take legal action shall always remain
                  unaffected, in the event of any controversy regarding the use
                  of N<sup>IQ</sup> or the Service, Users are kindly asked to contact the
                  Owner at the contact details provided in this document.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The User may submit the complaint including a brief
                  description and if applicable, the details of the related
                  order, purchase, or account, to the Owner’s email address
                  specified in this document.
                </Typography>
                <Typography className={classes.Textstyle}>
                  The Owner will process the complaint without undue delay and
                  within 5 days of receiving it.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>Online dispute resolution for Consumers</strong>
                </Typography>
              </div>

              <div className={classes.TextColor}>
                <Typography className={classes.Textstyle}>
                  The European Commission has established an online platform for
                  alternative dispute resolutions that facilitates an
                  out-of-court method for solving any dispute related to and
                  stemming from online sale and service contracts.
                </Typography>
                <Typography className={classes.Textstyle}>
                  As a result, any European Consumer can use such platform for
                  resolving any dispute stemming from contracts which have been
                  entered into online. The platform is{" "}
                  <u>available at the following link</u>.
                </Typography>
                <Typography className={classes.Textstyle}>
                  <strong>France: Mediation</strong>
                </Typography>
                <Typography className={classes.Textstyle}>
                  Within one year of submitting a written complaint to the Owner
                  regarding any dispute stemming from these Terms, Consumers
                  have the right to initiate a mediation procedure before
                </Typography>
                <Typography className={classes.Textstyle}>
                  <ul className={classes.linkResStyle}>
                    <li>the following mediation body (or bodies): FEVAD,</li>
                    <li style={{listStyleType: "none"}}>
                      <a href="mailto:mediateurduecommerce@fevad.com" style={{color:"#f00",textDecoration:"none"}}>
                      mediateurduecommerce@fevad.com,
                      </a>
                      </li>
                    <li style={{listStyleType: "none"}}>
                      <a href="http://www.mediateurfevad.fr" target={"_blank"} style={{color:"#f00",textDecoration:"none"}}>
                      http://www.mediateurfevad.fr.
                      </a>
                    </li>  
                  </ul>
          
                </Typography>
                      
              </div>
            </div>
            
          </div>
        </div>
      </Grid>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withTranslation('footerLng')(PrivacyPolicy));
