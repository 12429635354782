import React, { useEffect, useState } from 'react'
import { FormControl, Select, MenuItem, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next'
import Cookies from 'js-cookie';

const baseURL = require("../../framework/src/config");

const useStyles = makeStyles((theme) => ({
    icon: {
        fill: '#fff',
    },
}));

const LanguageDropDrown = () => {
    const classes = useStyles();
    const [selectedLanguage, setSelectedLanguage] = useState<any>("English");
    
    const languageListApi = async () => {
        try {
            let response = await fetch(`${baseURL.baseURL}/{{dev}}/bx_block_categories/categories`);
            const data = await response?.json();
        }
        catch(error){console.error(error, 'error while calling api')}

    }

    const languages = [
        {
            id: 1,
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            id: 2,
            code: 'hi',
            name: 'हिंदी',
            country_code: 'in',
        },
        {
            id: 3,
            code: 'kn',
            name: 'ಕನ್ನಡ',
            country_code: 'in',
        },
    ]
    const handleLanguageChange = (e: any) => {
        setSelectedLanguage(e.target.value)     
        // window.location.reload()
    };

    useEffect(() => {
        let languageStore;
        let langFromCookie: any = Cookies.get('i18next')
        let language: any = "en";
        if (langFromCookie.length > 0) {
            language = langFromCookie
        }
        else {
            language = document.documentElement.lang
        }

        languages.forEach(lang_name => {
            if (lang_name.code === language) {
                languageStore = lang_name.name;
                localStorage.setItem("languageSelect", JSON.stringify(lang_name.id))
            }
        });
        setSelectedLanguage(languageStore)
    }, [])
    return (
        <FormControl>
            <Select
                disableUnderline
                value={selectedLanguage}
                defaultValue={selectedLanguage}
                onChange={handleLanguageChange}
                inputProps={{
                    name: 'agent',
                    id: 'age-simple',
                    classes: {
                        icon: classes.icon,
                    },
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
                style={{
                    color: '#ffff',
                    fontFamily: `'Montserrat', sans-serif`,
                    fontStyle: "normal",
                    fontWeight: 500,
                }} >
                {languages.map((value, index) => {
                    return (
                        <MenuItem value={value.name} key={value.country_code}
                            onClick={

                                () => i18next.changeLanguage(value.code)}
                            disabled={value.name === selectedLanguage ? true : false}
                            style={{
                                fontFamily: `'Montserrat', sans-serif`,
                                fontStyle: 'normal',
                                fontWeight: 600,
                                color: '#38006D'
                            }}>
                            {value.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    )
}

export default LanguageDropDrown