import React, { useState } from 'react'
import { Typography, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomDeleteAlartDailogBox from '../CustomDeleteAlartDailogBox';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    basketProduct: {
        border: "double 1px transparent",
        borderRadius: '5px',
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
    },
    foodProductInfoContainer: {
        paddingLeft: '2rem',
        [theme.breakpoints.down("sm")]: {
            paddingLeft: '0'
        },
    },
    foodProductName: {
        color: '#290743',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '27px',
        textTransform: 'capitalize',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        },
    },
    totalFoodProduct: {
        color: '#263238',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '20px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
        },
    },
    PtFoodProduct: {
        color: '#38006F',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 600,
        fontSize: '27px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '15px',
        },
    },
    deleteButton: {
        width: '50px',
        height: '50px',
        border: "double 1px transparent",
        borderRadius: '50%',
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '40px',
            height: '40px',
        },
    },

}));

interface Props {
    item: any;
    redirectPath: string;
    deleteFunction: (productId: any) => void;
}

const HealthBasketList = ({
    item, redirectPath, deleteFunction
}: Props) => {
    const classes = useStyles();
    const [t] = useTranslation(["HealthyFoodBasketWebLang"]);
    const [modalOpen, setModalOpen] = useState(false)
    const [itemId, setItemId] = useState('')

    const handelModelOpen = (id: any) => {
        setModalOpen(true);
        setItemId(id);
    }
    const handelModelClose = () => {
        setModalOpen(false);
    }

    return (
        <>
            <Grid container wrap="nowrap" spacing={2} style={{ padding: '1rem 0' }} data-testid="test">
                <Grid item xs>

                    <Link to={`/${redirectPath}?id=${item?.attributes?.id}`} style={{ textDecoration: 'none' }}>
                        <div className={classes.basketProduct}>
                            <Grid container spacing={3} style={{ padding: '1rem' }}>
                                <Grid item xs={12} sm={8} md={9}>
                                    <div className={classes.foodProductInfoContainer}>
                                        <Typography className={classes.foodProductName}>{item?.attributes?.order_name}</Typography>
                                        <Typography className={classes.totalFoodProduct}>
                                            {t("Total_Products")}: <span style={{ color: 'red' }}>{item?.attributes?.product_count}</span>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography className={classes.PtFoodProduct}>-- pt</Typography>
                                        <ArrowRightIcon className={classes.PtFoodProduct} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Link>

                </Grid>
                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className={classes.deleteButton}>
                        <IconButton aria-label="delete" data-testid="handelModelOpen" onClick={() => handelModelOpen(item?.attributes?.id)}>
                            <DeleteIcon style={{ fontSize: '1.4rem' }} />
                        </IconButton>
                    </div>

                </Grid>
            </Grid>
            <CustomDeleteAlartDailogBox isOpen={modalOpen} cancleFunction={handelModelClose} deleteFunction={deleteFunction} deleteId={itemId} />
        </>
    )
}

export default HealthBasketList