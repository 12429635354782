import React from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    userNameText: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontStyle: "normal",
        color: "#FED600",
        fontSize: "35px",
        [theme.breakpoints.down("sm")]: {
            fontSize: '25px',
        },
    },
    userGenderText: {
        fontFamily: "Poppins",
        textTransform: "capitalize",
        fontWeight: 500,
        fontStyle: "normal",
        color: "#FFFFFF",
        fontSize: "20px",
        [theme.breakpoints.down("sm")]: {
            fontSize: '15px',
        },
    },
}));

export const BmiUserInfo = ({ userName, userGender, userAge }: any) => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.userNameText}>{userName}</Typography>
            <Typography className={classes.userGenderText}>({userGender},{userAge})</Typography>
        </>
    )
}