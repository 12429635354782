import React from "react";
// Customizable Area Start
import { Button } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { heroBgImage, heroTopImage } from "./assets";
import LandingPageWeb from "./LandingPage.web";
import HomePageController from "./HomepageController";
import HomePageDetailsWeb from "./HomePageDetails.web";
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0
    },
    hero__section: {
      height: "100vh",
      background: `linear-gradient(109.52deg, rgba(56, 0, 111, 0.82) 55.17%, rgba(255, 57, 57, 0.82) 111.06%), url(${heroBgImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover"
    },
    hero__image: {
      [theme.breakpoints.only("md")]: {
        width: "30%"
      },
      [theme.breakpoints.down("sm")]: {
        width: "40%"
      }
    },
    hero__content: {
      width: "100%",
      position: "absolute",
      justifyContent: "center",
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%)`,
      textAlign: "center"
    },
    hero_text: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "32px",
      lineHeight: "43px",
      color: "#FFFFFF",
      [theme.breakpoints.down("md")]: {
        fontSize: "22px",
        lineHeight: "30px"
      }
    },
    supText: {
      fontSize: "16px"
    },
    hero_button_text: {
      backgroundColor: "white",
      color: "rgba(56, 0, 109, 1)",
      fontWeight: 600,
      width: "250px",
      fontSize: "27px",
      lineHeight: "40px",
      textTransform: "capitalize",
      borderRadius: "8px",
      [theme.breakpoints.down("md")]: {
        fontSize: "21px",
        lineHeight: "30px",
        width: "200px"
      }
    }
  });
// Customizable Area End

class HomePage extends HomePageController {
  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        {this.state.isSearchBar ? (
          <>
            {/* @ts-ignore */}
            <LandingPageWeb />
          </>
        ) : (
          <div className={classes.hero__section}>
            <div className={classes.hero__content}>
              <img src={heroTopImage} alt="" className={classes.hero__image} />
              <h3 className={classes.hero_text}>
                {t("homePageText1")}
                <sup className={classes.supText}>{t("IQ")}</sup>{" "}
                {t("homePageText2")}
              </h3>
              <Button
                variant="contained"
                size="large"
                className={classes.hero_button_text}
                data-testid="SearchNowBtn"
                onClick={() => this.searchNow("LandingPage")}
              >
                {t("searchNowBtn")}
              </Button>
            </div>
          </div>
        )}
        {/* @ts-ignore */}
        <HomePageDetailsWeb />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(
  withTranslation("homePageLng")(HomePage)
);
