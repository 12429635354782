// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ContentManagementController, { Props } from "./ContentManagementController";
import { withTranslation } from 'react-i18next';
const styles = (theme: Theme) => createStyles({

  container: {
    margin: 50, marginTop: "100px",
    [theme.breakpoints.down("md")]: {
      margin: 40, marginTop: "80px",

    },
    [theme.breakpoints.down("sm")]: {
      margin: 10, marginTop: "60px",

    },
  },
  Text: {
    fontFamily: `'Poppins', sans-serif`,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    color: "#000",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      fontSize: "14px",
    },
  },

  Heading: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "39px",
    lineHeight: "48px",
    color: "#455A64",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "30px",
    },
  },
  subHeading: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  }



});

class TermsAndConditionsWeb extends ContentManagementController {
  constructor(props: Props) {
    super(props)
  }
  render() {

    const { classes, t } = this.props;
    return (
      <div className={classes.container}>
        <>
          <Typography className={classes.Heading}>{t("Terms_&_Conditions")}</Typography>
          <Typography className={classes.Text}>We value the trust you place in us and recognize the importance of secure transactions and information privacy.</Typography>
          <Typography className={classes.Text}>This document is an electronic record in terms of the Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</Typography>
          <Typography className={classes.Text}>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name <a href="https://www.superfoodsvalley.com" target={"_blank"}>www.superfoodsvalley.com</a> (“Website”), including the related mobile site and mobile application (hereinafter referred to as “Platform”)</Typography>
          <Typography className={classes.Text}>This Terms of Use describes what the terms and conditions are that you are agreeing to when you transact with <span style={{ color: "#0000EE" }}>Superfoods Valley Private Limited (“SFV”)</span> through its websites <a href="https://www.superfoodsvalley.com" target={"_blank"}>www.superfoodsvalley.com</a>, goodmonk.superfoodsvalley.com, superfoodsvalley.storehippo.com, goodmonk.in, its mobile application, and m-site (hereinafter referred to as the “<span style={{ color: "#0000EE" }}>Platform</span>”).</Typography>
          <Typography className={classes.Text}>For the purpose of these Terms of Use (“Terms”), wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the Platform and/or the Company, as the context so requires. “You”, “Your”, “Yourself”, “User” shall mean and refer to natural and legal individuals who shall be Users of this Platform either availing the services of the platform and listed on the platform and the same shall be as the context so requires and who is competent to enter into binding contracts, as per Indian laws. “Third Parties” refer to any Application, Company or individual apart from the Users and the creator of this platform. “Platform” refers to the Website created by the Company which provides the User to avail services of or from the Company through the Website.</Typography>
          <Typography className={classes.Text}>When you use any of the services provided by Us through the Platform, including but not limited to, (e.g., Product Reviews, Seller Reviews), You will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates / changes. Your continued use of the Platform following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these Terms of Use, we grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform.</Typography>
          <Typography className={classes.Text}>Accessing, browsing or otherwise using the site indicates your agreement to all the terms and conditions under these terms of use, so please read the terms of use carefully before proceeding. By impliedly or expressly accepting these Terms of Use, you also accept and agree to be bound by SFV Policies (including but not limited to Privacy Policy available at Privacy) as amended from time to time.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>Eligibility to Transact</Typography>
          <Typography className={classes.Text}>Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If you are a minor i.e., under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian.</Typography>
          <Typography className={classes.Text}>As a minor if you wish to transact on the Platform, such transaction on the Platform may be made by your legal guardian or parents. SFV reserves the right to terminate your transaction and / or refuse to provide you with access to the Platform if it is brought to SFV’s notice or if it is discovered that You are under the age of 18 years and transacting on the Platform.</Typography>
          <Typography className={classes.Text}>The Users represent that they will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>Your Account and Registration Obligations</Typography>
          <Typography className={classes.Text}>If You use the Platform, you shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform and refuse to provide You with access to the Platform.</Typography>
          <Typography className={classes.Text}>Your mobile phone number and/or e-mail address is treated as Your primary identifier on the Platform. It is your responsibility to ensure that your mobile phone number and your email address is up to date on the Platform at all times. You agree to notify Us promptly if your mobile phone number or e-mail address changes by updating the same on the Platform through a onetime password verification.</Typography>
          <Typography className={classes.Text}>You agree that SFV shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under your Account in cases, including, where You have failed to update Your revised mobile phone number and/or e-mail address on the Website Platform.</Typography>
          <Typography className={classes.Text}>If You share or allow others to have access to Your account on the Platform (“Account”), by creating separate profiles under Your Account, or otherwise, they will be able to view and access Your Account information. You shall be solely liable and responsible for all the activities undertaken under Your Account, and any consequences therefrom.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>Communications</Typography>
          <Typography className={classes.Text}>When You use the Platform or send emails or other data, information or communication to us, you agree and understand that You are communicating with Us through electronic records, and You consent to receive communications via electronic records from Us periodically and as and when required. We may communicate with you by email or by other mode of communication, electronic or otherwise.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>Platform for Transaction and Communication</Typography>
          <Typography className={classes.Text}>The Platform is a platform that Users utilize to meet and interact with one another for their transactions. SFV is not and cannot be a party to or control in any manner any transaction between the Platform’s Users.</Typography>
          <Typography className={classes.Text}>Henceforward, all commercial/contractual terms are offered by and agreed to between Buyers and Sellers alone. The commercial/contractual terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. SFV does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and Sellers. All discounts, offers (including exchange offers) are by the Seller/Brand and not by SFV.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>GENERAL TERMS</Typography>
          <Typography className={classes.Text}>The use of this Platform by the Users is solely governed by these Terms, Privacy Policy and as well as other policies that may be uploaded on the platform the purpose of the Users effective applicability, and any modifications or amendments made thereto by the Company, from time to time, at its sole discretion. If you as a User continue to access and use this Platform, you agree to comply with and be bound by the following Terms and Conditions, Privacy Policy and such other policy as that may be available and applicable to the Users of the Platform.</Typography>
          <Typography className={classes.Text}>The User expressly agrees and acknowledges that these Terms and Policy are co-terminus in nature and that expiry/termination of either one will lead to the termination of the other.</Typography>
          <Typography className={classes.Text}>The User unequivocally agrees that these Terms and the aforementioned Policies constitute a legally binding agreement between the User and the Company, and that the User shall be subject to the rules, guidelines, policies, terms, and conditions applicable to any service that is provided by the Platform, and that the same shall be deemed to be incorporated into these Terms, and shall be treated as part and parcel of the same. The User acknowledges and agrees that no signature or express act is required to make these Terms and the Policy binding on the User and that the User’s act of visiting any part of the Platform constitutes the User’s full and final acceptance of these Terms and the aforementioned Policy.</Typography>
          <Typography className={classes.Text}>The Company reserves the sole and exclusive right to amend or modify these Terms without any prior permission or intimation to the User, and the User expressly agrees that any such amendments or modifications shall come into effect immediately. If the User does not adhere to the changes, they must stop using the Services at once. Their continuous use of the Services will signify your acceptance of the changed terms.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>CONTENT</Typography>
          <Typography className={classes.Text}>All text, graphics, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music and artwork (collectively, ‘Content’), is generated/provided or based on information provided by the interviewer or third parties and the Platform has no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the Platform.</Typography>
          <Typography className={classes.Text}>All the Content displayed on the Platform is subject to copyright and shall not be reused by any party (or a third party) without the prior written consent of the Company and the copyright owner.</Typography>
          <Typography className={classes.Text}>The Users are solely responsible for the integrity, authenticity, quality and genuineness of the content provided on the Platforms and whilst feedback and comments by Users can be made via the Platform, the Platform bears no liability whatsoever for any feedback or comments made by the Users or made in respect of any of the content on the Platform. Further, the Platform reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the Platform or to terminate the account of any User who is found to have created or shared or submitted any Content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. The User shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading.</Typography>
          <Typography className={classes.Text}>The Users have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the Content on the Platform. Users shall not copy, adapt, or modify any content without written permission of the Company.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>INDEMNITY</Typography>
          <Typography className={classes.Text}>
            <ul><li>The Users of this Platform agree to indemnify, defend and hold harmless the Company/Platform, and their respective directors, officers, employees and agents (collectively, “Parties”), from and against any losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed according to these terms of use. Further, the User agrees to hold the Company/Platform harmless against any claims made by any third party due to, or arising out of, or in connection with:</li>
              <ol>
                <li>&nbsp;&nbsp;User’s use of the Platform,</li>
                <li>&nbsp;&nbsp;User’s violation of these Terms and Conditions;</li>
                <li>&nbsp;&nbsp;User’s violation of any rights of another;</li>
                <li>&nbsp;&nbsp;User’s alleged improper conduct according to these Terms;</li>
                <li>&nbsp;&nbsp;User’s conduct in connection with the Platform;</li>
              </ol>
            </ul>
          </Typography>
          <Typography className={classes.Text}>The user agrees to fully cooperate in indemnifying the Company and the Platform at the user’s expense. The user also agrees not to settle with any party without the consent of the Company.</Typography>
          <Typography className={classes.Text}>In no event shall the Company be liable to compensate the User or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not the Company/ Platform had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the User’s use of or access to the Platform and/or the Services or materials contained therein.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>LIMITATION OF LIABILITY</Typography>
          <Typography className={classes.Text}>The Founders/ Promoters/ Partners/ Associated people of the Company/Platform are not responsible for any consequences arising out of the following events:</Typography>
          <Typography className={classes.Text}>If the Platform is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure;</Typography>
          <Typography className={classes.Text}>If the User has fed incorrect information or data or for any deletion of data;</Typography>
          <Typography className={classes.Text}>If there is an undue delay or inability to communicate through email;</Typography>
          <Typography className={classes.Text}>If there is any deficiency or defect in the Services managed by Us;</Typography>
          <Typography className={classes.Text}>If there is a failure in the functioning of any other service provided by the Platform.</Typography>
          <Typography className={classes.Text}>If there is any reaction/effect on consumption of products sold by Superfoods Valley: All due diligence and research/doctor consultation is to be done by the consumer prior to buying the product, and is the consumer’s responsibility. </Typography>
          <Typography className={classes.Text}>The Platform accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to the User, the User’s belongings, or to any third party, resulting from the use or misuse of the Platform or any service availed of by the User through the Platform. The service and any Content or material displayed on the service is provided without any guarantees, conditions or warranties as to its accuracy, suitability, completeness or reliability. The Platform will not be liable to you for the unavailability or failure of the Platform.</Typography>
          <Typography className={classes.Text}>Users are to comply with all laws applicable to them or their activities, and with all Policies, which are hereby incorporated into this Agreement by reference.</Typography>
          <Typography className={classes.Text}>The Platform expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the Platform, and which is incurred by you in connection with the Platform, including loss of profits; and any loss or damage incurred by you as a result of your breach of these terms.</Typography>
          <Typography className={classes.Text}>To the fullest extent permitted by law, the Platform shall not be liable to you or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Platform.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>TERM</Typography>
          <Typography className={classes.Text}>These Terms shall continue to form a valid and binding contract between the Parties and shall continue to be in full force and effect until the User continues to access and use the Platform</Typography>
          <Typography className={classes.Text}>The Users may terminate their use of the Platform at any time.</Typography>
          <Typography className={classes.Text}>The Company may terminate these Terms and close any account at any time without notice and/or suspend or terminate a User’s access to the Platform at any time and for any reason, if any discrepancy or legal issue arises.</Typography>
          <Typography className={classes.Text}>Such suspension or termination shall not limit our right to take any other action against you that the Company considers appropriate.</Typography>
          <Typography className={classes.Text}>It is also hereby declared that the Company may discontinue the Services and Platforms without any prior notice.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>TERMINATION</Typography>
          <Typography className={classes.Text}>The Company reserves the right, in its sole discretion, to unilaterally terminate the User’s access to the Platform, or any portion thereof, at any time, without notice or cause.</Typography>
          <Typography className={classes.Text}>The Platform also reserves the universal right to deny access to particular users, to any/all of are on its Platform without any prior notice/explanation to protect the interests of the Platform and/or other visitors to the Platform.</Typography>
          <Typography className={classes.Text}>The Platform reserves the right to limit, deny or create different access to the Platform and its features concerning different Users, or to change any of the features or introduce new features without prior notice.</Typography>
          <Typography className={classes.Text}>The User shall continue to be bound by these Terms, and it is expressly agreed to by the Parties that the User shall not have the right to terminate these Terms till the expiry of the same.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>COMMUNICATION</Typography>
          <Typography className={classes.Text}>By using this Platform and providing his/her identity and contact information to the Company through the Platform, the Users hereby agree and consent to receive calls, e-mails or SMS from the Company and/or any of its representatives at any time. (Only with users’ consent)</Typography>
          <Typography className={classes.Text}>USERS CAN REPORT TO “<a href="mailto:terms@superfoodsvalley.com">terms@superfoodsvalley.com</a>” IF THEY FIND ANY DISCREPANCY WITH REGARD TO PLATFORM OR CONTENT-RELATED INFORMATION AND THE COMPANY WILL TAKE NECESSARY ACTION AFTER AN INVESTIGATION. THE RESPONSE WITH RESOLUTION (IF ANY ISSUES FOUND) SHALL BE DEPENDENT ON THE TIME IS TAKEN FOR INVESTIGATION.</Typography>
          <Typography className={classes.Text}>The User expressly agrees that notwithstanding anything contained hereinabove, it may be contacted by the Company or any representatives relating to any services availed by the User on the Platform or anything pursuant thereto and the Users agrees to indemnify the Company from any harassment claims. It is expressly agreed by the Parties that any information shared by the User with the Company shall be governed by the Privacy Policy.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT</Typography>
          <Typography className={classes.Text}>The User agrees and acknowledges that they are a restricted user of this Platform and that they:</Typography>
          <Typography className={classes.Text}>Agree to provide genuine credentials during the process of registration on the Platform. You shall not use a fictitious identity to register. Company is not liable if the User has provided incorrect information.</Typography>
          <Typography className={classes.Text}>Agree to ensure the Name, Email address, Address, Mobile number and any such other information provided during account registration is valid at all times and shall keep your information accurate and up-to-date.</Typography>
          <Typography className={classes.Text}>Users agree that they are solely responsible for maintaining the confidentiality of your account password. You agree to notify us immediately of any unauthorized use of your account. The company reserves the right to close your account at any time for any or no reason.</Typography>
          <Typography className={classes.Text}>Understand and acknowledge that the data submitted is manually entered into the database of the Platform. The User also acknowledges the fact that data so entered into the database is for easy and ready reference for the User, and to streamline the Services through the Platform.</Typography>
          <Typography className={classes.Text}>Authorize the Platform to use, store or otherwise process certain personal information and all published Content, Client responses, Client locations, User comments, reviews and ratings for personalization of Services, marketing and promotional purposes and for optimization of User-related options and Services.</Typography>
          <Typography className={classes.Text}>Understand and agree that, to the fullest extent permissible by law, the Platform/Company and their successors and assigns, or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the Platform or this terms of use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages.</Typography>
          <Typography className={classes.Text}>Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information obtained from the Platform. Any such use/limited use of the Platform will only be allowed with the prior express written permission of the Company.</Typography>
          <Typography className={classes.Text}>Agree not to access (or attempt to access) the Platform and/or the materials or Services by any means other than through the interface provided by the Platform. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or its content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform will lead to suspension or termination of the User’s access to the Platform. The User acknowledges and agrees that by accessing or using the Platform or any of the Services provided therein, it may be exposed to content that it may consider offensive, indecent or otherwise objectionable. The Company disclaims any liabilities arising concerning such offensive content on the Platform.</Typography>
          <Typography className={classes.Text}>Expressly agree and acknowledge that the Content generated by the Users and displayed on the Platform is not owned by the Company and that the Company is in no way responsible for the content of the same. The User may, however, report any offensive or objectionable content, which the Company may then remove from the Platform, at its sole discretion.</Typography>
          <Typography className={classes.Text}>Expressly consents to follow the terms and conditions, and policies of the Vendor affiliated with the Company from whom the Users are utilizing services.</Typography>
          <Typography className={classes.Text}>The User further undertakes not to:</Typography>
          <Typography className={classes.Text}>Engage in any activity that interferes with or disrupts access to the Platform or the Services provided therein (or the servers and networks which are connected to the Platform);</Typography>
          <Typography className={classes.Text}>Impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity;</Typography>
          <Typography className={classes.Text}>Probe, scan or test the vulnerability of the Platform or any network connected to the Platform, nor breach the security or authentication measures on the Platform or any network connected to the Platform. The User may not reverse look-up, trace or seek to trace any information relating to any other User of, or visitor to, the Platform, or any other viewer of the Platform, including any User account maintained on the Platform not operated/managed by the User, or exploit the Platform or information made available or offered by or through the Platform, in any manner;</Typography>
          <Typography className={classes.Text}>Disrupt or interfere with the security of, or otherwise cause harm to, the Platform, systems resources, accounts, passwords, servers, or networks connected to or accessible through the Platform or any affiliated or linked Platform</Typography>
          <Typography className={classes.Text}>Use the Platform or any material or content therein for any purpose that is unlawful or prohibited by these Terms, or to solicit the performance of any illegal activity or other activity which infringes the rights of this Platform or any other third party (s);</Typography>
          <Typography className={classes.Text}>Violate any code of conduct or guideline which may apply for or to any particular service offered on the Platform;</Typography>
          <Typography className={classes.Text}>Violate any applicable laws, rules or regulations currently in force within or outside India;</Typography>
          <Typography className={classes.Text}>Violate any portion of these Terms or the Privacy Policy, including but not limited to any applicable additional terms of the Platform contained herein or elsewhere, whether made by amendment, modification, or otherwise;</Typography>
          <Typography className={classes.Text}>Commit any act that causes the Company to lose (in whole or in part) the Services of its Internet Establishment (“ISP”) or in any manner disrupts the Services of any other supplier/service provider of the Company/Platform;</Typography>
          <Typography className={classes.Text}>Further:</Typography>
          <Typography className={classes.Text}>The User hereby expressly authorizes the Company/Platform to disclose any and all information relating to the User in the possession of the Company/Platform to law enforcement or other government officials, as the Company may in its sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. The User further understands that the Company/Platform might be directed to disclose any information (including the identity of persons providing information or materials on the Platform) as necessary to satisfy any judicial Order, law, regulation or valid governmental request.</Typography>
          <Typography className={classes.Text}>By indicating the User’s acceptance to purchase any service offered on the site, the user is obligated to complete such transactions after making payment. Users shall be prohibited from indicating their acceptance to avail services where the transactions have remained incomplete.</Typography>
          <Typography className={classes.Text}>The User agrees to use the services provided by the Company, its affiliates, consultants and contracted companies, for lawful purposes only.</Typography>
          <Typography className={classes.Text}>The User agrees not to make any bulk purchase to indulge in any resale activities. In case of any such instance, the Company reserves all rights to cancel the current and future orders and block the concerned User account.</Typography>
          <Typography className={classes.Text}>The User agrees to provide authentic and true information. The Company reserves the right to validate the information and other details provided by the User at any point of time. If upon verification such User details are found to be false, not to be true (wholly or partly), the Company shall in its sole discretion reject the registration and debar the User from using the Services available on its Mobile Application, Website, and/or other affiliated websites without prior intimation whatsoever.</Typography>
          <Typography className={classes.Text}>The User agrees not to post any material on the website that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful, or advertising any goods or services. More specifically, the User agrees not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that:</Typography>
          <Typography className={classes.Text}>belongs to another person and to which the User has no right to;</Typography>
          <Typography className={classes.Text}>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</Typography>
          <Typography className={classes.Text}>is in any way harmful to minors;</Typography>
          <Typography className={classes.Text}>infringes any patent, trademark, copyright or other proprietary rights;</Typography>
          <Typography className={classes.Text}>violates any law for the time being in force;</Typography>
          <Typography className={classes.Text}>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing;</Typography>
          <Typography className={classes.Text}>Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</Typography>
          <Typography className={classes.Text}>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</Typography>
          <Typography className={classes.Text}>Publish, post, disseminate, any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to “indecent representation of women” within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</Typography>
          <Typography className={classes.Text}>Threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>SUSPENSION OF USER ACCESS AND ACTIVITY</Typography>
          <Typography className={classes.Text}>Notwithstanding other legal remedies that may be available, the Company may in its sole discretion, limit the User’s access and/or activity by immediately removing the User’s access credentials either temporarily or indefinitely, or suspend/terminate the User’s association with the Platform, and/or refuse to the usage of the Platform to the User, without being required to provide the User with notice or cause:</Typography>
          <Typography className={classes.Text}>If the User is in breach any of these Terms or the Privacy Policy;</Typography>
          <Typography className={classes.Text}>If the User has provided wrong, inaccurate, incomplete or incorrect information;</Typography>
          <Typography className={classes.Text}>If the User’s actions may cause any harm, damage or loss to the other Users or the Company, at the sole discretion of the Company.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>INTELLECTUAL PROPERTY RIGHTS</Typography>
          <Typography className={classes.Text}>Unless expressly agreed to in writing, nothing contained herein shall give the User a right to use any of the Platform’s trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by the Platform and other distinctive brand features of the Platform are the property of the Company or the respective copyright or trademark owner. Furthermore, concerning the Platform created by the Company, the Company shall be the exclusive owner of all the designs, graphics and the like, related to the Platform.</Typography>
          <Typography className={classes.Text}>The User may not use any of the intellectual property displayed on the Platform in any manner that is likely to cause confusion among existing or prospective Users of the Platform, or that in any manner disparages or discredits the Company/Platform, to be determined in the sole discretion of the Company.</Typography>
          <Typography className={classes.Text}>The User is aware all intellectual property, including but not limited to copyrights, relating to said services resides with the owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators to the Company or any other User. The User is aware that the Company merely provides a platform through which the Users can communicate and schedule meetings, and the Company/Platform does not own any of the intellectual property relating to the independent content displayed on the Platform, apart from created graphics and specified content.</Typography>
          <Typography className={classes.Text}>The User is further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by the User will result in legal action being initiated against the User by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>DISCLAIMER OF WARRANTIES AND LIABILITIES</Typography>
          <Typography className={classes.Text}>The User agrees and undertakes that they are accessing the Platform at their sole risk and that they are using their best and prudent judgment before availing any service listed on the Platform or accessing/using any information displayed thereon.</Typography>
          <Typography className={classes.Text}>The User agrees that any kind of information, resources, activities, recommendations obtained/availed from Platform, written or oral, will not create any warranty and the Platform disclaims all liabilities resulting from these.</Typography>
          <Typography className={classes.Text}>The Platform does not guarantee that the Services contained in the Platform will be uninterrupted or error-free, or that the Platform or its server will be free of viruses or other harmful components, and the User hereby expressly accepts any associated risks involved with the User’s use of the Platform.</Typography>
          <Typography className={classes.Text}>It is further agreed by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>FORCE MAJEURE</Typography>
          <Typography className={classes.Text}>Neither the Company nor the Platform shall be liable for damages for any delay or failure to perform its obligations hereunder if such delay or failure is due to cause beyond its control or without its fault or negligence, due to Force Majeure events including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>DISPUTE RESOLUTION AND JURISDICTION</Typography>
          <Typography className={classes.Text}>It is expressly agreed by the Parties hereto that the formation, interpretation, and performance of these Terms and any disputes arising therefrom will be resolved through a two-step Alternate Dispute Resolution (“ADR”) mechanism. It is further agreed by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy.</Typography>
          <Typography className={classes.Text}>Mediation: In case of any dispute between the parties, the Parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of all parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to any other Party, the dispute will be resolved by arbitration, as detailed hereinbelow;</Typography>
          <Typography className={classes.Text}>Arbitration: In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration by a sole arbitrator to be appointed by the Company, and the award passed by such sole arbitrator will be valid and binding on all parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. The arbitration shall be conducted in English, and the seat of Arbitration shall be the city of Gurugram, Haryana, India.</Typography>
          <Typography className={classes.Text}>The Parties expressly agree that the Terms, Policy and any other agreements entered between the Parties are governed by the laws, rules, and regulations of India.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>NOTICES/GRIEVANCES</Typography>
          <Typography className={classes.Text}>ANY AND ALL COMMUNICATION RELATING TO ANY DISPUTE OR GRIEVANCE EXPERIENCED BY THE USER MAY BE COMMUNICATED TO THE COMPANY BY THE USER BY EMAILING TO <a href="mailto:terms@superfoodsvalley.com">terms@superfoodsvalley.com</a></Typography>
        </>
        <>
          <Typography className={classes.subHeading}>MISCELLANEOUS PROVISIONS</Typography>
          <Typography className={classes.Text}>Entire Agreement: These Terms, read with the Policy, form the complete and final contract between the User and the Company with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto.</Typography>
          <Typography className={classes.Text}>Waiver: The failure of either Party at any time to require performance of any provision of these Terms shall in no manner affect such Party’s right at a later time to enforce the same. No waiver by either party of any breach of these Terms, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms.</Typography>
          <Typography className={classes.Text}>Severability: If any provision/clause of these Terms is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms shall in no way be affected or impaired thereby, and each such provision/clause of these Terms shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions, and commercial expectations of the Parties hereto, as expressed herein.</Typography>
        </>
        <>
          <Typography className={classes.subHeading}>PROHIBITED GOODS</Typography>
          <Typography className={classes.Text}>Superfoods Valley Private Limited, which operates this online marketplace called Good Monk, clearly establishes that the following products will not be sold on its platform. Good Monk is explicitly indemnified for any 3rd party indiscretion at the time of uploading and publishing any product that falls under the scope of the following items.
            <ol start={2}>
              <li>&nbsp;&nbsp;Adult goods, toys and services: Like sex toys, child pornography items or anything that is prohibited by the law to sell.</li>
              <li>&nbsp;&nbsp;Alcohol/ Tobacco and cigarettes: Alcohol or alcoholic beverages such as beer, liquor, wine, or chocolate filled with liquor</li>
              <li>&nbsp;&nbsp;Direct Selling of Cigarettes, e-cigarettes, cigars, hukkas, chewing tobacco, and related products on platform</li>
              <li>&nbsp;&nbsp;Art / Antique item: Items older than 99 years including items detached from historic buildings, craftsmanship, things of historical interest and any manuscript or other document of historical, literary or aesthetic value of 75 years or more.</li>
              <li>&nbsp;&nbsp;Body parts: Body Organs or body parts and anything else that is medically prohibited by the law.</li>
              <li>&nbsp;&nbsp;Cable descramblers: Devices intended to obtain/decode satellite codes or signals</li>
              <li>&nbsp;&nbsp;Copyrighted devices (without legal documents): Copyrights unlocking devices, Unauthorized copies of books/ media, music and other licensed products/ Software</li>
              <li>&nbsp;&nbsp;Drugs and drug paraphernalia: Illegal drugs and drug accessories, Illegal Herbal drugs like salvia and magic mushrooms, drug cleansing shakes, urine test additives</li>
              <li>&nbsp;&nbsp;Endangered species/Live animals: Animals or other organisms (including product derivatives) in danger of extinction</li>
              <li>&nbsp;&nbsp;Gambling / lottery / Lotto: Lottery tickets, sports bets, memberships/ enrolment in online gambling sites, and related content</li>
              <li>&nbsp;&nbsp;Government IDs or documents: Fake IDs, passports, government documents and medals</li>
              <li>&nbsp;&nbsp;Hacking and cracking materials: Manuals, how-to guides, information, or equipment enabling hacking/ illegal access to software, servers, websites or patented/ protected property</li>
              <li>&nbsp;&nbsp;Miracle cures: Unsubstantiated/ Unverified cures, remedies or other items marketed as quick health fixes, Black Magic</li>
              <li>&nbsp;&nbsp;Offensive goods: Literature, products or any materials that discriminate based on race, ethnicity, national origin, religion or sex that encourage or incite violent acts or promote intolerance, hatred or crime</li>
              <li>&nbsp;&nbsp;Political donation: Donations catering to a political candidate or organization regarding elections or campaigns</li>
              <li>&nbsp;&nbsp;Pyrotechnic devices and hazardous materials: Fireworks including Crackers; toxic, flammable (Diesel, petrol and pure spirit), and radioactive materials</li>
              <li>&nbsp;&nbsp;Cryptocurrency; Any un-authorized cryptocurrencies</li>
              <li>&nbsp;&nbsp;Regulated goods: Batteries containing mercury; Freon or similar substances. Coolant refrigerants, chemical/industrial solvents (Except Agricultural products); Government uniforms, Government car titles; Car license plates; police badges and law enforcement equipment, lock picking devices, surveillance equipment except video-based CCTV only, slot machines, any Unlawful, harmful, threatening, abusive, harassing, defamatory comes under its ambit</li>
              <li>&nbsp;&nbsp;Weapons: Firearms, ammunition, knives, Gun, air gun, brass knuckles, gun parts, and armaments</li>
              <li>&nbsp;&nbsp;Counterfeit and unauthorized goods: Replicas or imitations of patented products; items without a celebrity endorsement that would normally require such an association; fake autographs</li>
              <li>&nbsp;&nbsp;Home based earning scheme: Multi-Level marketing collection fees, Data entry Service provider, Work from home service provider</li>
              <li>&nbsp;&nbsp;Dating Services: Chat/Video based dating services</li>
            </ol>
            Contact Us: If you have any questions about this Policy or any other policy, the practices of the Platform, or your experience, you can contact us at <a href="mailto:terms@superfoodsvalley.com">terms@superfoodsvalley.com</a>
          </Typography>
        </>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withTranslation('footerLng')(TermsAndConditionsWeb));
