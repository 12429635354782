import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string; 
  classes:any; 
  t:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  loading: boolean;
  defaultFoodType: any;
  foodFilter: any;
  searchResult: any
  pagenationData: any;
  paginationRemove: any
  filteredSearchResults: any;  
  filterCategory:any;  
  noRatedData:any;
  noProducts:boolean;  
  pageNumber:number;
  previousSearchValue:any;
  // Customizable Area End
}

interface SS {
  id: any;
  PaginationWeb: any;
}

export default class SearchControllerWeb extends BlockComponent<Props, S, SS> {
  getSearchApiCallID: any = "";
  getFilterCategoryApiCallID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: '',
      // Customizable Area Start
      loading: false,
      defaultFoodType: "Packaged Foods",
      foodFilter: "",      
      searchResult: "",
      pagenationData: {},
      paginationRemove: false,
      pageNumber:1,
      filteredSearchResults: [],            
      filterCategory:[],  
      noRatedData:"",  
      noProducts:false,  
      previousSearchValue:-1,
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();    
   /* istanbul ignore next */
    if(this.props.navigation.history.location.pathname === "/search") {    
    const searchValue:any = localStorage.getItem("searchValue") == "" ? this.props.navigation.history.location.search.substring(1) :  localStorage.getItem("searchValue")
    this.getFilterCategory();
    let forcepageNum:any = localStorage.getItem("forcePageNumber");     
    let previousSearchValueData:any = localStorage.getItem("previousSearchValue");  
    await this.setState({previousSearchValue: previousSearchValueData}, ()=>{this.state.previousSearchValue})   
    if(forcepageNum) {
      let rx_num: any = JSON.parse(forcepageNum)
      this.setState({pageNumber: rx_num}, ()=>{this.state.pageNumber})
  }    
    document.addEventListener('keydown', (e: any) => this.keyFun(e))    
    this.setState({ searchResult: searchValue }, 
      ()=>{                        
                
    if(searchValue !== this.state.previousSearchValue) {
      this.setState({previousSearchValue: previousSearchValueData}, ()=>{this.state.previousSearchValue})   
      localStorage.setItem("previousSearchValue", searchValue);
      localStorage.removeItem("forcePageNumber"); 
      this.setState({pageNumber:1})                              
      this.getSearchProducts(this.state.searchResult,1,1);    
    }
    else{                   
      localStorage.removeItem("previousSearchValue");                                 
      this.getSearchProducts(this.state.searchResult,this.state.pageNumber,1);    
      this.props.navigation?.history?.push({search:searchValue});      
    }  
    })
    
  }    
  if(this.props?.navigation?.history?.location?.pathname === "/norated") { 
  const Data:any = localStorage.getItem("noRated") ? localStorage.getItem("noRated") : ""  
  this.setState({noRatedData: JSON.parse(Data)})
}
  }
  // Customizable Area Start    
  keyFun =  (event: any) => {
    /* istanbul ignore next */
    if (event.code == 'Enter' && this.props?.navigation?.history?.location?.pathname === "/search") {      
      this.setState({ loading: true })                
      this.componentDidMount()
    }
  }

  getSearchProducts = (value: any, pageNO: any, category_id:any) => {         
    this.setState({ loading: true })
    const header = {
      'Content-Type': 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getSearchProductsEndPoint}?category_id=${category_id}&query=${value}&page=${pageNO}&language_id=${this.getLanguage()}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  // Method to Call the Filter Type Category API
  getFilterCategory = () => {    
    this.setState({ loading: true })
    const header = {'Content-Type': 'application/json',};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilterCategoryApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getFilterCategoryEndPoint}?language_id=${this.getLanguage()}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  async receive(from: string, message: Message) {    

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSearchApiCallID &&
      this.getSearchApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });
      
      if (responseJson?.products || responseJson?.meta) {
        this.setState({ pagenationData: responseJson?.meta });
        this.setState({noProducts: false, paginationRemove: false})        
        this.setState({filteredSearchResults: responseJson?.products})
      }   
   
     else if (responseJson?.errors) {
        this.setState({noProducts: true, paginationRemove: true})
      }
// Need Backend Response to handle this
else { 
        /* istanbul ignore next */
        this.props.navigation.navigate("NoProudctFound") 
      }

    }
    else { this.setState({ loading: false }) }

    
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFilterCategoryApiCallID &&
      this.getFilterCategoryApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var categoryResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });
             
        this.setState({filterCategory:categoryResponseJson.data})
    }
    else { this.setState({ loading: false }) }
  }

  goToNoRatedPage = (noRatedProduct:any) => {       
    this.props.navigation.history.push({pathname: "/norated",
      search: `?name=${noRatedProduct?.attributes?.product_name}`,
      state: {noRatedProduct} });

    localStorage.setItem("noRated",JSON.stringify(noRatedProduct) )
  }

  handlePageClick = (pageNumber: any) => {    
    this.setState({ loading: true });
    let num = pageNumber.selected + 1
    localStorage.setItem("forcePageNumber", JSON.stringify(num))
    this.setState({pageNumber:num},()=>{
      this.getSearchProducts(this.state.searchResult, this.state.pageNumber, this.state.foodFilter);    
    })
  }

  searchAnotherProductButton = (path:any) => {    
    this.props.navigation.navigate(path);    
    
  }

  // function for navigate to the product details page with GetProductDetailsAdapter {adapter} {add by rifadulDev}
  goToProductDetailsPage = (id: any) => {
    localStorage.setItem('proId', id)
    const msg: Message = new Message(getName(MessageEnum.FromProductPageNavigationMessage)); // here nedd to change messageEnum name {adapter} {add by rifadulDev}
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), id); // here nedd to change value {adapter} {add by rifadulDev}
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDetails"); // here need to change Route name
    this.send(msg);
  }

  FoodType = (fd_type:any) => {            
    this.setState({foodFilter: fd_type,pageNumber:1}, ()=>{this.state.foodFilter;this.state.pageNumber})        
    this.getSearchProducts(this.state.searchResult,this.state.pageNumber, fd_type);  

  }
  onProductSelection = (event:any) => {      
     
    event?.attributes?.product_rating === 'NA' ?                                      
    this.goToNoRatedPage(event)
     : this.goToProductDetailsPage(event?.attributes?.id);  
  }

  getLanguage = () => {
    let languageId:any;
    const defaultEnglishLanguageId:any = configJSON.defaultLanguageIDEnglish    
    if(localStorage.getItem('languageSelect')) {
      languageId = localStorage.getItem('languageSelect');
    }
    else {
     languageId = defaultEnglishLanguageId;
    }
    return languageId;
  }
  handleToolTipStyle = (rating: any) => {
    let productRating;
    switch (true) {
        case rating === "A":
            productRating = "#3C9637";
            break;
        case rating === "B":
            productRating = "#AAC83C";
            break;
        case rating === "C":
            productRating = "#FCC300";
            break;
        case rating === "D":
            productRating = "#FF784B";
            break;
        case rating === "E":
            productRating = "#FF5050";
            break;
        default: productRating = "#c8c4c4";
    }
    return productRating;
}

  // Customizable Area End
}