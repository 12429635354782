// Customizable Area Start
import React, { Component } from 'react'
import { favIcon, compareIcon, lodingImage } from '../assets'
import { Grid } from "@material-ui/core";
import { Typography } from '@material-ui/core';

export default class FavoriteProductWebComponent extends Component<any> {

    handleRating = (data: any) => {
        if (data === 'A') {
            return '#00AB79'
        } else if (data === 'B') {
            return "#AAC83C"
        } else if (data === 'C') {
            return "#fcc300"
        } else if (data === 'D') {
            return "#ff784b"
        } else if (data === 'E') {
            return "#ff5050"
        } else {
            return '#000'
        }
    }

    render() {
        return (
            <>
                <Grid item xs={12} sm={6} md={3} key={this.props.i}>
                    <div className={this.props.classes.favProduct}>
                        <div className={this.props.classes.productImgContainer} onClick={() => {
                            localStorage.setItem("proId", this.props.e.attributes.product_id);
                            this.props?.navigation?.history?.push({
                                pathname: "/ProductDetails",
                                search: `${this.props.e.attributes.product_id}`,
                                state: { searchName: `${this.props.e.attributes.product_id}` }
                            });
                        }
                        }>
                            <img src={this.props?.e?.attributes?.product?.data?.attributes?.image ? this.props?.e?.attributes?.product?.data?.attributes?.image : lodingImage} height='154px' />
                        </div>
                        <div className={this.props.classes.favProductCompare}>
                            <Typography className={this.props.classes.productNameTextStyle} onClick={() => {
                                localStorage.setItem("proId", this.props.e.attributes.product_id);
                                this.props?.navigation?.history?.push({
                                    pathname: "/ProductDetails",
                                    search: `${this.props.e.attributes.product_id}`,
                                    state: { searchName: `${this.props.e.attributes.product_id}` }
                                });
                            }
                            }>{this.props?.e?.attributes?.product?.data?.attributes?.product_name ? this.props?.e?.attributes?.product?.data?.attributes?.product_name : 'title'}</Typography>
                        </div>
                        <div className={this.props.classes.favProductCompareNiq} >
                            <Typography className={this.props.classes.niqtextheading}>N<sup>IQ</sup> Score  <span className={this.props.classes.niqProduct} style={{
                                background: this.handleRating(this.props?.e?.attributes?.product?.data?.attributes?.product_rating)
                            }}>{this.props?.e?.attributes?.product?.data?.attributes?.product_rating}</span></Typography>
                        </div>
                        <div className={this.props.classes.favProductCompare} style={{ position: 'absolute', bottom: 0, width: '90%', marginBottom: '10px' }}>
                            <div style={{ cursor: 'pointer' }} data-testid="favButton" onClick={() => this.props.deletefavList(this.props?.e?.attributes?.product_id)}>
                                <img src={favIcon} className={this.props.classes.favIcon} />
                            </div>
                            {this.props?.e?.attributes?.added_to_compare ? (
                                <div className={this.props.classes.compareContainer}>
                                    <span>Compare</span>
                                    <span>
                                        <img src={compareIcon} alt="bgImage" className={this.props.classes.compareImg} />
                                    </span>
                                </div>
                            ) : (
                                <div style={{ color: 'gray', fontWeight: 500, cursor: 'pointer' }} onClick={() => this.props.checkCompareProducts(this.props.e.attributes.product_id)}>Compare</div>
                            )}
                        </div>
                    </div>
                </Grid>

            </>
        )
    }
}

// Customizable Area End
