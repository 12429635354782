import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import {
    Grid,
    Button,
    Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => (
    {
        modelContent: {
            padding: '2rem',
            borderRadius: '1rem',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1.5),
            top: theme.spacing(2),
            color: '#455A64',
            border: '2px solid #38006D',
            padding: '2px'

        },
        notificationText: {
            textAlign: 'center',
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "33px",
            // margin: '1rem 0rem 0rem 0rem',
            color: "#455A64",
            [theme.breakpoints.down("sm")]: {
                fontSize: "13px",
                lineHeight: "12px",
            },
        },
        deleteDilogBoxBtn: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "28px",
            padding: '0.3rem 1.3rem',
            borderRadius: '0.5rem',
            backgroundColor: '#12125c',
            color: '#ffff',
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "23px",
                padding: '0.4rem 1.6rem',
            },
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },

        },
        cancelDilogBoxBtn: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "28px",
            padding: '0.2rem 1rem',
            borderRadius: '0.5rem',
            // backgroundColor: '#12125c',
            color: '#12125c',
            border: '2px solid #12125c',
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "23px",
                padding: '0.3rem 1.6rem',
            },
            '&:hover': {
                background: '#ffff',
            },

        },
    }
));

interface Props {
    isOpen: boolean,
    cancleFunction: () => void;
    deleteFunction: any;
    deleteId: any;
}

const CustomDeleteAlartDailogBox = ({
    isOpen,
    cancleFunction,
    deleteFunction,
    deleteId
}: Props) => {
    const classes = useStyles();
    const [t] = useTranslation(["CustomDeleteAlartDailogBoxLang"]);
    const handelCancel = () => {
        cancleFunction()
    }
    const handelDelete = () => {
        deleteFunction(deleteId)
    }
    return (
        <Dialog
            open={isOpen}
            style={{ zIndex: 9999 }}
            aria-labelledby="form-dialog-title"
            BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
        >
            <div className={classes.modelContent}>
                {/* <MuiDialogTitle disableTypography> */}

                <IconButton aria-label="close" className={classes.closeButton} onClick={handelCancel}>
                    <CloseIcon style={{ fontSize: '13px' }} />
                </IconButton>

                {/* </MuiDialogTitle> */}
                <DialogTitle id="form-dialog-title">
                    <Typography className={classes.notificationText}>{t("Notification_Message")}</Typography>
                </DialogTitle>

                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        <Button variant="outlined" data-testid='handelCancel' onClick={() => handelCancel()} className={classes.cancelDilogBoxBtn}>
                            {t("Cancel")}
                        </Button>
                        <Button data-testid='handelDelete' onClick={() => handelDelete()} variant="contained" className={classes.deleteDilogBoxBtn}>
                            {t("Delete")}
                        </Button>
                    </Grid>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default CustomDeleteAlartDailogBox

