import React, { useEffect, useState, useCallback,useRef } from "react";
import { withRouter } from "react-router-dom";
//Customise area start
import { InputBase, Typography, Button,Paper,Modal,CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import VoiceBarCodeSearch from "./VoiceBarCodeSearch.web"
import Scanner from "./BarcodeScanner.web"
import { useTranslation } from "react-i18next";

const baseURL = require("../../framework/src/config");
const noProductFoundImg = require("../assets/NpProductFoundImg.svg") as string;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerStyle: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: 50,
      marginRight: 50,
      [theme.breakpoints.down("xl")]: {
        marginLeft: 40,
        marginRight: 40,
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: 30,
        marginRight: 30,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: 20,
        marginRight: 20,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 10,
        marginRight: 10,
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    search: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: "#fff",
      borderRadius: "59px",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      paddingRight: "1em",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },

      gap: "10px",
    },

    Homesearch: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: "#fff",
      borderRadius: "59px",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      width: "100%",
      minHeight: "70px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },

    inputInput: {
      background: "none !important",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      transition: theme.transitions.create("width"),
      fontFamily: "Poppins",
      color: "#38006D",
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "18px",
      fontStyle: "normal",
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        width: "34ch",
      },
      [theme.breakpoints.only("md")]: {
        width: "34ch",
      },
      [theme.breakpoints.only("sm")]: {
        width: "34ch",
      },
      [theme.breakpoints.between(599, 655)]: {
        width: "30ch",
      },
      [theme.breakpoints.between(360, 599)]: {
        maxWidth: "30ch",
        fontSize: 16,
      },
      [theme.breakpoints.down(360)]: {
        width: "6ch",
        fontSize: 16,
      },
    },

    HomeinputInput: {
      background: "none !important",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      paddingRight: `calc(1em + ${theme.spacing(1)}px)`,
      transition: theme.transitions.create("width"),
      fontFamily: "Poppins",
      color: "#38006D",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "18px",
      fontStyle: "normal",
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        width: "55ch",
      },
      [theme.breakpoints.only("md")]: {
        width: "45ch",
      },
      [theme.breakpoints.only("sm")]: {
        width: "35ch",
      },
      [theme.breakpoints.between(599, 655)]: {
        width: "30ch",
      },
      [theme.breakpoints.only("xs")]: {
        width: "18ch",
        fontSize: 16,
      },
      [theme.breakpoints.down(355)]: {
        width: "13.4ch",
        fontSize: 16,
      },
    },
    searchDropdown: {
      marginTop: '4px',
      position: "absolute",
      zIndex: -1,
      maxHeight: 400,
      background: "#fff",
      borderRadius: "15px",
      padding: 20,
      paddingTop: 25,
      paddingBottom: 0,
      overflowY: "scroll",
      width: "-webkit-fill-available",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.00)",
      },
    },
    HomesearchDropdown: {
      marginTop: '4px',
      position: "absolute",
      zIndex: -1,
      maxHeight: 150,
      background: "#fff",
      borderRadius: "29px",
      padding: 20,
      paddingTop: 75,
      paddingBottom: 0,
      overflowY: "scroll",
      width: "-webkit-fill-available",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.00)",
      },
    },
    noProductImage: {
      width: 64,
      height: 'auto',
      padding: 15,
    },
    noProductTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: "117.5%",
      color: '#FF3939',
      textAlign: "center",
      padding: 10
    },
    loadMoreBtn:{
      textTransform: "capitalize", 
      textDecoration:"underline",
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      color: '#38006D'
    }
  })
);

//Customise area stop

const CustomSearch = (props: any) => {
  //Customise area start
  const classes = useStyles();
  const [t] = useTranslation(["translation", "TopNavLng"]);
  const [searchTxt, setSearchTxt] = useState<any>(localStorage.getItem("reloadValue") ? localStorage.getItem("reloadValue"): "");
  const [ResultTxt, setResultTxt] = useState([]);
  const [dropDown, setDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noProducts, setNoproducts] = useState(false);
  const [isUserClicked, setIsUserClicked] = useState(false);
  const [camModal, setCamModal] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [barCode, setBarCode] = useState("");
  const [pagination, setpagination] = useState<any>("");
  

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const getLanguage = () => {
    let languageId:any;
    const defaultEnglishLanguageId:any = 1;
    if(localStorage.getItem('languageSelect')) {
      languageId = localStorage.getItem('languageSelect');
    }
    else {
     languageId = defaultEnglishLanguageId;
    }
    return languageId;
  }

  // UseEffect Hook for Voice Search functionality start
  useEffect(() => {
    if (isUserClicked && transcript) {
      setSearchTxt(transcript);
      optimisedVersion(transcript);
      localStorage.setItem("searchValue", transcript)
      localStorage.setItem("reloadValue", transcript);
      dropDownClose();

      if (transcript && listening && props?.history?.location?.pathname !== "/search") {
        localStorage.removeItem("reloadValue");
        setTimeout(() => {
          props?.history?.push({
            pathname: '/search',
            search: `${transcript}`,
            state: { searchName: `${transcript}` }
          });
        }, 2000);

      }
      else if (transcript && listening && props?.history?.location?.pathname === "/search") {
        localStorage.setItem("reloadValue", transcript);
        window.location.reload();
        if (!localStorage.getItem("searchValue")) { localStorage.setItem("searchValue", searchTxt);localStorage.setItem("reloadValue", searchTxt); }
        else { localStorage.setItem("searchValue", transcript) }
      }
    }
  }, [transcript]);

  useEffect(() => {
    if (isUserClicked && !listening) {
      setIsUserClicked(false)
    }
  }, [listening]);

  // UseEffect Hook for Voice Search functionality stop

  //  Use Effect Hook for send & update data to locaStorage start
  useEffect(() => {
    transcript && listening ? localStorage.setItem("searchValue", transcript) : localStorage.setItem("searchValue", searchTxt)
    transcript && listening ? localStorage.setItem("reloadValue", transcript) : localStorage.setItem("reloadValue", searchTxt)
  }, [searchTxt || transcript]);

    
  useEffect(() => {

    if (
      props?.history?.location?.pathname === "/ProductDetails" ||     
      props?.history?.location?.pathname === "/search" ||
      props?.history?.location?.pathname === "/noproduct" ||
      props?.history?.location?.pathname === "/norated"
      ) {
      const x: any = localStorage.getItem("searchValue")
      x ? setSearchTxt(x) : setSearchTxt(searchTxt)
    }
    else {               
      searchTxt ? setSearchTxt("") : searchTxt
    }
  }, [props?.history?.location]);
  
  
  //  Use Effect Hook for send & update data to locaStorage stop

  const handleChange = (e: any) => {
    setSearchTxt(e.target.value);
    optimisedVersion(e.target.value);
  }

  const handleSearchAutoComplete = async (value: any, currentPage: any = 1) => {
    if (String(value).trim().length > 2) {
      try {
        setLoading(true);
        let response = await fetch(`${baseURL.baseURL}/bx_block_catalogue/search?category_id=1&query=${value}&page=${currentPage}&language_id=${getLanguage()}`,
        );
        const data = await response?.json();
        if (Object.prototype.hasOwnProperty.call(data, "products") || Object.prototype.hasOwnProperty.call(data, "meta")) {          
          setNoproducts(false);
          setResultTxt(data?.products?.data);
          setpagination(data?.meta?.pagination)
          setLoading(false);          
        }
        else if (data?.errors) { setNoproducts(true); }
        document.addEventListener('click', function (event) { setDropDown(false); })
      }
      catch (error) { console.error(error, "error while calling api"); }
    }

  };
  const dropDownClose = () => { setDropDown(false);setIsLoadMore(false); };

  const debounce = (func: any) => {
    let timer: any;
    return function (...args: any) {
      // @ts-ignore
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args);
      }, 500);
    }
  }

  const optimisedVersion = useCallback(debounce(handleSearchAutoComplete), []);

  const handleBarcodeSearch = async (value: any) => {     
    if (value?.length !== 0) {
      try {

        setLoading(true);
        let response = await fetch(`${baseURL.baseURL}/bx_block_catalogue/search?category_id=1&query=${value}&language_id=${getLanguage()}`,
        );
        const data = await response?.json();
        if (data?.products || data?.meta) {
          setNoproducts(false);
          if (data?.products?.data[0]?.id) {
            if(data?.products?.data[0]?.attributes.product_rating === 'NA' || null){
              handleCloseCam();              
              if (props?.history?.location?.pathname === "/norated") {                
                goToNoRatedPage(data?.products?.data[0]);                                              
                window.location.reload();
              }
              else {                                
                goToNoRatedPage(data?.products?.data[0]);
              }              
            }
            else {
            if (props?.history?.location?.pathname == "/ProductDetails") {
              if(data?.products?.data[0]?.attributes.product_rating === 'NA' || null){
                handleCloseCam();                
                goToNoRatedPage(data?.products?.data[0]);
                setTimeout(()=>{
                    window.location.reload();
                },1800)
            
              }
              else {
              
              window.location?.reload();
            }
            }
            else {
              props?.history?.push({
                pathname: '/ProductDetails',
                search: `${data?.products?.data[0]?.id}`,
                state: { searchName: `${data?.products?.data[0]?.id}` }
              });

              await window.location?.reload();
            }

            await localStorage.setItem("proId", data?.products?.data[0]?.id)
            setLoading(false);
            handleCloseCam();
          }
          }
        }
        else if (data?.errors) {
          if (props?.history?.location?.pathname == "/noproduct") {
            window.location?.reload();
          }
          else {
            props?.history?.push({
              pathname: '/noproduct',
              search: `${data?.errors}`,
              state: { searchName: `${data?.errors}` }
            });
            await window.location?.reload();
          }
          setNoproducts(true);
          handleCloseCam();

        }
      }
      catch (error) { console.error(error, "error while calling api"); }
    }

  };
  

  const handleOpenCam = () => {
    setCamModal(true);
  }

  const handleCloseCam = async () => {
    setCamModal(false);
  }
  
  const goToNoRatedPage = (noRatedProduct:any) => {        
    props?.history?.push({
      pathname: "/norated",
      search: `?name=${noRatedProduct?.attributes?.product_name}`,
      state: {noRatedProduct}  
});
localStorage.setItem("noRated",JSON.stringify(noRatedProduct) )
  }

  const handleLoadMore = () => {    
    setIsLoadMore(true)
    let page = pagination.current_page + 1     
    handleSearchAutoComplete(searchTxt, page)
  }
  const stopListeningVoice = () =>{
    SpeechRecognition.stopListening();
    setIsUserClicked(false)
    setIsLoadMore(false);
  }
  const startListeningVoice = () =>{
    if (!browserSupportsSpeechRecognition) {
      alert("Oops....!!! This Browser is Not support")
    }
    else {
      setIsUserClicked(true)
      setIsLoadMore(false);
      SpeechRecognition.startListening();
    }
  }
  const handleBarcodeResult = (result:any) => {
    
    if(result !== undefined) {                
      if (result.codeResult.code) {        
        handleBarcodeSearch(result.codeResult.code);
        setBarCode(result.codeResult.code);              
      }      
        handleCloseCam()
    }
  }

  const searchPlacHolder: any = t("TopNavLng:searchPlaceholder")
  //Customise area stop
  return (
              
        <div
          className={`${props.data === "TopNav" ? classes.search : classes.Homesearch}`}
          style={{position: "relative",}}>
          <InputBase
            placeholder= {searchPlacHolder}            
            classes={{ input: `${props.data === "TopNav" ? classes.inputInput : classes.HomeinputInput}` }}
            inputProps={{ "aria-label": "search" }}
            fullWidth
            value={searchTxt}
            onChange={(e) => {
              handleChange(e);
              setDropDown(true);
            }}

            onKeyPress={(e) => {                        
              dropDownClose();              
              if (e?.key === "Enter" && props?.history?.location?.pathname !== "/search") {
                // @ts-ignore
                localStorage.setItem("searchValue", e.target.value)
                // @ts-ignore
                localStorage.setItem("reloadValue", e.target.value)
                props?.history?.push({
                  pathname: '/search',
                  // @ts-ignore
                  search: `${e.target.value}`,
                  // @ts-ignore
                  state: { searchName: `${e.target.value}` }
                });


              }
              else if (e?.key === "Enter" && props?.history?.location?.pathname === "/search") {

                if (!localStorage.getItem("searchValue")) { localStorage.setItem("searchValue", searchTxt);localStorage.setItem("reloadValue", searchTxt); }
                else {
                  // @ts-ignore
                  localStorage.setItem("searchValue", e.target.value)
                  // @ts-ignore
                  localStorage.setItem("reloadValue", e.target.value)
                }
              }
            }}
          />

          <VoiceBarCodeSearch
          stopListeningVoice={stopListeningVoice}
          startListeningVoice={startListeningVoice}
          handleOpenCam={handleOpenCam}
          listening={listening}
          isUserClicked={isUserClicked}
          propData={props.data}

          />

          {searchTxt?.length > 2 && (
            <div style={{ 
              display: dropDown || isLoadMore ? "block" : "none",
             }}
              className={`${props.data === "TopNav" ? classes.searchDropdown : classes.HomesearchDropdown}`} >

              {noProducts ?
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src={noProductFoundImg} alt="bgImage" className={classes.noProductImage} />
                  <Typography className={classes.noProductTitle}>{t("TopNavLng:NoProductFound")}</Typography>
                </div> :

                <>
                  {loading ?
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress
                        style={{ width: "25px", height: "25px" }}
                      />
                    </div>
                    :
                    <>
                    <>
                      {ResultTxt?.map((product: any, index: any) => {                        
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingLeft: `${props.data !== "TopNav" ? 20 : 5}`,
                              paddingRight: `${props.data !== "TopNav" ? 20 : 5}`,
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              if(product.attributes.product_rating === 'NA' || null){
                                if (props?.history?.location?.pathname === "/norated") {                                  
                                  goToNoRatedPage(product);                             
                                  await localStorage.setItem("reloadValue",searchTxt);
                                  window.location.reload();
                                }
                                else {                                  
                                  goToNoRatedPage(product);                                       
                                }                                
                              }else{
                                localStorage.setItem("proId", product?.id);
                                localStorage.setItem("reloadValue",searchTxt);
                                dropDownClose();
                                if (props?.history?.location?.pathname == "/ProductDetails") {                                
                                props?.history?.push({                                
                                  search: `${product?.id}`,                                  
                                });                                    
                                window.location.reload();
                              }
                                else {
                                  props?.history?.push({
                                    pathname: '/ProductDetails',
                                    search: `${product?.id}`,
                                    state: { searchName: `${product?.id}` }
                                  });                                  
                                }
                              }
                            }}
                            key={product?.id}
                          >
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 5, }}>
                              <div style={{ marginTop: 10 }}>
                                <Typography
                                  style={{
                                    color: "#455A64",
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "14px",
                                    letterSpacing: "0em",
                                    textAlign: "left",
                                  }}
                                >
                                  {product.attributes.product_name}                                  
                                </Typography>

                                <Typography
                                  style={{
                                    color: "#455A64",
                                    fontFamily: "Montserrat",
                                    fontSize: "8px",
                                    fontWeight: 600,
                                    lineHeight: "7px",
                                    textAlign: "left"
                                  }}>
                                  Category:{product.attributes.category_type}
                                </Typography>
                              </div>

                              <ArrowRightAltIcon style={{ color: "#455A64" }} />
                            </div>
                          </div>
                        );
                      })}
</>
                    <div style={{textAlign:"center"}}>
                    <Button className={classes.loadMoreBtn} variant="text" onClick={handleLoadMore}
                    disabled={pagination?.current_page === pagination?.total_pages ? true : false}
                    >Load More</Button>
                    </div>  
                    </>
                  }
                </>
              }
            </div>
          )}
      {/* @ts-ignore */}
      <Modal
      open={camModal}
      onClose={() => {  handleCloseCam(); window.location.reload(); }}
        style={{
          width: 'fit-content',
          margin: "50px auto",
          zIndex:9999,
        }}
      >
      <>
      <Paper style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 10 }}>
        <>
        {/* @ts-ignore */}
        <Scanner onDetected={handleBarcodeResult} />
        </>
        <>
          <Button onClick={() => {handleCloseCam(); window.location.reload(); }}
           style={{ textTransform: "none", background: "#38006D", color: "#fff", marginTop: 5 }} size="small"
           variant="contained">{t("TopNavLng:stop")}</Button>
          </>
        </Paper>        
      </>
      </Modal>
  </div>
            
  );
};
export default withRouter(CustomSearch);