import React from 'react'
import { Typography, Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles: any = makeStyles((theme) => ({
    scrollingContainer: {
        maxHeight: 640,
        overflow: "hidden",
        overflowY: "scroll",
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.00)',
        }
    },
    linearGradientBorderColorContainer: {
        margin: '1.5rem 0',
        border: "double 2px transparent",
        borderRadius: '10px',
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(6deg,#38006F,#FF5050)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        width: '100%',
        cursor: 'pointer'
    },
    card: {
        margin: 10,
        border: '0.4px solid #ADADAD',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
        borderRadius: '4px', width: 75,
        height: 96,
        [theme.breakpoints.down("xs")]: {
            margin: 5,
            width: 46,
            height: 56,
        },
    },
    media: {
        width: "auto",
        height: 96,
        [theme.breakpoints.down("xs")]: {
            width: "auto",
            height: 56,
        },
    },
    productName: {
        color: '#455A64',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600,
        fontSize: '24px',
        [theme.breakpoints.only("xs")]: {
            fontSize: '15px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '17px',
        },
    },
    productStatus: {
        color: '#ADADAD',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600,
        fontSize: '16px',
        textTransform: "capitalize",
        [theme.breakpoints.only("xs")]: {
            fontSize: '12px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '14px',
        },
    },
    reportText: {
        color: '#FF5050',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600,
        fontSize: '15px',
        [theme.breakpoints.only("xs")]: {
            fontSize: '11px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '11px',
        },
    },
    reportDate: {
        color: '#FF5050',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600,
        fontSize: '15px',
        [theme.breakpoints.only("xs")]: {
            fontSize: '11px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '11px',
        },
    },
    NoProductNofificationText: {
        color: '#FF3939',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '23px',
        // textTransform: 'capitalize',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        },
    },

}));

interface Props {
    reportedProductLists: any;
    formatTheDate: (data: any) => any;
    pageProps: any
}

export const ProductReportedLists = ({ reportedProductLists, formatTheDate, pageProps }: Props) => {
    const classes = useStyles();
    const [t] = useTranslation(["ProductDetailsLang"]);
    return (
        <>
            {
                reportedProductLists.length > 0 ? <div style={{ margin: '1rem 0' }} className={classes.scrollingContainer}>
                    {reportedProductLists.map((item: any, key: any) => {
                        return (
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                                className={classes.linearGradientBorderColorContainer}
                                spacing={2}
                                key={key}
                                onClick={() => {
                                    pageProps.navigation.history.push({ pathname: "/ProductReportFormWebPage", state: { item }, search: `?view=${item?.id}` })
                                }}
                            >
                                <Grid item xs={3} md={2} lg={1} container justifyContent="center" style={{ display: 'flex' }}>
                                    <Card style={{
                                    }} className={classes.card}>
                                        <CardMedia
                                            className={classes.media}
                                            image={item?.attributes?.product?.data?.attributes?.image}
                                        />
                                    </Card>
                                </Grid>

                                <Grid item xs={9} md={10} lg={11} sm container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item xs={12} md={10} lg={10} container direction="column" spacing={2}>
                                        <Typography className={classes.productName}>{item?.attributes?.product?.data?.attributes?.product_name}</Typography>
                                        <Typography className={classes.productStatus}>{t("Status")}: {item?.attributes?.status}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2} lg={2} container direction="column" spacing={2}>
                                        <Typography className={classes.reportText}>
                                            {t("Reported_On")}
                                        </Typography>
                                        <Typography className={classes.reportDate}>{formatTheDate(item?.attributes?.created_at)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </div> : <Typography className={classes.NoProductNofificationText}>{t("You_did_not_report_any_product")}</Typography>
            }
        </>

    )
}
