import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
    getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    t: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
    loading: boolean;
    addFoodBasketloader: boolean;
    foodBasketListLoader: boolean;
    foodProductDetailsLoader: boolean;
    foodBasketLists: any;
    foodProductDetails: any;
    foodProductNutritionValue: any;
    addFoodBasketErrorText: any;
    noFoodBasketNotification: any;
    openFoodBasketDeleteModal: boolean;
    openIndividualFoodBasketProductDeleteModal: boolean;
    foodBasketDeleteId: any;
    foodOrderId: any;
    foodproductId: any;
    openNotificationModal: any;

    // Customizable Area End
}

interface SS {
    id: any;
}


export default class HealthyFoodBasketWebController extends BlockComponent<Props, S, SS> {
    addToCompareProductsId: string = '';
    getFoodBasketApiCallId: string = '';
    getHealthFoodDetailsApiCallId: string = '';
    deletefoodBasketApiCallId: string = '';
    deletefoodBasketIndividualProductApiCallId: string = '';
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage), //{rf}
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: '',
            loading: false,
            addFoodBasketloader: false,
            foodBasketListLoader: false,
            foodProductDetailsLoader: false,
            foodBasketLists: [],
            foodProductDetails: [],
            foodProductNutritionValue: [],
            addFoodBasketErrorText: '',
            noFoodBasketNotification: '',
            openFoodBasketDeleteModal: false,
            openIndividualFoodBasketProductDeleteModal: false,
            foodBasketDeleteId: '',
            foodOrderId: '',
            foodproductId: '',
            openNotificationModal: false,

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getFoodBasketList();
        const param = new URLSearchParams(window.location.search).get('id')
        if (param)
            this.getHealthProductDetails();
    }


    // Customizable Area Start


    //  <---------- API call for food basket list {rf} ---------->
    getFoodBasketList = () => {
        
        this.setState({
            foodBasketListLoader: true
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFoodBasketApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.foodBasketApiEndPoint}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // delete food basket
    deleteFoodBasket = (id: any) => {        
        this.setState({
            foodBasketListLoader: true,
            foodProductDetailsLoader: true
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deletefoodBasketApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.foodBasketApiEndPoint}/:id?order_id=${id}&language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getHealthProductDetails = () => {        
        this.setState({
            foodProductDetailsLoader: true
        })
        const id = new URLSearchParams(window.location.search).get('id')
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getHealthFoodDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.foodBasketApiEndPoint}/:id?order_id=${id}&language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    deleteIndividualFoodBasketProduct = () => {
          
        const body = {
            order_id: this.state.foodOrderId,
            product_id: this.state.foodproductId,            
        }
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deletefoodBasketIndividualProductApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.removeBasketIndividualProductEndpoint}?language_id=${this.getLanguage()}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.setState({ openIndividualFoodBasketProductDeleteModal: false })
    }

    addToCompareProducts = (proId: any) => {
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': configJSON.applicationJsonContentType,
            token: userToken,
        };

        // rest api call message create
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // save rest api resuest id
        this.addToCompareProductsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addTocompareApiEndPoint}=${proId}&language_id=${this.getLanguage()}`  // end point setup
        );

        // set header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod // api method
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // receive function for API response
    async receive(from: string, message: Message) {

        //  <---------- response for  food basket list {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFoodBasketApiCallId &&
            this.getFoodBasketApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.data) {
                this.setState({
                    foodBasketLists: responseJson?.data,
                    foodBasketListLoader: false,
                    noFoodBasketNotification: ''

                })
            }
            if (responseJson?.error) {
                this.setState({
                    foodBasketListLoader: false,
                    noFoodBasketNotification: responseJson.error,
                    foodBasketLists: []

                })
                // this.props?.navigation?.navigate('NoProudctFound');
            }
            this.setState({
                foodBasketListLoader: false,

            })

        }

        // end for {rf}

        //  <---------- response for  food basket delete api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.deletefoodBasketApiCallId &&
            this.deletefoodBasketApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.message) {
                this.setState({
                    foodBasketListLoader: false,
                    foodProductDetailsLoader: false
                })
                this.getFoodBasketList();
                this.navigationBtnFnc('HealthyFoodBasket');
            }

        }

        // end for {rf}



        //  <---------- response for  food basket product Details {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getHealthFoodDetailsApiCallId &&
            this.getHealthFoodDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson.error) {
                this.setState({
                    foodProductDetailsLoader: false
                })
                this.navigationBtnFnc('HealthyFoodBasket');
            }
            if (responseJson && !responseJson.error) {

                if (responseJson?.product?.data?.attributes?.order_id?.data.length > 0) {
                    this.setState({
                        foodProductDetails: responseJson?.product?.data,
                        foodProductNutritionValue: responseJson.nutrition_value[0],
                        foodProductDetailsLoader: false

                    })
                } else {
                    this.navigationBtnFnc('HealthyFoodBasket')
                }
            }

        }

        // end for {rf}

        //  <---------- response for  food basket delete api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.deletefoodBasketIndividualProductApiCallId &&
            this.deletefoodBasketIndividualProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.message && responseJson?.success === 1) {
                const param = new URLSearchParams(window.location.search).get('id')
                if (param)
                    this.getHealthProductDetails();
            }
        }

        // end for {rf}

        //  <---------- response for  food basket addToCompareProductsId api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.addToCompareProductsId &&
            this.addToCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.data) {
                //   if(this.state.allComparedProducts.length >0 && responseJson.data.attributes.errors){
                //     return this.props.navigation.history.push({pathname:"CompareProducts",state:responseJson.data?.attributes?.errors?.selected})
                //   }else if(this.state.allComparedProducts.length >0){
                return this.props.navigation.history.push({ pathname: "CompareProducts", state: 'no error' })
                //   }
                //   this.getAllCompareProducts();
            }
        }

    }

    backButton = () => {
        this.props?.navigation?.goBack();
    }

    handleModalOpen = (id: any) => {
        this.setState({ openFoodBasketDeleteModal: true, foodBasketDeleteId: id })
    }
    handleIndividualFoodBasketProductDeleteModal = (order_id: any, product_id: any) => {
        this.setState({ openIndividualFoodBasketProductDeleteModal: true, foodOrderId: order_id, foodproductId: product_id })
    }
    handleModalClose = () => {
        this.setState({ openFoodBasketDeleteModal: false, openIndividualFoodBasketProductDeleteModal: false })
    }

    navigationBtnFnc = (path: any) => {
        this.props?.navigation?.navigate(path);
    }

    handleNotificationModalClose = () => {
        this.setState({
            openNotificationModal: false
        })
    }

    handleNotificationModalOpen = () => {
        this.setState({
            openNotificationModal: true
        })
    }


    // function for navigate to the product details page with GetProductDetailsAdapter {adapter} {add by rifadulDev}
    goToProductDetailsPage = (id: any) => {
        localStorage.setItem('proId', id)
        const msg: Message = new Message(getName(MessageEnum.FromProductPageNavigationMessage)); // here nedd to change messageEnum name {adapter} {add by rifadulDev}
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationRaiseMessage), id); // here nedd to change value {adapter} {add by rifadulDev}
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDetails"); // here need to change Route name
        this.send(msg);
    }

    getLanguage = () => {
        let languageId:any;
        const defaultEnglishLanguageId:any = configJSON.defaultLanguageIDEnglish;
        if(localStorage.getItem('languageSelect')) {
          languageId = localStorage.getItem('languageSelect');
        }
        else {
         languageId = defaultEnglishLanguageId;
        }
        return languageId;
      }
    // Customizable Area End
}