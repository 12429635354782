export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const topBanner = require("../assets/topBanner.svg");
export const Rectangle = require("../assets/Rectangle.png");
export const submittedIcon = require("../assets/submitIcon.svg");
export const weightIcon = require("../assets/weightIcon.svg");
export const approvedIcon = require("../assets/approvedIcon.svg");
export const pendingIcon = require("../assets/pendingIcon.svg");
export const rejectIcon = require("../assets/rejectIcon.svg");
export const barcodescanner = require("../assets/barcodescanner.svg");
