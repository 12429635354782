import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
    getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import ApiCall from '../../../../components/src/APICall';

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    t: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    token: string;
    // Customizable Area Start
    loading: boolean;
    switchChecked: boolean;
    reportedProductList: any;
    reportQuestionList: any;
    reportQuestionAnswer: any;
    reportDescription: any;
    productDetails: any;
    checkTheQuestion: any;
    reportQuestionValidation: boolean;
    reportDescriptionValidation: boolean;
    notificationModal: boolean;
    notificationText: any,
    firstCharIsNotLetter: boolean,
    viewReportProductDetails: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
}


export default class ProductReportedWebController extends BlockComponent<Props, S, SS> {
    getReportProductListApiCallId: string = '';
    getQuestionListApiCallId: string = '';
    submitProductReportApiCallId: string = '';
    getProductApiCallId: string = '';
    getReportProductDetailsApiCallId: string = '';
    productId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage), //{rf}
            // Customizable Area End
        ];

        this.state = {
            token: '',
            loading: false,
            switchChecked: false,
            reportedProductList: [],
            reportQuestionList: [],
            reportQuestionAnswer: [],
            reportDescription: '',
            productDetails: [],
            checkTheQuestion: [],
            reportQuestionValidation: false,
            reportDescriptionValidation: false,
            notificationModal: false,
            notificationText: '',
            firstCharIsNotLetter: false,
            viewReportProductDetails: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();

        if ('/ProductReportedWebPage' === this.props?.navigation?.history?.location?.pathname) {
            this.setState({ switchChecked: true })
            this.getReportProductList();
        }
        if ('/ProductReportFormWebPage' === this.props?.navigation?.history?.location?.pathname) {
            const prams = new URLSearchParams(window.location.search).get('proid')
            if (prams) {
                this.getQuestionList();
                this.getProductDetails(prams);
                return
            }

            const reportId = new URLSearchParams(window.location.search).get('view')
            if (reportId) {
                this.handelComFunction(reportId)
                return
            } else {
                this.navigationBtnFnc('ProductReportedWebPage')
            }
        }
    }


    // Customizable Area Start

    // here API calling function {rf}

    handelComFunction = async (reportId: any) => {
        this.getReportProductDetails(reportId);
        this.getQuestionList();
        this.setState({
            viewReportProductDetails: true
        })
    }

    getProductDetails = (id: any) => {
        this.setState({
            loading: true
        })

        const contentType = configJSON.applicationJsonContentType;
        const apiEndPoint = `${configJSON.getProductDetailsEndPoint}/${id}?language_id=${this.getLanguage()}`;
        const apiMethod = configJSON.getApiMethodType
        const apiCallingData = {
            contentType, apiEndPoint, apiMethod
        }
        const requestMessage = ApiCall(apiCallingData);
        this.getProductApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getReportProductList = () => {
        this.setState({
            loading: true
        })

        const contentType = configJSON.applicationJsonContentType;
        const apiEndPoint = `${configJSON.getReportProductsApiEndPoint}?language_id=${this.getLanguage()}`;
        const apiMethod = configJSON.getApiMethodType

        const apiCallingData = {
            contentType, apiEndPoint, apiMethod
        }
        const requestMessage = ApiCall(apiCallingData);

        this.getReportProductListApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getQuestionList = () => {
        this.setState({
            loading: true
        })

        const contentType = configJSON.applicationJsonContentType;
        const apiEndPoint = `${configJSON.getQuestionListApiEndPoint}?language_id=${this.getLanguage()}`;
        const apiMethod = configJSON.getApiMethodType;

        const apiCallingData = {
            contentType, apiEndPoint, apiMethod
        }
        const requestMessage = ApiCall(apiCallingData);

        this.getQuestionListApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    getReportProductDetails = (reportId: any) => {
        this.setState({
            loading: true
        })

        const contentType = configJSON.applicationJsonContentType;
        const apiEndPoint = `${configJSON.getReportProductDetailsApiEndPoint}/?id=${reportId}/?language_id=${this.getLanguage()}`;
        const apiMethod = configJSON.getApiMethodType;

        const apiCallingData = {
            contentType, apiEndPoint, apiMethod
        }
        const requestMessage = ApiCall(apiCallingData);

        this.getReportProductDetailsApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    reportValidationCheck() {
        if (this.state.reportQuestionAnswer.length < this.state.reportQuestionList.length) {
            this.setState({
                reportQuestionValidation: true
            })
        } else if (this.state.reportDescription.length === 0) {
            this.setState({
                reportDescriptionValidation: true
            })
        }
        else {
            this.setState({
                reportQuestionValidation: false,
                reportDescriptionValidation: false
            })
            return true;
        }
    }

    submitProductReport = async (productId: any) => {
        if (this.reportValidationCheck()) {
            const userToken = await localStorage.getItem("token");
            const header = {
                token: userToken,
            };

            const formData = new FormData();
            formData.append("product_id", productId);
            if (this.state.reportQuestionAnswer) {
                this.state.reportQuestionAnswer.map((id: any) => {
                    formData.append("ans_ids[]", id);
                })
            }
            formData.append("description", this.state.reportDescription);
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.submitProductReportApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postAPiMethod);
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.submitProductReportApiEndPoint}?language_id=${this.getLanguage()}`);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }

    }

    // receive function for API response
    async receive(from: string, message: Message) {


        //  <---------- response for  get reported product list from api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getReportProductListApiCallId &&
            this.getReportProductListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.getReportProductListApiResponse(responseJson);
        }

        // end for {rf}

        //  <---------- response for  get question list from api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getQuestionListApiCallId &&
            this.getQuestionListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.getQuestionListApiResponse(responseJson);
        }

        // end for {rf}
        //  <---------- response for  submit product report from api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.submitProductReportApiCallId &&
            this.submitProductReportApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.submitProductReportApiResponse(responseJson);
        }

        // end for {rf}
        //  <---------- response for  get product details from api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getProductApiCallId &&
            this.getProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.getProductApiResponse(responseJson);
        }
        // end for {rf}
        //  <---------- response for  get report product details from api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getReportProductDetailsApiCallId &&
            this.getReportProductDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            console.log('responseJson fron recive', responseJson?.data);

            this.getReportProductApiResponse(responseJson);
        }
        // end for {rf}

    }


    // <-------------------------- Api responses functionalities {rf} -------------------------->

    getReportProductListApiResponse = (responseJson: any) => {
        if (responseJson?.data && responseJson?.data.length > 0) {
            this.setState({
                reportedProductList: responseJson?.data,
                loading: false
            })
        }
        else {
            this.setState({
                loading: false
            })
        }
    }
    getQuestionListApiResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({
                reportQuestionList: responseJson?.data,
                loading: false,
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }
    submitProductReportApiResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({
                loading: false,
                notificationModal: true,
            })
        } else {
            this.setState({
                loading: false
            })
        }
        if (responseJson?.error) {
            this.setState({
                loading: false,
                notificationModal: true,
                notificationText: responseJson?.error,
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }
    getProductApiResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({
                productDetails: responseJson?.data,
                loading: false,
            })
        } else {
            this.setState({
                loading: false
            })
        }
        if (responseJson?.errors) {
            this.setState({
                loading: false
            })
            this.navigationBtnFnc("SmartSearch")
        } else {
            this.setState({
                loading: false
            })
        }
    }

    getReportProductApiResponse = (responseJson: any) => {
        if (responseJson?.data && responseJson?.data?.attributes?.product?.data && responseJson?.data?.attributes?.description && responseJson?.data?.attributes?.ans_ids) {
            this.setState({
                productDetails: responseJson?.data?.attributes?.product?.data,
                reportDescription: responseJson?.data?.attributes?.description,
                reportQuestionAnswer: responseJson?.data?.attributes?.ans_ids,
                loading: false
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }


    // <-------------------------- Here needed helper functionalities {rf} -------------------------->

    formatTheDate = (date: any) => {
        let newDate = new Date(date);
        /* Date format you have */
        let dateMDY = `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
        return dateMDY;
    }

    toggleChecked = () => {
        this.state.switchChecked ? this.setState({
            switchChecked: false
        }, () => { this.navigationBtnFnc("YourProductWeb"); }) : this.setState({
            switchChecked: true
        }, () => { this.navigationBtnFnc("ProductReportedWebPage"); })
    };

    handelReportQuestionAnswer = (event: any, question: any) => {
        const answerId = event.target.value
        const indexNumber = this.state.checkTheQuestion.indexOf(question)

        if (indexNumber != -1) {
            this.state.reportQuestionAnswer[indexNumber] = answerId;

        } else {
            this.setState(prevState => ({
                reportQuestionAnswer: [...prevState.reportQuestionAnswer, answerId],
                reportQuestionValidation: false
            }))
        }
        this.setState(prevState => ({
            checkTheQuestion: [...prevState.checkTheQuestion, question]
        }))

    }

    backButton = () => {
        this.props?.navigation?.goBack();
    }

    navigationBtnFnc = (path: any) => {
        this.props?.navigation?.navigate(path);
    }
    getLanguage = () => {
        let languageId: any;
        const defaultEnglishLanguageId: any = configJSON.defaultLanguageIDEnglish
        if (localStorage.getItem('languageSelect')) {
            languageId = localStorage.getItem('languageSelect');
        }
        else {
            languageId = defaultEnglishLanguageId;
        }
        return languageId;
    }

    //  functionality for handel form {rf}

    handelreportDescription = (event: any) => {
        const input = event.target.value;
        const firstChar = input.charAt(0);
        const isLetter = /^[a-zA-Z]$/.test(firstChar);
        if (!isLetter) {
            this.setState({
                firstCharIsNotLetter: true,
                reportDescription: '',
            })
        } else {
            this.setState({
                reportDescription: event.target.value,
                reportDescriptionValidation: false,
                firstCharIsNotLetter: false,
            })
        }
    }

    // Customizable Area End
}