import React from 'react'
import { Divider } from "@material-ui/core";
import {
    Grid,
    Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    recommendationText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        color: "#455A64",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            fontSize: '15px',
        },

    },
    recommendationContainer: {
        border: "double 2px transparent",
        borderRadius: 10,
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(180deg,#440A70,#FF3939)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 16,
        },
    },
    recommendationContainerItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    productRecommendatedText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '30px',
        color: "#008D59",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: '11px',
        },
    },
    divaiderContainer: {
        display: "flex",
        justifyContent: "center"
    },
    circleDiv: {
        width: '13px',
        height: '13px',
        backgroundColor: "#00AB79",
        borderRadius: '50%',
        marginBottom: '10px'
    },
    divider: {
        backgroundColor: "#455A64",
        // width: '2px',
        height: '100px',
        [theme.breakpoints.down("sm")]: {
            // width: '1.5px',
            height: '80px'
        },
    }

}));

const ProductRecommendationForSmartSearch = () => {
    const classes = useStyles();
    const [t] = useTranslation(["ProductDetailsLang"]);

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            style={{
                marginTop: '2rem'
            }}
        >
            <Typography className={classes.recommendationText}>{t("Nutritional_consideration_for_recommending_for_your_smart_search")}</Typography>
            <div className={classes.recommendationContainer} style={{ margin: '1.5rem 0' }}>
                <div style={{
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}>
                    <div className={classes.recommendationContainerItem}>
                        <div className={classes.circleDiv}></div>
                        <Typography className={classes.productRecommendatedText}>{t("High Immunonutrients")}
                            <br /> {t("Vitamins_Minerals")}
                        </Typography>
                    </div>
                    <div className={classes.divaiderContainer}>
                        <Divider orientation="vertical" className={classes.divider} />
                    </div>
                    <div className={classes.recommendationContainerItem}>
                        <div className={classes.circleDiv}></div>
                        <Typography className={classes.productRecommendatedText}>{t("High_in")} <br /> {t("Protein")}</Typography>
                    </div>

                    <div className={classes.divaiderContainer}>
                        <Divider orientation="vertical" className={classes.divider} />
                    </div>

                    <div className={classes.recommendationContainerItem}>
                        <div className={classes.circleDiv} style={{ backgroundColor: '#FF3939' }}></div>
                        <Typography className={classes.productRecommendatedText} style={{ color: '#FF3939' }}>{t("High_in")} <br /> {t("Sodium")}
                        </Typography>
                    </div>

                    <div className={classes.divaiderContainer}>
                        <Divider orientation="vertical" className={classes.divider} />
                    </div>

                    <div className={classes.recommendationContainerItem}>
                        <div className={classes.circleDiv}></div>
                        <Typography className={classes.productRecommendatedText}>{t("High_in")} <br /> {t("Fiber")}</Typography>
                    </div>
                </div>

            </div>

        </Grid>
    )
}

export default ProductRecommendationForSmartSearch