import React, { useCallback,memo } from "react";
//Customise area start
import { CircularProgress, Fade} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

const Niqlogo = require("../assets/Niqlogo.png") as string;

//Customise area stop
const CustomLoaderWeb = ({...filterData}: any) => {
  //Customise area start
    return ( 
    <>
    {/* @ts-ignore */}
  <Modal
    open={filterData?.favSearchLoader}                    
    aria-labelledby="Custom Loader"
    aria-describedby="Custom Loader module with Niq logo around with circular progress"
    style={{display:"flex", justifyContent:"center", alignItems:"center",background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.65) 3.11%, rgba(38, 2, 37, 0.65) 91.84%',}}  
    closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 1500,}} >
    <Fade in={filterData?.favSearchLoader}>
        <div style={{position:"relative", display:"inline-flex"}}>
            <CircularProgress size={150} style={{color:"#12125c",}} thickness={2} />                    
        <div style={{top:0,left:0,bottom:0,right:0,position:"absolute",display:"flex",alignItems:"center",justifyContent:"center",}}>
            <img src={Niqlogo} alt='' width={100} height={"auto"}/>
        </div>
        </div>                   
    </Fade>                                                                                               
    </Modal>
    </>
  );
};
export default CustomLoaderWeb;
 // Customise area stop