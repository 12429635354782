Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdvancedSearch";
exports.labelBodyText = "AdvancedSearch Body";
exports.getFilterTypeEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.getCategoryTypeEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.getSubCategoryTypeEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.getNiqScoreEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.getFoodAllergiesEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.getFoodPreferenceEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.getFunctionalPreferenceEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.getHealthPreferenceEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.getFavouriteSearchEndPoint = "bx_block_catalogue/favourite_searches";
exports.postFavouriteSearchEndPoint = "bx_block_catalogue/favourite_searches";
exports.updateFavouriteSearchEndPoint = "bx_block_catalogue/favourite_searches/";
exports.deleteFavouriteSearchEndPoint = "bx_block_catalogue/favourite_searches/";
exports.getSearchResultsApiCallIDEndPoint = "bx_block_catalogue/products/product_smart_search";
exports.deleteAPiMethod = "Delete";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End