import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles: any = makeStyles((theme) => ({
    scrollingContainer: {
        maxHeight: 640,
        overflow: "hidden",
        overflowY: "scroll",
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.00)',
        },
        margin: '2rem 0'
    },
}));
interface Props {
    children: any
}
const ScrollingContainer: React.FunctionComponent<Props> = (props: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.scrollingContainer}>
            {props.children}
        </div>
    );
};

export default ScrollingContainer;
