import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
    getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    t: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
    loading: boolean;
    addFoodBasketloader: boolean;
    foodBasketListLoader: boolean;
    foodBasketLists: any;
    switchChecked: boolean;
    personName: string;
    bmiProfileList: any;
    memberData: any;
    bmiResult: any;
    heightBmi: any;
    weightBmi: any;
    openDeleteModalBox: boolean;
    deleteId: any;
    BmiProfileDetails: any;
    errorReponse: any;
    // Customizable Area End
}

interface SS {
    id: any;
}


export default class BmiCalculatorWebController extends BlockComponent<Props, S, SS> {
    getBmiProfileListApiCallID: string = '';
    getBmiCalulateApiCallID: string = '';
    deleteProfileMemberApiCallId: string = '';
    getBmiNewMemberProfileDetailsApiCallID: string = '';
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage), //{rf}
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: '',
            loading: false,
            addFoodBasketloader: false,
            foodBasketListLoader: false,
            foodBasketLists: [],
            switchChecked: false,
            personName: 'Select',
            bmiProfileList: [],
            BmiProfileDetails: [],
            bmiResult: {},
            heightBmi: 0,
            weightBmi: 0,
            memberData: {},
            openDeleteModalBox: false,
            deleteId: '',
            errorReponse: false,


        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();

        if (this.props?.navigation?.history?.location?.pathname === "/BmiCalculatorDashboardWebPage" || "BmiCalculatorDashboardWebPage" || "BmiSignAndSymptomsWebPage") {
            this.setState({ switchChecked: false })
        }
        if (this.props?.navigation?.history?.location?.pathname === "/BmiCalculatorPageWeb") {
            this.getBmiProfileList();
        }
        if (this.props?.navigation?.history?.location?.pathname === "/BmiSignAndSymptomsWebPage" || "/BmiCalculatorDashboardWebPage") {
            const param = this.props.navigation?.history?.location?.state
            if (param)
                this.getBmiNewMemberProfileDetails(param);
        }
    }

    // Customizable Area Start


    // <-------------------- API Call for BMI Profile List --------------------->
    getBmiProfileList = () => {
        this.setState({ loading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBmiProfileListApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getBmiProfileListApiCallIDEndPoint}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
    }

    // <-------------------- API Call for BMI Profile List --------------------->
    getBmiCalulation = (profile_id: any, height: number, weight: number) => {
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const body =
        {
            "profile_id": profile_id,
            "height": height,
            "weight": weight
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBmiCalulateApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postAPiMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getBmiCalulationApiCallIDEndPoint}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
    }

    //  <---------- API call for delete profile member {rf} ---------->
    deleteProfileMember = async (id: any) => {
        const userToken = await localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteProfileMemberApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.deleteProfileMemberApiEndPoint}/${this.state.deleteId}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBmiNewMemberProfileDetails = (id: any) => {
        this.setState({ loading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBmiNewMemberProfileDetailsApiCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getBmiProfileListApiCallIDEndPoint}/${id}?language_id=${this.getLanguage()}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
    }



    // receive function for API response
    async receive(from: string, message: Message) {


        //  <---------- response for get List of Profiles type  ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getBmiProfileListApiCallID &&
            this.getBmiProfileListApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.getBmiProfileListApiResponse(responseJson);
        }

        //  <---------- response for  BMi calculation  ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getBmiCalulateApiCallID &&
            this.getBmiCalulateApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.getBmiCalulateApiResponse(responseJson);
        }

        //  <---------- response for  delete profile member api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.deleteProfileMemberApiCallId &&
            this.deleteProfileMemberApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.deleteProfileMemberApiResponse(responseJson);
        }


        //  <---------- response for  BMI new member details api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getBmiNewMemberProfileDetailsApiCallID &&
            this.getBmiNewMemberProfileDetailsApiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.getBmiNewMemberProfileDetailsApiResponse(responseJson);
        }
    }



    // <-------------------------- Api responses functionalities {rf} -------------------------->

    getBmiProfileListApiResponse = (responseJson: any) => {
        if (responseJson) {
            this.setState({ bmiProfileList: responseJson?.data, loading: false });
        }
        if (responseJson?.error) {
            this.setState({ errorReponse: true, loading: false })
        }
    }
    getBmiCalulateApiResponse = (responseJson: any) => {
        if (responseJson) {
            this.setState({
                BmiProfileDetails: responseJson?.data,
                heightBmi: responseJson?.data?.attributes?.height,
                weightBmi: responseJson?.data?.attributes?.weight,

            })
        }
        this.setState({ loading: false })

        if (responseJson?.error) {
            this.setState({ errorReponse: true, loading: false })
        }
    }
    deleteProfileMemberApiResponse = (responseJson: any) => {
        if (responseJson?.success) {
            this.handleModalClose()
            if ('/BmiCalculatorPageWeb' === this.props?.navigation?.history?.location?.pathname) {
                this.getBmiProfileList();
            }
        } else {
            this.setState({ loading: false })
        }
        this.handleModalClose()
    }
    getBmiNewMemberProfileDetailsApiResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({
                BmiProfileDetails: responseJson?.data,
                heightBmi: responseJson?.data?.attributes?.height,
                weightBmi: responseJson?.data?.attributes?.weight,
                loading: false
            })
        } else {
            this.setState({ loading: false })
        }
        if (responseJson?.error) {
            this.setState({ errorReponse: true, loading: false })
        }
    }

    // <-------------------------- Here needed helper functionalities {rf} -------------------------->

    toggleChecked = () => {
        this.state.switchChecked ? this.setState({ switchChecked: false }, () => { this.navigationBtnFnc("BmiCalculatorPageWeb"); }) :
            this.setState({ switchChecked: true }, () => { this.navigationBtnFnc("NutritionGapCalculatorWeb"); })
    };

    backButton = () => {
        this.props?.navigation?.goBack();
    }


    navigationBtnFnc = (path: any) => {
        this.props?.navigation?.navigate(path);
    }

    sliderChange = (event: any, newValue: number | number[], slider: any) => {
        if (slider == "weight") { this.setState({ weightBmi: newValue }); }
        if (slider == "height") { this.setState({ heightBmi: newValue }) }
        this.debouncedLog();
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>, slider: any) => {
        if (slider == "weight") { this.setState({ weightBmi: event.target.value === '' ? '' : Number(event.target.value) }) }
        if (slider == "height") { this.setState({ heightBmi: event.target.value === '' ? '' : Number(event.target.value) }) }
        this.debouncedLog();
    };

    loadData = () => {
        this.setState({
            heightBmi: this.props.navigation?.history?.location?.state?.bmiProfile?.attributes?.height,
            weightBmi: this.props.navigation?.history?.location?.state?.bmiProfile?.attributes?.weight,
            bmiResult: this.props.navigation?.history?.location?.state?.bmiProfile
        })
    }

    postBmiData = () => {
        const param = this.props.navigation?.history?.location?.state
        if (param) {
            this.getBmiCalulation(
                param,
                this.state.heightBmi,
                this.state.weightBmi
            );
        }

    }

    debounce = (callback: any, delay: any) => {
        let timer: any;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => callback(...args), delay)
        }
    }

    debouncedLog = this.debounce(() => this.postBmiData(), 2000);

    handleDeleteModalOpen = (id: any) => {
        this.setState({ openDeleteModalBox: true, deleteId: id })
    }

    handleModalClose = () => {
        this.setState({ openDeleteModalBox: false, deleteId: '' })
    }
    getLanguage = () => {
        let languageId:any;
        const defaultEnglishLanguageId:any = configJSON.defaultLanguageIDEnglish        
        if(localStorage.getItem('languageSelect')) {
          languageId = localStorage.getItem('languageSelect');
        }
        else {
         languageId = defaultEnglishLanguageId;
        }
        return languageId;
      }

    // Customizable Area End
}