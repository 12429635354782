Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.phones = {
  "AF":{"primary":"+93","secondary":"Afghanistan",},
  "AL":{"primary":"+355","secondary":"Albania",},
  "DZ":{"primary":"+213","secondary":"Algeria",},
  "AS":{"primary":"+1-684","secondary":"American Samoa",},
  "AD":{"primary":"+376","secondary":"Andorra",},
  "AO":{"primary":"+244","secondary":"Angola",},
  "AI":{"primary":"+1-264","secondary":"Anguilla",},
  "AQ":{"primary":"+672","secondary":"Antarctica",},
  "AG":{"primary":"+1-268","secondary":"Antigua and Barbuda",},
  "AR":{"primary":"+54","secondary":"Argentina",},
  "AM":{"primary":"+374","secondary":"Armenia",},
  "AW":{"primary":"+297","secondary":"Aruba",},
  "AU":{"primary":"+61","secondary":"Australia",},
  "AT":{"primary":"+43","secondary":"Austria",},
  "AZ":{"primary":"+994","secondary":"Azerbaijan",},
  "BS":{"primary":"+1-242","secondary":"Bahamas",},
  "BH":{"primary":"+973","secondary":"Bahrain",},
  "BD":{"primary":"+880","secondary":"Bangladesh",},
  "BB":{"primary":"+1-246","secondary":"Barbados",},
  "BY":{"primary":"+375","secondary":"Belarus",},
  "BE":{"primary":"+32","secondary":"Belgium",},
  "BZ":{"primary":"+501","secondary":"Belize",},
  "BJ":{"primary":"+229","secondary":"Benin",},
  "BM":{"primary":"+1-441","secondary":"Bermuda",},
  "BT":{"primary":"+975","secondary":"Bhutan",},
  "BO":{"primary":"+591","secondary":"Bolivia",},
  "BA":{"primary":"+387","secondary":"Bosnia and Herzegovina",},
  "BW":{"primary":"+267","secondary":"Botswana",},
  "BR":{"primary":"+55","secondary":"Brazil",},
  "IO":{"primary":"+246","secondary":"British Indian Ocean Territory",},
  "VG":{"primary":"+1-284","secondary":"British Virgin Islands",},
  "BN":{"primary":"+673","secondary":"Brunei",},
  "BG":{"primary":"+359","secondary":"Bulgaria",},
  "BF":{"primary":"+226","secondary":"Burkina Faso",},
  "BI":{"primary":"+257","secondary":"Burundi",},
  "KH":{"primary":"+855","secondary":"Cambodia",},
  "CM":{"primary":"+237","secondary":"Cameroon",},
  "CA":{"primary":"+1","secondary":"Canada",},
  "CV":{"primary":"+238","secondary":"Cape Verde",},
  "KY":{"primary":"+1-345","secondary":"Cayman Islands",},
  "CF":{"primary":"+236","secondary":"Central African Republic",},
  "TD":{"primary":"+235","secondary":"Chad",},
  "CL":{"primary":"+56","secondary":"Chile",},
  "CN":{"primary":"+86","secondary":"China",},
  "CX":{"primary":"+61","secondary":"Christmas Island",},
  "CC":{"primary":"+61","secondary":"Cocos Islands",},
  "CO":{"primary":"+57","secondary":"Colombia",},
  "KM":{"primary":"+269","secondary":"Comoros",},
  "CK":{"primary":"+682","secondary":"Cook Islands",},
  "CR":{"primary":"+506","secondary":"Costa Rica",},
  "HR":{"primary":"+385","secondary":"Croatia",},
  "CU":{"primary":"+53","secondary":"Cuba",},
  "CW":{"primary":"+599","secondary":"Curacao",},
  "CY":{"primary":"+357","secondary":"Cyprus",},
  "CZ":{"primary":"+420","secondary":"Czech Republic",},
  "CD":{"primary":"+243","secondary":"Democratic Republic of the Congo",},
  "DK":{"primary":"+45","secondary":"Denmark",},
  "DJ":{"primary":"+253","secondary":"Djibouti",},
  "DM":{"primary":"+1-767","secondary":"Dominica",},
  "DO":{"primary":"+1-809, 1-829, 1-849","secondary":"Dominican Republic",},
  "TL":{"primary":"+670","secondary":"East Timor",},
  "EC":{"primary":"+593","secondary":"Ecuador",},
  "EG":{"primary":"+20","secondary":"Egypt",},
  "SV":{"primary":"+503","secondary":"El Salvador",},
  "GQ":{"primary":"+240","secondary":"Equatorial Guinea",},
  "ER":{"primary":"+291","secondary":"Eritrea",},
  "EE":{"primary":"+372","secondary":"Estonia",},
  "ET":{"primary":"+251","secondary":"Ethiopia",},
  "FK":{"primary":"+500","secondary":"Falkland Islands",},
  "FO":{"primary":"+298","secondary":"Faroe Islands",},
  "FJ":{"primary":"+679","secondary":"Fiji",},
  "FI":{"primary":"+358","secondary":"Finland",},
  "FR":{"primary":"+33","secondary":"France",},
  "PF":{"primary":"+689","secondary":"French Polynesia",},
  "GA":{"primary":"+241","secondary":"Gabon",},
  "GM":{"primary":"+220","secondary":"Gambia",},
  "GE":{"primary":"+995","secondary":"Georgia",},
  "DE":{"primary":"+49","secondary":"Germany",},
  "GH":{"primary":"+233","secondary":"Ghana",},
  "GI":{"primary":"+350","secondary":"Gibraltar",},
  "GR":{"primary":"+30","secondary":"Greece",},
  "GL":{"primary":"+299","secondary":"Greenland",},
  "GD":{"primary":"+1-473","secondary":"Grenada",},
  "GU":{"primary":"+1-671","secondary":"Guam",},
  "GT":{"primary":"+502","secondary":"Guatemala",},
  "GG":{"primary":"+44-1481","secondary":"Guernsey",},
  "GN":{"primary":"+224","secondary":"Guinea",},
  "GW":{"primary":"+245","secondary":"Guinea-Bissau",},
  "GY":{"primary":"+592","secondary":"Guyana",},
  "HT":{"primary":"+509","secondary":"Haiti",},
  "HN":{"primary":"+504","secondary":"Honduras",},
  "HK":{"primary":"+852","secondary":"Hong Kong",},
  "HU":{"primary":"+36","secondary":"Hungary",},
  "IS":{"primary":"+354","secondary":"Iceland",},
  "IN":{"primary":"+91","secondary":"India",},
  "ID":{"primary":"+62","secondary":"Indonesia",},
  "IR":{"primary":"+98","secondary":"Iran",},
  "IQ":{"primary":"+964","secondary":"Iraq",},
  "IE":{"primary":"+353","secondary":"Ireland",},
  "IM":{"primary":"+44-1624","secondary":"Isle of Man",},
  "IL":{"primary":"+972","secondary":"Israel",},
  "IT":{"primary":"+39","secondary":"Italy",},
  "CI":{"primary":"+225","secondary":"Ivory Coast",},
  "JM":{"primary":"+1-876","secondary":"Jamaica",},
  "JP":{"primary":"+81","secondary":"Japan",},
  "JE":{"primary":"+44-1534","secondary":"Jersey",},
  "JO":{"primary":"+962","secondary":"Jordan",},
  "KZ":{"primary":"+7","secondary":"Kazakhstan",},
  "KE":{"primary":"+254","secondary":"Kenya",},
  "KI":{"primary":"+686","secondary":"Kiribati",},
  "XK":{"primary":"+383","secondary":"Kosovo",},
  "KW":{"primary":"+965","secondary":"Kuwait",},
  "KG":{"primary":"+996","secondary":"Kyrgyzstan",},
  "LA":{"primary":"+856","secondary":"Laos",},
  "LV":{"primary":"+371","secondary":"Latvia",},
  "LB":{"primary":"+961","secondary":"Lebanon",},
  "LS":{"primary":"+266","secondary":"Lesotho",},
  "LR":{"primary":"+231","secondary":"Liberia",},
  "LY":{"primary":"+218","secondary":"Libya",},
  "LI":{"primary":"+423","secondary":"Liechtenstein",},
  "LT":{"primary":"+370","secondary":"Lithuania",},
  "LU":{"primary":"+352","secondary":"Luxembourg",},
  "MO":{"primary":"+853","secondary":"Macao",},
  "MK":{"primary":"+389","secondary":"Macedonia",},
  "MG":{"primary":"+261","secondary":"Madagascar",},
  "MW":{"primary":"+265","secondary":"Malawi",},
  "MY":{"primary":"+60","secondary":"Malaysia",},
  "MV":{"primary":"+960","secondary":"Maldives",},
  "ML":{"primary":"+223","secondary":"Mali",},
  "MT":{"primary":"+356","secondary":"Malta",},
  "MH":{"primary":"+692","secondary":"Marshall Islands",},
  "MR":{"primary":"+222","secondary":"Mauritania",},
  "MU":{"primary":"+230","secondary":"Mauritius",},
  "YT":{"primary":"+262","secondary":"Mayotte",},
  "MX":{"primary":"+52","secondary":"Mexico",},
  "FM":{"primary":"+691","secondary":"Micronesia",},
  "MD":{"primary":"+373","secondary":"Moldova",},
  "MC":{"primary":"+377","secondary":"Monaco",},
  "MN":{"primary":"+976","secondary":"Mongolia",},
  "ME":{"primary":"+382","secondary":"Montenegro",},
  "MS":{"primary":"+1-664","secondary":"Montserrat",},
  "MA":{"primary":"+212","secondary":"Morocco",},
  "MZ":{"primary":"+258","secondary":"Mozambique",},
  "MM":{"primary":"+95","secondary":"Myanmar",},
  "NA":{"primary":"+264","secondary":"Namibia",},
  "NR":{"primary":"+674","secondary":"Nauru",},
  "NP":{"primary":"+977","secondary":"Nepal",},
  "NL":{"primary":"+31","secondary":"Netherlands",},
  "AN":{"primary":"+599","secondary":"Netherlands Antilles",},
  "NC":{"primary":"+687","secondary":"New Caledonia",},
  "NZ":{"primary":"+64","secondary":"New Zealand",},
  "NI":{"primary":"+505","secondary":"Nicaragua",},
  "NE":{"primary":"+227","secondary":"Niger",},
  "NG":{"primary":"+234","secondary":"Nigeria",},
  "NU":{"primary":"+683","secondary":"Niue",},
  "KP":{"primary":"+850","secondary":"North Korea",},
  "MP":{"primary":"+1-670","secondary":"Northern Mariana Islands",},
  "NO":{"primary":"+47","secondary":"Norway",},
  "OM":{"primary":"+968","secondary":"Oman",},
  "PK":{"primary":"+92","secondary":"Pakistan",},
  "PW":{"primary":"+680","secondary":"Palau",},
  "PS":{"primary":"+970","secondary":"Palestine",},
  "PA":{"primary":"+507","secondary":"Panama",},
  "PG":{"primary":"+675","secondary":"Papua New Guinea",},
  "PY":{"primary":"+595","secondary":"Paraguay",},
  "PE":{"primary":"+51","secondary":"Peru",},
  "PH":{"primary":"+63","secondary":"Philippines",},
  "PN":{"primary":"+64","secondary":"Pitcairn",},
  "PL":{"primary":"+48","secondary":"Poland",},
  "PT":{"primary":"+351","secondary":"Portugal",},
  "PR":{"primary":"+1-787, 1-939","secondary":"Puerto Rico",},
  "QA":{"primary":"+974","secondary":"Qatar",},
  "CG":{"primary":"+242","secondary":"Republic of the Congo",},
  "RE":{"primary":"+262","secondary":"Reunion",},
  "RO":{"primary":"+40","secondary":"Romania",},
  "RU":{"primary":"+7","secondary":"Russia",},
  "RW":{"primary":"+250","secondary":"Rwanda",},
  "BL":{"primary":"+590","secondary":"Saint Barthelemy",},
  "SH":{"primary":"+290","secondary":"Saint Helena",},
  "KN":{"primary":"+1-869","secondary":"Saint Kitts and Nevis",},
  "LC":{"primary":"+1-758","secondary":"Saint Lucia",},
  "MF":{"primary":"+590","secondary":"Saint Martin",},
  "PM":{"primary":"+508","secondary":"Saint Pierre and Miquelon",},
  "VC":{"primary":"+1-784","secondary":"Saint Vincent and the Grenadines",},
  "WS":{"primary":"+685","secondary":"Samoa",},
  "SM":{"primary":"+378","secondary":"San Marino",},
  "ST":{"primary":"+239","secondary":"Sao Tome and Principe",},
  "SA":{"primary":"+966","secondary":"Saudi Arabia",},
  "SN":{"primary":"+221","secondary":"Senegal",},
  "RS":{"primary":"+381","secondary":"Serbia",},
  "SC":{"primary":"+248","secondary":"Seychelles",},
  "SL":{"primary":"+232","secondary":"Sierra Leone",},
  "SG":{"primary":"+65","secondary":"Singapore",},
  "SX":{"primary":"+1-721","secondary":"Sint Maarten",},
  "SK":{"primary":"+421","secondary":"Slovakia",},
  "SI":{"primary":"+386","secondary":"Slovenia",},
  "SB":{"primary":"+677","secondary":"Solomon Islands",},
  "SO":{"primary":"+252","secondary":"Somalia",},
  "ZA":{"primary":"+27","secondary":"South Africa",},
  "KR":{"primary":"+82","secondary":"South Korea",},
  "SS":{"primary":"+211","secondary":"South Sudan",},
  "ES":{"primary":"+34","secondary":"Spain",},
  "LK":{"primary":"+94","secondary":"Sri Lanka",},
  "SD":{"primary":"+249","secondary":"Sudan",},
  "SR":{"primary":"+597","secondary":"Suriname",},
  "SJ":{"primary":"+47","secondary":"Svalbard and Jan Mayen",},
  "SZ":{"primary":"+268","secondary":"Swaziland",},
  "SE":{"primary":"+46","secondary":"Sweden",},
  "CH":{"primary":"+41","secondary":"Switzerland",},
  "SY":{"primary":"+963","secondary":"Syria",},
  "TW":{"primary":"+886","secondary":"Taiwan",},
  "TJ":{"primary":"+992","secondary":"Tajikistan",},
  "TZ":{"primary":"+255","secondary":"Tanzania",},
  "TH":{"primary":"+66","secondary":"Thailand",},
  "TG":{"primary":"+228","secondary":"Togo",},
  "TK":{"primary":"+690","secondary":"Tokelau",},
  "TO":{"primary":"+676","secondary":"Tonga",},
  "TT":{"primary":"+1-868","secondary":"Trinidad and Tobago",},
  "TN":{"primary":"+216","secondary":"Tunisia",},
  "TR":{"primary":"+90","secondary":"Turkey",},
  "TM":{"primary":"+993","secondary":"Turkmenistan",},
  "TC":{"primary":"+1-649","secondary":"Turks and Caicos Islands",},
  "TV":{"primary":"+688","secondary":"Tuvalu",},
  "VI":{"primary":"+1-340","secondary":"U.S. Virgin Islands",},
  "UG":{"primary":"+256","secondary":"Uganda",},
  "UA":{"primary":"+380","secondary":"Ukraine",},
  "AE":{"primary":"+971","secondary":"United Arab Emirates",},
  "GB":{"primary":"+44","secondary":"United Kingdom",},
  "US":{"primary":"+1","secondary":"United States",},
  "UY":{"primary":"+598","secondary":"Uruguay",},
  "UZ":{"primary":"+998","secondary":"Uzbekistan",},
  "VU":{"primary":"+678","secondary":"Vanuatu",},
  "VA":{"primary":"+379","secondary":"Vatican",},
  "VE":{"primary":"+58","secondary":"Venezuela",},
  "VN":{"primary":"+84","secondary":"Vietnam",},
  "WF":{"primary":"+681","secondary":"Wallis and Futuna",},
  "EH":{"primary":"+212","secondary":"Western Sahara",},
  "YE":{"primary":"+967","secondary":"Yemen",},
  "ZM":{"primary":"+260","secondary":"Zambia",},
  "ZW":{"primary":"+263","secondary":"Zimbabwe",},
        } 
// Customizable Area End