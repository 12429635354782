import React from 'react';
import BmiCalculatorWebController, { Props } from './BmiCalculatorWebController';
import { Typography, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link } from "react-router-dom";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import { sideBarBanner } from '../../../ProductRecommendationEngine/src/assets'
import { bonePain, lowImmunity, fatigue, symptomShape } from '../assets'
import CustomizedToggleSwitche from '../../../../components/src/CustomizedToggleSwitche';
import { BmiSymPgTopBanner } from "../assets"
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BmiReaultGradeShowCircle } from './BmiReaultGradeShowCircle';
import BmiMeasurementInfo from './BmiMeasurementInfo';
import { BmiUserInfo } from './BmiUserInfo';
import { LoadingErrorNotificationText } from '../../../../components/src/LoadingErrorNotificationText';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            width: '100%',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },
        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
        },
        profileTopBanner: {
            marginLeft: "-1px",
            marginTop: "-1px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.down("xs")]: {
                marginTop: "7px"
            },
        },
        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '1000px',
            backgroundSize: 'inherit',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },


        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            margin: '1.5rem 0 1.5rem 0',
            alignItems: 'center',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        pageHeadingText: {
            fontFamily: `'Montserrat', sans-serif`,
            color: '#37006E',
            fontWeight: 600,
            lineHeight: "33px",
            textTransform: 'capitalize',
            fontSize: "25px",
            [theme.breakpoints.down("sm")]: {
                lineHeight: "12px",
                fontSize: "20px",
            },
        },

        backButtonText: {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontStyle: "normal",
            lineHeight: "12px",
            fontSize: "22px",
            cursor: 'pointer',
            color: "#FF5050",
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },


        productButton: {
            width: '80%',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: '22px',
            textTransform: 'uppercase',
            textAlign: 'center',
            color: '#FFFFFF',
            background: '#38006D',
            padding: '15px 80px',
            borderRadius: "44px",
            margin: '2rem 0',
            '&:hover': {
                border: 'none',
                background: '#12125c',
            },
            [theme.breakpoints.down("md")]: {
                fontSize: '15px',
                padding: '12px 60px',
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: '12px',
                padding: '8px 50px',
            },
        },
        userHeightWeightValueText: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontStyle: "normal",
            color: "#ffff",
            fontSize: "33px",
            [theme.breakpoints.down("sm")]: {
                fontSize: '23px',
            },
        },
        userHeightWeightText: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontStyle: "normal",
            color: "#FB951A",
            fontSize: "15px",
            [theme.breakpoints.down("sm")]: {
                fontSize: '12px',
            },
        },
        unitText: {
            fontSize: "15px",
            [theme.breakpoints.down("sm")]: {
                fontSize: '12px',
            },
        },
        heightWeightDiv: {
            display: 'flex',
            justifyContent: 'space-around',
            margin: '2rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '1.5rem 0',
            },
        },
        // bml health card end
        AccordionStyle: {
            marginBottom: 34,
            background: '#FFFFFF',
            borderRadius: '5px',
            border: '0.3px solid #757D8A',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
                marginLeft: "auto",
                marginRight: "auto",
            },
        },

        AccordionBottomLinkText: {
            fontFamily: "Poppins",
            fontSize: 20,
            fontStyle: "normal",
            lineHeight: 2,
            fontWeight: 500,
            color: "#FF6B00",
            textDecoration: 'none',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down("sm")]: {
                fontWeight: 500,
                fontSize: 13,
            },
        },
        AccordionDetailStyle: {
            fontFamily: "Poppins",
            lineHeight: 2,
            fontSize: 25,
            fontWeight: 500,
            color: "#455A64",
            fontStyle: "normal",
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down("sm")]: {
                fontWeight: 500,
                fontSize: 14,
                flexDirection: "column",
            },
        },


        AccordionTitleTextStyle: {
            fontFamily: "Poppins",
            fontSize: 25,
            fontWeight: 500,
            fontStyle: "normal",
            color: "#455A64",
            margin: '0.7rem 12px',
            [theme.breakpoints.down("sm")]: {
                fontSize: 16,
                margin: 5,
                fontWeight: 500,
            },
        },
        AccordionDetailBoxStyle: {
            display: "flex",
            alignItems: 'center',
            flexDirection: "column",
            padding: '1rem'
        },
        //
        AccordionSummaryTextStyle: {
            fontFamily: "Poppins",
            color: "#455A64",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 25,
            margin: '2rem 12px',
            [theme.breakpoints.down("sm")]: {
                fontSize: 16,
                margin: 5,
                fontWeight: 500,
            },
        },
        dashBoardContainer: { backgroundColor: '#38006D', color: 'white', padding: '1.5rem', borderRadius: '1rem', marginBottom: '2rem' },

    });




class BmiSignAndSymptomsWebPage extends BmiCalculatorWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
        } = this.state;
        const { classes, navigation, t } = this.props;

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} >
                        <img src={BmiSymPgTopBanner} alt="bgImage" style={{ width: "100%" }} className={classes.profileTopBanner} />
                        <div className={classes.addNewMemberContainer}>
                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText={t('BMIResult')} rightToggleText={t('NutritionalGap')} />
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>BMI {t("Sign_Symptoms")}</Typography>
                                <Typography className={classes.backButtonText} data-testid="backbtn" onClick={this.backButton}>
                                    {t("Back")}
                                </Typography>
                            </div>

                            {
                                loading ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> : (
                                    <div style={{ margin: '2rem 0' }} >
                                        {
                                            this.state.BmiProfileDetails.length === 0 || this.state.errorReponse ? <LoadingErrorNotificationText /> : (
                                                <>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        className={classes.dashBoardContainer}
                                                    >
                                                        <Grid item xs={12} sm={6}>
                                                            <BmiUserInfo userName={this.state.BmiProfileDetails.attributes?.full_name} userGender={this.state.BmiProfileDetails.attributes?.gender} userAge={this.state.BmiProfileDetails.attributes?.age} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                alignItems="center"
                                                            >
                                                                <Grid item xs={6}>
                                                                    <div className={classes.heightWeightDiv}>
                                                                        <BmiMeasurementInfo BmiMeasurementLable={t("Height")} BmiMeasurementValue={this.state.BmiProfileDetails.attributes?.height} BmiMeasurementUnit={t("meter")} />
                                                                        <BmiMeasurementInfo BmiMeasurementLable={t("Weight")} BmiMeasurementValue={this.state.BmiProfileDetails.attributes?.weight} BmiMeasurementUnit={t("KiloGram")} />
                                                                    </div>
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <BmiReaultGradeShowCircle BmiProfileDetails={this.state.BmiProfileDetails} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Accordion className={classes.AccordionStyle} defaultExpanded={true}>
                                                        <AccordionSummary
                                                            expandIcon={<ArrowDropDownIcon style={{
                                                                backgroundColor: "#FCC300", borderRadius: "50%", color: '#ffff'
                                                            }} />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <img src={symptomShape} /> <Typography className={classes.AccordionTitleTextStyle}>
                                                                {t("Underweight")}: {t("Sign_Symptoms")}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails className={classes.AccordionDetailStyle}>
                                                            <div className={classes.AccordionDetailBoxStyle}>
                                                                <img style={{ width: '150px' }} src={bonePain} />
                                                                <Typography className={classes.AccordionSummaryTextStyle}>
                                                                    {t("Bone_Joint_Pain")}
                                                                </Typography>

                                                            </div>
                                                            <div className={classes.AccordionDetailBoxStyle}>
                                                                <img style={{ width: '150px' }} src={lowImmunity} />
                                                                <Typography className={classes.AccordionSummaryTextStyle}>
                                                                    {t("Low_immunity")}
                                                                </Typography>

                                                            </div>
                                                            <div className={classes.AccordionDetailBoxStyle}>
                                                                <img style={{ width: '150px' }} src={fatigue} />
                                                                <Typography className={classes.AccordionSummaryTextStyle}>
                                                                    {t("Fatigue")}
                                                                </Typography>

                                                            </div>
                                                        </AccordionDetails>
                                                        <AccordionDetails style={{ justifyContent: 'center' }}>
                                                            <Link to='#' className={classes.AccordionBottomLinkText}>{t("KnowMore")}</Link>
                                                        </AccordionDetails>

                                                    </Accordion>

                                                    <div style={{ textAlign: 'center' }}>
                                                        <Button
                                                            className={classes.productButton} data-testid="navigationToFavSmartSrcPageBtn" onClick={() => this.navigationBtnFnc('FavouriteSmartSearchWeb')}>
                                                            {t("smartSearchBtn")}
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                )}



                        </div>
                    </Grid >
                </Grid >
            </div >
        );
    }
}
// Customizable Area End
export default withStyles(styles, { withTheme: true })(withTranslation('BMiCalculatorLng')(BmiSignAndSymptomsWebPage));
