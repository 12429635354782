import React from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles: any = makeStyles((theme) => ({
    errorvalidationText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#FF3939',
        fontSize: '15px',
        marginTop: '0.785rem'
    },
}));


interface Props {
    errorState: boolean;
    errorMessage: string;
}
export const ErrorValidationMessage = ({ errorState, errorMessage }: Props) => {
    const classes = useStyles();
    return (
        <>
            {errorState ? <Typography className={classes.errorvalidationText}>{errorMessage}</Typography> : ''}
        </>

    )
}
