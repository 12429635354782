// Customizable Area Start
import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ContentManagementController, {Props} from "./ContentManagementController";
import { withTranslation } from 'react-i18next';

const styles = (theme: Theme) =>
  createStyles({
    containerStyle: {
      marginTop: 100,
      marginLeft: 55.5,
      marginRight: 55.5,
      background: "#fff",
      paddingBottom: 100,
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        marginRight: 0,
        paddingBottom: 0,
      },
    },
    TitleContainerStyle: {
      marginLeft: 11,
      marginBottom: 57,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginBottom: 20,
      },
    },
    headingStyle: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    AccordionStyle: {
      marginBottom: 34,
      border: "double 4px transparent",
      borderRadius: 4,
      backgroundImage:
        "linear-gradient(white, white), linear-gradient(180deg, #FF3939,#41479B)",
      backgroundOrigin: "border-box",
      backgroundClip: "content-box, border-box",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 16,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    AccordionSummaryStyle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 18,
      margin: 12,
      color: "#37474F",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        margin: 5,
        fontWeight: 500,
      },
    },
    AccordionDetailStyle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 2,
      marginLeft: 30,
      marginRight: 70,
      marginBottom: 24,
      color: "#757D8A",
      [theme.breakpoints.down("sm")]: {
        fontWeight: 500,
        fontSize: 14,
        marginLeft: 20,
        marginRight: 20,
      },
    },
    AccordionDetailBoxStyle: {
      display: "flex",
      flexDirection: "column",
      rowGap: 10,
    },
    AccordionRootStyle: {
      marginRight: 45,
      marginLeft: 59,
      marginBottom: 203.86,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 30,
      },
    },
  });

class FaqsComponent extends ContentManagementController {
  constructor(props: Props) {
    super(props);    
  }
  

  render() {
    const { classes, t } = this.props;
    return (
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid container justifyContent="center" alignItems="center">
            <div className={classes.containerStyle}>
              <Grid item>
                <div style={{ flexGrow: 1 }} />
                <div className={classes.TitleContainerStyle}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "35px",
                      color: "#37006E",
                    }}
                    className={classes.headingStyle}
                  >
                    {t("FAQs")}
                  </Typography>
                </div>
              </Grid>

              {/* Accordians Start */}
              <Grid item>
                <div style={{ flexGrow: 1 }} />
                <div className={classes.AccordionRootStyle}>
                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel1"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel1")}}
                  data-testid="accordianExpanded"
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        What are the different front of pack Nutrition labelling
                        systems around the world?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          There are 8 popular front of pack nutrition labelling
                          systems available across the world.
                        </Typography>
                        <Typography>They are</Typography>
                        <ol>
                          <li>Traffic Light labelling</li>
                          <li>Reference Intakes</li>
                          <li>NutriScore</li>
                          <li>Nordic Keyhole</li>
                          <li>Stop sign warnings</li>
                          <li>Health Star ratings</li>
                          <li>Choices program</li>
                          <li>Healthier Choices</li>
                        </ol>

                        <Typography>
                          All of them use different approaches to help bring
                          greater transparency around the nutritive quality of
                          packaged foods.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel2"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel2")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        What’s N<sup>IQ</sup> Score?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          N<sup>IQ</sup> means Nutrition IQ. Empowering consumers to make
                          smart food choices. Making Nutrition Easy.
                        </Typography>
                        <Typography>
                          N<sup>IQ</sup> score is a unique scientific algorithm which helps
                          understand the nutritive value of any packaged, raw or
                          cooked food. It's an independent, scientifically
                          robust yet easy to understand model adapted to Indian
                          market and designed basis both Indian FSSAI
                          guidelines, WHO & European guidelines. The model
                          grades products from A to E (Packaged & Cooked Food) &
                          from A to C (Raw Food) based on a cumulative score to
                          limit negative nutrients & to encourage positive
                          nutrients. Rating scale moves from A being the best to
                          E being the worst.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel3"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel3")}}
                  data-testid="accordianExpanded"
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        What's the N<sup>IQ</sup> Score model based on?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          N<sup>IQ</sup> Score (Packaged Foods) - Is based on Nutriscore as
                          the foundation model. Nutriscore exists in 7 European
                          markets like France, Germany, Switzerland, Belgium,
                          Spain, Netherlands & Luxembourg - Is widely accepted
                          as a highly credible rating system. N<sup>IQ</sup> score has been
                          further adapted to incorporate micronutrients to fully
                          capture the nutritive value of a product (based on the
                          advice of our scientific council)
                        </Typography>
                        <Typography>
                          N<sup>IQ</sup> score (Raw foods)- Is based on Nutrient Rich Food
                          Index (NRF) model which USFDA uses to assess food
                          quality in their nutrition database. Inspired from
                          this index, the Superfoods Valley team of
                          Nutritionist, wellness consultants & food regulators
                          have created a unique raw food scoring methodology -
                          in line with Indian diet.
                        </Typography>
                        <Typography>
                          N<sup>IQ</sup> Score (Cooked foods) – Is based on a similar
                          approach as raw foods - The Nutritive value of all
                          individual recipes are calculated by adding nutritive
                          values of each individual ingredient & by factoring in
                          the nutrition losses because of cooking process.
                          Source: The new Indian Food Composition Tables (IFCT)
                          2017.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel4"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel4")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Where did the N<sup>IQ</sup> idea come from?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          The idea came from watching how in our own homes - we
                          struggle to make the right choices & are swayed by
                          tall product claims or lack of awareness about genuine
                          healthy alternatives which might exist in the market.
                          Our company vision is "To inspire the world to eat
                          nutritious and live healthy" - so we believe that we
                          should empower consumers to make the right choices. By
                          building a large database of nutrition information & a
                          unique & credible nutrition algorithm to understand
                          the true nutritive value of packaged, raw or cooked
                          food - we believe we can make nutrition choices easy.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel5"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel5")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        How was the database created?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          We created a robust database basis brand information
                          that's declared for public either on pack, company
                          websites & databases from NIN & ICMR. FSSAI mandates
                          food & beverage products to declare nutritional
                          information per 100g/100ml and the ingredient list
                          along with additive/allergen declaration. All of these
                          are the sources to build a robust database. We
                          continuously endeavour to expand our database to fully
                          represent the market
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel6"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel6")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        How is product information updated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <ul>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 10,
                            }}>
                            <li>
                              Database will be continuously updated (once a
                              month) to incorporate new product launches or if
                              there are any changes to existing brands.
                            </li>
                            <li>
                              Consumers can also upload product labels which are
                              not in the database currently, post that our data
                              quality team validates the inputs & if found
                              correct - uploads into the master database
                            </li>
                            <li>
                              Brands can contact us by emailing us at
                              support@whatsyourniq.com to inform us of changes
                              in their products’ ingredients
                            </li>
                          </div>
                        </ul>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel7"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel7")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Who has developed N<sup>IQ</sup> score? What’s the credibility of
                        this model?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          N<sup>IQ</sup> score is a proprietary algorithm developed by
                          Superfoods Valley Pvt Ltd. A team of nutritionist,
                          wellness consultants & food regulators have worked
                          tirelessly behind the scenes to make this one of the
                          most robust & comprehensive algorithm to evaluate
                          nutritive value.
                        </Typography>
                        <ul>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 10,
                            }}
                          >
                            <li>
                              Our founder Amarpreet worked with lead Senior
                              Nutritionist Mrs Ankita Gupta to curate the
                              principles on which the algorithm was built.
                            </li>
                            <li>
                              These were further finetuned by key members of our
                              scientific council (Mrs Mallika Janakiraman, Mrs
                              Sheela Krishnaswamy & Mr B Sesikeran).
                            </li>
                            <li>
                              This was further audited by a global independent
                              board – Led by Dr. Peter Van dael, Ex- SVP and VP,
                              DSM & Mead Johnson. The independent audit board
                              constituted of global experts in the field of
                              nutrition.
                            </li>
                          </div>
                        </ul>
                        <Typography>
                          The detailed introductions of our Scientific Council &
                          Independent Audit Board is available in the About us
                          section.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel8"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel8")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Is What’s your N<sup>IQ</sup> an independent application?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          Yes, the application is fully independent.
                        </Typography>
                        <ul>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 10,
                            }}
                          >
                            <li>
                              This means that our product evaluations and
                              recommendations are entirely objective: no brand
                              or manufacturer can influence them in any way.
                            </li>
                            <li>
                              There's a global independent board, which has
                              audited the algorithm & established it to be
                              robust, accurate, scientific & un-biased.
                            </li>
                            <li>
                              We also don't take any advertising money/support
                              from any brands which are listed with us. This way
                              we are able to establish & maintain our
                              independence.
                            </li>
                          </div>
                        </ul>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel9"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel9")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        How is N<sup>IQ</sup> score calculated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          The calculation takes key parameters like calories,
                          sugar, sodium, saturated fats, protein, fibre and
                          fruits and vegetable content into account. In addition
                          to these, N<sup>IQ</sup> Score also considers the other essential
                          nutrients like micronutrients – vitamins, minerals &
                          probiotics which are a key component for all healthy
                          products.
                        </Typography>
                        <Typography>
                          For cooked food – the model captures the nutritive
                          loss which occurs in the cooking process to give as
                          accurate a score as possible
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel10"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel10")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Why are some food products not rated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          There are various types of products that app does not
                          rate because they are very specific products for which
                          no rating method exists:
                        </Typography>
                        <ol>
                          <li>Alcohol</li>
                          <li>Sugar</li>
                          <li>Infant formula</li>
                          <li>
                            Food products designed for special medical purposes
                          </li>
                          <li>
                            Unprocessed products made of single ingredient or
                            category of ingredients
                          </li>
                          <li>Water</li>
                          <li>
                            Herbs, spices , salt and salt substitutes, table top
                            sweeteners
                          </li>
                          <li>Milled coffee beans</li>
                          <li>Herbal and fruit infusions</li>
                          <li>Pet food</li>
                          <li>
                            Nutritional supplements (Sports nutrition,
                            Multi-vitamins etc.)
                          </li>
                          <li>
                            Products which have incomplete or no nutritional
                            declaration - will not be rated
                          </li>
                        </ol>
                        <Typography>
                          All of them use different approaches to help bring
                          greater transparency around the nutritive quality of
                          packaged foods.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel11"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel11")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        How are reconstituted products rated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          Products said to be "reconstituted" are products that
                          aren't consumed as they are, but need to be mixed with
                          water or milk. Examples include: fortified powders,
                          chocolate syrups, dehydrated soups, cube broths etc.
                        </Typography>
                        <Typography>
                          Example: For a milk shake powder, the values
                          corresponding to 100 g of dry powder is not taken into
                          account. Instead, “Nutritive value per serving of
                          semi-skimmed milk" is added to "Nutritive value per
                          serving of milk shake powder" to calculate the final
                          values. Per serving declarations are mandated by FSSAI
                          & are published on the packs by the manufacturer.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel12"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel12")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Does the app take the quantity of consumption into
                        account?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          No, the application does not calculate daily intakes
                          based on the quantity consumed.
                        </Typography>
                        <Typography>
                          It analyses the inherent quality of the product
                          regardless of the quantity consumed. This is why our
                          ratings are calculated on the basis of 100g or 100 ml
                          of product.
                        </Typography>
                        <Typography>
                          The goal is not to totally eliminate products rated as
                          "D" or "E", but to make consumers aware of the total
                          nutritive value of each product.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel13"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel13")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Why are beverages rated differently?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          According to the scoring methodology used, the
                          beverage (excluding milk) rating scale is stricter for
                          calories and sugar than it is for solids. Liquids are
                          absorbed quickly by the body so their glycaemic
                          indices are higher. This is why liquid calories and
                          sugars are penalized more severely.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel14"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel14")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Why are all butters poorly rated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          N<sup>IQ</sup> score has a specific rating for fats (such as
                          oils, butters and margarines). The application doesn't
                          take into account the amount of fat, but rather the
                          ratio of fats, i.e. the percent of saturated fat
                          compared to the total amount of fat.
                        </Typography>
                        <Typography>
                          Unfortunately, butter is very rich in saturated fats,
                          so the ratio is always bad.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel15"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel15")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Why is cheese often poorly rated on What's your N<sup>IQ</sup>?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          Cheese is naturally rich in saturated fat and sodium.
                          The N<sup>IQ</sup> score penalizes high levels of saturated fat
                          and sodium. This explains why the majority of cheese
                          does not receive a high rating in the application.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel16"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel16")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Does the app take food preferences into account?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          Yes, the application offers customizable filters based
                          on “Smart Search” criteria as below
                        </Typography>
                        <ul>
                          <li>
                            <strong>Functional Preferences:</strong>{" "}
                            High/Medium/Low levels of Protein, Sugar, Calories,
                            Sodium etc.
                          </li>
                          <li>
                            <strong>Health Preferences:</strong> Heart friendly,
                            diabetic friendly, weight gain, weight loss,
                            immunity, gut health etc.
                          </li>
                          <li>
                            <strong>Food Preference:</strong> Vegetarian, Non
                            Vegetarian, Gluten free, No Colors, No preservatives
                            etc.
                          </li>
                          <li>
                            <strong>Food Allergies:</strong> 8 allergies are
                            captured (Dairy, Egg, Fish, Shellfish, Treenut,
                            Peanut, Soyabean & Wheat)
                          </li>
                          <li>
                            <strong>N<sup>IQ</sup> score:</strong> If you want to search
                            product with a specific N<sup>IQ</sup> score.
                          </li>
                        </ul>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel17"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel17")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Is the application useful for diabetics?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          Yes – our smart search criteria tells you which
                          products are diabetic friendly based on a
                          comprehensive set of nutritional composition
                          parameters.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel18"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel18")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Is the presence of palm oil taken into account in the
                        rating?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          N<sup>IQ</sup> score does not include palm oil as such in its
                          rating. The rating takes saturated fats into
                          consideration and palm oil, being half saturated fat,
                          has a negative impact on products rating as a result.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel19"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel19")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Why is sugar not rated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          N<sup>IQ</sup> score doesn't rate sugar, honey, agave syrup,
                          coconut sugar, maple syrup or sweeteners. They are
                          very specific products and our rating method is not
                          suitable for this type of product.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel20"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel20")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Why are protein supplements not rated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          N<sup>IQ</sup> score does not rate protein supplements (which are
                          usually found in powder form), such as: whey protein,
                          creatine, milk protein, soy protein, etc. These are
                          not food products strictly speaking, but supplements.
                          What'syourNIQ rating methodology is not adapted to
                          these very specialized types of products.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel21"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel21")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Why are nutritional supplements and medications not
                        rated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          N<sup>IQ</sup> score doesn't rate nutritional supplements or
                          medications since their ingredients are very specific
                          and we have not yet developed a method for analysing
                          these products.
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel22"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel22")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        How are N<sup>IQ</sup> choices selected?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          The N<sup>IQ</sup> choices are recommended in a totally neutral
                          and objective way: no brand pays or influences us in
                          order to appear in the recommendations list.
                        </Typography>
                        <Typography>
                          The algorithm for selecting is based on N<sup>IQ</sup> score -
                          which is derived based on the product's nutritional
                          information. Following criterions considered for
                          recommending N<sup>IQ</sup> choices
                        </Typography>
                        <ol>
                          <li>
                            The product category (chocolates, noodles, bars
                            etc.) in order to recommend a product as similar to
                            the original product as possible.
                          </li>
                          <li>
                            We recommend only N<sup>IQ</sup> Score - A/B/C products as
                            choices.
                          </li>
                        </ol>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle} expanded={this.state.expanded === "panel23"}
                  onChange={(event:any, isExpanded:boolean)=> {this.handleChange(isExpanded, "panel23")}}
                  data-testid="accordianExpanded">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        Can raw food be rated as D/E?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography>
                          The rating scale is a 5 point scale for packaged &
                          cooked food (A-E), but only 3 points for raw foods
                          (A-C). As explained in FAQ 3 - the algorithm for all
                          three are different. We believe that raw food
                          shouldn't be rated as D/E - because mother nature
                          offers only good products (which are naturally rich in
                          nutrients), so here it's the question of which is a
                          good or better raw food & not poor or bad. So the raw
                          food scale - doesn't extend to D/E & only rates
                          between A/B/C
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
            </div>
          </Grid>
        </Toolbar>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withTranslation('footerLng')(FaqsComponent));
// Customizable Area End
