import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  aboutImage,
  globalGuidelinesImage,
  productAnalysisImage,
  nutritiveValueImage,
  extensiveDataImage,
} from '../../catalogue/src/assets';
import {
  aboutBgImage,
  productAnalysisBgImage,
  globalGuidelinesBgImage,
  siteLogo,
  NutritiveValueScaleBgImage,
  nextArrow,
} from './assets';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    margin: "0 auto",
    overflow: "hidden",
    width: "100%", height: "100%",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  caroselBgImage: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    backgroundRepeat: 'no-repeat',
    position: "relative",
    display: "inline-block",
    height: '100vh'
  },
  caroselTitleText: {
    whiteSpace: "normal",
    fontWeight: 600,
    fontFamily: `'Montserrat', sans-serif`,
    fontSize: '30px',
    lineHeight: '37px',
    textAlign: 'left',
    padding: '0 100px',
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
      padding: '0 50px',
      fontSize: '30px',
      lineHeight: '50px',
    },
    color: '#FFFFFF',
  },
  caroselContentText: {
    whiteSpace: "normal",
    fontWeight: 500,
    fontFamily: `'Montserrat', sans-serif`,
    fontSize: '23px',
    lineHeight: '28px',
    textAlign: 'left',
    padding: '0 100px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 50px',
    },
    color: '#FFFFFF',
  },
  intro_img: {
    width: 500,
    height: 500,
    margin: "0 auto",
    [theme.breakpoints.down('lg')]: {
      width: 250,
      height: 250,
    },
    [theme.breakpoints.only('sm')]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.only('xs')]: {
      width: 150,
      height: 150,
    },

  },
  logoImagePosi: {
    marginTop: "5%",
    display: "block",
    marginLeft: 'auto',
    marginRight: '5%',
    width: '20%',
    [theme.breakpoints.only('sm')]: {
      width: '40%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  caroseNextBtn: {
    position: "absolute",
    bottom: "8%",
    color: '#ffff',
    paddingLeft: 10,
    paddingRight: 20,
  },
  caroselDotBtn: {
    backgroundColor: '#ffff',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    marginLeft: '1rem',
    border: 'none',
    cursor: 'pointer',
  },
  caroselActiveDotBtn: {
    backgroundColor: '#ffff',
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    marginLeft: '1rem',
    border: 'none',
    cursor: 'pointer',
  },
}));

const ImageCarosel = ({ ...props }: any) => {
  const classes = useStyles();
  const [caroselInfo, setCaroselInfo] = useState([
    {
      id: 0,
      image: aboutImage,
      bgImage: aboutBgImage,
      title: 'What’s Your NIQ',

      details:
        'We are a completely independent app, that helps you assess the nutritive value of any packaged, raw or cooked food or drink',
    },
    {
      id: 1,
      image: productAnalysisImage,
      bgImage: productAnalysisBgImage,
      title: 'Product Analysis',
      details:
        'Is based on a scientific logic developed by some of the world’s best scientists and ratified by highly experienced nutrition experts',
    },
    {
      id: 2,
      image: globalGuidelinesImage,
      bgImage: globalGuidelinesBgImage,
      title: 'Global & Local Guidelines',
      details:
        'The analysis is based on WHO, European & Indian dietary guidelines, which are globally accepted as a benchmark',
    },
    {
      id: 3,
      image: nutritiveValueImage,
      bgImage: NutritiveValueScaleBgImage,
      title: 'Nutritive value scale',
      details:
        "NIQ score grades all products on their nutritive value from highest (A) to lowest (E) based on this unique scientific logic",
    },
    {
      id: 4,
      image: extensiveDataImage,
      bgImage: globalGuidelinesBgImage,
      title: 'Extensive Data & Deep Tech',
      details:
        'An extensive database of 20,000+ products, millions of data points & deep analytic tools to simplify complex information ',
    },
  ]);

  const [indexValue, setIndexValue] = useState(0);
  const timeoutRef = React.useRef<any>(null);
  const delay = 4000;
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => setIndexValue((prevIndex: any) => prevIndex === caroselInfo.length - 1 ? 0 : prevIndex + 1), delay);
    return () => { resetTimeout(); };
  }, [indexValue]);

  const nextSlide = () => {
    setIndexValue(indexValue === caroselInfo.length - 1 ? 0 : indexValue + 1);
  };

  const prevSlide = (index: any) => {
    setIndexValue(index);
  };
  return (
    <div className={classes.rootContainer}>
      <div style={{ transform: `translate3d(${-indexValue * 100}%, 0, 0)`, transition: "ease 1000ms", whiteSpace: "nowrap", }}>
        {caroselInfo.map((backgroundColor: any, index: any) => (
          <>
            <div key={index} style={{ backgroundImage: `url(${backgroundColor.bgImage})` }} className={classes.caroselBgImage}>
              <div>
                <img src={siteLogo} alt="logo" className={classes.logoImagePosi} onClick={() => props.navigation.navigate("Home")} style={{ cursor: "pointer" }} />
              </div>

              <div className={classes.intro_img}>
                <img
                  src={caroselInfo[indexValue].image}
                  alt=""
                  width={"100%"}
                  height={"100%"}
                />
              </div>

              <div>
                {
                  caroselInfo[indexValue].title == "What’s Your NIQ" ?
                    <Typography variant="h4" className={classes.caroselTitleText}>
                      What’s Your N<sup>IQ</sup>
                    </Typography>
                    : <Typography variant="h4" className={classes.caroselTitleText}>
                      {caroselInfo[indexValue].title}
                    </Typography>
                }
                {
                  caroselInfo[indexValue].title == "Nutritive value scale" ? <Typography className={classes.caroselContentText}>
                    N<sup>IQ</sup> score grades all products on their nutritive value from highest (A) to lowest (E) based on this unique scientific logic
                  </Typography> : <Typography className={classes.caroselContentText}>
                    {caroselInfo[indexValue].details}
                  </Typography>
                }
              </div>

            </div>
          </>
        ))}
      </div>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        className={classes.caroseNextBtn}
      >
        <div>
          {caroselInfo.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => { prevSlide(index); }}
                className={index === indexValue ? classes.caroselActiveDotBtn : classes.caroselDotBtn}
              />
            );
          })}
        </div>
        <div style={{ display: "flex", cursor: 'pointer' }} onClick={nextSlide}>
          <Typography>Next </Typography>
          <img src={nextArrow} alt="" style={{ marginLeft: 10 }} />
        </div>
      </Grid>
    </div>
  )
};

export default ImageCarosel;