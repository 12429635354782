import React from "react";
//Customise area start
import { Fade, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

//Customise area stop
const FavSearchStatusWeb = ({ ...filterData }: any) => {
  //Customise area start
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        open={filterData?.open}
        style={{ display: "flex", justifyContent: "center", alignItems: "center", }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, }}>
        <Fade in={filterData?.open}>
          <div style={{ background: "#fff", width: 400, height: 170, borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
            <CheckCircleOutlineIcon style={{ width: 100, height: 100, color: `${filterData?.color}` }} />
            <Typography
              style={{ fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', textAlign: 'center', color: '#455A64', }}
            >{filterData?.content}</Typography>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default FavSearchStatusWeb;
 // Customise area stop