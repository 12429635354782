import React from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles: any = makeStyles((theme) => ({
    nofificationText: {
        color: '#FF3939',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '23px',
        textTransform: 'capitalize',
        margin: '2rem 0',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        },
    },
}));


export const LoadingErrorNotificationText = () => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.nofificationText}>We're sorry, something went wrong. Please try again later. </Typography>
        </>

    )
}
