Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.putAPiMethod = "PUT";
exports.applicationJsonContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductRecommendationEngine";
exports.labelBodyText = "ProductRecommendationEngine Body";
exports.getProductDetailsEndPoint = "bx_block_catalogue/products";
exports.getNiqChoicesProductApiEndPoint = "bx_block_catalogue/products/niq_score";
exports.postFavProductApiEndPoint = "bx_block_catalogue/favourite_products";
exports.foodBasketApiEndPoint = "bx_block_catalogue/orders"
exports.favouriteProducts = "bx_block_catalogue/favourite_products"
exports.removeBasketIndividualProductEndpoint = "remove_product";
exports.getcompareApiEndPoint = "bx_block_catalogue/compare_products";
exports.deletecompareApiEndPoint = "bx_block_catalogue/compare_products/";
exports.addTocompareApiEndPoint = "bx_block_catalogue/compare_products?product_id"
exports.getcompareProductsApiEndPoint = "bx_block_catalogue/products/compare_product"
exports.getReportProductsApiEndPoint = "bx_block_catalogue/products/reported_product_list"
exports.getQuestionListApiEndPoint = "bx_block_catalogue/products/question_listing"
exports.submitProductReportApiEndPoint = "bx_block_catalogue/products/reported_product"
exports.getReportProductDetailsApiEndPoint = "bx_block_catalogue/products/show_reported_product"
exports.favouriteProductApiEndPoint = "bx_block_catalogue/favourite_products"
exports.defaultLanguageIDEnglish = 1;

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End