import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import React from 'react'
import { Favorite } from './assets';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ApiCall from '../../../components/src/APICall'
import { FavouriteProductContext } from './context/FavouriteProductContext';
// import { FavouriteProductContext } from './context/FavouriteProductContext';
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t: any;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  activeProduct: any;
  productId: any;
  products: any;
  positiveProductDetails: any;
  negativeProductDetails: any;
  niqProducts: any;
  loadingNiqChoicesProduct: boolean;
  loadingAddFavProduct: boolean;
  modalOpen: boolean;
  addFoodBasketloader: boolean;
  foodBasketListLoader: boolean;
  foodBasketLists: any;
  PostID: any;
  addFoodBasketApiResponseText: any;
  isLogin: boolean;
  loginNotifiModalOpen: boolean;
  allComparedProducts: any;
  isOpen: boolean;
  comparedataLimit: any;
  compareProductadded: boolean;
  compareProductAlreadyAdded: boolean;
  selectedProductId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}


export default class ProductDetailsScreenWebController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: string = '';
  getNiqChoicesProductApiCallId: string = '';
  addFoodBasketmessageId: string = '';
  updateFoodBasketmessageId: string = '';
  getFoodBasketApiCallId: string = '';
  addToCompareProductsId: any;
  getAllCompareProductsId: any;
  static contextType: any =FavouriteProductContext;

  getFavouriteProductApiCallId: string = '';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage), // nedd to subScribe messageEnum {adapter} {add by rifadulDev}
      // Customizable Area End
    ];

    this.state = {
      loading: false,
      productId: null,
      products: [],
      activeProduct: '',
      positiveProductDetails: [],
      negativeProductDetails: [],
      niqProducts: [],
      loadingNiqChoicesProduct: false,
      loadingAddFavProduct: false,
      modalOpen: false,
      addFoodBasketloader: false,
      foodBasketListLoader: false,
      foodBasketLists: [],
      PostID: [],
      addFoodBasketApiResponseText: 'Product added successfully',
      isLogin: false,
      loginNotifiModalOpen: false,
      allComparedProducts: [],
      isOpen: false,
      comparedataLimit: 0,
      compareProductadded: false,
      compareProductAlreadyAdded:false,
      selectedProductId: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }


  async componentDidMount() {
    super.componentDidMount();
    const haveToken = await localStorage.getItem('token')
    if (haveToken) {
      this.setState({
        isLogin: true
      })
      this.getFoodBasketList();
    }
    this.callingInitialFunctionalities();
  }


  callingInitialFunctionalities = () => {
    const productId = localStorage.getItem('proId')
    this.setState({ activeProduct: productId })
    this.getProductDetails(productId);
    this.getNiqChoicesProduct(productId);
    this.getAllCompareProducts()
  }


  goToProductDetailsPage = (id: any) => {
    localStorage.setItem('proId', id);
    this.setState({
      productId: id,
    }, () => {
      this.getProductDetails(id);
      this.getNiqChoicesProduct(id);
      this.getFoodBasketList();
    })
  }

  // Customizable Area Start
  deleteFavApiCall: any;


  //  <---------- API call for product details {rf} ---------->
  getProductDetails = (id: any) => {
    this.setState({
      loading: true
    })
    const contentType = configJSON.applicationJsonContentType;
    const apiEndPoint = `${configJSON.getProductDetailsEndPoint}/${id}?language_id=${this.getLanguage()}`;
    const apiMethod = configJSON.getApiMethodType
    const apiCallingData = {
      contentType, apiEndPoint, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);
    this.getProductApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFavouriteProduct = (productId: any, status: boolean) => {
    this.setState({
      selectedProductId: productId
    });
    if (status) {
      this.deleteFavouriteProductFromList(productId)
    } else {
      this.handleFavouriteProductSubmission({ product_id: productId })
    }
  }

  //  <---------- API call for Add Favourite ---------->

  deleteFavouriteProductFromList = (id: any) => {
    const token = localStorage.getItem("token")
    const headers = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFavApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouriteProductApiEndPoint}/${id}?language_id=${this.getLanguage()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "delete"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFavouriteProductSubmission = (data: any) => {
    const token = localStorage.getItem("token")
    const headers = {
      token: token
    };
    const form = new FormData();
    form.append("data[attributes][favourite]", 'true');
    form.append("language_id", this.getLanguage());
    form.append("data[attributes][product_id]", data.product_id);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFavouriteProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteProducts
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  //  <---------- API call for NIQ Choices products {rf} ---------->
  getNiqChoicesProduct = (id: any) => {
    this.setState({
      loadingNiqChoicesProduct: true
    })

    const contentType = configJSON.applicationJsonContentType;
    const apiEndPoint = `${configJSON.getNiqChoicesProductApiEndPoint}?product_id=${id}&language_id=${this.getLanguage()}`;
    const apiMethod = configJSON.getApiMethodType
    const apiCallingData = {
      contentType, apiEndPoint, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);
    this.getNiqChoicesProductApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // <---------- API call for Add food basket {rf} ---------->
  addFoodBasket = (data: any) => {

    this.setState({
      addFoodBasketloader: true
    })

    const contentType = configJSON.applicationJsonContentType;
    const apiEndPoint = `${configJSON.foodBasketApiEndPoint}?language_id=${this.getLanguage()}`;
    const apiMethod = configJSON.postAPiMethod
    const payload = {
      data: data
    }
    const apiCallingData = {
      contentType, apiEndPoint, payload, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);
    this.addFoodBasketmessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // <---------- API call for Add food basket {rf} ---------->
  updateFoodBasketName = (order_name: string, foodBasketId: any) => {

    this.setState({
      addFoodBasketloader: true
    })
    const contentType = configJSON.applicationJsonContentType; // need to change here
    const apiEndPoint = `${configJSON.foodBasketApiEndPoint}/${foodBasketId}?language_id=${this.getLanguage()}`; // need to change here
    const apiMethod = configJSON.putAPiMethod // need to change here

    const payload = {
      data: {
        order_name: order_name,
        language_id: this.getLanguage()
      }
    }
    const apiCallingData = {
      contentType, apiEndPoint, payload, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData); // need to change here
    this.updateFoodBasketmessageId = requestMessage.messageId; // need add apiCallId here
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //  <---------- API call for food basket list {rf} ---------->
  getFoodBasketList = () => {
    this.setState({
      foodBasketListLoader: true
    })

    const contentType = 'application/json';
    const apiEndPoint = `${configJSON.foodBasketApiEndPoint}?language_id=${this.getLanguage()}`;
    const apiMethod = configJSON.getApiMethodType;
    const apiCallingData = {
      contentType, apiEndPoint, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);
    this.getFoodBasketApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addToCompareProducts = () => {
    const contentType = configJSON.applicationJsonContentType;
    const apiEndPoint = `${configJSON.addTocompareApiEndPoint}=${localStorage.getItem('proId')}&language_id=${this.getLanguage()}`;
    const apiMethod = configJSON.postAPiMethod
    const apiCallingData = {
      contentType, apiEndPoint, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);
    this.addToCompareProductsId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllCompareProducts = () => {
    const contentType = configJSON.applicationJsonContentType;
    const apiEndPoint = `${configJSON.getcompareApiEndPoint}?language_id=${this.getLanguage()}`
    const apiMethod = configJSON.getApiMethodType
    const apiCallingData = {
      contentType, apiEndPoint, apiMethod
    }
    const requestMessage = ApiCall(apiCallingData);
    this.getAllCompareProductsId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  // receive function for API response
  async receive(from: string, message: Message) {
    // recive value from {adapter} {add by rifadulDev}
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const productId = message.getData(
        getName(MessageEnum.FromProductPageNavigationMessage)
      );
      this.setState({
        productId: productId
      })
      localStorage.removeItem('proId')
      localStorage.setItem('proId', productId)
    }

    //  response API for Add to compare products  //
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addToCompareProductsId &&
      this.addToCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {

        let totalValues = 0;
        if (this.state.allComparedProducts.length === 1 && responseJson.data.attributes.selected) {
          totalValues = 2;
        }
        if (this.state.allComparedProducts.length > 1 || totalValues > 1) {
          return this.props.navigation.history.push({ pathname: "CompareProducts" })
        }
        this.getAllCompareProducts();
      }

    }
    //response API for Addto compare products
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFavouriteProductApiCallId &&
      this.getFavouriteProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        let values = await localStorage.getItem('favList');
        if (values) {
          let allValues = JSON.parse(values);
          allValues.push(responseJson.data)
          await localStorage.setItem('favList', JSON.stringify(allValues));
          this.getProductDetails(this.state.selectedProductId);
          this.context?.getFavouriteListAPI();
        }
      }
      if (responseJson?.error) {
      }

    }

    // response API delete
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteFavApiCall &&
      this.deleteFavApiCall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.success) {
        this.getProductDetails(this.state.selectedProductId);
        this.context?.getFavouriteListAPI();
      }

    }

    //response API for getAllCompareProducts  
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllCompareProductsId &&
      this.getAllCompareProductsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({ allComparedProducts: responseJson.data }, () => {

        })
      }

    }




    //  <---------- response for  get product details api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data) {
        this.setState({
          products: responseJson?.data,
          loading: false
        })

        // Good And Not So Good Functionality Start

        let filteredGood: any = [];
        let filteredNotSoGood: any = [];

        // Logic for Not So Good Products
        if (this.state.products?.attributes?.negative_not_good != null) {
          for (let i = 0; i < this.state.products?.attributes?.negative_not_good?.length; i++) {

            // @ts-ignore
            filteredNotSoGood.push(this.state.products?.attributes?.negative_not_good[i])
          }
        }

        // Logic for Good Products
        if (this.state.products?.attributes?.positive_good != null) {
          for (let i = 0; i < this.state.products?.attributes?.positive_good?.length; i++) {
            // @ts-ignore
            filteredGood.push(this.state.products?.attributes?.positive_good[i])
          }
        }
        this.setState({ positiveProductDetails: filteredGood })
        this.setState({ negativeProductDetails: filteredNotSoGood })
        // Good And Not So Good Functionality Stop

      }
      if (responseJson.errors) {
        if (responseJson?.errors === 'Product not present') {
          this.setState({
            loading: false,
          })
          this.props?.navigation?.navigate('NoProudctFound');
        } else {
          this.setState({
            loading: false
          })
          this.props?.navigation?.navigate('UserSignUpScreenWeb');
        }
      }
    }

    // end for {rf}

    //  <---------- response for  get NIQ Choices product api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getNiqChoicesProductApiCallId &&
      this.getNiqChoicesProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          niqProducts: responseJson?.data,
          loadingNiqChoicesProduct: false
        })

      } else {
        this.setState({
          loadingNiqChoicesProduct: false
        })
      }
      if (responseJson?.errors) {
        this.setState({
          loadingNiqChoicesProduct: false
        })
      }

    }

    // end for {rf}

    //  <---------- response for  Add food basket api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addFoodBasketmessageId &&
      this.addFoodBasketmessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          addFoodBasketloader: false
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }
      if (responseJson?.error) {
        this.setState({
          addFoodBasketloader: false,
          addFoodBasketApiResponseText: responseJson?.error === 'Bad Request' ? 'Please try again' : responseJson?.error,
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }

    }

    // end for {rf}

    //  <---------- response for  update food basket api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateFoodBasketmessageId &&
      this.updateFoodBasketmessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          addFoodBasketloader: false,
          addFoodBasketApiResponseText: 'Basket name updated'
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }
      if (responseJson?.error) {
        this.setState({
          addFoodBasketloader: false,
          addFoodBasketApiResponseText: responseJson?.error === 'Bad Request' ? 'Please try again' : responseJson?.error,
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }

    }

    // end for {rf}

    //  <---------- response for  food basket list {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFoodBasketApiCallId &&
      this.getFoodBasketApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          foodBasketLists: responseJson?.data,
          foodBasketListLoader: false

        })
      }
      if (responseJson?.error) {
        this.setState({
          foodBasketListLoader: false
        })
      }

    }

    // end for {rf}

  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true })
  }
  handleModalClose = () => {
    this.setState({ addFoodBasketApiResponseText: 'Product added successfully', modalOpen: false })
  }

  handleLoginNotifiModalOpen = () => {
    this.setState({ loginNotifiModalOpen: true })
  }
  handleLoginNotifiModalClose = () => {
    this.setState({ loginNotifiModalOpen: false })
  }


  backButton = () => {
    this.props?.navigation?.goBack();
  }

  checkStatusfav = (favProductStatus: boolean) => {
    if (favProductStatus) {
      return <img src={Favorite} alt="Favorite" />
    } else {
      return <FavoriteBorderIcon style={{ color: '#ADADAD' }} fontSize="large" />
    }
  }

  handleOpen = (data: any) => {
    this.setState({ isOpen: true, comparedataLimit: data })
  };

  handleClose = () => {
    this.setState({ isOpen: false })
    this.setState({compareProductAlreadyAdded: false})
  };

  navigationBtnFnc = (path: any) => {
    this.props?.navigation?.navigate(path);
  }

  checkCompareProducts = () => {
    if (this.state.isLogin) {
      if (this.state.allComparedProducts.length > 0) {
        if (this.state.allComparedProducts.length >= 1) {
          return this.handleOpen(this.state.allComparedProducts.length)
        } else {
          return this.addToCompareProducts()
        }
      } else {
        return this.handleOpen(this.state.allComparedProducts.length)
      }
    }
    else {
      return this.handleLoginNotifiModalOpen()
    }
  }

  getLanguage = () => {
    let languageId: any;
    const defaultEnglishLanguageId: any = configJSON.defaultLanguageIDEnglish
    if (localStorage.getItem('languageSelect')) {
      languageId = localStorage.getItem('languageSelect');
    }
    else {
      languageId = defaultEnglishLanguageId;
    }
    return languageId;
  }
  isCompareProductTrue = (isCompare:any) => {    
    this.setState({comparedataLimit: this.state.allComparedProducts.length})
    if(isCompare) {
      this.setState({compareProductAlreadyAdded: true})
    } else {
      this.setState({compareProductAlreadyAdded: false})
      this.checkCompareProducts()
    }
  }
  // Customizable Area End
}