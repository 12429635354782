import React from 'react';
import Loader from '../../../components/src/Loader';
import HealthyFoodBasketWebController, { Props } from './HealthyFoodBasketWebController';
import { Typography, Grid } from "@material-ui/core";
import { topHeaderBanner, sideBarBanner } from './assets';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import SideNavbarWeb from '../../../components/src/SideNavbarWeb';
import HealthBasketList from '../../../components/src/healthComponents/HealthBasketList';
import { withTranslation } from 'react-i18next';


const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '3.5rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        // css for textContainer
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        searchResultText: {
            fontFamily: `'Montserrat', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "33px",
            color: "#37006E",
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "12px",
            },
        },
        healthPointText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF7900",
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        FoodTypeUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        // css for foodBasketContainer {rf}
        foodBasketContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },

        NoProductNofificationText: {
            color: '#FF3939',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '23px',
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px',
            },
        },
        favTopBanner: {
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "7px"
            },
        },
    });

class HealthyFoodBasketWeb extends HealthyFoodBasketWebController {
    // Customizable Area Start
    render() {
        const {
            foodBasketListLoader,
            foodBasketLists
        } = this.state;
        const { classes, t } = this.props;
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={false} sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>               
                        <img src={topHeaderBanner} alt="bgImage" style={{ width: "100%" }} className={classes.favTopBanner} />
                        <div className={classes.textContainer}>
                            <Typography className={classes.searchResultText}>
                                <span className={classes.FoodTypeUnderline}>{t("Healthy_Basket")}</span>
                            </Typography>
                            <Typography className={classes.healthPointText}>
                                {t("Health_Points")} : --
                            </Typography>
                        </div>
                        <div className={classes.foodBasketContainer}>
                            {
                                this.state.noFoodBasketNotification && <Typography className={classes.NoProductNofificationText}>{t("No_products_added_here")}, <Link to="/">{t("Create_one")}</Link> </Typography>
                            }
                            {
                                foodBasketListLoader && !this.state.noFoodBasketNotification ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> : foodBasketLists.map((item: any, key: any) => {
                                    return (
                                        <HealthBasketList item={item} redirectPath='HealthyFoodDetails' deleteFunction={this.deleteFoodBasket} key={key} />
                                    )
                                })
                            }
                        </div>
                    </Grid >
                </Grid >

            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('HealthyFoodBasketWebLang')(HealthyFoodBasketWeb)
);