import React, { useState } from 'react';
import {
  Button,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useEffect } from 'react';
import Loader from '../../../components/src/Loader';
import { useTranslation } from "react-i18next";


const useStyles: any = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0, 17),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(0, 13),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0, 9),
    },
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(0, 12),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 44,
    backgroundColor: '#38006D',
    color: '#ffff',
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 600,
    padding: '15px 0',
    '&:hover': {
      background: '#38006D',
    },
  },
  // css for signupTitleText
  signupTitleText: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 600,
    fontSize: '35px',
    lineHeight: '43px',
    color: '#37006E',
    paddingBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      lineHeight: '33px',
    },
  },

  tms_and_condi_text: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 500,
    fontSize: '20px',
    textAlign: 'center',
    color: '#37474F',
    margin: '2rem 0',
    [theme.breakpoints.only('xs')]: {
      fontSize: '11px',
      margin: '1.2rem 0',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '14px',
      margin: '1.3rem 0',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '18px',
      margin: '1.2rem 0',
    },
  },
  t_c_policy_text: {
    color: '#ec6b47',
  },
}));

interface Props {
  userProfileUpdate: (full_name: string, email: string, gender: string) => void;
  navigationBtnFnc: (path: any) => void;
  loading: boolean;
}

const DescribeInputPage = ({ userProfileUpdate, navigationBtnFnc, loading }: Props) => {
  const classes = useStyles();
  const [t] = useTranslation(["translation", "signUpLoginLng"]);
  const [fName, setFName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [gender, setGender] = useState('');
  const [inputError, setInputError] = useState(false)
  const [disabledEmailInput, setDisabledEmailInput] = useState(false)
  const [emailError, setemailError] = useState(false)
  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user) {
      const { name, email } = JSON.parse(user)
      if (email !== null && name !== null) {
        setDisabledEmailInput(true)
        setFName(name)
        setUserEmail(email)
      }
    }
  }, [])

  const handleEmailChange = (e: any) => {
    let email = e.currentTarget.value as string;
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailArray = email.split("@");
    !regex.test(email)
      ? setemailError(true)
      : setemailError(false)
    setUserEmail(email)
  };

  const handelProfileData = (e: any) => {
    e.preventDefault();
    if (fName === '' || userEmail === '' || gender === '') {
      setInputError(true);
      setemailError(false)
    } else {
      userProfileUpdate(fName, userEmail, gender);
      setFName('');
      setUserEmail('');
      setGender('');
      setInputError(false)
      setDisabledEmailInput(false)
    }
  };
  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <Loader loading={loading} />
      </Grid>
    );
  }

  return (
    <div className={classes.paper}>
      <Typography
        component="h1"
        variant="h5"
        className={classes.signupTitleText}
      >
        {t("signUpLoginLng:DescribeYourSelf")}
      </Typography>
      <form onSubmit={handelProfileData} className={classes.form} noValidate>
        <TextField
          margin="normal"
          fullWidth
          id="name"
          label={t("signUpLoginLng:FullName")}
          name="name"
          autoComplete="name"
          value={fName}
          // disabled={fName ? true : false}
          // {fullName&& disable}
          autoFocus
          onChange={(e: any) => setFName(e.target.value)}
        />
        <TextField
          margin="normal"
          type="email"
          fullWidth
          label={t("signUpLoginLng:EmailID")}
          value={userEmail}
          disabled={disabledEmailInput}
          autoComplete="email"
          // autoFocus
          onChange={(e: any) => handleEmailChange(e)}
        />
        {emailError &&
          <p style={{ color: 'red', textAlign: 'left' }}>Please enter valid email.</p>

        }
        <FormControl fullWidth margin="normal" component="fieldset">
          <FormLabel style={{ textAlign: 'left' }}>{t("signUpLoginLng:Gender")}</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender"
            row
            // value={value}
            onChange={(e: any) => setGender(e.target.value)}
          >
            <FormControlLabel value="male" control={<Radio />} label={t("signUpLoginLng:Male")} />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label={t("signUpLoginLng:Female")}
            />

            <FormControlLabel value="other" control={<Radio />} label={t("signUpLoginLng:Other")} />
          </RadioGroup>
        </FormControl>
        {inputError && <Alert severity="error">Please fill up all field!</Alert>}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
        >
          Continue
        </Button>
      </form>
      <div>
        <Typography className={classes.tms_and_condi_text}>
          {t("signUpLoginLng:T&CPrivacyPolicy1")}
          <span className={classes.t_c_policy_text}> <Link style={{ cursor: 'pointer', color: '#ec6b47' }} onClick={() => navigationBtnFnc('TermsAndConditionsWeb')}>{t("signUpLoginLng:T&CPrivacyPolicy2")}</Link> <Link style={{ cursor: 'pointer', color: '#ec6b47' }} onClick={() => navigationBtnFnc('ContentManagementPrivacyPolicy')} >{t("signUpLoginLng:T&CPrivacyPolicy3")}  </Link>
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default DescribeInputPage;
