import React from 'react';
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    foodBasketTypeTextField: {
        height: '55px',
        border: "double 1px transparent",
        borderRadius: 8,
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(205deg,#41479B,#FF3939)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    selectInput: {
        padding: '0 0.7rem'
    },
    selectRoot: {
        '&:focus': {
            backgroundColor: 'transparent'
        },
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    menuItemText: {
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 500,
        fontStyle: "normal",
        height: '100%',
        backgroundColor: 'transparent',
    }
}));


const ActivityLevelSelectField = ({ instance, ActivityLevel, MenuProps }: any) => {
    const classes = useStyles();
    const [t] = useTranslation(["UserProfilePageWebLang"]);
    return (
        <div className={classes.foodBasketTypeTextField}>
            <Select
                disableUnderline
                data-testid="activityField"
                value={instance.state.activityLevel}
                onChange={(event: React.ChangeEvent<{ value: any }>) => instance.handelActivityLevelSelectField(event)}
                fullWidth
                className={`${classes.selectInput} ${classes.menuItemText}`}
                classes={{ root: classes.selectRoot }}
                MenuProps={MenuProps}
            >
                <MenuItem value="Select" className={classes.menuItemText}>
                    Select
                </MenuItem>
                {ActivityLevel?.map((item: any, key: any) => {
                    return (
                        <MenuItem
                            key={key}
                            value={item.value}
                            className={classes.menuItemText}
                        >
                            {item.label}
                        </MenuItem>
                    )
                })}
            </Select>
        </div>
    )
}

export default ActivityLevelSelectField

