import React from "react";
//Customise area start
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagenationContainer: {
      display: "flex",
      listStyleType: "none",
    },
    pageClassName: {
      color: "#808080",
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '117.5%',
      textAlign: 'center',
      cursor: "pointer",
      display: "flex",
    },
    pageLinkClassName: {
      cursor: "pointer",
      margin: 5,
    },
    previousClassName: {
      background: "#FFFFFF",
      border: "0.2px solid #AAA7A7",
      borderRadius: "2px",
      cursor: "pointer",
      padding: 4
    },
    previousLinkClassName: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 600,
      fonSsize: '10px',
      lineHeight: '117.5%',
      textAlign: "center",
      color: "#000000",
    },
    activeClassName: {
      color: "#05727D",
    },
    disabledLinkClassName: {
      color: '#ADADAD',
    },


  })
);
interface Props {
  pageCount: any;
  pageChange: any;
  pageNumber: any
}
//Customise area stop

const PaginationWeb = ({ pageCount, pageChange, pageNumber, }: Props) => {
  //Customise area start
  const classes = useStyles();
  const [t] = useTranslation(["searchLng"]);
  let forcepage:any = pageNumber - 1;
  return (
    // Customise area start
    <>   
    {/* @ts-ignore */}
   <ReactPaginate
                    previousLabel={t("Prev")}
                    breakLabel="..."
                    nextLabel={t("Next")}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    onPageChange={pageChange}
                    containerClassName={classes.pagenationContainer}
                    pageClassName={classes.pageClassName}
                    pageLinkClassName={classes.pageLinkClassName}
                    previousClassName={classes.previousClassName}
                    previousLinkClassName={classes.previousLinkClassName}
                    nextClassName={classes.previousClassName}
                    nextLinkClassName={classes.previousLinkClassName}
                    breakClassName={classes.pageClassName}
                    breakLinkClassName={classes.pageLinkClassName}
                    activeClassName={classes.activeClassName}
                    disabledLinkClassName={classes.disabledLinkClassName}
                    // initialPage={forcepage}
                    forcePage={forcepage}

      />
    </>
    //Customise area stop  
  );
};
export default PaginationWeb;
